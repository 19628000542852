import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveFinalAuthLinkedin } from "../../redux/actions/authActions";
import { successAlert } from "../../utils/sweetAlert";
function LinkedInAccountSelection(props) {
  const dispatch = useDispatch();
  const [linkedinAccDetails, setLinkedinAccDetails] = React.useState([]);
  useEffect(() => {
    console.log("first");
    if (!props.authJSON) {
      console.log("Returning");
      console.log(props);
      return;
    }
    if (props.authJSON.authFinalized) {
      props.setLinkedinAccountSelectionModal(false);
    }
    var tmpOrgs = [];
    for (let i = 0; i < props.authJSON?.organizations?.length; i++) {
      if (props.authJSON.organizations[i].id == props.authJSON.selectedOrganization?.id) {
        props.authJSON.organizations[i].selected = true;
      }
      props.authJSON.organizations[i].type = "organization";
      tmpOrgs.push(props.authJSON.organizations[i]);
    }
    tmpOrgs.push({
      localizedName: props.authJSON.localizedFirstName + " " + props.authJSON.localizedLastName,
      id: props.authJSON.id,
      selected: props.authJSON.authMode == "personal" ? true : false,
      type: "personal",
    });
    setLinkedinAccDetails(tmpOrgs);
    // console.log(tmpOrgs);
  }, [props]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    var selectedId = event.target.checkboxDefault.value;
    var selectedAccount = linkedinAccDetails.find((account) => account.id == selectedId);
    const res = await dispatch(
      await saveFinalAuthLinkedin({
        authMode: selectedAccount.type,
        organizationId: selectedId,
        profileId: props.profileId,
      })
    );
    if (res) {
      props.setLinkedinAccountSelectionModal(false);
      successAlert({
        title: "LinkedIn Account Added Successfully",
      });
      window.location.reload();
    }
  };
  return (
    <>
      <div
        className="fixed inset-0 z-[99] min-h-screen min-w-screen flex justify-center items-center"
        aria-label="modal"
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[90]"
          onClick={() => props.setLinkedinAccountSelectionModal(false)}
        ></div>
        <div className="relative z-[99] bg-white rounded-[10px] p-7 w-[450px] show-modal">
          <div
            className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
            onClick={() => props.setLinkedinAccountSelectionModal(false)}
          >
            &times;
          </div>
          <h3 className="text-lg font-semibold ">Which account whould you like to add?</h3>
          <p className="text-md text-[#6B7280]">
            <span className="text-red-500">*</span>You can connect only 1 LinkedIn account.
          </p>
          <hr />
          <form action="" onSubmit={handleFormSubmit}>
            <div className="items-center mt-3 ">
              {linkedinAccDetails.map((account, index) => (
                <div className="mb-3 block min-h-[1.5rem] pl-[1.5rem] " key={account.id}>
                  <input
                    className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[0.8rem] w-[1.125rem]"
                    type="radio"
                    name="checkboxDefault"
                    id="checkboxDefault"
                    value={account.id}
                  />
                  <div className="">
                    <p className="pl-[0.15rem] p-0 m-0 leading-tight text-md font-semibold hover:cursor-pointer">
                      {account.localizedName}
                    </p>
                    <p className="pl-[0.15rem] p-0 m-0 leading-tight text-sm text-gray-500 hover:cursor-pointer">
                      {account.type == "personal" ? "Personal" : "Organization"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center">
              <button
                className={`py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#D1D5DB] flex items-center justify-center transition duration-300`}
                onClick={() => props.setLinkedinAccountSelectionModal(false)}
              >
                <span className={`text-sm font-[500] text-semibold text-[#111928]`}>Cancel</span>
              </button>
              <button
                type="submit"
                className={`py-[10px] px-[15px] w-fit rounded-[12px]  border-0 flex items-center justify-center transition duration-300`}
                style={{ backgroundColor: "#0A66C2", border: "1px solid #0A66C2" }}
              >
                <span className={`text-sm font-[500] text-semibold text-[#fff]`}>Add to Wordout</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LinkedInAccountSelection;
