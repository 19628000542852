import axios from "axios";

export const getTeamData = (currentProfile) => async (dispatch) => {
  try {
    dispatch({ type: "TEAM_LOADING_TRUE", payload: true });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/team/get-all-members`,
      {
        profileId: currentProfile.id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status) {
      dispatch({ type: "ADD_TEAM_DATA", payload: data.members });
      dispatch({ type: "TEAM_LOADING_FALSE", payload: false });

      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });

    return false;
  }
};
