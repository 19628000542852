import axios from "axios";

const createAxiosCall = (url, data) => {
  try {
    const request = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/track`,
      data,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    request(url, data);
  } catch (error) {
    console.log(error);
  }
};

export const updateTwitterPost = async (id, data) => {
  createAxiosCall("/twitter/update", { data: { id, data } });
};

export const updateLinkedinPost = async (id, data) => {
  createAxiosCall("/linkedin/update", { data: { id, data } });
};

export const updateConvertkitPost = async (id, data, block, emailData) => {
  createAxiosCall("/convertkit/update", { data: { id, data, block, emailData } });
};
export const updateWebflowPost = async (id, data, block, columnMeta) => {
  createAxiosCall("/webflow/update", { data: { id, data, block, columnMeta } });
};

const saveAllPosts = async () => {};

export default saveAllPosts;
