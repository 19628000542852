import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error (can also report to error reporting service)
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // This will be the fallback UI
      return <h1 className={`text-center my-5 ${this.props.className}`}>Sorry, something went very wrong...</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
