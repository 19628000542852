export const toggleAddCompanyModal = (state) => {
  if (state === true) {
    return {
      type: "OPEN_ADD_COMPANY_MODAL",
    };
  }

  return {
    type: "CLOSE_ADD_COMPANY_MODAL",
  };
};

export const toggleRescheduleTrackModal = (state, trackId = null) => {
  if (state === true) {
    return {
      type: "OPEN_RESCHEDULE_TRACK_MODAL",
      payload: { trackId },
    };
  }

  return {
    type: "CLOSE_RESCHEDULE_TRACK_MODAL",
  };
};
