export const items = [
  {
    value: "Everyday",
    label: "Everyday",
  },
  {
    value: "Monday",
    label: "Monday",
  },
  {
    value: "Tuesday",
    label: "Tuesday",
  },
  {
    value: "Wednesday",
    label: "Wednesday",
  },
  {
    value: "Thursday",
    label: "Thursday",
  },
  {
    value: "Friday",
    label: "Friday",
  },
  {
    value: "Saturday",
    label: "Saturday",
  },
  {
    value: "Sunday",
    label: "Sunday",
  },
];
export const platformItems = [
  {
    key: "twitter",
    value: "twitter",
    href: "/twitter",
    label: "Twitter",
    title: "Twitter",
  },
  {
    key: "linkedin",
    value: "linkedin",
    href: "/linkedin",
    label: "Linkedin",
    title: "Linkedin",
  },
  {
    key: "instagram",
    value: "instagram",
    href: "/instagram",
    label: "Instagram",
    title: "Instagram",
  },
];
