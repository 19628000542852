import axios from "axios";

export const getSchedule = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/schedule/get-all`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (data) {
      dispatch({ type: "ADD_ALL_SCHEDULES", payload: data });
      console.log(data);

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return true;
    } else {
      return false;
    }
  } catch (error) {}
};
export const updateSchedule = (profileId, platform, json) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/schedule/update`,
      {
        profileId: profileId,
        platform: platform,
        json: json,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data) {
      dispatch({ type: "UPDATE_SCHEDULE", payload: data });
      console.log(data);

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return true;
    } else {
      return false;
    }
  } catch (error) {}
};
export const addtoQueue = (platform, trackId) => async (dispatch) => {};
