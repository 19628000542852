import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, getDay, parse, startOfWeek } from "date-fns";
import EventComponent from "./CalenderEvent";
import { useEffect, useState } from "react";
import TwitterPreview from "../StagePreview/TwitterPreview";
import LinkedInPreview from "../StagePreview/LinkedinPreview";
import InstagramPreview from "../StagePreview/InstagramPreview";

import { Modal } from "antd";

const locales = {
  "en-US": import("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// function getRandomColor() {
//   const letters = "0123456789ABCDEF";
//   let color = "#";
//   let brightness;

//   do {
//     color = "#";
//     brightness = 0;
//     for (let i = 0; i < 6; i++) {
//       const letter = letters[Math.floor(Math.random() * 16)];
//       color += letter;

//       if (i % 2 === 1) {
//         brightness += parseInt(letter, 16);
//       }
//     }
//   } while (brightness > 40); // Adjust the value to avoid shades of white

//   return color;
// }
const ScheduleCalender = (props) => {
  const { tracks, user } = props;
  const [item, setItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [platform, setplatform] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
  });
  useEffect(() => {
    // if (isAuthenticated === true) {

    if (props.profile) {
      var profiles = props.profile;

      var socialAccounts = profiles.socialAccounts;
      var tempselectedplatform = {
        twitter: false,
        linkedin: false,
        instagram: false,
      };

      for (let j = 0; j < socialAccounts.length; j++) {
        if (socialAccounts[j].accountType === "twitter") {
          tempselectedplatform.twitter = socialAccounts[j].authJSON;
          // console.log("found twitter");
          // console.log(socialAccounts[j].authJSON);
        }
        if (socialAccounts[j].accountType === "linkedin") {
          tempselectedplatform.linkedin = socialAccounts[j].authJSON;
        }
        if (socialAccounts[j].accountType === "instagram") {
          tempselectedplatform.instagram = socialAccounts[j].authJSON;
        }
      }

      setplatform(tempselectedplatform);
    }

    // }
  }, [props.profile]);
  // const events = tracks.map((track) => ({
  //   title: track.draft.body_json.title,
  //   start: new Date(track.scheduledTime),
  //   end: new Date(track.scheduledTime),
  //   backgroundColor: "#5850EC",
  //   textColor: "white",
  //   track,
  // }));
  // console.log(props.profile);
  var newevents = [];
  var temp = [];
  for (const track of tracks) {
    // console.log(track);
    var backgroundColor = track.backgroundColor ? track.backgroundColor : "#5850EC"; // Use the random color generator function
    var textColor = "white";
    for (const li of track.linkedInPost) {
      if (li.status !== "scheduled") continue;
      temp.push({
        linkedInPost: li,
        title: track.draft.body_json.title,
        start: new Date(li.scheduledTime),
        end: new Date(li.scheduledTime),
        backgroundColor: backgroundColor,
        textColor: "white",
        track,
        type: "linkedin",
      });
    }
    for (const fb of track.instaPost) {
      if (fb.status !== "scheduled") continue;
      temp.push({
        instaPost: fb,
        title: track.draft.body_json.title,
        start: new Date(fb.scheduledTime),
        end: new Date(fb.scheduledTime),
        backgroundColor: backgroundColor,
        textColor: "white",
        track,
        type: "instagram",
      });
    }
    for (const tw of track.twitterPost) {
      if (tw.status !== "scheduled") continue;
      temp.push({
        twitterPost: tw,
        title: track.draft.body_json.title,
        start: new Date(tw.scheduledTime),
        end: new Date(tw.scheduledTime),
        backgroundColor: backgroundColor,
        textColor: "white",
        track,
        type: "twitter",
      });
    }
    newevents = newevents.concat(temp);
  }
  const events = tracks.map((track) => ({
    title: track.draft.body_json.title,
    start: new Date(track.scheduledTime),
    end: new Date(track.scheduledTime),
    backgroundColor: getRandomColor(), // Use the random color generator function
    textColor: "white",
    track,
  }));
  return (
    <>
      <Modal
        open={showModal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <div className="py-3"></div>
        {item && item.type === "twitter" && (
          <TwitterPreview
            track={item.track}
            data={item}
            profile={platform}
            isInsideRescheduleModal={true}
            isdeleteActive={false}
          />
        )}
        {item && item.type === "linkedin" && (
          <LinkedInPreview
            track={item.track}
            data={item}
            profile={platform}
            isInsideRescheduleModal={true}
            isdeleteActive={false}
          />
        )}
        {item && item.type === "instagram" && (
          <InstagramPreview
            track={item.track}
            data={item}
            profile={platform}
            isInsideRescheduleModal={true}
            isdeleteActive={false}
          />
        )}
      </Modal>
      <Calendar
        localizer={localizer}
        events={temp}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800, width: "100%" }}
        eventPropGetter={(myEventsList) => {
          const backgroundColor = myEventsList.backgroundColor ? myEventsList.backgroundColor : "blue";
          const color = myEventsList.textColor ? myEventsList.textColor : "white";
          return { style: { backgroundColor, color } };
        }}
        components={{
          event: EventComponent,
          eventWrapper: ({ event, children }) => {
            return (
              <li
                style={{
                  listStyleType: "none",
                  maxHeight: "auto",
                  height: "auto",
                  display: "block",
                }}
                onClick={(e) => {
                  setItem(event);
                  setShowModal(true);
                  // alert(`${event.title} is clicked.`);
                  e.preventDefault();
                }}
              >
                {children}
              </li>
            );
          },
        }}
      />
    </>
  );
};

export default ScheduleCalender;
