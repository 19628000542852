import { useState } from "react";
import InstagramMediaReorder from "./InstagramMediaReorder";
import instaDefault from "../../assets/Images/Navbar/instagram_default.jpg";
import Loader from "../utils/Loader";

const InstagramMediaPreview = (props) => {
  const {
    user,
    uploadedFiles,
    setUploadedFiles,
    setInstagramStage,
    uploadFiles,
    isCurrentlyUploading,
    updateMarkAsDraft,
  } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [reorderGallery, setReorderGallery] = useState(false);

  return (
    <>
      <div className="flex justify-between items-center p-[15px]">
        <div className="flex items-center">
          <img
            src={instaDefault}
            alt={user.fullName}
            className="mr-[15px] rounded-full w-[32px] h-[32px]"
          />

          <span className="text-[#27272A] font-semibold text-[16px]">
            {props.profile?.instagram
              ? props.profile.instagram.instaDetails.username
              : user && user?.fullName}
          </span>
        </div>
        <div>
          <span
            className="text-[12px] text-[#3f3f46] font-normal cursor-pointer underline"
            onClick={() => setInstagramStage("upload")}
          >
            Change Images
          </span>
        </div>
      </div>
      {isCurrentlyUploading ? (
        <div className="w-full h-[450px] flex justify-center items-center">
          <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
        </div>
      ) : (
        <div className="w-full h-[450px] relative overflow-hidden">
          {/* Slider control button */}
          {currentSlide > 0 && (
            <div
              className="h-[25px] w-[25px] rounded-full bg-[rgba(26,26,26,.8)] absolute top-[50%] left-[10px] z-10 flex justify-center items-center cursor-pointer"
              onClick={() => setCurrentSlide(currentSlide - 1)}
            >
              <i className="fa fa-chevron-left ml-[-2px] text-[12px] text-[#fff]"></i>
            </div>
          )}
          {currentSlide < uploadedFiles.length - 1 && (
            <div
              className="h-[25px] w-[25px] rounded-full bg-[rgba(26,26,26,.8)] absolute top-[50%] right-[10px] z-10 flex justify-center items-center cursor-pointer"
              onClick={() => setCurrentSlide(currentSlide + 1)}
            >
              <i className="fa fa-chevron-right mr-[-2px] text-[12px] text-[#fff]"></i>
            </div>
          )}

          {/* Slider media */}
          {uploadedFiles.map((media, i) => {
            const translateNumber = (i - currentSlide) * 100;
            const mediaUrl = media.url
              ? media.url
              : window.URL.createObjectURL(media);

            return (
              <div
                className="absolute top-0 left-0 w-full h-full object-contain duration-500"
                style={{ transform: `translateX(${translateNumber}%)` }}
                key={i}
              >
                <img
                  src={mediaUrl}
                  alt={media.name}
                  className="w-full h-full object-contain"
                />
              </div>
            );
          })}

          {reorderGallery && (
            <InstagramMediaReorder
              updateMarkAsDraft={updateMarkAsDraft}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              setCurrentSlide={setCurrentSlide}
              setInstagramStage={setInstagramStage}
              uploadFiles={uploadFiles}
            />
          )}

          <div
            className="h-[32px] w-[32px] rounded rounded-full p-[8px] cursor-pointer bg-[rgba(26,26,26,.8)] absolute bottom-[20px] right-[10px]"
            onClick={() => setReorderGallery(!reorderGallery)}
          >
            <svg
              aria-label="Open media gallery"
              color="#ffffff"
              fill="#ffffff"
              height="16"
              role="img"
              viewBox="0 0 24 24"
              width="16"
            >
              <path
                d="M19 15V5a4.004 4.004 0 0 0-4-4H5a4.004 4.004 0 0 0-4 4v10a4.004 4.004 0 0 0 4 4h10a4.004 4.004 0 0 0 4-4ZM3 15V5a2.002 2.002 0 0 1 2-2h10a2.002 2.002 0 0 1 2 2v10a2.002 2.002 0 0 1-2 2H5a2.002 2.002 0 0 1-2-2Zm18.862-8.773A.501.501 0 0 0 21 6.57v8.431a6 6 0 0 1-6 6H6.58a.504.504 0 0 0-.35.863A3.944 3.944 0 0 0 9 23h6a8 8 0 0 0 8-8V9a3.95 3.95 0 0 0-1.138-2.773Z"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default InstagramMediaPreview;
