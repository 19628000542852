import React, { useEffect } from "react";
import Modal from "../../utils/Modal";
import { set } from "lodash";
import { useDispatch } from "react-redux";
import { saveWebFlowConnection } from "../../../redux/actions/trackActions";
import { failAlert } from "../../../utils/sweetAlert";

const CollectionsModal = (props) => {
  const { sites, collections } = props.webflowCollections;
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const [selectedSite, setSelectedSite] = React.useState(sites[0]._id);
  const [selectedCollection, setSelectedCollection] = React.useState(null);
  const [columnMap, setColumnMap] = React.useState({
    title: null,
    content: null,
  });
  useEffect(() => {
    if (selectedSite) {
      setSelectedCollection(Object.keys(collections[selectedSite])[0]);
    }
  }, [selectedSite]);
  const submitData = async (e) => {
    e.preventDefault();
    if (step === 2) {
      console.log(e);
      console.log(e.target.title.value);
      console.log(e.target.content.value);

      const res = await dispatch(
        await saveWebFlowConnection(
          selectedSite,
          selectedCollection,
          {
            title: e.target.title.value,
            content: e.target.content.value,
          },
          props.profileId,
          props.webflowCollections
        )
      );

      props.setIsCollectionsModalHidden(true);
      props.setconnectedPlatform({ ...props.connectedPlatform, webflow: true });
    } else {
      setStep(step + 1);
    }
  };
  return (
    <div>
      {!props.IsCollectionsModalHidden && (
        <Modal setisModalHidden={props.setIsCollectionsModalHidden}>
          <form action="" onSubmit={submitData}>
            {step === 0 && (
              <div>
                <p>Select Correct Site.</p>
                <select name="" id="" onChange={(e) => setSelectedSite(e.target.value)} defaultValue={sites[0]._id}>
                  <option disabled={true} value="null">
                    Select Site
                  </option>
                  {sites.map((site) => (
                    <option key={site._id} value={site._id}>
                      {site.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {step === 1 && (
              <div>
                <p>Select Correct Collection.</p>
                <select name="" id="" onChange={(e) => setSelectedCollection(e.target.value)}>
                  {collections[selectedSite] &&
                    Object.values(collections[selectedSite]).length > 0 &&
                    Object.values(collections[selectedSite]).map((collection) => (
                      <option key={collection._id} value={collection._id}>
                        {collection.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {step === 2 && (
              <div>
                <p>Map Fields</p>

                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <p className="mr-2 w-fit">Title:</p>
                    <select
                      name="title"
                      id=""
                      className="h-8"
                      required={true}
                      onChange={(e) =>
                        setColumnMap({
                          ...columnMap,
                          title: e.target.value,
                        })
                      }
                    >
                      <option value="null" disabled={true}>
                        Select Field
                      </option>
                      {collections[selectedSite] &&
                        collections[selectedSite][selectedCollection].fields.map((field) => (
                          <option key={field.slug} value={field.slug}>
                            {field.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="flex flex-row">
                    <p className="mr-2">Content:</p>
                    <select
                      name="content"
                      id=""
                      className="h-8"
                      required={true}
                      onChange={(e) =>
                        setColumnMap({
                          ...columnMap,
                          content: e.target.value,
                        })
                      }
                    >
                      <option value="null" disabled={true}>
                        Select Field
                      </option>
                      {collections[selectedSite] &&
                        collections[selectedSite][selectedCollection].fields.map((field) => (
                          <option key={field.slug} value={field.slug}>
                            {field.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-between item-center mt-4">
              <button
                type="button"
                className="my-auto"
                onClick={() => setStep(step - 1)}
                style={{
                  borderRadius: "12px",
                  padding: "4px 8px 4px 8px",
                  border: "1px solid #D1D5DB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "80px",
                  height: "40px",
                }}
              >
                <div className="text-sm font-semibold text-[#111928]">{"Back"}</div>
              </button>
              <button
                type="submit"
                className=" ml-auto"
                style={{
                  borderRadius: "12px",
                  padding: "9px 17px 9px 17px",
                  border: "1px solid #1C64F2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#1C64F2",
                  width: "100px",
                }}
              >
                <svg
                  className="mr-3"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z"
                    fill="white"
                    stroke="white"
                  />
                </svg>
                <div className="text-sm font-semibold text-[white]">Next</div>
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};
export default CollectionsModal;
