const INITIAL_NOTIFICATIONS_STATE = {
  notifications: [],
  newNotifications: false,
};

export const notificationsReducer = (state = INITIAL_NOTIFICATIONS_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_NOTIFICATIONS":
      const newNotifications = payload.find(
        (notification) => notification.status === "unread"
      );

      return {
        notifications: payload,
        newNotifications: newNotifications ? true : false,
      };
    case "READ_ALL_NOTIFICATIONS":
      return {
        ...state,
        newNotifications: false,
      };
    default:
      return state;
  }
};
