import { useDispatch } from "react-redux";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitterIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedinIcon.svg";
import TimesWatched from "./TimesWatched";
import { FaRobot } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tooltip } from "antd";
const LibraryUnapprovedTweets = (props) => {
  const { item, profileId, isSelected } = props;
  const dispatch = useDispatch();
  const selectClickHandler = () => {
    console.log(isSelected);
    if (isSelected) {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
    } else {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: item.text });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: props.item.id });
    }
  };

  const formatedDate = (given) => {
    var newData = new Date();
    var givenDate = new Date(given);
    // Calculate the time difference in milliseconds
    var diffMs = newData - givenDate;
    // Calculate the time difference in seconds, minutes, hours, and days
    var diffSeconds = Math.floor(diffMs / 1000);
    var diffMinutes = Math.floor(diffMs / (1000 * 60));
    var diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    var diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    // Format the output based on the duration of the time difference
    if (diffSeconds < 60) {
      return diffSeconds + " seconds ago";
    } else if (diffMinutes < 60) {
      return diffMinutes + " minutes ago";
    } else if (diffHours < 24) {
      return diffHours + " hours ago";
    } else if (diffDays == 1) {
      return "Yesterday";
    } else if (diffDays < 7) {
      return diffDays + " days ago";
    } else if (diffDays < 30) {
      return Math.floor(diffDays / 7) + " weeks ago";
    } else if (diffDays < 365) {
      return Math.floor(diffDays / 30) + " months ago";
    }
  };

  // console.log(item.type);
  if (item.status === "PROCESSING") {
    return (
      <div className="">
        <div
          className={`min-h-[8rem] bg-white border-[2px]  opacity-70 
        rounded-[3px]   w-full p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}
        >
          {/* {thread.tweet.map((item, index) => (
          <div key={item.id}>
            <p className="font-[500]">{textlength < 160 ? item.text : item.text.substring(0, 160)}</p>
            {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />}
          </div>
        ))} */}
          <Skeleton count={3} />

          <div>
            <div className="text-[#1F2A37] flex text-[12px] mt-[10px]">
              processing | {formatedDate(item.updatedAt)}
              {item.type == "unapproved_tweet" && (
                <Tooltip title={"Unapproved Tweets"}>
                  <div className="flex gap-[4px] bg-[#ebcac5] py-[4px] px-[6px]  rounded-[12px] ml-auto mr-[10px]">
                    <TwitterIcon className="w-[14px] h-[14px] text-red-300" fill="#eb3a1e" />
                    {/* <LinkedInIcon className="w-[14px] h-[14px]" fill="#1C64F2" /> */}
                  </div>
                </Tooltip>
              )}
              {/* <TimesWatched timesWatched={3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`bg-white border-[2px] 
      ${isSelected ? "border-[#1C64F2]" : "border-[#e4e4e7]"} 
      rounded-[3px] shadow-[0_1px_2px_rgba(0,0,0,0.06)] cursor-pointer w-full p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between min-h-[7.5rem]`}
      onClick={selectClickHandler}
    >
      <p className="font-[500] library-clamped-text">
        {item && item.tweet && item.tweet.length > 0 && item.tweet[0] && item.tweet[0].substring(0, 80)}{" "}
        {item && item.tweet && item.tweet.length > 0 && item.tweet[0] && item.tweet[0].length > 80 && "..."}
      </p>
      <div className="text-[#1F2A37] flex text-[12px] mt-[20px] ">
        <div>
          {item && item.text
            ? item.text.split(/\s+/).length + " words |"
            : item.status == "PROCESSING" && "processing |"}{" "}
          {formatedDate(item.updatedAt)}
        </div>

        {item.type == "unapproved_tweet" && (
          <div className="flex gap-2 ml-auto items-center my-auto bg-[#ebcac5] py-[5px] px-[6px]  rounded-[12px] mr-[10px]">
            <Tooltip title={"AI Generated"}>
              <FaRobot size={14} color="#373737" />
            </Tooltip>
            <Tooltip title={"Unapproved Tweets"}>
              <TwitterIcon className="w-[14px] h-[14px] text-red-300" fill="#eb3a1e" />
            </Tooltip>

            {/* <LinkedInIcon className="w-[14px] h-[14px]" fill="#1C64F2" /> */}
          </div>
        )}
        {/* <TimesWatched timesWatched={3} /> */}
      </div>
    </div>
  );
};

export default LibraryUnapprovedTweets;
