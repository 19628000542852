const INITIAL_DRAFT_STATE = {
  twitter: true,
  instagram: true,
  linkedin: true,
};

export const saveAsDraftReducer = (state = INITIAL_DRAFT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case "SET_TWITTER_AS_DRAFT":
      return {...state, twitter: true };

    case "SET_INSTAGRAM_AS_DRAFT":
      return {...state, instagram: true };

    case "SET_LINKEDIN_AS_DRAFT":
      return {...state, linkedin: true };

    case "REMOVE_TWITTER_AS_DRAFT":
      return {...state, twitter: false };

    case "REMOVE_INSTAGRAM_AS_DRAFT":
      return {...state, instagram: false };

    case "REMOVE_LINKEDIN_AS_DRAFT":
      return {...state, linkedin: false };

    default:
      return state;
  }
};