export default class FBApi {
  constructor() {
    this.authData = {};
  }

  FacebookLoginApi(setfacebookAuthrespone) {
    window.FB.login(
      function (response) {
        if (response && !response.error) {
          console.log("Got access\n", response);
          setfacebookAuthrespone(response);
          this.authData = response;
        }
      },
      { scope: "public_profile,email,pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement" }
    );
  }
  FacebookGetAllPagesApi(fbId, access_token, setFbPageData) {
    window.FB.api("/" + fbId + "/accounts", "get", { access_token: access_token }, function (response) {
      if (response && !response.error) {
        console.log("Some page api response\n", JSON.stringify(response));
        setFbPageData(response["data"]);
      } else {
        console.error("Some error\n", JSON.stringify(response));
      }
    });
  }

  async FacebookInstagramIdApi(fbPageData, access_token, setInstaAccDetails, setAllResolved) {
    const data = [];
    let counter = 0;

    const getInstagramAccount = (pageId) => {
      return new Promise((resolve, reject) => {
        window.FB.api(
          `/${pageId}?fields=instagram_business_account`,
          "get",
          { access_token },
          async function (response) {
            if (response && !response.error && response["instagram_business_account"]) {
              window.FB.api(
                `/${response["instagram_business_account"]["id"]}?fields=id,username`,
                "get",
                { access_token },
                function (res) {
                  if (res && !res.error) {
                    const instagramData = { ...res, instaId: res.id };
                    data.push({ ...instagramData, ...fbPageData[counter] });
                    setInstaAccDetails(data);

                    counter++;

                    if (counter === fbPageData.length) {
                      setAllResolved(true);
                    }

                    resolve();
                  } else {
                    counter++;
                    resolve();
                  }
                }
              );
            } else {
              counter++;
              resolve();
            }
          }
        );
      });
    };

    const promises = fbPageData.map((page) => getInstagramAccount(page.id));
    await Promise.all(promises);
  }

  SinglePostContainer(instaId, access_token, imgUrl, setContainerId) {
    window.FB.api(
      "/" + instaId + `/media?access_token=${access_token}&image_url=${imgUrl}`,
      "post",
      function (response) {
        if (response && !response.error) {
          console.log("SinglePostContainer response\n", JSON.stringify(response));
          setContainerId(response["id"]);
        } else {
          console.error("Some error\n", JSON.stringify(response));
        }
      }
    );
  }
  SinglePostPublish(instaId, access_token, container_id, setResetImgUrl) {
    window.FB.api(
      "/" + instaId + `/media_publish?access_token=${access_token}&creation_id=${container_id}`,
      "post",
      function (response) {
        if (response && !response.error) {
          console.log("SinglePostContainer response\n", JSON.stringify(response));
          setResetImgUrl(true);
        } else {
          console.error("Some error\n", JSON.stringify(response));
        }
      }
    );
  }
}
