import React, { useState, useEffect } from "react";
import PublishPlatform from "./PublishPlatformIcon";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ConvertkitModal from "./ConvertkitModal";
import WebflowModal from "./webflowModal";
import InstagramAccountSelection from "./InstagramAccountSelection";
import LinkedInAccountSelection from "./LinkedInAccountSelection";
import { useLocation } from "react-router-dom";
import FBApi from "../../utils/facebook/_utils/fb.utils";
import { confirmationModal, failAlert, successAlert } from "../../utils/sweetAlert";
import { getLoggedUserInfo } from "../../redux/actions/authActions";
import PublishingModalFooter from "./PublishingModalFooter";
import { checkConvertkitConnection, checkWebflowConnection } from "../../redux/actions/trackActions";
import CollectionsModal from "./webflowCollections/CollectionsModal";
import { set } from "date-fns";
const platforms = ["twitter", "instagram", "linkedin", "convertkit", "webflow"];

const checkInstagramCorrect = (post) => {
  if (!post) return 0;

  if (!post.body_json?.assets.length) {
    return 0;
  }

  if (post.body_json.texts > 2200) {
    return 0;
  }

  return 1;
};
const PublishingModalContent = (props) => {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [facebookAuthrespone, setfacebookAuthrespone] = useState("");
  const [fbPageData, setFbPageData] = useState([]);
  const [instaSelectedData, setInstaSelectedData] = useState([]);
  const [instaAccDetails, setInstaAccDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [instaAccountSelectionModal, setInstaAccountSelectionModal] = useState(false);
  const [linkedinAccountSelectionModal, setLinkedinAccountSelectionModal] = useState(false);
  const [allResolved, setAllResolved] = useState(false);
  const [isConvertkitModal, setIsConvertkitModal] = useState(false);
  const [isWebflowModal, setIsWebflowModal] = useState(false);
  const [activeTrack, setActiveTrack] = useState(null);
  const [ConvertkitSecret, setConvertkitSecret] = useState("");
  const [webflowSecret, setWebflowSecret] = useState("");
  const [webflowCollections, setWebflowCollections] = useState(null);
  const [IsCollectionsModalHidden, setIsCollectionsModalHidden] = useState(true);

  const dispatch = useDispatch();
  // console.log(user, "user");
  useEffect(() => {
    async function getPages() {
      if (facebookAuthrespone.status == "connected") {
        // console.log("facebookAuthrespone", facebookAuthrespone);
        var fb = new FBApi();
        fb.FacebookGetAllPagesApi(
          facebookAuthrespone.authResponse.userID,
          facebookAuthrespone.authResponse.accessToken,
          setFbPageData
        );
      }
    }

    getPages();
  }, [facebookAuthrespone]);

  useEffect(() => {
    const fetchData = async () => {
      if (fbPageData.length > 0) {
        const fbclass = new FBApi();
        await fbclass.FacebookInstagramIdApi(
          fbPageData,
          facebookAuthrespone.authResponse.accessToken,
          setInstaAccDetails,
          setAllResolved
        );
      }
    };

    (async () => {
      await fetchData();
    })();
  }, [fbPageData]);

  useEffect(() => {
    if (instaAccDetails.length > 0 && allResolved) {
      setInstaAccountSelectionModal(true);
    }
  }, [instaAccDetails, allResolved]);

  const [connectedPlatform, setconnectedPlatform] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    convertkit: false,
    webflow: false,
  });
  const [socialAccountsData, setSocialAccountsData] = useState([]);

  const [selectedPlatform, setSelectedPlatform] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    convertkit: false,
    webflow: false,
  });

  const [isAnyPlatformSelected, setIsAnyPlatformSelected] = useState(
    selectedPlatform.instagram || selectedPlatform.twitter || selectedPlatform.linkedin
  );

  const checkConvertkit = async () => {
    if (ConvertkitSecret) {
      const res = await dispatch(checkConvertkitConnection(ConvertkitSecret, props.profileId));
      if (res) {
        setconnectedPlatform({ ...connectedPlatform, convertkit: true });
        setIsConvertkitModal(false);
      }
    }
  };
  const checkWebflow = async () => {
    if (webflowSecret) {
      const res = await dispatch(checkWebflowConnection(webflowSecret, props.profileId));
      if (res.status) {
        setWebflowCollections(res);
        setIsCollectionsModalHidden(false);

        setIsWebflowModal(false);
      }
    }
  };

  useEffect(() => {
    setIsAnyPlatformSelected(
      selectedPlatform.instagram ||
        selectedPlatform.twitter ||
        selectedPlatform.linkedin ||
        selectedPlatform.convertkit ||
        selectedPlatform.webflow
    );
  }, [selectedPlatform]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/get-track-by-id/`,
          { trackId: Number(props.trackId) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (!data.Track) return;

        if (data.Track?.scheduledPlatform) {
          setScheduledTime(data.Track.scheduledTime);
          var schedules = data.Track.scheduledPlatform;
          if (schedules.instagram || schedules.linkedin || schedules.twitter) {
            setSelectedPlatform(data.Track.scheduledPlatform);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [props.trackId]);

  const markPlatformSelected = (draft, connected) => {
    const instagram = checkInstagramCorrect(draft.instaPost) && true;
    const twitter = draft.status === "composing" ? false : true;
    const linkedin = draft.status === "composing" ? false : true;
    // : instaPoint + Number(draft.linkedInPost.markedAsDraft) + Number(draft.twitterPost.markedAsDraft);
    setSelectedPlatform({
      instagram: !instagram || !connected.instagram ? false : instagram && connected.instagram,
      twitter: !twitter || !connected.twitter ? false : twitter && connected.twitter,
      linkedin: !linkedin || !connected.linkedin ? false : linkedin && connected.linkedin,
    });
  };
  useEffect(() => {
    if (props.draftTracks) {
      for (var i = 0; i < props.draftTracks.length; i++) {
        if (props.draftTracks[i].id === parseInt(props.trackId)) {
          setActiveTrack(props.draftTracks[i]);
        }
      }
    }
  }, [props.draftTracks]);
  useEffect(() => {
    if (isAuthenticated === true) {
      var profiles = user.profiles;
      // console.log("profiles", profiles);
      var platSeletedtrack = {};
      // console.log("profiles", profiles)
      for (var i = 0; i < profiles.length; i++) {
        var righttrack = false;
        if (props.profileId) {
          if (profiles[i].id === parseInt(props.profileId)) {
            righttrack = true;
          }
        }
        if (props.trackId) {
          for (var j = 0; j < profiles[i].tracks.length; j++) {
            if (profiles[i].tracks[j].id === parseInt(props.trackId)) {
              righttrack = true;
              platSeletedtrack = profiles[i].tracks[j];
              // setActiveTrack(platSeletedtrack);
            }
          }
        }
        if (righttrack) {
          var socialAccounts = profiles[i].socialAccounts;
          // console.log("socialAccounts", socialAccounts)
          var tempselectedplatform = {
            twitter: false,
            linkedin: false,
            instagram: false,
            convertkit: false,
            webflow: false,
          };
          var tempsocialAccountsData = {};
          for (var k = 0; k < socialAccounts.length; k++) {
            if (socialAccounts[k].accountType === "twitter") {
              tempselectedplatform.twitter = true;
              tempsocialAccountsData.twitter = socialAccounts[k];
            }
            if (socialAccounts[k].accountType === "linkedin") {
              tempselectedplatform.linkedin = true;
              tempsocialAccountsData.linkedin = socialAccounts[k];
              // console.log("tempsocialAccountsData", tempsocialAccountsData);
            }
            if (socialAccounts[k].accountType === "instagram") {
              tempselectedplatform.instagram = true;
              tempsocialAccountsData.instagram = socialAccounts[k];
            }
            if (socialAccounts[k].accountType === "convertkit") {
              tempselectedplatform.convertkit = true;
              tempsocialAccountsData.convertkit = socialAccounts[k];
            }
            if (socialAccounts[k].accountType === "webflow") {
              tempselectedplatform.webflow = true;
              tempsocialAccountsData.webflow = socialAccounts[k];
            }
          }
          setSocialAccountsData(profiles[i].socialAccounts);
          // console.log("tempsocialAccountsData", tempsocialAccountsData);
          markPlatformSelected(platSeletedtrack, tempselectedplatform);
          setconnectedPlatform(tempselectedplatform);
        }
      }
    }
  }, [isAuthenticated, user, props.trackId]);

  const connectPlatformHandler = async (platform, value) => {
    try {
      if (platform === "convertkit") {
        setIsConvertkitModal(true);
        return;
      }
      if (platform === "webflow") {
        setIsWebflowModal(true);
        return;
      }
      var pathname = location.pathname;
      if (pathname[pathname.length - 1] === "/") {
        pathname = pathname.slice(0, -1);
      }
      if (!pathname.includes("/true/") && !pathname.includes("true/" + props.trackId)) {
        pathname = pathname + "/true/" + props.trackId;
      }
      if (platform === "twitter") {
        window.open(
          `${process.env.REACT_APP_TWITTER_REDIRECT_BASE_URL}/twitter/login/${user.uuid}/${props.profileId}/` +
            btoa(pathname),
          "_blank"
        );
      } else if (platform === "linkedin") {
        window.open(
          `${process.env.REACT_APP_TWITTER_REDIRECT_BASE_URL}/linkedin/login/${user.uuid}/${props.profileId}/` +
            btoa(pathname),
          "_blank"
        );
      } else if (platform === "instagram") {
        const fb = new FBApi();
        fb.FacebookLoginApi(setfacebookAuthrespone);
        //
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postNowHandler = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/post-now/`,
        { trackId: parseInt(props.trackId), platforms: selectedPlatform },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      props.setisModalHidden(true);
      props.getAllTracks();
      setIsLoading(false);

      if (res.data.status) {
        successAlert({ title: res.data.message });
      } else {
        failAlert({ title: res.data.message });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    props.getAllTracks();
  }, []);
  const schedulePost = async (scheduleDate) => {
    if (!scheduleDate) {
      failAlert({ title: "Please select the date and time" });
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/draft/schedule`,
        {
          id: parseInt(props.trackId),
          scheduledTime: scheduleDate,
          scheduledPlatform: selectedPlatform,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status === true) {
        props.setisModalHidden(true);
        props.getAllTracks();
        successAlert({ title: "Scheduled Successfully" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectPlatform = (platform) => {
    confirmationModal({}, async () => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/disconnect-social-account`,
          { platform, profileId: Number(props.profileId) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (data.status !== true) {
          throw new Error("Sorry, something went wrong");
        }

        await dispatch(getLoggedUserInfo());
        props.getAllTracks();
        props.setisModalHidden(true);
        setconnectedPlatform((prev) => ({ ...prev, [platform]: false }));
        successAlert({ title: "Success" });
      } catch (error) {
        failAlert({
          title: "Sorry, something went wrong",
        });
      }
    });
  };

  const setPlatformHandler = (platform, value) => {
    setSelectedPlatform((prev) => ({ ...prev, [platform]: value }));
  };

  const handleCheckboxChange = (event, object) => {
    if (event.target.checked) {
      setInstaSelectedData([object]);
    } else {
      setInstaSelectedData(instaSelectedData.filter((selectedObject) => selectedObject.id !== object.id));
    }
  };

  const handleInstagramSubmit = () => {
    if (instaSelectedData.length < 2) {
      var json = {
        instaDetails: instaSelectedData,
        instaDetails: {
          instaId: instaSelectedData.id,
          username: instaSelectedData.username,
          ...instaSelectedData["0"],
        },
        pages: {
          ...fbPageData,
        },
        facebook: { ...facebookAuthrespone.authResponse },
      };

      try {
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/instagram/save-tokens/`,
          { json, profileId: props.profileId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setconnectedPlatform({ ...connectedPlatform, instagram: true });
        setInstaAccountSelectionModal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      failAlert({
        title: "Please select only one of them!",
      });
    }
  };

  // useEffect(() => {
  //   // only added to remove the facebook page selection modal
  //   // remove it when adding the modal back again
  //   if (instaSelectedData.length === 0) return;
  //   handleInstagramSubmit();
  // }, [instaSelectedData]);
  useEffect(() => {
    // if (!socialAccountsData || !socialAccountsData.linkedin) return;
    // console.log(socialAccountsData);
    if (socialAccountsData.length === 0) return;
    // console.log(socialAccountsData["linkedin"]?.authJSON?.authFinalized);
    const linkedin = socialAccountsData.find((account) => account.accountType === "linkedin");
    // console.log(linkedin);
    if (linkedin && linkedin.authJSON && !linkedin.authJSON?.authFinalized) {
      setLinkedinAccountSelectionModal(true);
    }
  }, [socialAccountsData]);
  return (
    <>
      <h3 className="text-[30px] font-semibold mb-[10px]">Publish</h3>
      <p className="text-[12px] md:text-[18px] text-[#6B7280] mb-[20px]">
        Choose connected social media platforms to share this post
      </p>

      <div className="md:flex grid grid-cols-2 gap-[20px] md:gap-[45px] justify-center -ml-5">
        {platforms.map((platform, index) => (
          <PublishPlatform
            key={index}
            profileId={props.profileId}
            platform={platform}
            socialAccountsData={socialAccountsData}
            connectionStatus={connectedPlatform[platform]}
            isSelected={selectedPlatform[platform]}
            selectPlatform={setPlatformHandler}
            connectPlatform={connectPlatformHandler}
            disconnectPlatform={disconnectPlatform}
          />
        ))}
      </div>

      {/* <div>
        <PublishPlatform
          key={3}
          profileId={props.profileId}
          platform={"convertkit"}
          connectionStatus={connectedPlatform["convertkit"]}
          isSelected={selectedPlatform["convertkit"]}
          selectPlatform={setPlatformHandler}
          connectPlatform={connectPlatformHandler}
          disconnectPlatform={disconnectPlatform}
        />
      </div> */}

      <PublishingModalFooter
        scheduledTime={scheduledTime}
        isAnyPlatformSelected={isAnyPlatformSelected}
        selectedPlatform={selectedPlatform}
        isLoading={isLoading}
        trackId={parseInt(props.trackId)}
        postNowHandler={postNowHandler}
        schedulePost={schedulePost}
        getAllTracks={props.getAllTracks}
        setisModalHidden={props.setisModalHidden}
        activeTrack={activeTrack}
      />

      {linkedinAccountSelectionModal && (
        <LinkedInAccountSelection
          setLinkedinAccountSelectionModal={setLinkedinAccountSelectionModal}
          authJSON={socialAccountsData.find((account) => account.accountType === "linkedin")?.authJSON}
          profileId={props.profileId}
        />
      )}
      {instaAccountSelectionModal && (
        <InstagramAccountSelection
          setInstaAccountSelectionModal={setInstaAccountSelectionModal}
          instaAccDetails={instaAccDetails}
          handleCheckboxChange={handleCheckboxChange}
          handleInstagramSubmit={handleInstagramSubmit}
        />
      )}

      {isConvertkitModal && (
        <ConvertkitModal
          setConvertkitSecret={setConvertkitSecret}
          setIsConvertkitModal={setIsConvertkitModal}
          checkConvertkit={checkConvertkit}
        />
      )}
      {isWebflowModal && (
        <WebflowModal
          setWebflowSecret={setWebflowSecret}
          setIsWebflowModal={setIsWebflowModal}
          checkWebflow={checkWebflow}
        />
      )}
      {webflowCollections && (
        <CollectionsModal
          IsCollectionsModalHidden={IsCollectionsModalHidden}
          webflowCollections={webflowCollections}
          setWebflowCollections={setWebflowCollections}
          profileId={props.profileId}
          setIsCollectionsModalHidden={setIsCollectionsModalHidden}
          setconnectedPlatform={setconnectedPlatform}
          connectedPlatform={connectedPlatform}
        />
      )}
    </>
  );
};

export default PublishingModalContent;
