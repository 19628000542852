import { useRef, useState, useEffect } from "react";
import Button from "../utils/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaRobot } from "react-icons/fa";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitterIcon.svg";

const LibraryTopBar = (props) => {
  const {
    openModal,
    totalSelectedAssets,
    setSelectedAssets,
    setImportPanelActive,
    setIsPanelOneActive,
    setIsPanelTwoActive,
    libItems,
    setLibItems,
    libLoading,
    setLibLoading,
  } = props;
  const dispatch = useDispatch();
  const [isHelpBubbleOpen, setIsHelpBubbleOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const sortRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");
  const filterRef = useRef(null);
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [activeSort, setactiveSort] = useState("Newest");
  const { libraryData, libraryLoading } = useSelector((state) => state.library);
  const [libItemPlatformCount, setLibItemPlatformCount] = useState({
    linkedin: 0,
    instagram: 0,
    twitter: 0,
    ai: 0,
    audio: 0,
    video: 0,
    image: 0,
    text: 0,
    newsletter: 0,
  });
  useEffect(() => {
    if (libraryData) {
      var temp = {
        linkedin: 0,
        instagram: 0,
        twitter: 0,
        ai: 0,
        audio: 0,
        video: 0,
        image: 0,
        text: 0,
        newsletter: 0,
      };
      for (const item of libraryData) {
        // console.log(item);
        item.type.includes("linkedin") && temp.linkedin++;
        item.type.includes("instagram") && temp.instagram++;
        item.type.includes("tweet") && temp.twitter++;
        item.type.includes("thread") && temp.twitter++;
        item.prev && temp.ai++;
        item.type.includes("audio") && temp.audio++;
        item.type.includes("video") && temp.video++;
        item.type.includes("image") && temp.image++;
        item.type.includes("text") && temp.text++;
        item.type.includes("newsletter") && temp.newsletter++;
      }
      setLibItemPlatformCount(temp);
      // console.log(temp);
    }
  }, [libraryData]);
  const handleFilterChange = (event) => {
    const { id, checked } = event.target;
    var newFilters = checkedFilters;
    console.log(id, checked);
    if (checked) {
      if (id == "audio") {
        newFilters.push(id);
        newFilters.push("video");
        setCheckedFilters((prevFilters) => [...prevFilters, id, "video"]);
      } else {
        newFilters.push(id);
        setCheckedFilters((prevFilters) => [...prevFilters, id]);
      }
    } else {
      if (id == "audio") {
        newFilters = newFilters.filter((filter) => filter !== id);
        newFilters = newFilters.filter((filter) => filter !== "video");
        setCheckedFilters((prevFilters) => prevFilters.filter((filter) => filter !== id));
        setCheckedFilters((prevFilters) => prevFilters.filter((filter) => filter !== "video"));
      }
      newFilters = newFilters.filter((filter) => filter !== id);
      setCheckedFilters((prevFilters) => prevFilters.filter((filter) => filter !== id));
    }
    dispatch({ type: "FILTER_LIBRARY_DATA", payload: newFilters });
  };
  const handleClearAll = () => {
    setCheckedFilters([]);
    dispatch({ type: "FILTER_LIBRARY_DATA", payload: [] });
    document.querySelectorAll(".filter-field").forEach((checkbox) => {
      checkbox.checked = false;
    });
    setIsFilterOpen(false);
  };
  const handleSortChange = (sort) => {
    console.log(sort);
    dispatch({ type: "SORT_LIBRARY_DATA", payload: sort });
    setactiveSort(sort);
    setIsSortOpen(false);
  };
  const handleSearch = (e) => {
    setsearchQuery(e.target.value);
    console.log(e.target.value);
    dispatch({ type: "SEARCH_LIBRARY_DATA", payload: e.target.value });
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!sortRef.current.contains(event.target)) {
        setIsSortOpen(false);
      }
      if (!filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  useEffect(() => {
    if (checkedFilters.length === 0) {
      dispatch({ type: "FILTER_LIBRARY_DATA", payload: [] });
    }
  }, [checkedFilters]);
  const navigate = useNavigate();
  return (
    <div class="sub-header">
      <div class="header-fake-border"></div>
      <div class="sub-header-inside">
        <div class="view-title">
          Library{" "}
          <button class="help" onClick={() => setIsHelpBubbleOpen(!isHelpBubbleOpen)}>
            <i class="ri-question-fill"></i>
          </button>
        </div>
        <div class={`help-bubble ${isHelpBubbleOpen && "active"}`}>
          <button id="" class="close-help" type="button" onClick={() => setIsHelpBubbleOpen(false)}>
            <i class="ri-close-line"></i>
          </button>
          <p>
            Assets in your library are everything that can be used in the <span class="strong">composer</span> to edit
            and schedule content or <span class="strong">transformer</span> to generate new content.
          </p>
        </div>
        <div class="menu library">
          <div class="sub-menu-wrap" ref={sortRef}>
            <a class="lib-sort show-sub" onClick={() => setIsSortOpen(!isSortOpen)}>
              <span class="data-sort-selected">
                <i class="ri-sort-asc left"></i>
                <span class="sort-selected-text">{activeSort}</span>
              </span>{" "}
              <i class="ri-arrow-down-s-line right"></i>
            </a>

            <div class={`sub-menu sort ${isSortOpen && "active"}`}>
              <a
                onClick={() => handleSortChange("Newest")}
                class={`data-sort-item sort-newest sort-timeline ${activeSort === "Newest" && "active"}`}
                data-sort-item="<i class='ri-sort-asc'></i> <span class='sort-selected-text'>Newest</span>"
              >
                <i class="ri-sort-asc"></i> Newest
              </a>
              <a
                onClick={() => handleSortChange("Oldest")}
                class={`data-sort-item sort-oldest sort-timeline ${activeSort === "Oldest" && "active"}`}
                data-sort-item="<i class='ri-sort-desc'></i> <span class='sort-selected-text'>Oldest</span>"
              >
                <i class="ri-sort-desc"></i> Oldest
              </a>
              <a
                onClick={() => handleSortChange("A-Z")}
                class={`data-sort-item sort-a-z ${activeSort === "A-Z" && "active"}`}
                data-sort-item="<i class='ri-sort-asc'></i> <span class='sort-selected-text'>A-Z</span>"
              >
                <i class="ri-sort-asc"></i> A-Z
              </a>
              <a
                onClick={() => handleSortChange("Z-A")}
                class={`data-sort-item sort-z-a ${activeSort === "Z-A" && "active"}`}
                data-sort-item="<i class='ri-sort-desc'></i> <span class='sort-selected-text'>Z-A</span>"
              >
                <i class="ri-sort-desc"></i> Z-A
              </a>
            </div>
          </div>
          <div class="sub-menu-wrap" ref={filterRef}>
            <a class="lib-filter show-sub" onClick={() => setIsFilterOpen(!isFilterOpen)}>
              <i class="ri-filter-2-line left"></i> <span class="data-filter-selected">Filter</span>{" "}
              <i class="ri-arrow-down-s-line right"></i>
            </a>
            <div class={`sub-menu filter ${isFilterOpen && "active"} h-fit`}>
              <button class="clear-filters" onClick={handleClearAll}>
                Clear All
              </button>
              <div class="sub-menu-section type">
                <div class="sub-menu-title">Type</div>
                <div class="filter-item">
                  <input class="filter-field" id="image" type="checkbox" onChange={handleFilterChange} />
                  <label class="withicon" for="image">
                    <i class="ri-image-2-line"></i> Image
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="text" type="checkbox" onChange={handleFilterChange} />
                  <label class="withicon" for="text">
                    <i class="ri-text"></i> Text
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="document" type="checkbox" onChange={handleFilterChange} />
                  <label class="withicon" for="document">
                    <i class="ri-article-line"></i> Document
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="audio" type="checkbox" onChange={handleFilterChange} />
                  <label class="withicon" for="audio">
                    <i class="ri-movie-line"></i> Video Or Audio
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="original" type="checkbox" onChange={handleFilterChange} />
                  <label for="original">Original</label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="has-parents" type="checkbox" onChange={handleFilterChange} />
                  <label for="has-parents">AI Generated</label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="published" type="checkbox" onChange={handleFilterChange} />
                  <label for="published">Published</label>
                </div>
                <div class="filter-item">
                  <input
                    class="filter-field"
                    id="paraphrased&published"
                    type="checkbox"
                    onChange={handleFilterChange}
                  />
                  <label for="paraphrased&published">Paraphrased & Published</label>
                </div>
              </div>
              {/* <div class="sub-menu-section published">
                <div class="sub-menu-title">Is Published</div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-twitter" type="checkbox" />
                  <label class="withicon" for="filter-twitter">
                    <i class="ri-twitter-fill"></i> Twitter
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-instagram" type="checkbox" />
                  <label class="withicon" for="filter-instagram">
                    <i class="ri-instagram-fill"></i> Instagram
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-linkedin" type="checkbox" />
                  <label class="withicon" for="filter-linkedin">
                    <i class="ri-linkedin-box-fill"></i> LinkedIn
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-facebook" type="checkbox" />
                  <label class="withicon" for="filter-facebook">
                    {" "}
                    <i class="ri-facebook-box-fill"></i> Facebook
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-email" type="checkbox" />
                  <label class="withicon" for="filter-email">
                    <i class="ri-mail-fill"></i> Email
                  </label>
                </div>
              </div>
              <div class="sub-menu-section tagged">
                <div class="sub-menu-title">Tagged</div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-tagged-and" type="checkbox" />
                  <label for="filter-tagged-and">
                    Tag1 <span class="secondary">AND</span> Tag2 ...
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-field" id="filter-tagged-or" type="checkbox" />
                  <label for="filter-tagged-or">
                    Tag1 <span class="secondary">OR</span> Tag2 ...
                  </label>
                </div>
                <div class="filter-item">
                  <input class="filter-tags" type="text" placeholder="tag1, tag2" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <button class="search-mobile">
          <i class="ri-search-line"></i>
        </button>
        <div class="lib-search-wrap">
          <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearch} />
          <button class="search">
            <i class="ri-search-line"></i>
          </button>
        </div>
        <div className="hidden md:flex items-center h-fit my-auto space-x-1 text-white ">
          {/* {item.type.includes("linkedin") && <i class="ri-linkedin-box-line"></i>}
          {item.type.includes("instagram") && <i class="ri-instagram-line"></i>} */}
          {/* <FaRobot size={14} color="#373737" /> */}

          <div className="flex items-center">
            <TwitterIcon className="w-[14px] h-[14px]" fill="#fff" />
            <span className="text-[14px] ml-1"> {libItemPlatformCount.twitter}</span>
          </div>
          <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <i class="ri-linkedin-box-line"></i>
            <span className="text-[14px] ml-1"> {libItemPlatformCount.linkedin}</span>
          </div>
          <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <i class="ri-instagram-line"></i>
            <span className="text-[14px] ml-1"> {libItemPlatformCount.instagram}</span>
          </div>
          <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <i class="ri-newspaper-line"></i>
            <span className="text-[14px] ml-1"> {libItemPlatformCount.newsletter}</span>
          </div>
          <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <FaRobot size={14} color="#fff" />
            <span className="text-[14px] ml-1"> {libItemPlatformCount.ai}</span>
          </div>
          {/* 
          <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <i class="ri-text"></i>
            <span className="text-[14px] ml-1"> : {libItemPlatformCount.text}</span>
          </div> 
          */}

          {/* <div className="text-[14px]">|</div>
          <div className="flex items-center">
            <i class="ri-mic-line"></i>
            <span className="text-[14px] ml-1"> : {libItemPlatformCount.audio}</span>
          </div> */}
        </div>
        <button
          class="button small primary withicon import-asset"
          onClick={() => {
            setImportPanelActive(true);
            setIsPanelOneActive(false);
            setIsPanelTwoActive(false);
            setSelectedAssets([]);
          }}
          type="button"
        >
          <i class="ri-add-line"></i> Import
        </button>
      </div>
    </div>
  );
  return (
    <div className="mt-11 py-[10px] px-[20px] flex items-center border border-[#E4E4E7]">
      <div>
        <h1 className="text-[#111928] text-[24px] font-semibold leading-[36px]">Library</h1>
        <p className="text-[18px] text-[#6B7280]">
          Assets in your library are everything that can be imported to the composer to create content.
        </p>
      </div>

      <div className="flex items-center gap-[15px] ml-auto">
        {totalSelectedAssets > 0 && (
          <button
            className={`flex justify-center items-center gap-2 ${totalSelectedAssets === 1 ? "" : "opacity-50"}`}
            onClick={totalSelectedAssets === 1 ? () => navigate(`/library/questions`) : null}
          >
            {/* prettier-ignore */}
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6666 4.83333L7.99992 1.5L1.33325 4.83333M14.6666 4.83333L7.99992 8.16667M14.6666 4.83333V13.1667L7.99992 16.5M1.33325 4.83333L7.99992 8.16667M1.33325 4.83333V13.1667L7.99992 16.5M7.99992 8.16667V16.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
              </svg>
            <span className="text-[#1C64F2] font-semibold">Transform</span>
          </button>
        )}
        <div>
          <Button className="!px-[10px] !py-[6px]" onClick={openModal}>
            + Import to Library
          </Button>
        </div>
      </div>
      <button className="ml-[10px]">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0001 7.6999C11.3636 7.6999 10.7531 7.44705 10.303 6.99696C9.85295 6.54687 9.6001 5.93642 9.6001 5.2999C9.6001 4.66338 9.85295 4.05293 10.303 3.60285C10.7531 3.15276 11.3636 2.8999 12.0001 2.8999C12.6366 2.8999 13.2471 3.15276 13.6972 3.60285C14.1472 4.05293 14.4001 4.66338 14.4001 5.2999C14.4001 5.93642 14.1472 6.54687 13.6972 6.99696C13.2471 7.44705 12.6366 7.6999 12.0001 7.6999ZM12.0001 14.8999C11.3636 14.8999 10.7531 14.647 10.303 14.197C9.85295 13.7469 9.6001 13.1364 9.6001 12.4999C9.6001 11.8634 9.85295 11.2529 10.303 10.8028C10.7531 10.3528 11.3636 10.0999 12.0001 10.0999C12.6366 10.0999 13.2471 10.3528 13.6972 10.8028C14.1472 11.2529 14.4001 11.8634 14.4001 12.4999C14.4001 13.1364 14.1472 13.7469 13.6972 14.197C13.2471 14.647 12.6366 14.8999 12.0001 14.8999ZM12.0001 22.0999C11.3636 22.0999 10.7531 21.847 10.303 21.397C9.85295 20.9469 9.6001 20.3364 9.6001 19.6999C9.6001 19.0634 9.85295 18.4529 10.303 18.0028C10.7531 17.5528 11.3636 17.2999 12.0001 17.2999C12.6366 17.2999 13.2471 17.5528 13.6972 18.0028C14.1472 18.4529 14.4001 19.0634 14.4001 19.6999C14.4001 20.3364 14.1472 20.9469 13.6972 21.397C13.2471 21.847 12.6366 22.0999 12.0001 22.0999Z"
            fill="#000"
          />
        </svg>
      </button>
    </div>
  );
};

export default LibraryTopBar;
