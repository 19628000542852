import { ReactComponent as CheckCircle } from "../../assets/icons/checkCircle.svg";
import ComingSoonTag from "../utils/ComingSoonTag";

const LibraryQuestionItem = (props) => {
  const { questionTitle, questionDescription, isSelected, index, selectAnswer, comingSoon, disabled } = props;

  return (
    <div
      className={`flex items-center gap-[30px] cursor-pointer p-[20px] w-full border bg-white
      ${isSelected ? "border-[#1C64F2]" : "border-[#E4E4E7]"} 
      ${disabled && `bg-gray-200`}
      rounded-[12px]`}
      onClick={() => !disabled && selectAnswer(index)}
    >
      {isSelected ? (
        <CheckCircle className="h-[25px] w-[25px] " />
      ) : (
        <div className="rounded-full border bg-gray-200 border-[#E4E4E7] h-[25px] w-[25px]"></div>
      )}
      <div>
        <p className="flex items-center gap-[15px] font-[500] text-[16px] text-[#4B5563] mb-[5px]">
          {questionTitle}

          {comingSoon && <ComingSoonTag />}
        </p>
        <p className="font-[500] text-[14px] text-[#6B7280] mb-[5px]">{questionDescription}</p>
      </div>
    </div>
  );
};

export default LibraryQuestionItem;
