import { useState } from "react";
import { Link } from "react-router-dom";
import PreSigninNavbar from "../components/Navbar/PreSigninNavbar";
import { handleSignin } from "../redux/actions/authActions";
import { useDispatch } from "react-redux";
import Button from "../components/utils/Button";
import Loader from "../components/utils/Loader";
import { failAlert } from "../utils/sweetAlert";

const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      failAlert({
        title: "Please enter a valid email and password",
        confirmButtonText: "OK",
      });
    }

    setIsLoading(true);
    const res = await dispatch(handleSignin({ email, password }));
    console.log(res, "res")
    if (res[0] === false) {
      setIsLoading(false);

      console.log(res);

      failAlert({
        title: res[1] ?? "Something weng wrong",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="h-[100vh] flex flex-col bg-[#f9fafb]">
      <PreSigninNavbar />
      <div className="m-auto flex justify-center items-center">
        <div className="flex items-center p-8 bg-[#FFFFFF] rounded-[16px] border border-[#E4E4E7]">
          <div className="flex items-center justify-center">
            <img width={"400px"} height={"400px"} src="Images/SignIn/SignIn.png" alt="" />
          </div>
          <div className="">
            <div className="text-3xl font-bold text-[#111928] mb-5 ">Sign In</div>
            <div className="mb-4">
              <label className="text-base mb-2 font-medium text-[#111928] " htmlFor="email-address">
                Your email
              </label>
              <input
                id="email-address"
                type="email"
                autoComplete="email"
                required
                value={email}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="name@example.com"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="text-base mb-2 font-medium text-[#111928] " htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                value={password}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="**********"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Button
                className="border border-[#1C64F2] w-full !py-2 px-4 text-[#FFFFFF] text-[16px]"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="flex gap-[5px]">
                    <div>
                      <Loader height="20px" width="20px" />
                    </div>
                    Loading...
                  </div>
                ) : (
                  <>Signin</>
                )}
              </Button>
            </div>
            <hr className="w-[50%] ml-[25%] border border-[#3f83f8] mt-[1rem]" />
            <div className="flex items-center justify-center" style={{ marginTop: "0.7rem" }}>
              <Link to="/register" className="text-base font-semibold text-[#1C64F2] ml-3 hover:text-blue-700">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
