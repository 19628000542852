const INITIAL_ADD_COMPANY_MODAL_STATE = {
  visible: false,
  rescheduleModal: {
    visible: false,
    trackId: null,
  },
};

export const modalReducer = (state = INITIAL_ADD_COMPANY_MODAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN_ADD_COMPANY_MODAL":
      return {
        ...state,
        visible: true,
      };
    case "CLOSE_ADD_COMPANY_MODAL":
      return {
        ...state,
        visible: false,
      };
    case "OPEN_RESCHEDULE_TRACK_MODAL":
      return {
        ...state,
        rescheduleModal: {
          visible: true,
          trackId: payload.trackId,
        },
      };
    case "CLOSE_RESCHEDULE_TRACK_MODAL":
      return {
        ...state,
        rescheduleModal: {
          visible: false,
        },
      };
    default:
      return state;
  }
};
