import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Button from "../components/utils/Button";
import ListItem from "../components/Tracks/ListItem";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import ScheduleCalender from "../components/Tracks/Calender";
import GreenTick from "../components/utils/GreenTick";
import PublishingModal from "../components/Publishing/PublishingModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/utils/Loader";
import { createTrack } from "../redux/actions/trackActions";
import Dots from "../components/Tracks/Dots";
import DraftsList from "../components/Tracks/DraftsList";
import { getUserBillingInfo } from "../redux/actions/authActions";
import ScheduleList from "../components/Tracks/ScheduleList";
import RescheduleModal from "../components/Tracks/RescheduleModal";
import { BiShuffle } from "react-icons/bi";
import { Tooltip } from "antd";
const filterTracks = (allTracks) => {
  const draftTracks = [];
  const schedulingTracks = [];
  const publishedTracks = [];

  for (const track of allTracks) {
    if (track.status === "draft" || track.status === "composing" || track.status === "staging") {
      draftTracks.push(track);
      continue;
    }

    if (track.status === "scheduled") {
      schedulingTracks.push(track);
      continue;
    }

    if (track.status === "published") {
      publishedTracks.push(track);
      continue;
    }
  }

  const sortedDraftTracks = draftTracks.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return { draftTracks: sortedDraftTracks, schedulingTracks, publishedTracks };
};
const Tracks = () => {
  const { profileId, publishModal, trackId } = useParams();

  const [trackIdState, setTrackIdState] = useState();
  const [currentProfile, setcurrentProfile] = useState();
  const [isModalHidden, setisModalHidden] = useState(true);
  const [draftTracks, setDraftTracks] = useState([]);
  const [schedulingTracks, setSchedulingTracks] = useState([]);
  const [publishedTracks, setPublishedTracks] = useState([]);
  const [loadingTracks, setLoadingTracks] = useState(true);
  const [hasCompletedBilling, setHasCompletedBilling] = useState(false);

  const dispatch = useDispatch();

  const openPublishingModal = (id) => {
    setTrackIdState(id);
    setisModalHidden(false);
  };

  const user = useSelector((state) => state.auth.user);
  const { rescheduleModal } = useSelector((state) => state.modals);
  useEffect(() => {
    if (user?.id) {
      const profile = user.profiles.find((profile) => profile.id === Number(profileId));
      setcurrentProfile(profile);
    }
  }, [user, profileId]);
  // Will fetch all tracks and set each of the track to state based on status
  useEffect(() => {
    if (!trackId) return;
    setTrackIdState(trackId);
    if (publishModal === "true") {
      setisModalHidden(false);
    }
  }, [trackId, publishModal]);
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const getAllTracks = async () => {
    let tracks = [];

    if (user?.id) {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/get-tracks-by-user`,
          { userId: user.id, profileId: Number(profileId) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        setLoadingTracks(false);
        if (data.status !== true) return;

        tracks = data.allTracks || [];
        for (const track of tracks) {
          track.backgroundColor = getRandomColor();
        }
      } catch (error) {
        console.log(error);
        tracks = [];
      }
    }

    const { draftTracks, schedulingTracks, publishedTracks } = filterTracks(tracks);
    setDraftTracks(draftTracks);
    setSchedulingTracks(schedulingTracks);
    setPublishedTracks(publishedTracks);
  };

  useEffect(() => {
    getAllTracks();
  }, [user, profileId]);

  useEffect(() => {
    (async () => {
      const billingData = await getUserBillingInfo();

      if (billingData?.stripeCustomer?.plan && billingData?.stripeCustomer?.planStatus === "active") {
        setHasCompletedBilling(true);
      }
    })();
  }, []);
  const handleShuffle = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/schedule/shuffle`,
        { profileId: Number(profileId) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status) {
        getAllTracks();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {trackIdState && !isModalHidden && (
        <PublishingModal
          getAllTracks={getAllTracks}
          profileId={profileId}
          isModalHidden={isModalHidden}
          draftTracks={draftTracks}
          trackId={trackIdState}
          setisModalHidden={setisModalHidden}
        />
      )}
      {rescheduleModal?.visible && <RescheduleModal getAllTracks={getAllTracks} />}
      <PostSigninNavbar title="Your Tracks" />
      <div className="w-[90%] m-auto mt-[70px] pb-[50px] font-Inter animate-fade-in ">
        <div className="drafts mb-[50px]">
          <div className="flex justify-between items-center mb-[10px]">
            <h3 className="text-[20px] font-semibold color-[#374151]">Drafts</h3>

            <div
              className="flex items-center gap-[10px] cursor-pointer text-[#1C64F2] font-semibold"
              onClick={() => dispatch(createTrack(Number(profileId)))}
            >
              + Compose New Track
            </div>
          </div>

          <div className="flex flex-col gap-[10px]">
            {draftTracks.length ? (
              <DraftsList
                draftTracks={draftTracks}
                openPublishingModal={openPublishingModal}
                profileId={profileId}
                getAllTracks={getAllTracks}
                hasCompletedBilling={hasCompletedBilling}
              />
            ) : (
              <div>
                {loadingTracks ? (
                  <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
                ) : (
                  <h4>No Drafts for now!</h4>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="  posting-schedule mb-[50px]">
          <h3 className="text-[20px] flex items-center  font-semibold color-[#374151] mb-[10px]">
            Posting Schedule{" "}
            <Tooltip title="Shuffle">
              <span onClick={handleShuffle} className="cursor-pointer mx-2">
                <BiShuffle />
              </span>
            </Tooltip>
          </h3>

          <div>
            <ScheduleCalender tracks={schedulingTracks ? schedulingTracks : []} profile={currentProfile} />
          </div>
        </div>

        <div className="scheduled mb-[50px]">
          <h3 className="text-[20px] font-semibold color-[#374151] mb-[10px]">Scheduled</h3>

          <div className="flex flex-col gap-[10px]">
            {schedulingTracks.length ? (
              <ScheduleList schedulingTracks={schedulingTracks} getAllTracks={getAllTracks} profileId={profileId} />
            ) : (
              <div>
                {loadingTracks ? (
                  <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
                ) : (
                  <h4>No scheduled posts!</h4>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="published">
          <h3 className="text-[20px] font-semibold color-[#374151] mb-[10px]">Published</h3>

          <div className="flex flex-col gap-[10px]">
            {publishedTracks.length ? (
              publishedTracks.map((publish, i) => {
                // let totalScheduledPlatforms = 0;

                // for (let platform in publish.scheduledPlatform) {
                //   if (publish.scheduledPlatform[platform] === true) {
                //     totalScheduledPlatforms++;
                //   }
                // }

                // const backgroundColor = progressColors[totalScheduledPlatforms]["background"];
                // const textColor = progressColors[totalScheduledPlatforms]["text"];
                // const totalScore = totalScheduledPlatforms;

                return (
                  <ListItem key={i}>
                    <div className="flex items-center h-full">
                      <div className="text-[12px] md:text-[18px] text-[#374151] mr-auto w-[50%] whitespace-nowrap text-ellipsis overflow-hidden">
                        {publish.draft.body_json.title}
                      </div>

                      <GreenTick height={20} width={20} />
                      {/* <div className={`rounded-[10px] px-[10px] py-[2px]`} style={{ backgroundColor, color: textColor }}>
                        {totalScore} / 3
                      </div> */}
                      <div className="w-px h-[36px] bg-gray-300 ml-[50px]"></div>
                      <div className="flex items-center gap-[8px]">
                        <Button className="ml-3 px-[15px] py-[6px] tooltip text-sm md:text-base" variant="disabled">
                          <span className="tooltip-text">Coming Soon</span>
                          Performance
                        </Button>
                        <Dots getAllTracks={getAllTracks} trackId={Number(publish.id)} profileId={Number(profileId)} />
                      </div>
                    </div>
                  </ListItem>
                );
              })
            ) : (
              <div>
                {loadingTracks ? (
                  <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
                ) : (
                  <h4>No published posts!</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracks;
