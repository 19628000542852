import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLinkedinPostValidState } from "../../redux/actions/stageActions";
import { getAllAssets } from "../../utils/asset";
import { updateLinkedinPost } from "../../utils/saveAllPosts";
import { uploadMedia } from "../../utils/uploadTos3";
import Button from "../utils/Button";
import LinkedinMediaPreview from "./LinkedinMediaPreview";
import Loader from "../utils/Loader";
import ScheduleButtonsFotter from "./ScheduleButtonsFotter";
import instaDefault from "../../assets/Images/Navbar/instagram_default.jpg";
import axios from "axios";
import { GiFairyWand } from "react-icons/gi";
import { Tooltip } from "antd";
import { RePurposeWithAI } from "../../redux/actions/trackActions";
import Skeleton from "react-loading-skeleton";

const postLengthLimit = 3000;

const LinkedinPreview = (props) => {
  const { data, setSavedTime, isInsideRescheduleModal, setData } = props;

  const [value, setValue] = useState(props.data.linkedInPost.body_json.texts);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);
  const [isSetAsDraft, setIsSetAsDraft] = useState(null);

  const textAreaRef = useRef(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { currentTrack } = useSelector((state) => state.tracks);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const updateMarkAsDraft = async (markAsDraft) => {
    if (isInsideRescheduleModal) return;
    if (!currentTrack) return;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/draft/update-set-as-draft`,
        { postId: currentTrack.linkedInPostId, platform: "linkedin", markAsDraft },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status !== true) return;

      setIsSetAsDraft(data.markAsDraft);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    //to resize the textarea on load
    if (!textAreaRef.current) return;
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  useEffect(() => {
    (async () => {
      if (data.linkedinPost) return;

      setIsCurrentlyUploading(true);
      const allAssets = await getAllAssets(data.linkedInPost.body_json?.assets || []);
      setIsCurrentlyUploading(false);

      setUploadedFiles(allAssets);
    })();
  }, []);

  useEffect(() => {
    if (props.data.linkedInPost) {
      setValue(props.data.linkedInPost.body_json.texts);
    }
  }, [props.data.linkedInPost]);

  useEffect(() => {
    if (!currentTrack) return;

    setIsSetAsDraft(currentTrack.linkedInPost.markedAsDraft);
  }, []);

  const uploadFiles = async (files) => {
    const data = await uploadMedia(files, "linkedin", props.data.linkedInPost.id);

    const allImages = await getAllAssets(data);
    return allImages;

    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }
  };

  const handleOnChange = async (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    setValue(e.target.value);

    if (e.target.value.length > postLengthLimit) {
      dispatch(updateLinkedinPostValidState(false));
    } else {
      dispatch(updateLinkedinPostValidState(true));
    }

    if (isSetAsDraft === true) {
      await updateMarkAsDraft(false);
    }
    updateLinkedinPost(data.linkedInPost.id, e.target.value);
    setSavedTime(new Date());
  };

  const removeUploadedFile = async (index) => {
    const files = [...uploadedFiles];

    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];

    const updatedFiles = await uploadFiles(newFiles);
    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }
    if (updatedFiles) {
      setUploadedFiles(updatedFiles);
    }
  };

  const uploadFile = async (e, type) => {
    var files = [];

    if (type === "image") {
      files = Array.from(e.currentTarget.files).filter((file) => file.type.startsWith("image/")) || [];
    } else {
      files = Array.from(e.currentTarget.files).filter((file) => file.type === "application/pdf") || [];
    }

    if (uploadedFiles.length + files.length > 9) {
      const newFiles = [...uploadedFiles, ...files].slice(-9);
      setIsCurrentlyUploading(true);
      const uploadedAssets = await uploadFiles(newFiles);
      setIsCurrentlyUploading(false);
      setUploadedFiles(uploadedAssets);
      e.target.value = "";
      return;
    }

    const newFiles = [...uploadedFiles, ...files];
    setIsCurrentlyUploading(true);
    const uploadedAssets = await uploadFiles(newFiles);

    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }

    setIsCurrentlyUploading(false);
    setUploadedFiles(uploadedAssets);
    e.target.value = "";
  };

  const datePickerHandler = (e) => {
    if (e === null) {
      setSelectedDate(null);
      return;
    }

    setSelectedDate({
      day: e.$D,
      month: e.$M,
      year: e.$y,
    });
  };
  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  const checkBoxChangeHandler = async ({ checkbox, date, time }) => {
    var selectedDateVar = date ? date : selectedDate;
    var selectedTimeVar = time ? time : selectedTime;
    if (checkbox) {
      if (!selectedDateVar || !selectedTimeVar) {
        console.log("Please select date and time");
        return;
      }
      const date = new Date(
        selectedDateVar.year,
        selectedDateVar.month,
        selectedDateVar.day,
        selectedTimeVar.hour,
        selectedTimeVar.minute,
        0
      );
      if (date) {
        // make api to save this info
        const resdata = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
          {
            postId: data?.linkedInPost.id,
            trackId: currentTrack.id,
            platform: "linkedin",
            schedule: date,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (resdata.data.status === true) {
          setData(resdata.data.track);
        }
      }
    }
  };
  const cancelSchedule = async () => {
    const resdata = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
      {
        postId: data?.linkedInPost.id,
        trackId: currentTrack.id,
        platform: "linkedin",
        schedule: null,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (resdata.data.status === true) {
      setData(resdata.data.track);
    }
  };

  return (
    <div className="h-auto md:mx-10 py-3 w-fit  md:w-[400px] animate-fade-in staging-container">
      <div className="flex">
        <div className="flex items-center justify-center w-8 h-8 md:w-12 md:h-12 mr-[9px] my-[5px]">
          {props.profile.linkedin && props.profile.linkedin.profilePicture ? (
            <img
              className="w-full rounded-full"
              src={props.profile.linkedin.profilePicture}
              alt="Avatar 1"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = instaDefault;
              }}
            />
          ) : (
            <img className="w-full rounded-full" src={instaDefault} alt="Avatar 1" />
          )}
        </div>
        <div>
          <div className="flex items-center">
            <span className="text-[#1f1f1f] font-[600]">
              {props.profile.linkedin
                ? props.profile.linkedin.localizedFirstName + " " + props.profile.linkedin.localizedLastName
                : user && user?.fullName}
            </span>
            <span className="text-[#8e8e8e] ml-[3px]"></span>
          </div>
          <div className="flex items-center gap-1 mt-[1.5px]">
            <div className="text-[#00000099] font-[300] text-[14px] pb-[2px]">1h</div>
            <div className="text-[#00000099] text-[13px]">•</div>
            <svg
              className="text-[#00000099]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              width="16"
              height="16"
              focusable="false"
            >
              <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
            </svg>
          </div>
        </div>
      </div>
      {data?.linkedInPost.status == "processing" ? (
        <Skeleton count={3} />
      ) : (
        <>
          <textarea
            ref={textAreaRef}
            value={value}
            onChange={handleOnChange}
            disabled={false}
            className="w-full text-sm border-0 outline-none my-[10px] resize-none overflow-y-scroll"
          />

          {uploadedFiles.length === 0 && isCurrentlyUploading && (
            <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
          )}
          {uploadedFiles.length > 0 && (
            <LinkedinMediaPreview uploadedFiles={uploadedFiles} removeUploadedFile={removeUploadedFile} />
          )}
          <div className="mt-[15px] flex item-center gap-x-3">
            <div>
              <label
                htmlFor="linkedin-file-upload"
                style={{
                  cursor: "pointer",
                  opacity: uploadedFiles.length >= 9 ? 0.5 : 1,
                  pointerEvents: uploadedFiles.length >= 9 ? "none" : "all",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#666"
                  width="24"
                  height="24"
                  focusable={false}
                >
                  <path d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm1 13a1 1 0 01-.29.71L16 14l-2 2-6-6-4 4V7a1 1 0 011-1h14a1 1 0 011 1zm-2-7a2 2 0 11-2-2 2 2 0 012 2z"></path>
                </svg>
              </label>
              <input
                type="file"
                multiple={true}
                id="linkedin-file-upload"
                className="hidden"
                onChange={(e) => {
                  uploadFile(e, "image");
                }}
                accept="image/png, image/jpeg, image/jpg"
              />
            </div>

            <div>
              <label
                htmlFor="linkedin-pdf-upload"
                style={{
                  cursor: "pointer",
                  opacity: uploadedFiles.length >= 9 ? 0.5 : 1,
                  pointerEvents: uploadedFiles.length >= 9 ? "none" : "all",
                }}
                disabled={true}
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#666"
                  width="20"
                  height="20"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    {" "}
                    <path d="M980 5109 c-160 -31 -299 -173 -330 -339 -14 -73 -14 -4347 0 -4420 32 -170 170 -308 339 -340 74 -14 3069 -14 3141 0 170 32 308 170 340 339 6  35 10 683 10 1833 0 1563 -2 1784 -15 1812 -20 42 -1082 1102 -1118 1115 -35  14 -2300 13 -2367 0z m2220 -533 c0 -183 5 -358 10 -387 32 -169 170 -307 340 -339 29 -5 203 -10 386 -10 l334 0 0 -1705 c0 -1218 -3 -1719 -11 -1756 -16 -74 -84 -142 -158 -158 -74 -16 -3008 -16 -3082 0 -74 16 -142 84 -158 158 -16 74 -16 4288 0 4362 15 69 85 143 150 157 27 6 458 10 1117 11 l1072 1 0 -334z m673 -524 c-296 -3 -328 3 -393 67 -64 64 -70 96 -70 394 l0 242 350 -350 350 -350 -237 -3z" />
                    <path d="M2347 3396 c-126 -47 -190 -152 -207 -341 -18 -188 37 -347 204 -587 l50 -70 -63 -192 c-80 -243 -160 -458 -174 -472 -7 -6 -131 -69 -277 -139 -315 -151 -427 -215 -498 -285 -64 -63 -92 -115 -99 -186 -20 -188 240 -330 439 -241 190 85 390 306 561 618 l36 65 123 42 c143 48 301 90 480 128 l127 27 68 -50 c112 -82 183 -128 270 -173 76 -40 89 -44 152 -42 90 1 138 15 197 55 60 40 95 106 102 192 11 142 -59 228 -216 265 -75 17 -288 15 -402 -4 -52 -9 -100 -16 -107 -16 -32 0 -304 250 -429 396 l-51 59 43 140 c83 265 97 335 91 465 -7 178 -51 275 -147 329 -35 20 -61 25 -139 28 -66 2 -108 -1 -134 -11z m185 -226 c24 -33 35 -165 19 -240 -6 -30 -26 -104 -43 -163 -36 -123 -34  -123 -90 -12 -51 99 -68 161 -68 244 0 93 14 146 46 178 34 34 108 30 136 -7z m162 -1104 l136 -133 -61 -12 c-34 -7 -123 -30 -198 -51 -75 -21 -137 -37 -138 -36 -3 3 118 366 122 366 2 0 64 -60 139 -134z m888 -266 c38 -11 51 -29  41 -57 -9 -26 -23 -33 -67 -33 -38 0 -123 38 -176 80 l-25 20 95 0 c52 0 111 -5 132 -10z m-1618 -412 c-24 -42 -115 -146 -178 -205 -113 -106 -182 -132 -262 -98 l-35 15 17 30 c10 16 38 44 63 62 43 31 378 207 394 208 5 0 5 -6 1 -12z" />
                  </g>
                </svg>
              </label>
              <input
                type="file"
                multiple={false}
                id="linkedin-pdf-upload"
                className="hidden"
                onChange={(e) => {
                  uploadFile(e, "pdf");
                }}
                accept="application/pdf"
              />
            </div>
          </div>
        </>
      )}

      {value.length > 3000 && (
        <div className="flex justify-between items-center text-[#cc1016] text-[14px]">
          <div className="flex gap-[8px] items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              className="mb-[-2px]"
              width="13"
              height="14"
              focusable="false"
              fill="#cc1016"
            >
              <path d="M10.8 1H5.2L1 5.2v5.6L5.2 15h5.6l4.2-4.2V5.2zM12 9H4V7h8z"></path>
            </svg>
            <span>You have exceeded the maximum character limit</span>
          </div>
          <span className="font-semibold">{postLengthLimit - value.length}</span>
        </div>
      )}

      {data.linkedInPost.status != "processing" && (
        <>
          <div className="bg-[#E4E4E7] w-full h-[1px] m-auto rounded-[10px] mt-[10px]"></div>
          <ScheduleButtonsFotter
            isChecked={data.linkedInPost.scheduledTime ? true : false}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            checkBoxChangeHandler={checkBoxChangeHandler}
            datePickerHandler={datePickerHandler}
            timePickerHandler={timePickerHandler}
            data={data.linkedInPost}
            cancelSchedule={cancelSchedule}
          />

          <div className="set-as-draft-btn-container flex justify-between">
            {props.isdeleteActive && (
              <button className="text-red-600 text-sm font-medium ml-0 mt-1 my-auto" onClick={props.deleteItem}>
                Delete
              </button>
            )}
            <Tooltip title={"Repurpose with AI"}>
              <button
                className=" ml-auto mx-2 my-auto flex items-center text-sm font-medium"
                onClick={() => {
                  dispatch(
                    RePurposeWithAI({
                      postId: data?.linkedInPost.id,
                      platform: "linkedin",
                      trackId: data?.linkedInPost?.trackId,
                    })
                  );
                }}
              >
                <GiFairyWand className="mr-1" />
                <span className="">AI Repurpose</span>
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default LinkedinPreview;
