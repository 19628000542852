import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitterIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedinIcon.svg";
import TimesWatched from "./TimesWatched";
import { formatTags, formatedDate } from "../../../utils/date";
import Skeleton from "react-loading-skeleton";

function LibraryImage(props) {
  const { item, isSelected } = props;
  if (item.status === "PROCESSING") {
    return (
      <div className="">
        <div
          className={`min-h-[8rem] opacity-70 w-full p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}
        >
          {/* {thread.tweet.map((item, index) => (
          <div key={item.id}>
            <p className="font-[500]">{textlength < 160 ? item.text : item.text.substring(0, 160)}</p>
            {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />}
          </div>
        ))} */}
          <Skeleton count={3} />
          <div>
            <div className="text-[#1F2A37] flex text-[12px] mt-[20px]">
              processing | {formatedDate(item.updatedAt)}
              {/* <TimesWatched timesWatched={3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div class="asset image">
      <div class="asset-fake-border"></div>
      <div class="asset-image">
        <img
          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.image}`}
          alt=""
        />
      </div>
      <div class="asset-content">
        <div class="asset-title">
          <div class="asset-type" title="Image">
            <i class="ri-image-2-line"></i>
          </div>
          <div class="asset-title-text" data-asset-title="Woman at a desk with a tablet">
            {item.imageTitle}
          </div>
        </div>
        <div class="asset-excerpt"></div>
        <div class="asset-tags">
          {item.topics.map((topic) => (
            <div class="tag">
              <span class="tag-text">{topic}</span>
              <button class="remove-tag">
                <i class="ri-close-line"></i>
              </button>
            </div>
          ))}
        </div>
        <div class="asset-meta">
          <div class="asset-size meta-item"></div>
          <div class="asset-imported-date meta-item">{formatedDate(item.updatedAt)}</div>
          <div class="asset-imported-date meta-item">{formatTags(item.tags)}</div>
          {/* <div class="asset-published meta-item">
            <div class="pub-platform twitter" title="Twitter">
              <i class="ri-twitter-fill"></i>
              <span class="platform-count">2</span>
            </div>
            <div class="pub-platform instagram" title="Instagram">
              <i class="ri-instagram-fill"></i>
              <span class="platform-count">1</span>
            </div>
            <div class="pub-platform linkedin" title="LinkedIn">
              <i class="ri-linkedin-box-fill"></i>
              <span class="platform-count">1</span>
            </div>
            <div class="pub-platform facebook" title="Facebook">
              <i class="ri-facebook-box-fill"></i>
              <span class="platform-count">1</span>
            </div>
            <div class="pub-platform email" title="Email">
              <i class="ri-mail-line"></i>
              <span class="platform-count">1</span>
            </div>
          </div>
          <div class="asset-heat-index meta-item">
            <span class="heat-measure mid1" title="Heat Index">
              <i class="ri-fire-fill"></i>
              <span class="heat-index-value">45</span>
            </span>
          </div> */}
        </div>
        <div class="asset-page-content">
          <div class="page-image-wrap">
            <img
              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.image}`}
              alt=""
            />
            <div class="image-dimensions">1920x1080px</div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div
      className={`bg-white border-[2px] 
      ${isSelected ? "border-[#1C64F2]" : "border-[#e4e4e7]"} 
      rounded-[3px] shadow-[0_1px_2px_rgba(0,0,0,0.06)] cursor-pointer w-[300px] overflow-hidden relative`}
    >
      {/* <TimesWatched timesWatched={6} className="absolute top-[5px] left-[5px]" /> */}
      <img
        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.image}`}
        alt=""
        className="object-cover h-[180px] w-full"
      />
      <div className="flex gap-[12px] p-[10px]">
        {/* <div>
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.89581 7.45817L6.90623 9.59359L4.77081 10.5832L6.90623 11.5728L7.89581 13.7082L8.8854 11.5728L11.0208 10.5832L8.8854 9.59359L7.89581 7.45817ZM14.1458 7.45817L13.4687 8.86442L12.0625 9.5415L13.4687 10.2186L14.1458 11.6248L14.8229 10.2186L16.2291 9.5415L14.8229 8.86442L14.1458 7.45817ZM0.083313 16.8332V0.166504H2.16665L4.24998 4.33317H7.37498L5.29165 0.166504H7.37498L9.45831 4.33317H12.5833L10.5 0.166504H12.5833L14.6666 4.33317H17.7916L15.7083 0.166504H20.9166V16.8332H0.083313Z"
              fill="#A1A1AA"
            />
          </svg>
        </div> */}

        <div className="flex-1">
          <p className="text-[#3F3F46] text-[14px] mb-[5px] font-semibold">{item.imageTitle}</p>
          <div className="text-[#1F2A37] flex justify-between text-[12px]">
            <div>{formatedDate(item.updatedAt)}</div>
            {/* <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded rounded-[12px]">
              <TwitterIcon className="w-[14px] h-[14px]" fill="#1C64F2" />
              <LinkedInIcon className="w-[14px] h-[14px]" fill="#1C64F2" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LibraryImage;
