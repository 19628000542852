import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UploadImage from "../../assets/composer-upload.png";
import { deleteAssetFromDraft, getAllAssets } from "../../utils/asset";
import { uploadMedia } from "../../utils/uploadTos3";
import Loader from "../utils/Loader";

const ComposerUploadMedia = () => {
  const { id: trackId } = useParams();
  const { currentTrack } = useSelector(state => state.tracks);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);
  const addFile = useCallback(
    async (files) => {
      const newFiles = [...uploadedFiles, ...files];
      
      if (!currentTrack?.draft.id) return;
      setIsCurrentlyUploading(true);
      const data = await uploadMedia(newFiles, "composer", currentTrack.draft.id);

      setIsCurrentlyUploading(false);
      setUploadedFiles(data ?? []);
    },
    [setUploadedFiles, uploadedFiles, currentTrack]
  );

  useEffect(() => {
    (async () => {
      if (Number(trackId) !== currentTrack?.id) return;

      if (!currentTrack?.draft?.body_json?.assets) {
        setUploadedFiles([]);
        return;
      };

      const assets = await getAllAssets(currentTrack.draft.body_json.assets)

      setUploadedFiles(assets);
    })();
  }, [currentTrack]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) =>  file.type.startsWith("image"));

      addFile(filteredFiles);
    },
    [addFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".jpg", ".jpeg", ".png"],
    },
  });

  const removeUploadedMedia = async (indexToRemove) => {
    const files = [...uploadedFiles];

    const deletedSuccessfully = await deleteAssetFromDraft(
      currentTrack.draft.id,
      files[indexToRemove].id
    );

    if (deletedSuccessfully === false) return;

    const newFiles = [
      ...files.slice(0, indexToRemove),
      ...files.slice(indexToRemove + 1),
    ];

    setUploadedFiles(newFiles);
  };

  if (uploadedFiles?.length > 0 || isCurrentlyUploading) {
    return (
      <div className="flex justify-center items-center gap-[20px] flex-wrap mt-[80px]">
        {uploadedFiles.map((file, i) => {
          const fileSrc = file instanceof File ? window.URL.createObjectURL(file) : file.url;

          return (
            <div
              key={i}
              className="relative h-[200px] w-[200px] border border-gray rounded rounded-[12px] overflow-hidden"
            >

              <img src={fileSrc} alt="" className="h-full w-full object-contain" />

              <div
                className="absolute top-[10px] left-[10px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full"
                onClick={() => removeUploadedMedia(i)}
              >
                <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px]">
                  &times;
                </span>
              </div>
            </div>
          );
        })}
        {isCurrentlyUploading && (
          <div>
            <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
          </div>
        )}
        <div>
          <label
            className="flex items-center justify-center w-10 h-10 border rounded-full hover:cursor-pointer ml-[10px]"
            title="Upload more"
          >
            <input {...getInputProps()} />
            <div className="pb-0.5">+</div>
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div
        className="w-[min(680px,95vw)] h-[150px] bg-[#F8F9FA] rounded-[10px] flex flex-col justify-center items-center gap-[25px] p-5 mt-[80px]"
        {...getRootProps()}
      >
        <img src={UploadImage} alt="Upload" className="w-[45px]" />
        <p className="text-[#7B809A] text-[14px]">
          Drag & drop images to add them here.
        </p>
        <input className="hidden" multiple={true} {...getInputProps()} />
      </div>
    </div>
  );
};

export default ComposerUploadMedia;
