import { useNavigate } from "react-router-dom";
import Button from "../../utils/Button";

const LibraryQuestionsBottomBar = (props) => {
  const { onClick, loading, buttonText = "Next", acceptedTweesCount, variant } = props;
  const navigate = useNavigate();
  return (
    <div className="bg-[#f9fafb] w-full py-2 flex fixed bottom-0 z-50" style={{ borderTop: "0.5px solid #D4D4D8" }}>
      <button className="ml-5 w-fit flex items-center justify-center hover:cursor-pointer" onClick={() => navigate(-1)}>
        <svg
          className="mt-[1px] mr-1"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 15.8333L6.66667 9.99992L12.5 4.16659"
            stroke="#374151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="text-sm font-semibold text-[#374151]">Go Back</div>
      </button>

      <div className="ml-auto mr-5 w-fit flex items-center justify-center">
        {acceptedTweesCount ?? null}
        <Button loading={loading} className="ml-3" onClick={onClick} variant={variant}>
          <div className="flex justify-center items-center">
            {/* prettier-ignore */}
            <svg className="mt-[2px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.66675L13.3333 10.5001L7.5 16.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="text-sm font-[500] ml-1">{buttonText}</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default LibraryQuestionsBottomBar;
