import React, { useEffect, useState } from "react";
import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, Select, TimePicker, Anchor } from "antd";
// import { DatePicker, TimePicker } from "antd";
import TimezoneSelect from "react-timezone-select";
const Queues = () => {
  const { profileId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [Profile, setProfile] = useState({
    profileName: "",
  });
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  useEffect(() => {
    for (const pro of user.profiles) {
      if (pro.id == profileId) {
        // console.log(pro);
        setProfile(pro);
      }
    }
  }, [user]);
  const items = [
    {
      value: 0,
      label: "Everyday",
    },
    {
      value: 1,
      label: "Monday",
    },
    {
      value: 2,
      label: "Tuesday",
    },
    {
      value: 3,
      label: "Wednesday",
    },
    {
      value: 4,
      label: "Thursday",
    },
    {
      value: 5,
      label: "Friday",
    },
    {
      value: 6,
      label: "Saturday",
    },
    {
      value: 7,
      label: "Sunday",
    },
  ];
  const platformItems = [
    {
      key: "twitter",
      value: "twitter",
      href: "/twitter",
      label: "Twitter",
      title: "Twitter",
    },
    {
      key: "linkedin",
      value: "linkedin",
      href: "/linkedin",
      label: "Linkedin",
      title: "Linkedin",
    },
    {
      key: "instagram",
      value: "instagram",
      href: "/instagram",
      label: "Instagram",
      title: "Instagram",
    },
  ];
  const [selectedTime, setSelectedTime] = useState(null);

  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  return (
    <>
      <PostSigninNavbar />
      <div className="px-12 my-6 mt-16 flex-col">
        <div className="flex items-center space-x-4 w-fit  border-b-2 mb-2">
          {user &&
            user.profiles.map((item) => (
              <button
                onClick={() => setProfile(item)}
                className={`text-black px-3 py-1 text-sm ${item.id == Profile.id && "border-b-2 border-[#1D4ED8]"}`}
              >
                {item.profileName}
              </button>
            ))}
        </div>
        <h2 className=" text-lg">
          Your posting schedule for <span className="font-semibold">{Profile.profileName}</span>
        </h2>
        <div className="px-5 w-full flex-col my-2 items-center">
          <div className="flex items-center   justify-between">
            <h3 className="mt-6 mb-1 text-md">TimeZone</h3>
            <p className="mt-6 mb-1 text-sm font-light"> Stop sending new posts to this channel.</p>
          </div>
          <div className="flex items-center   justify-between">
            <TimezoneSelect className="w-2/5" value={selectedTimezone} onChange={setSelectedTimezone} />
            <div className="text-end">
              <button className="bg-[#1D4ED8] rounded-lg text-white px-3 py-2">Pause Queue</button>
            </div>
          </div>
          <div className="mt-10">
            <h3 className="mt-6 mb-1 text-md">Add a new posting time.</h3>
            <div className="flex items-center py-1">
              <Select
                // defaultValue="lucy"
                defaultValue={0}
                style={{
                  width: 120,
                }}
                // onChange={handleChange}
                options={items}
              />
              <p className="px-2 text-sm">Choose Time:</p>
              <TimePicker
                className="w-[170px] h-8 rounded-none border border-[#D1D5DB]"
                onChange={timePickerHandler}
                // getPopupContainer={() => datepickerParent.current}
                style={{ borderRadius: "6px" }}
              />
              <button className="bg-[#1D4ED8] rounded-lg text-white px-3 ml-2 py-2">Add Posting Time</button>
            </div>
          </div>
          <div className="mt-8">
            <Select
              defaultValue={"twitter"}
              style={{
                width: 160,
              }}
              options={platformItems}
            ></Select>
          </div>
          <div className="mt-8 w-2/4">
            <div className="flex pr-40 justify-between items-center">
              <h2 className="font-semibold">Posting Times:</h2>
              <button className="bg-white border-2 rounded-lg text-black px-3 py-2">Clear all Posting Times</button>
            </div>
            <div className="mt-4 flex">
              {items.slice(1, 8).map((item) => (
                <div className="px-2">
                  <div className="flex-col items-center justify-between">
                    <p className="text-sm">{item.label}</p>

                    <p className="text-sm py-1">
                      {selectedTime
                        ? `${selectedTime.hour + item.value - 1}:${selectedTime.minute - item.value}`
                        : "00:00"}
                    </p>
                    <p className="text-sm py-1">
                      {selectedTime
                        ? `${selectedTime.hour + item.value - 2}:${selectedTime.minute - item.value}`
                        : "00:00"}
                    </p>
                    <p className="text-sm py-1">
                      {selectedTime
                        ? `${selectedTime.hour + item.value - 3}:${selectedTime.minute - item.value}`
                        : "00:00"}
                    </p>
                    <p className="text-sm py-1">
                      {selectedTime
                        ? `${selectedTime.hour + item.value + 2}:${selectedTime.minute - item.value}`
                        : "00:00"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Queues;
