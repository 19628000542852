import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { verifyInvite } from "../redux/actions/authActions";

const VerifyInvite = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = `/composer/${user.profiles[0].tracks[0].id}`;
    }

    const token = searchParams.get("token");

    if (!token) {
      console.log("something went wrong");
      return;
    }

    (async () => {
      const verificationStatus = await dispatch(verifyInvite(token));

      if (verificationStatus === false) {
        navigate("/");
      }
    })();
  }, []);

  return <div>Verifying...</div>;
};

export default VerifyInvite;
