import axios from "axios";

export const createCheckOutSession = async (planId) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/stripe/create-stripe-checkout-session`,
      { planId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status) {
      return data.data.sessionId;
    }
  } catch (error) {
    console.log(error, "in stripe action");

    return {
      status: false,
    };
  }
};

export const stripeCustomerPortal = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/stripe/customer-portal`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error, "in setting action");
    return {
      status: false,
    };
  }
};

// Get the stripe session from session id
export const updateStripe = async (sessionId) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/stripe/update`,
      { sessionId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (res.data.status === "success") {
      return res.data.data;
    }
  } catch (error) {
    console.log(error, "in stripe action");

    return {
      status: false,
    };
  }
}
