const initState = {
  loading: false,
  tracks: {},
  currentTrack: null,
  currentProfile: null,
};
function filterProcessing(data, newdata) {
  console.log(data);
  console.log(newdata);
  if (!data) return newdata;
  console.log("'returning");

  for (const tw of data.twitterPost) {
    if (tw.status == "processing") {
      return newdata;
    }
  }
  for (const tw of data.instaPost) {
    if (tw.status == "processing") {
      return newdata;
    }
  }
  for (const tw of data.linkedInPost) {
    if (tw.status == "processing") {
      return newdata;
    }
  }

  return data; // Return data if no processing statuses found
}

const tracksReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_TRACK":
      return {
        ...state,
        tracks: action.payload,
      };
    case "SET_CURRENT_TRACK":
      return {
        ...state,
        currentTrack: action.payload,
      };
    case "SET_CURRENT_TRACK_AUTO_RELOAD":
      const filteredTrack = filterProcessing(state.currentTrack, action.payload);
      return {
        ...state,
        currentTrack: filteredTrack,
      };
    case "SET_CURRENT_PROFILE":
      return {
        ...state,
        currentProfile: action.payload,
      };
    default:
      return state;
  }
};

export default tracksReducer;
