import { Navigate, Route, Routes } from "react-router-dom";
import ConfirmEmail from "../pages/ConfirmEmail";
import Login from "../pages/Login";
import Register from "../pages/Register";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import VerifyEmail from "../pages/VerifyEmail";
import VerifyInvite from "../pages/VerifyInvite";

const UnprotectedRoute = () => {
  return (
    <Routes>
      <Route path="/signup/:code?" element={<SignUp />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="/verify-invite" element={<VerifyInvite />} />

      {/* FOR AUTH */}
      <Route path="/register" element={<Register />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/" element={<Login />} />

      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default UnprotectedRoute;
