import { useDispatch } from "react-redux";
import { toggleRescheduleTrackModal } from "../../redux/actions/modalActions";
import Dots from "./Dots";
import ListItem from "./ListItem";
import { useNavigate } from "react-router-dom";
const progressColors = {
  0: {
    background: "#fde8e8",
    text: "#E02424",
  },
  1: {
    background: "#FDE8E8",
    text: "#E02424",
  },
  2: {
    background: "#FEECDC",
    text: "#FF5A1F",
  },
  3: {
    background: "#DEF7EC",
    text: "#31C48D",
  },
};

const ScheduleList = (props) => {
  const navigate = useNavigate();
  const { schedulingTracks, getAllTracks, profileId } = props;
  const dispatch = useDispatch();

  return schedulingTracks.map((schedule, i) => {
    let totalScheduledPlatforms = 0;

    for (let platform in schedule.scheduledPlatform) {
      if (schedule.scheduledPlatform[platform] === true) {
        totalScheduledPlatforms++;
      }
    }

    const backgroundColor = progressColors[totalScheduledPlatforms]["background"];
    const textColor = progressColors[totalScheduledPlatforms]["text"];
    const totalScore = totalScheduledPlatforms;
    // console.log(schedule);
    return (
      <ListItem key={i}>
        <div className="flex items-center h-full">
          <div
            className={`w-[6px] h-[6px] md:w-[10px] md:h-[10px] bg-[${schedule.backgroundColor}] rounded-full mr-[8px] z-9999`}
            style={{
              backgroundColor: schedule.backgroundColor,
            }}
          ></div>
          <div className="text-[12px] md:text-[18px] text-[#374151] mr-auto w-[50%] whitespace-nowrap text-ellipsis overflow-hidden">
            {schedule.draft.body_json.title}
          </div>
          {/* <div className={`rounded-[10px] px-[10px] py-[2px]`} style={{ backgroundColor, color: textColor }}>
            {totalScore} / 3
          </div> */}

          <div className="w-px ml-[70px]"></div>
          <div className="min-w-[100px] text-center">
            <span
              className="text-[#1C64F2] text-sm md:text-base font-semibold cursor-pointer"
              // onClick={() => dispatch(toggleRescheduleTrackModal(true, schedule.id))}
              onClick={() => navigate(`/staging/${schedule.id}`)}
            >
              Edit
            </span>
          </div>
          <Dots getAllTracks={getAllTracks} trackId={Number(schedule.id)} profileId={Number(profileId)} schedule />
        </div>
      </ListItem>
    );
  });
};

export default ScheduleList;
