import axios from "axios";
import { confirmationModal, failAlert } from "../../utils/sweetAlert";

const Teams = ({ user }) => {
  const handleDeleteChange = async (profileId) => {
    confirmationModal(
      {
        text: "You'll lose all the data related to this company. Are you sure you want to delete this company?",
        confirmButtonText: "Yes",
      },
      async () => {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/team/delete-profile`,
            {
              profileId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );
          if (res.data.status) {
            window.location.reload();
          }
        } catch (err) {
          console.log(err);
          failAlert({
            title: "Something went wrong",
          });
        }
      }
    );
  };

  return (
    <>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 notdark:divide-neutral-700">
              <thead className="bg-gray-50 notdark:bg-neutral-700">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6"
                  >
                    Company ID
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6 text-center"
                  >
                    Company Name
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white notdark:bg-neutral-800">
                {user.profiles &&
                  user.profiles.map((profile) => (
                    <tr key={profile.id} className="border-2">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 notdark:text-white sm:pl-6 text-center">
                        {profile.id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400 text-center">
                        {profile.profileName}
                      </td>
                      {user.id == profile.userId ? (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400 text-center">
                          <button className="text-red-600" onClick={() => handleDeleteChange(profile.id)}>
                            Delete
                          </button>
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400 text-center">
                          <></>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
