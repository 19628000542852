import React, { useEffect } from "react";

function InstagramAccountSelection(props) {
  // useEffect(() => {
  //   const data = props.instaAccDetails[0];

  //   // FIXME:
  //   // remove when needed the modal
  //   // if (props.instaAccDetails && props.instaAccDetails[0]) {
  //     props.handleCheckboxChange({ target: { checked: true } }, data);
  //     // props.handleInstagramSubmit();
  //   // }
  // }, []);

  // return <></>;

  // eslint-disable-next-line no-unreachable
  return (
    <>
      <div
        className="fixed inset-0 z-[99] min-h-screen min-w-screen flex justify-center items-center"
        aria-label="modal"
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[90]"
          onClick={() => props.setInstaAccountSelectionModal(false)}
        ></div>
        <div className="relative z-[99] bg-white rounded-[10px] p-7 w-[450px] show-modal">
          <div
            className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
            onClick={() => props.setInstaAccountSelectionModal(false)}
          >
            &times;
          </div>
          <h3 className="text-lg font-semibold ">Which channels whould you like to add?</h3>
          <p className="text-md text-[#6B7280]">
            <span className="text-red-500">*</span>You can connect only 1 instagram account.
          </p>
          <hr />

          <div className="items-center mt-3 ">
            {props.instaAccDetails.map((account, index) => (
              <div className="mb-3 block min-h-[1.5rem] pl-[1.5rem]">
                <input
                  className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[0.8rem] w-[1.125rem]"
                  type="radio"
                  name="checkboxDefault"
                  id="checkboxDefault"
                  onChange={(event) => {
                    props.handleCheckboxChange(event, account);
                  }}
                />
                <div className="">
                  <p className="pl-[0.15rem] p-0 m-0 leading-tight text-md font-semibold hover:cursor-pointer">
                    {account.username}
                  </p>
                  <p className="pl-[0.15rem] p-0 m-0 leading-tight text-sm text-gray-500 hover:cursor-pointer">
                    Instagram Business
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center">
            <button
              className={`py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#D1D5DB] flex items-center justify-center transition duration-300`}
              onClick={() => props.setInstaAccountSelectionModal(false)}
            >
              <span className={`text-sm font-[500] text-semibold text-[#111928]`}>Cancel</span>
            </button>
            <button
              className={`py-[10px] px-[15px] w-fit rounded-[12px]  border border-[#D1D5DB] flex items-center justify-center transition duration-300`}
              onClick={() => {
                props.handleInstagramSubmit();
              }}
            >
              <span className={`text-sm font-[500] text-semibold text-[#111928]`}>Add to Wordout</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InstagramAccountSelection;
