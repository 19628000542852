import { combineReducers } from "redux";

import auth from "./authReducer";
import tracks from "./trackReducer";
import postValidationReducer from "./stageReducer";
import { modalReducer } from "./modalReducer";
import { notificationsReducer } from "./notificationReducer";
import { saveAsDraftReducer } from "./saveAsDraftReducer";
import tourReducer from "./tourReducer";
import scheduleReducer from "./scheduleReducer";
import library from "./library";
import team from "./teamReducer";

export default combineReducers({
  auth,
  tracks,
  stagePostValidation: postValidationReducer,
  modals: modalReducer,
  notifications: notificationsReducer,
  appTour: tourReducer,
  schedules: scheduleReducer,
  saveAsDraft: saveAsDraftReducer,
  library,
  team,
});
