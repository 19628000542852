import React from "react";
import { Link } from "react-router-dom";

const PreSigninNavbar = () => {
  return (
    <nav className="bg-white py-2" style={{ borderBottom: "1px solid #E5E7EB" }}>
      <div className="mx-auto px-2 sm:px-4">
        <div className="relative flex items-center justify-between">
          <div className="m-1 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img src="/Images/Navbar/WordOut-Logo.png" className="w-auto h-[15px] pr-3" alt="Wordout" />
            </div>
            <div className="ml-auto flex justify-center items-center">
              <svg
                className="mt-0.7"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 13L5 9M5 9L9 5M5 9H19M14 13V14C14 14.7956 13.6839 15.5587 13.1213 16.1213C12.5587 16.6839 11.7956 17 11 17H4C3.20435 17 2.44129 16.6839 1.87868 16.1213C1.31607 15.5587 1 14.7956 1 14V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H11C11.7956 1 12.5587 1.31607 13.1213 1.87868C13.6839 2.44129 14 3.20435 14 4V5"
                  stroke="#111928"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm font-medium text-[#6B7280] ml-3">
                <Link to="/signin">Login</Link>/<Link to="/signup">Register</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PreSigninNavbar;
