import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import LinkedinPreview from "../components/StagePreview/LinkedinPreview";
import LinkedIn from "../components/StagePreview/LinkedIn";
import Convertkit from "../components/StagePreview/Convertkit";
import WebflowPreview from "../components/StagePreview/WebflowPreview";
import Twitter from "../components/StagePreview/Twitter";
import Instagram from "../components/StagePreview/Instagram";
import PreviewButtons from "../components/StagePreview/PreviewButtons";
import TwitterPreview from "../components/StagePreview/TwitterPreview";
import InstagramPreview from "../components/StagePreview/InstagramPreview";
import BottomBar from "../components/BottomBar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrackById } from "../redux/actions/trackActions";
import { useNavigate, useParams } from "react-router-dom";
import { updateInstagramPostValidState, updateLinkedinPostValidState } from "../redux/actions/stageActions";
import ErrorBoundary from "../components/utils/ErrorBoundary";

const Staging = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = React.useState(0);
  const { currentTrack } = useSelector((state) => state.tracks);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [data, setData] = React.useState(null);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [savedTime, setsavedTime] = React.useState(null);

  const navigate = useNavigate();

  const saveDraft = () => {
    setsavedTime(new Date());
  };

  const [platform, setplatform] = React.useState({
    twitter: false,
    linkedin: false,
    instagram: false,
  });

  React.useEffect(() => {
    if (isAuthenticated === true) {
      var profiles = user.profiles;
      for (var i = 0; i < profiles.length; i++) {
        var righttrack = false;
        for (var j = 0; j < profiles[i].tracks.length; j++) {
          if (profiles[i].tracks[j].id === parseInt(id)) {
            righttrack = true;
          }
        }
        if (righttrack) {
          var socialAccounts = profiles[i].socialAccounts;
          var tempselectedplatform = {
            twitter: false,
            linkedin: false,
            instagram: false,
          };

          for (let j = 0; j < socialAccounts.length; j++) {
            if (socialAccounts[j].accountType === "twitter") {
              tempselectedplatform.twitter = socialAccounts[j].authJSON;
              // console.log("found twitter");
              // console.log(socialAccounts[j].authJSON);
            }
            if (socialAccounts[j].accountType === "linkedin") {
              tempselectedplatform.linkedin = socialAccounts[j].authJSON;
            }
            if (socialAccounts[j].accountType === "instagram") {
              tempselectedplatform.instagram = socialAccounts[j].authJSON;
            }
          }

          setplatform(tempselectedplatform);
        }
      }
    }
  }, [isAuthenticated, user, id]);

  useEffect(() => {
    if (currentTrack) {
      if (currentTrack?.status === "published") {
        window.location.href = `/${currentTrack.profileId}/tracks`;
        return;
      }

      if (currentTrack.id !== parseInt(id)) {
        dispatch(getTrackById(parseInt(id)));
      }
      setData(currentTrack);
    }
  }, [currentTrack, dispatch, id]);

  useEffect(() => {
    const linkedinLimit = 3000;

    // if (data?.linkedInPost?.body_json.texts) {
    //   dispatch(updateLinkedinPostValidState(data.linkedInPost.body_json.texts.length < linkedinLimit));
    // }
  }, [data, dispatch]);

  const onClickHandler = async () => {
    navigate(`/${currentTrack.profileId}/tracks/true/${currentTrack.id}`);
  };


  useEffect(() => {
    dispatch(getTrackById(parseInt(id)));
  }, [activeButton]);
  // Auto call getTrackById every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTrackById(parseInt(id), currentTrack));
    }, 45000);
    return () => clearInterval(interval);
  }, [dispatch, id]);

  return (
    <div className="max-w-screen min-h-screen flex flex-col" id="staging-area">
      <PostSigninNavbar title={currentTrack?.draft.body_json.title ?? ""} editableTitle />
      <div className="animate-fade-in ">
        <PreviewButtons activeButton={activeButton} setActiveButton={setActiveButton} />
        {data && activeButton === 0 && (
          <div className="flex justify-center flex-1 my-7 mb-[100px]">
            <div>
              <ErrorBoundary>
                <Twitter data={data} profile={platform} setSavedTime={setsavedTime} setData={setData} />
              </ErrorBoundary>
            </div>
          </div>
        )}
        {data && activeButton === 1 && (
          <ErrorBoundary>
            <div className="flex justify-center flex-1 mt-7 mb-[100px] animate-fade-in ">
              <Instagram data={data} setsavedTime={setsavedTime} uploadedFiles={uploadedFiles} setData={setData} />
            </div>
          </ErrorBoundary>
        )}
        {data && activeButton === 2 && (
          <div className="flex justify-center flex-1 my-7 mb-[100px]">
            <div>
              <ErrorBoundary>
                <LinkedIn setSavedTime={setsavedTime} profile={platform} data={data} setData={setData} />
              </ErrorBoundary>
            </div>
          </div>
        )}
        {data && activeButton === 3 && (
          <div className="flex justify-center flex-1 my-7 mb-[100px]">
            <div className="w-full flex justify-center">
              <ErrorBoundary>
                <Convertkit setSavedTime={setsavedTime} profile={platform} data={data} setData={setData} />
              </ErrorBoundary>
            </div>
          </div>
        )}
        {data && activeButton === 4 && (
          <div className="flex justify-center flex-1 my-7 mb-[100px]">
            <div className="w-full flex justify-center">
              <ErrorBoundary>
                <WebflowPreview setSavedTime={setsavedTime} profile={platform} data={data} setData={setData} />
              </ErrorBoundary>
            </div>
          </div>
        )}
      </div>
      <BottomBar stage={1} onClickHandler={onClickHandler} savedTime={savedTime} saveDraft={saveDraft} trackId={id} />
    </div>
  );
};

export default Staging;
