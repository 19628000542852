import BottomBar from "../components/BottomBar";
import React, { useEffect, useState, useRef, startTransition } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import { useDispatch, useSelector } from "react-redux";
import { updateDraft, updateDraftTitle, transformDraft } from "../redux/actions/draftActions";
import { updateTrack, getTrackById } from "../redux/actions/trackActions";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import ComposerUploadMedia from "../components/Composer/ComposerUploadMedia";
import decode from "html-entities-decoder";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import Modal from "../components/utils/Modal";
import { failAlert, successAlert } from "../utils/sweetAlert";
// To strip html from the text that should be plain
function stripHtml(html) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const Composer = () => {
  const ejInstance = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentTrack } = useSelector((state) => state.tracks);
  const { id } = useParams();

  const [title, setTitle] = useState(currentTrack?.draft?.body_json.title ?? "");
  const [savedTime, setsavedTime] = useState(null);
  const [isModalHidden, setisModalHidden] = useState(true);
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const [transformMetadata, setTransformMetadata] = useState({
    tweet: 1,
    thread: 1,
    insta: 1,
    linkedin: 1,
    comment: null,
  });
  const onTransformMetadataChange = (e) => {
    const { name, value } = e.target;
    if (name === "comment" && value.length > 200) return alert("Comment should be less than 200 characters");
    setTransformMetadata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [draftJson] = useState({ title, editor: [] });

  useEffect(() => {
    if (currentTrack?.draft?.body_json.editor) {
      setIsEditorEmpty(currentTrack.draft?.body_json.editor.length > 0 ? false : true);
    }
  }, [currentTrack]);

  const initEditor = (draft) => {
    const editor = new EditorJS({
      holder: "editorjs",
      tools: {
        header: Header,
        list: List,
      },
      data: {
        time: new Date().getTime(),
        blocks: draft.body_json.editor,
      },
      onReady: () => {
        ejInstance.current = editor;
      },
      placeholder: "Let`s write an awesome story",
      onChange: _.debounce(() => saveDraft(), 200),
    });
  };

  const saveDraft = async () => {
    var data = [];
    var djson = {};
    const oldData = draftJson;

    ejInstance.current
      ?.save()
      .then(async (outputData) => {
        await outputData.blocks.forEach((element) => {
          var tmp = {
            type: element.type,
            data: element.data,
          };
          data.push(tmp);
        });

        djson = { currentTrack: parseInt(id), editor: data };

        if (!_.isEqual(oldData, djson)) {
          await dispatch(updateDraft(djson));
          setIsEditorEmpty(djson.editor.length > 0 ? false : true);

          const manuallyUpdatedDrafts = Number(sessionStorage.getItem("draftUpdatedManually"));

          if (manuallyUpdatedDrafts !== Number(id) && !currentTrack.draft.body_json.titleUpdatedManually) {
            let newTitle = "";
            if (data[0]?.type === "paragraph" || data[0]?.type === "header") {
              newTitle = decode(
                stripHtml(
                  data[0].data.text
                    .split(" ")
                    .filter((_, i) => i < 5)
                    .join(" ")
                    .trim()
                )
              );
            }

            if (data[0]?.type === "list") {
              newTitle = decode(stripHtml(data[0].data.items[0].trim()));
            }

            saveDraftTitle(newTitle);
            setTitle(newTitle.trim());
          }
        } else {
          console.log("equal");
        }

        setsavedTime(new Date());
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  const saveDraftTitle = (title, updatedManually) => {
    if (updatedManually) {
      dispatch(updateDraftTitle(Number(id), title, true));
    } else {
      dispatch(updateDraftTitle(Number(id), title));
    }
    setsavedTime(new Date());
  };

  const onClickHandler = async () => {
    sessionStorage.removeItem("draftUpdatedManually");

    await dispatch(updateTrack(currentTrack.id));
    navigate(`/staging/${id}`);
  };

  useEffect(() => {
    if (currentTrack?.draft?.body_json.title) {
      setTitle(currentTrack?.draft?.body_json.title);
    }
  }, [currentTrack?.draft?.body_json.title]);

  useEffect(() => {
    if (!id) return;

    if (!currentTrack || currentTrack.id !== Number(id)) {
      dispatch(getTrackById(parseInt(id)));
    }
  }, [currentTrack]);

  useEffect(() => {
    if (!ejInstance.current && currentTrack && currentTrack.id == id) {
      initEditor(currentTrack.draft);
    }

    return () => {
      ejInstance.current = null;
    };
  }, [currentTrack, id]);
  const showTransformModal = async () => {
    setisModalHidden(false);
  };
  const startTransformation = async (e) => {
    e.preventDefault();
    const { tweet, thread, insta, linkedin, comment } = transformMetadata;
    const data = {
      tweet,
      thread,
      insta,
      linkedin,
      comment,
      trackId: id,
    };
    const res = await dispatch(transformDraft(data));
    if (res) {
      successAlert({ title: "Transformation Started." });
      navigate(`/staging/${id}`);
    } else {
      failAlert({ title: "Something went wrong." });
    }
    setisModalHidden(true);
  };
  return (
    <div className="min-h-screen flex flex-col animate-fade-in bg-white">
      <PostSigninNavbar title={title} editableTitle />
      <Sidebar />
      {!isModalHidden && (
        <Modal isModalHidden={isModalHidden} setisModalHidden={setisModalHidden}>
          <div className="flex flex-col px-4">
            <div className="text-2xl font-bold text-center">Transform & Send to Staging</div>
            <div className="text-sm text-gray-500 font-normal text-center">
              Specify number of posts/tweets you want to generate.
            </div>
            <form action="" onSubmit={startTransformation}>
              <div className="flex mt-8 items-center">
                <label htmlFor="tweet" className="font-normal mr-2">
                  Tweets :
                </label>
                <input
                  type="number"
                  name="tweet"
                  id="tweet"
                  max={10}
                  min={1}
                  value={transformMetadata.tweet}
                  onChange={onTransformMetadataChange}
                  className="input border-2 w-12 rounded-lg text-center"
                />
                <span className="text-xs text-gray-400 ml-4">(Max 10)</span>
              </div>
              <div className="flex mt-3 items-center">
                <label htmlFor="thread" className="font-normal mr-2">
                  Twitter Threads :
                </label>
                <input
                  type="number"
                  name="thread"
                  id="thread"
                  max={5}
                  min={1}
                  value={transformMetadata.thread}
                  onChange={onTransformMetadataChange}
                  className="input border-2 w-12 rounded-lg text-center"
                />
                <span className="text-xs text-gray-400 ml-4">(Max 5)</span>
              </div>
              <div className="flex mt-3 items-center">
                <label htmlFor="insta" className="font-normal mr-2">
                  Instagram Post :
                </label>
                <input
                  type="number"
                  name="insta"
                  id="insta"
                  max={5}
                  min={1}
                  value={transformMetadata.insta}
                  onChange={onTransformMetadataChange}
                  className="input border-2 w-12 rounded-lg text-center"
                />
                <span className="text-xs text-gray-400 ml-4">(Max 5)</span>
              </div>
              <div className="flex mt-3 items-center">
                <label htmlFor="linkedin" className="font-normal mr-2">
                  LinkedIn Post :
                </label>
                <input
                  type="number"
                  name="linkedin"
                  id="linkedin"
                  max={5}
                  value={transformMetadata.linkedin}
                  onChange={onTransformMetadataChange}
                  min={1}
                  className="input border-2 w-12 rounded-lg text-center"
                />
                <span className="text-xs text-gray-400 ml-4">(Max 5)</span>
              </div>
              <div className="flex mt-3 items-center h-fit">
                <label htmlFor="linkedin" className="font-normal mr-2">
                  Comment:
                </label>
                <input
                  type="text"
                  multiple={true}
                  max={5}
                  name="comment"
                  id="comment"
                  value={transformMetadata.comment}
                  onChange={onTransformMetadataChange}
                  className="input border-2 w-40  rounded-lg text-center min-h-2 max-h-[8rem]"
                />
                <span className="text-xs text-gray-400 ml-4 w-40">
                  (Optional. <br />
                  Max 200 chars)
                </span>
              </div>
              <div className="w-full flex mt-6 items-center">
                <button
                  type="submit"
                  className=""
                  style={{
                    marginLeft: "auto",
                    background: "#1C64F2",
                    padding: "0.75rem 1.2rem",
                    fontSize: "1rem",
                    borderRadius: "0.7rem",
                    color: "#fff",
                    fontWeight: "normal",
                    border: "none",
                  }}
                >
                  Transform & Send to Staging
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <div className="bg-[#fff]">
        <ComposerUploadMedia />

        <div className="mt-10 pt-5">
          <div id="editorjs" className="w-[95vw] mx-auto"></div>
        </div>

        <BottomBar
          savedTime={savedTime}
          saveDraft={saveDraft}
          stage={0}
          showTransformModal={showTransformModal}
          disabled={isEditorEmpty}
          trackId={id}
          onClickHandler={onClickHandler}
        />
      </div>
    </div>
  );
};

export default Composer;
