import axios from "axios";
import Modal from "../components/utils/Modal";
import PaymentModal from "../components/PaymentModal";
import React, { useState, useEffect } from "react";
import Members from "../components/Settings/members";
import Teams from "../components/Settings/Teams";
import { useDispatch, useSelector } from "react-redux";
import { getTeamData } from "../redux/actions/teamActions";
import { failAlert, successAlert } from "../utils/sweetAlert";
import { getUserBillingInfo } from "../redux/actions/authActions";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
const Settings = () => {
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state.team);
  const { user } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { currentProfile } = useSelector((state) => state.tracks);
  useEffect(() => {
    if (!currentProfile) return;
    // console.log(user);
    dispatch(getTeamData(currentProfile));
  }, [currentProfile]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { stripeCustomer } = await getUserBillingInfo();

      if (!stripeCustomer) return;

      setCurrentPlan(stripeCustomer);
      setIsLoading(false);
    })();
  }, []);

  const [membersData, setMembersData] = useState(members);
  const [isModalHidden, setisModalHidden] = useState(true);

  const handleInvite = async (e) => {
    try {
      e.preventDefault();
      const email = e.target.email.value;
      const role = e.target.role.value;
      const fullName = e.target.fullName.value;
      const profileId = e.target.profileId.value;
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/team/invite-members`,
        {
          teamId: user.teamMembers[0].teamId,
          email,
          fullName,
          role,
          profileId: Number(profileId),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (data.status) {
        dispatch(getTeamData(user.teamMembers[0].teamId));

        setisModalHidden(true);
        successAlert({
          title: "Invitation sent successfully",
        });
      } else {
        failAlert({
          title: data.message,
        });
      }
    } catch (err) {
      console.log(err);
      failAlert({
        title: "Something went wrong",
      });
    }
  };

  const planDetails = [
    {
      name: "Team",
      price: "$450.00",
      support: "/month",
      description: "One Organization and upto 8 Team Member per organization",
      plan: process.env.REACT_APP_STRIPE_STARTUP_PLAN_ID,
    },
    {
      name: "Team Agency",
      price: "$600.00",
      support: "/month/organization",
      description: "Unlimited Organization and upto 8 Team Member per organization",
      plan: process.env.REACT_APP_STRIPE_STARTUP_PLUS_PLAN_ID,
    },
  ];

  const handleAddMember = () => {
    // if (!isLoading && currentPlan && (currentPlan.plan === "TEAM_PLAN" || currentPlan.plan === "TEAM_AGENCY_PLAN")) {
    //   setisModalHidden(!isModalHidden);
    // } else {
    //   setIsModalOpen(true);
    // }
    setisModalHidden(!isModalHidden);
  };

  return (
    <>
      <PostSigninNavbar />
      <div className="container px-1 md:px-20 py-8 mx-auto mt-[45px] animate-fade-in">
        {!isModalHidden && (
          <Modal setisModalHidden={setisModalHidden}>
            <>
              <h2>Invite Members</h2>
              <form className="mt-8 space-y-2" onSubmit={handleInvite}>
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="fullName" className="sr-only">
                      Full Name
                    </label>
                    <input
                      id="fullName"
                      name="fullName"
                      type="fullName"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 notdark:border-neutral-700 placeholder-gray-500 notdark:placeholder-neutral-400 text-gray-900 notdark:text-white rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 notdark:border-neutral-700 placeholder-gray-500 notdark:placeholder-neutral-400 text-gray-900 notdark:text-white rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="profileId" className="sr-only">
                      Role
                    </label>
                    <select name="profileId">
                      {user.profiles.map((profile) => (
                        <option value={profile.id}>{profile.profileName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Role
                    </label>
                    <select name="role">
                      <option value="admin">Admin</option>
                      <option value="member">Member</option>
                    </select>
                  </div>
                </div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-3 px-4  text-sm font-medium rounded-md text-white bg-[#1C2026]  "
                >
                  Invite
                </button>
              </form>
            </>
          </Modal>
        )}
        {user.teamMembers && user.teamMembers[0].role !== "member" && (
          <div className="w-full flex justify-between">
            <h2>Team Members</h2>
            <button
              className="bg-primary-500 hover:bg-primary-600 text-black font-semibold py-2 px-4 rounded"
              onClick={() => handleAddMember()}
            >
              Add Member
            </button>
          </div>
        )}
        <Members members={members} user={user} />
        <div className="w-full flex-col justify-between">
          <h2 className="mt-4">Teams</h2>
          {/* Teams */}
          <Teams user={user} />
        </div>
        {isModalOpen && (
          <PaymentModal planDetails={planDetails} currentPlan={currentPlan} setIsModalOpen={setIsModalOpen} />
        )}
      </div>
    </>
  );
};

export default Settings;
