import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import Button from "../components/utils/Button";
import Loader from "../components/utils/Loader";
import { getUserBillingInfo, referredUser } from "../redux/actions/authActions";
import { updateStripe } from "../utils/stripe";
import { handleSubscription } from "../utils/stripeClient";
import { failAlert, successAlert } from "../utils/sweetAlert";
import Modal from "../components/utils/Modal";

const Billing = () => {
  const dispatch = useDispatch();
  const [currentPlan, setCurrentPlan] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [searchParams] = useSearchParams();
  const { currentProfile } = useSelector((state) => state.tracks);
  const { buy } = useParams();
  const [isModalHidden, setisModalHidden] = useState(true);

  const [credits, setCredits] = useState({
    credits: 0,
    creditsUsed: 0,
    progressBarWidth: 0,
  });
  const { isAuthenticated, user, referredUsers } = useSelector((state) => state.auth);
  // Perform action after payment
  useEffect(() => {
    if (!user) return;
    dispatch(referredUser(user.id));
  }, [user]);
  useEffect(() => {
    if (!currentProfile) {
      console.log(currentProfile);
      return;
    }
    console.log(currentProfile);
    const acredits = currentProfile.wordoutCredits || 0;
    const creditsUsed = currentProfile.wordoutCreditsUsed || 0;

    // Calculate the width of the progress bar (don't let it exceed 100%)
    const progressBarWidth = Math.min((creditsUsed / acredits) * 100, 100);
    setCredits({ credits: acredits, creditsUsed, progressBarWidth });
  }, [currentProfile]);
  useEffect(() => {
    const handleStripe = async () => {
      const stripeSessionId = searchParams.get("session");
      const checkoutResult = searchParams.get("checkout");

      if (!stripeSessionId || !checkoutResult) return;

      if (checkoutResult === "fail") {
        failAlert({ title: "Payment failed" });
        return;
      }

      if (!stripeSessionId) return;

      successAlert({ title: "Payment successful" });

      const session = await updateStripe(stripeSessionId);

      console.log(session);

      if (isAuthenticated && user) {
        window.location.href = `/composer/${user.profiles[0].tracks[0].id}`;
      }
    };

    (async () => {
      await handleStripe();
    })();
  }, [isAuthenticated, user]);

  useEffect(() => {
    (async () => {
      const { stripeCustomer } = await getUserBillingInfo();

      if (!stripeCustomer) return;

      setCurrentPlan(stripeCustomer);
    })();
  }, []);

  const buyPlan = async (plan) => {
    try {
      setSelectedPlan(plan);

      var selectedplan = "";

      switch (plan) {
        case process.env.REACT_APP_STRIPE_WORDOUT_SUBSCRIPTION_PLAN_ID:
          selectedplan = "WORDOUT_SUBSCRIPTION";
          break;
        case process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_ID:
          selectedplan = "INDIVIDUAL_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_INDIVIDUAL_PLUS_PLAN_ID:
          selectedplan = "INDIVIDUAL_AGENCY_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_STARTUP_PLAN_ID:
          selectedplan = "TEAM_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_STARTUP_PLUS_PLAN_ID:
          selectedplan = "TEAM_AGENCY_PLAN";
          break;
        default:
          selectedplan = plan;
      }

      // if (
      //   (currentPlan.plan === "TEAM_AGENCY_PLAN" || currentPlan.plan === "TEAM_PLAN") &&
      //   user.teamMembers.length > 1 &&
      //   selectedplan !== "TEAM_PLAN"
      // ) {
      //   setSelectedPlan(null);
      //   failAlert({
      //     title:
      //       "Sorry, You cannot downgrade your plan from your existing plan at this time. Please contact our support team for further assistance.",
      //   });
      //   return;
      // }

      // if (
      //   (currentPlan.plan === "INDIVIDUAL_AGENCY_PLAN" || currentPlan.plan === "TEAM_AGENCY_PLAN") &&
      //   user.profiles.length > 1 &&
      //   (selectedplan === "INDIVIDUAL_PLAN" || selectedplan === "TEAM_PLAN")
      // ) {
      //   setSelectedPlan(null);
      //   failAlert({
      //     title:
      //       "Sorry, You cannot downgrade your plan from your existing plan at this time. Please delete a organization before downgrading.",
      //   });
      //   return;
      // }

      if (plan) {
        const data = await handleSubscription(plan);

        if (data === false) throw new Error("Sorry, something went wrong");
      }
    } catch (error) {
      failAlert({ title: "Sorry, something went wrong" });
      setSelectedPlan(null);
    }
  };
  const cancel = async () => {
    setCancelling(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/stripe/cancel-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (res.data?.status === true) {
        successAlert({
          title: "Success",
          didOpen() {
            window.location.reload();
          },
        });
        return;
      }

      throw new Error("Sorry, something went wrong");
    } catch (error) {
      console.log(error);
      failAlert({
        title: error?.response?.data.message ?? "Sorry, something went wrong",
      });
    }

    setCancelling(false);
  };

  const openCustomerPortal = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/stripe/customer-portal`,
        { id: user.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (data?.status === true) {
        window.open(data.portal, "_blank");
      }
    } catch (error) {
      console.log(error);
      failAlert({
        title: "Sorry, something went wrong",
      });
    }
  };
  useEffect(() => {
    if (buy && buy === "true") {
      buyPlan(process.env.REACT_APP_STRIPE_WORDOUT_SUBSCRIPTION_PLAN_ID);
    }
  }, [buy]);
  const planDetails = [
    {
      name: "WordOut Subscription",
      price: "$20.00",
      support: "/Month/Organization",
      description: "Upto 8 Organizations and 8 Team Members",
      description2: `<p class="text-center">We bill based on usage after the $20/mo base.<br/>
      <span class="text-left">
      $0.20/1k tokens for Generated Assets<br/>
      $0.20/Published Asset<br/>
      $0.20/1k Views</span></p>`,
      plan: process.env.REACT_APP_STRIPE_WORDOUT_SUBSCRIPTION_PLAN_ID,
    },
    // {
    //   name: "Individual",
    //   price: "$37.00",
    //   support: "/month",
    //   description: "One Organization and no Team Member",
    //   plan: process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_ID,
    // },
    // {
    //   name: "Individual Agency",
    //   price: "$300.00",
    //   support: "/month/organization",
    //   description: "Unlimited Organization and no Team Member",
    //   plan: process.env.REACT_APP_STRIPE_INDIVIDUAL_PLUS_PLAN_ID,
    // },
    // {
    //   name: "Team",
    //   price: "$450.00",
    //   support: "/month",
    //   description: "One Organization and upto 8 Team Member per organization",
    //   plan: process.env.REACT_APP_STRIPE_STARTUP_PLAN_ID,
    // },
    // {
    //   name: "Team Agency",
    //   price: "$600.00",
    //   support: "/month/organization",
    //   description: "Unlimited Organization and upto 8 Team Member per organization",
    //   plan: process.env.REACT_APP_STRIPE_STARTUP_PLUS_PLAN_ID,
    // },
  ];

  const handleInvite = async (e) => {
    try {
      e.preventDefault();
      const email = e.target.email.value;
      const fullName = e.target.fullName.value;
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/refer-user`,
        {
          email,
          name: fullName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (data.status) {
        dispatch(referredUser(user.id));
        setisModalHidden(true);
        successAlert({
          title: "Invitation sent successfully",
        });
      } else {
        failAlert({
          title: data.message,
        });
      }
    } catch (error) {}
  };
  return (
    <>
      <PostSigninNavbar />
      {!isModalHidden && (
        <Modal setisModalHidden={setisModalHidden}>
          <>
            <h2>Invite Members</h2>
            <form className="mt-8 space-y-2" onSubmit={handleInvite}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="fullName" className="sr-only">
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="fullName"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 notdark:border-neutral-700 placeholder-gray-500 notdark:placeholder-neutral-400 text-gray-900 notdark:text-white rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                    placeholder="Full name"
                  />
                </div>
              </div>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 notdark:border-neutral-700 placeholder-gray-500 notdark:placeholder-neutral-400 text-gray-900 notdark:text-white rounded-t-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4  text-sm font-medium rounded-md text-white bg-[#1C2026]  "
              >
                Invite
              </button>
            </form>
          </>
        </Modal>
      )}
      <div className="container px-2 md:px-20 py-8 mx-auto mt-[45px] animate-fade-in">
        <div className="mb-[1rem] w-full flex justify-between items-center">
          <h1 className="text-xl md:text-4xl font-semibold md:mb-[1rem]">Pricing Plans</h1>
          {/* <a href="http://" target="_blank" rel="noopener noreferrer"></a>
           */}
          <button className="text-sm md:text-base" onClick={openCustomerPortal}>
            Update Payment Details
          </button>
        </div>
        <div className="flex flex-col gap-[1rem] items-center space-y-8 lg:flex-row lg:items-stretch lg:space-y-0">
          {planDetails.map((item, index) => (
            <div
              className={`flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 rounded-[12px] ${
                currentPlan.plan === "INDIVIDUAL_PLAN" && currentPlan.planStatus === "active" && "border-[#1c64f2]"
              }`}
            >
              <div className="flex-shrink-0">
                <h2 className="text-[#1c64f2] font-semibold">{item.name}</h2>
              </div>
              <div className="flex-shrink-0">
                <span className="pt-2 text-4xl font-bold uppercase">{item.price}</span>
                <span>{item.support}</span>
              </div>
              <ul className="flex-1 space-y-4">
                <li className="text-gray-700 dark:text-gray-600">{item.description}</li>
                <li className="text-gray-700 dark:text-gray-600 text-sm">
                  <div dangerouslySetInnerHTML={{ __html: item.description2 }} />
                </li>
              </ul>

              {currentPlan.planId === item.plan && currentPlan.planStatus === "active" ? (
                <>
                  <div className=" font-semibold text-[#1c64f2]">Current Plan</div>
                  {/* <Button className="border-8">Current Plan</Button> */}
                  <Button className="!bg-red-700 !hover:bg-red-400 font-semibold" onClick={cancel}>
                    {cancelling === true ? (
                      <div className="h-[24px]">
                        <Loader height="16px" width="16px" />
                      </div>
                    ) : (
                      <>Cancel</>
                    )}
                  </Button>
                </>
              ) : (
                <Button id={item.plan} className="uppercase font-semibold" onClick={(e) => buyPlan(e.target.id)}>
                  {selectedPlan === item.plan ? (
                    <div className="h-[24px]">
                      <Loader height="16px" width="16px" />
                    </div>
                  ) : (
                    <>Buy</>
                  )}
                </Button>
              )}
            </div>
          ))}
        </div>
        <div className="w-full   justify-between items-center">
          <h1 className="text-xl md:text-4xl font-semibold my-[1.5rem]">Token Usage</h1>
          {currentProfile && (
            <>
              {" "}
              <div>
                <p className="flex text-sm md:text-lg mb-0">
                  <span>Prompt Tokens:</span>
                  <span>{currentProfile.openAiTokenUsage.prompt_tokens}</span>
                </p>
                <p className="flex text-sm md:text-lg mb-0">
                  <span>Completion Tokens:</span>
                  <span>{currentProfile.openAiTokenUsage.completion_tokens}</span>
                </p>
                <p className="flex text-sm md:text-lg mb-0">
                  <span>Total Tokens:</span>
                  <span>{currentProfile.openAiTokenUsage.total_tokens}</span>
                </p>
              </div>
              <div>
                <div className="space-y-4">
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">Wordout Credits Used</h2>
                    <div className="w-full bg-gray-300 rounded-full h-4">
                      <div
                        style={{ width: `${credits.progressBarWidth}%` }}
                        className="h-full bg-blue-500 rounded-full"
                      />
                    </div>
                    <p className="text-xl">{credits.creditsUsed.toFixed(2)}</p> {/* Adjust decimal places as needed */}
                  </div>
                  <div>
                    <h2 className="text-sm md:text-lg font-semibold">Total Wordout Credits</h2>
                    <p className="text-lg md:text-xl">{credits.credits}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full mt-5  justify-between items-center">
          <p>Earn more wordout credits by inviting new user. Your referal code is: {user.referralCode} </p>
        </div>
        <div className="w-full mt-5  justify-between items-center">
          <div className="w-full ">
            <div className="w-full flex justify-between items-center">
              <h1 className="text-xl md:text-4xl font-semibold my-[1.5rem]">Referred Users</h1>
              <button onClick={() => setisModalHidden(false)} className="bg-[#3B82F6] px-3 h-9 text-white rounded-lg">
                Invite by email
              </button>
            </div>
            {referredUsers.length > 0 && (
              <table className="min-w-full divide-y divide-gray-300 notdark:divide-neutral-700">
                <thead className="bg-gray-50 notdark:bg-neutral-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6 text-center"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3  text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6 text-center"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white notdark:bg-neutral-800">
                  {referredUsers.map((u) => (
                    <tr key={u.id} className="border-2">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 notdark:text-white sm:pl-6 text-center">
                        {u.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400 text-center">
                        {u.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400 text-center">
                        {u.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
