import React from "react";
import TwitterPreview from "./TwitterPreview";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AddNewPost, DeleteAPost } from "../../redux/actions/trackActions";
import { confirmationModal } from "../../utils/sweetAlert";
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { Tooltip } from "antd";
const Twitter = (props) => {
  const dispatch = useDispatch();
  const [twitterItems, settwitterItems] = React.useState([]);
  React.useEffect(() => {
    var temp = props.data.twitterPost.sort((a, b) => a.id - b.id);
    settwitterItems(temp);
  }, [props.data.twitterPost]);
  // console.log(props);
  const handleAddNew = async () => {
    // console.log(twitterItems);
    // var temp = [...twitterItems, twitterItems[0]];
    await dispatch(AddNewPost("twitter", twitterItems[0]));
    // settwitterItems(temp);
  };
  const deleteHandler = async (item, index) => {
    confirmationModal(
      {
        text: "Are you sure you want to delete this post?",
        confirmButtonText: "Yes, Delete it!",
      },
      async () => {
        console.log(item);
        var temp = [...twitterItems];
        temp.splice(index, 1);
        await dispatch(DeleteAPost("twitter", item));

        settwitterItems(temp);
      }
    );
  };
  return (
    <div
      className={`space-y-2 flex-col ${
        twitterItems.length > 2 && "md:grid md:grid-cols-2"
      } md:space-y-0 md:gap-6 md:flex md:justify-center `}
    >
      {twitterItems.map((item, index) => (
        <div key={index} className="bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] p-5 h-fit w-[min(600px,90vw)]">
          <div className="hidden md:flex justify-between">
            <div></div>
            <div className="flex items-center space-x-2">
              {item.status === "published" && (
                <div className="flex space-x-1 text-xs justify-end bg-green-300 items-center p-1 rounded-full px-2 -mb-8">
                  <AiOutlineCheckCircle />
                  <span className="ml-auto">{item.status.toUpperCase()}</span>
                </div>
              )}
              {item.status === "scheduled" && (
                <div className="flex space-x-1 text-xs justify-end bg-orange-300 items-center p-1 rounded-full px-2 -mb-8">
                  <AiOutlineClockCircle />
                  <span className="ml-auto">{item.status.toUpperCase()}</span>
                </div>
              )}
              {item.status === "failed" && (
                <div className="flex space-x-1 text-xs justify-end bg-red-500 items-center p-1 rounded-full px-2 -mb-8">
                  <AiOutlineExclamationCircle className="text-white" />
                  <span className="ml-auto text-white">{item.status.toUpperCase()}</span>
                </div>
              )}
              {item.aiGenerated && (
                <Tooltip title="AI Generated" className="-mb-8">
                  <FaRobot className="text-[#1C64F2] text-xl" />
                </Tooltip>
              )}
            </div>
          </div>
          <TwitterPreview
            {...props}
            data={{ twitterPost: item }}
            isdeleteActive={twitterItems.length > 1 ? true : false}
            deleteItem={() => deleteHandler(item, index)}
          />
        </div>
      ))}
      <div className={`flex justify-center  w-auto  min-h-[6rem] max-h-96`}>
        <div
          className={`flex justify-center items-center h-56 ${
            twitterItems.length < 3 && "-mr-16 ml-6"
          } text-sm text-[#1C64F2] font-medium cursor-pointer`}
          onClick={handleAddNew}
        >
          <PlusOutlined className=" text-[#1C64F2] mr-1 text-[12px] font-medium" /> Add
        </div>
      </div>
    </div>
  );
};

export default Twitter;
