import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateInstagramPostValidState } from "../../redux/actions/stageActions";
import Loader from '../utils/Loader';

const InstagramMediaReorder = (props) => {
  const {
    uploadedFiles,
    setUploadedFiles,
    setCurrentSlide,
    setInstagramStage,
    uploadFiles,
    updateMarkAsDraft,
  } = props;
  
  const dispatch = useDispatch();
  const [dragItem, setDragItem] = useState('');
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);

  const addFiles = async (e) => {
    const files = Array.from(e.currentTarget.files);

    const filteredFiles = files.filter((file) => file.type.startsWith("image"));

    const newArr = [...uploadedFiles, ...filteredFiles].slice(-10);
    setIsCurrentlyUploading(true);
    updateMarkAsDraft(false);
    await uploadFiles(newArr);
    setIsCurrentlyUploading(false);
    setUploadedFiles(newArr);
  };

  const removeImage = async (index) => {
    if (index === uploadedFiles.length - 1) {
      setCurrentSlide(uploadedFiles.length - 2);
    }

    const newFiles = [
      ...uploadedFiles.slice(0, index),
      ...uploadedFiles.slice(index + 1),
    ];

    updateMarkAsDraft(false);
    await uploadFiles(newFiles);

    setUploadedFiles(newFiles);
    dispatch(updateInstagramPostValidState(false));
  };

  useEffect(() => {
    if (uploadedFiles.length !== 0) return;

    setInstagramStage("upload");
  }, [uploadedFiles]);

  const handleOver = (e) => {
    e.preventDefault();
  };

  const handleDrag = (index) => {
    setDragItem(index);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    if (isNaN(Number(e.currentTarget.dataset?.id))) return;

    // Item that is being dragged
    const fromEl = uploadedFiles[dragItem];

    const newArr = [...uploadedFiles];
    
    // Remove dragged element and push it to the dropped index
    newArr.splice(dragItem, 1);
    newArr.splice(Number(e.currentTarget.dataset.id), 0, fromEl);

    await uploadFiles(newArr);
    setUploadedFiles(newArr);
  };

  return (
    <div className="h-[120px] absolute bottom-[55px] right-[10px] max-w-[95%] rounded-[12px] bg-[rgba(26,26,26,.8)] flex items-center gap-[15px] p-[10px] overflow-x-scroll">
      {uploadedFiles.map((media, i) => {
        const mediaUrl = media.url ? media.url : window.URL.createObjectURL(media);

        return (
          <div
            className="relative min-w-[140px] h-[90px]"
            key={i}
            data-id={i}
            draggable={true}
            onDragOver={(e) => handleOver(e)}
            onDragStart={() => handleDrag(i)}
            onDrop={(e) => handleDrop(e)}
          >
            <div
              className="absolute top-[5px] left-[5px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full flex justify-center items-center"
              onClick={() => removeImage(i)}
            >
              <svg
                aria-label="Delete"
                color="#ffffff"
                fill="#ffffff"
                height="12"
                role="img"
                viewBox="0 0 24 24"
                width="12"
              >
                <line
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  x1="21"
                  x2="3"
                  y1="3"
                  y2="21"
                ></line>
                <line
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  x1="21"
                  x2="3"
                  y1="21"
                  y2="3"
                ></line>
              </svg>
            </div>
            <img
              src={mediaUrl}
              alt={media.name}
              className="w-full h-full object-cover"
            />
          </div>
        );
      })}

      {isCurrentlyUploading && (
        <div>
          <Loader height="20px" width="20px" loaderFill="#333" color="#000" />
        </div>
      )}

      <div className="self-start">
        <label
          className="flex items-center justify-center w-10 h-10 border rounded-full hover:cursor-pointer ml-[10px]"
          title="Upload more"
        >
          <svg
            aria-label="Plus icon"
            color="#8e8e8e"
            fill="#8e8e8e"
            height="22"
            role="img"
            viewBox="0 0 24 24"
            width="22"
          >
            <path d="M21 11.3h-8.2V3c0-.4-.3-.8-.8-.8s-.8.4-.8.8v8.2H3c-.4 0-.8.3-.8.8s.3.8.8.8h8.2V21c0 .4.3.8.8.8s.8-.3.8-.8v-8.2H21c.4 0 .8-.3.8-.8s-.4-.7-.8-.7z"></path>
          </svg>
          <input
            type="file"
            multiple={true}
            accept="image/png, image/jpeg, image/jpg"
            className="hidden"
            onChange={addFiles}
          />
        </label>
      </div>
    </div>
  );
};

export default InstagramMediaReorder;
