const INITAIAL_STATE = {
  schedules: [],
};

const scheduleReducer = (state = INITAIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_ALL_SCHEDULES":
      return {
        ...state,
        schedules: payload,
      };
    case "UPDATE_SCHEDULE":
      return {
        ...state,
        schedules: state.schedules.map((schedule) => (schedule.id === payload.id ? payload : schedule)),
      };
    default:
      return state;
  }
};

export default scheduleReducer;
