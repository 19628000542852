export const updateTwitterPostValidState = (state) => ({
  type: "CHANGE_TWITTER_STATE",
  payload: state,
});

export const updateInstagramPostValidState = (state) => ({
  type: "CHANGE_INSTAGRAM_STATE",
  payload: state,
});

export const updateLinkedinPostValidState = (state) => ({
  type: "CHANGE_LINKEDIN_STATE",
  payload: state,
});
