import axios from "axios";
import { failAlert, successAlert } from "../../utils/sweetAlert";

export const createTrack = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/create/`,
      { profileId: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    dispatch({ type: "ADD_TRACK", payload: res.data.Track });
    dispatch({ type: "SET_CURRENT_TRACK", payload: res.data.Track });
    dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
    window.location.href = `/composer/${res.data.Track.id}`;
  } catch (error) {
    console.log(error);

    failAlert({
      title: error.response?.data?.message || "Sorry, something went very wrong",
    });
  }
};

export const updateTrack = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/update/`,
      { trackId: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    dispatch({ type: "ADD_TRACK", payload: res.data.Track });
    dispatch({ type: "SET_CURRENT_TRACK", payload: res.data.Track });
    dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
  } catch (error) {
    console.log(error);
  }
};
function filterProcessing(data) {
  console.log(data);
  if (!data) return true;
  console.log("'returning");

  for (const tw of data.twitterPost) {
    if (tw.status == "processing") {
      return true;
    }
  }
  for (const tw of data.instaPost) {
    if (tw.status == "processing") {
      return true;
    }
  }
  for (const tw of data.linkedInPost) {
    if (tw.status == "processing") {
      return true;
    }
  }

  return false; // Return data if no processing statuses found
}
export const getTrackById =
  (id, state = null) =>
  async (dispatch) => {
    try {
      const bool = filterProcessing(state);
      
      if (!bool) return;
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/get-track-by-id/`,
        { trackId: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      dispatch({ type: "SET_CURRENT_TRACK_AUTO_RELOAD", payload: res.data.Track });
    } catch (error) {
      console.log(error);
    }
  };

// prettier-ignore
export const setCurrentTrack = ({ trackId, user }) => async (dispatch) => {
  var profiles = user.profiles;
  for (var i = 0; i < profiles.length; i++) {
    for (var j = 0; j < profiles[i].tracks.length; j++) {
      if (profiles[i].tracks[j].id === trackId) {
        dispatch({
          type: "SET_CURRENT_TRACK",
          payload: profiles[i].tracks[j],
        });
        break;
      }
    }
  }
};

// prettier-ignore
export const duplicateSingleTrack = ({ profileId, trackId }) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/duplicate`,
      { profileId, trackId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      successAlert({
        title: "Success",
      });

      return true;
    } else {
      throw Error("Sorry, something went wrong");
    }
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
  }
};

export const deleteSingleTrack = (trackId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/delete`,
      { trackId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      successAlert({ title: "Successfully deleted" });
    } else {
      throw Error("Sorry, something went wrong");
    }
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
  }
};

export const DeleteAPost = (platform, newData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/delete-a-post`,
      { platform, data: newData, trackId: newData.trackId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      // successAlert({ title: "Successfully deleted" });
      dispatch({ type: "ADD_TRACK", payload: data.track });
      dispatch({ type: "SET_CURRENT_TRACK", payload: data.track });
    } else {
      failAlert({ title: "Sorry, something went wrong" });
      throw Error("Sorry, something went wrong");
    }
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
  }
};

export const AddNewPost = (platform, newData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/add-new-post`,
      { platform, data: newData, trackId: newData.trackId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      // successAlert({ title: "Successfully deleted" });
      dispatch({ type: "ADD_TRACK", payload: data.track });
      dispatch({ type: "SET_CURRENT_TRACK", payload: data.track });
    } else {
      failAlert({ title: "Sorry, something went wrong" });
      throw Error("Sorry, something went wrong");
    }
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
  }
};
export const RePurposeWithAI =
  ({ platform, postId, trackId }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/re-purpose-with-ai`,
        { platform, postId, trackId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      console.log(data);
      if (data.status === true) {
        // successAlert({ title: "Successfully deleted" });
        dispatch({ type: "ADD_TRACK", payload: data.track });
        dispatch({ type: "SET_CURRENT_TRACK", payload: data.track });
      } else {
        failAlert({ title: "Sorry, something went wrong" });
        throw Error("Sorry, something went wrong");
      }
    } catch (error) {
      console.log(error);
      failAlert({ title: "Sorry, something went wrong" });
    }
  };
export const ImproveHookWithAI =
  ({ platform, postId, trackId }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/improve-hook-with-ai`,
        { platform, postId, trackId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status === true) {
        // successAlert({ title: "Successfully deleted" });
        dispatch({ type: "ADD_TRACK", payload: data.track });
        dispatch({ type: "SET_CURRENT_TRACK", payload: data.track });
        return true;
      } else {
        failAlert({ title: "Sorry, something went wrong" });
        throw Error("Sorry, something went wrong");
        return false;
      }
    } catch (error) {
      console.log(error);
      failAlert({ title: "Sorry, something went wrong" });

      return false;
    }
  };

export const checkConvertkitConnection = (ConvertkitSecret, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/convertkit/connection`,
      { ConvertkitSecret, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
    return false;
  }
};
export const checkWebflowConnection = (webflowSecret, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/webflow/connection`,
      { webflowSecret, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error);
    failAlert({ title: "Sorry, something went wrong" });
    return false;
  }
};
export const saveWebFlowConnection =
  (selectedSite, selectedCollection, columnMap, profileId, webflowCollections) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/webflow/save-connection`,
        {
          selectedSite,
          selectedCollection,
          columnMap,
          profileId,
          webflowCollections: {
            authJSON: webflowCollections.authJSON,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error);
      failAlert({ title: "Sorry, something went wrong" });
      return false;
    }
  };
