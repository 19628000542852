import React from "react";
import InstagramPreview from "./InstagramPreview";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AddNewPost, DeleteAPost } from "../../redux/actions/trackActions";
import { confirmationModal } from "../../utils/sweetAlert";
import { FaRobot } from "react-icons/fa";

const Instagram = (props) => {
  const [InstagramItems, setInstagramItems] = React.useState([]);
  const dispatch = useDispatch();
  const deleteHandler = async (item, index) => {
    confirmationModal(
      {
        text: "Are you sure you want to delete this post?",
        confirmButtonText: "Yes, Delete it!",
      },
      async () => {
        console.log(item);
        var temp = [...InstagramItems];
        temp.splice(index, 1);
        await dispatch(DeleteAPost("instagram", item));

        setInstagramItems(temp);
      }
    );
  };
  React.useEffect(() => {
    var temp = props.data.instaPost.sort((a, b) => a.id - b.id);
    setInstagramItems(temp);
  }, [props.data.instaPost]);

  return (
    // linkedinItems.map((item, index) => {
    //     return (
    // <div className="grid gap-2 grid-cols-3 ">
    <div
      className={`space-y-2 flex-col max-w-screen ${
        InstagramItems.length > 2 && "md:grid md:grid-cols-2"
      } md:space-y-0 md:gap-6 md:flex md:justify-center`}
    >
      {InstagramItems.map((item, index) => (
        <>
          <div className="">
            <InstagramPreview
              {...props}
              data={{ instaPost: item }}
              isdeleteActive={InstagramItems.length > 1 ? true : false}
              deleteItem={() => deleteHandler(item, index)}
            />
          </div>
        </>
      ))}
      <div className="flex justify-center  w-auto  min-h-[17rem] max-h-96">
        <p
          className={`flex justify-center items-center ${
            InstagramItems.length < 3 && "-mr-16 ml-6"
          } text-sm text-[#1C64F2] font-medium cursor-pointer`}
          onClick={() => {
            dispatch(AddNewPost("instagram", InstagramItems[0]));
            // setInstagramItems([...InstagramItems, InstagramItems[0]]);
          }}
        >
          <PlusOutlined className=" text-[#1C64F2] mr-1 text-[12px] font-medium" /> Add
        </p>
      </div>
    </div>
  );
};

export default Instagram;
