import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleRescheduleTrackModal } from "../../redux/actions/modalActions";
import { updateInstagramPostValidState, updateLinkedinPostValidState } from "../../redux/actions/stageActions";
import { getTrackById } from "../../redux/actions/trackActions";
import PublishingModalContent from "../Publishing/PublishingModalContent";
import InstagramPreview from "../StagePreview/InstagramPreview";
import LinkedinPreview from "../StagePreview/LinkedinPreview";
import PreviewButtons from "../StagePreview/PreviewButtons";
import TwitterPreview from "../StagePreview/TwitterPreview";
import Button from "../utils/Button";
import ErrorBoundary from "../utils/ErrorBoundary";

import "./RescheduleModal.css";

const RescheduleModal = (props) => {
  const { getAllTracks } = props;

  const dispatch = useDispatch();
  const id = useSelector((state) => state.modals.rescheduleModal.trackId);
  const [activeButton, setActiveButton] = useState(0);
  const [currentEditStep, setCurrentEditStep] = useState(0);
  const { currentTrack } = useSelector((state) => state.tracks);
  const [uploadedFiles] = useState([]);
  const [data, setData] = useState(null);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [, setsavedTime] = useState(null);

  const [platform, setplatform] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
  });

  useEffect(() => {
    if (isAuthenticated === true) {
      var profiles = user.profiles;
      for (var i = 0; i < profiles.length; i++) {
        var righttrack = false;
        for (var j = 0; j < profiles[i].tracks.length; j++) {
          if (profiles[i].tracks[j].id === Number(id)) {
            righttrack = true;
          }
        }
        if (righttrack) {
          var socialAccounts = profiles[i].socialAccounts;
          var tempselectedplatform = {
            twitter: false,
            linkedin: false,
            instagram: false,
          };

          for (let j = 0; j < socialAccounts.length; j++) {
            if (socialAccounts[j].accountType === "twitter") {
              tempselectedplatform.twitter = socialAccounts[j].authJSON;
            }
            if (socialAccounts[j].accountType === "linkedin") {
              tempselectedplatform.linkedin = socialAccounts[j].authJSON;
            }
            if (socialAccounts[j].accountType === "instagram") {
              tempselectedplatform.instagram = socialAccounts[j].authJSON;
            }
          }

          setplatform(tempselectedplatform);
        }
      }
    }
  }, [isAuthenticated, user, id]);

  useEffect(() => {
    if (currentTrack) {
      if (currentTrack.id !== Number(id)) {
        dispatch(getTrackById(Number(id)));
      }
      setData(currentTrack);
    }
  }, [currentTrack, dispatch, id]);

  // useEffect(() => {
  //   const linkedinLimit = 3000;

  //   if (data?.linkedInPost?.body_json.texts) {
  //     dispatch(updateLinkedinPostValidState(data.linkedInPost.body_json.texts.length < linkedinLimit));
  //   }
  // }, [data, dispatch]);

  useEffect(() => {
    dispatch(getTrackById(Number(id)));
  }, [activeButton, dispatch, id]);

  // useEffect(() => {
  //   if (currentTrack?.instaPost?.body_json.assets?.length > 0) {
  //     dispatch(updateInstagramPostValidState(true));
  //   }
  // }, [currentTrack?.instaPost, dispatch]);

  const setIsModalHidden = (status) => {
    if (status === true) {
      dispatch(toggleRescheduleTrackModal(false));
    }
  };

  return (
    <div
      className="fixed inset-0 z-[99] min-h-screen min-w-screen flex justify-center items-center reschedule-modal"
      aria-label="modal"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[90]"
        onClick={() => dispatch(toggleRescheduleTrackModal(false))}
      ></div>
      <div
        className={`relative z-[99] bg-white rounded-[10px] py-[20px] px-[15px] overflow-y-scroll show-modal flex flex-col justify-between reschedule-modal-container step-${currentEditStep}`}
      >
        <div
          className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
          onClick={() => dispatch(toggleRescheduleTrackModal(false))}
        >
          &times;
        </div>

        {currentEditStep === 0 ? (
          <div className="animate-fade-in flex flex-col flex-1">
            <PreviewButtons activeButton={activeButton} setActiveButton={setActiveButton} />
            {data && activeButton === 0 && (
              <div className="flex justify-center flex-1 my-7 mb-[1rem]">
                <div className="bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] p-5 h-fit w-[min(600px,90vw)] editor-container">
                  <ErrorBoundary>
                    <TwitterPreview
                      data={data}
                      profile={platform}
                      setSavedTime={setsavedTime}
                      isInsideRescheduleModal
                    />
                  </ErrorBoundary>
                </div>
              </div>
            )}
            {data && activeButton === 1 && (
              <ErrorBoundary>
                <div className="flex justify-center flex-1 mt-7 mb-[1rem] animate-fade-in">
                  <InstagramPreview
                    data={data}
                    setsavedTime={setsavedTime}
                    uploadedFiles={uploadedFiles}
                    isInsideRescheduleModal
                  />
                </div>
              </ErrorBoundary>
            )}
            {data && activeButton === 2 && (
              <div className="flex justify-center flex-1 my-7 mb-[1rem]">
                <div className="bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] p-5 h-fit editor-container">
                  <ErrorBoundary>
                    <LinkedinPreview
                      setSavedTime={setsavedTime}
                      profile={platform}
                      data={data}
                      isInsideRescheduleModal
                    />
                  </ErrorBoundary>
                </div>
              </div>
            )}

            <div className="flex justify-end items-center mt-auto">
              <div className="mr-[20px] text-center">
                <span className="text-[#1C64F2] font-semibold cursor-pointer">Save</span>
              </div>

              <Button className="flex items-center justify-center" onClick={() => setCurrentEditStep(1)}>
                <svg
                  className="mt-[2px]"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 4.66675L13.3333 10.5001L7.5 16.3334"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <PublishingModalContent
              profileId={currentTrack.profileId}
              trackId={id}
              setisModalHidden={setIsModalHidden}
              getAllTracks={getAllTracks}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RescheduleModal;
