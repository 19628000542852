import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTwitterPostValidState } from "../../redux/actions/stageActions";
import { deleteAssetFromTwitter, getAllAssets } from "../../utils/asset";
import Loader from "../utils/Loader";
import { GiFairyWand } from "react-icons/gi";
import { IoTrashOutline } from "react-icons/io5";
import { Tooltip } from "antd";
import { ImproveHookWithAI } from "../../redux/actions/trackActions";

const postWordLimit = 280;

const TwitterTextarea = (props) => {
  const {
    index: threadIndex,
    value,
    changeHandler,
    addFocus,
    isFocused,
    checkAllThreadsValid,
    postId,
    updloadTwitterImages,
    addNewThread,
    updateMarkAsDraft,
    improveHookActive,
    trackId,
    focuesdEditor,
    setFocusedEditor,
    deleteNode,
    deleteActive,
  } = props;
  // console.log("threadIndex", threadIndex);
  const dispatch = useDispatch();
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);

  const textAreaRef = useRef(null);
  const onChange = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    const text = e.target.value;
    changeHandler(text);
    if (text.length > postWordLimit) {
      dispatch(updateTwitterPostValidState(false));
    } else {
      dispatch(updateTwitterPostValidState(checkAllThreadsValid() ? true : false));
    }
  };

  useEffect(() => {
    //to resize the textarea on load
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  useEffect(() => {
    (async () => {
      if (!value.assets || !value.assets.length) return;

      const assetIds = value.assets?.map((asset) => {
        if (typeof asset === "object") return asset.id;

        return asset;
      });

      setIsCurrentlyUploading(true);
      const mediaObjs = await getAllAssets(assetIds);
      setIsCurrentlyUploading(false);
      setUploadedMedia(mediaObjs);
    })();
  }, [value]);

  const uploadFiles = async (e) => {
    const files =
      Array.from(e.currentTarget.files)
        .filter((file) => file.type.startsWith("image/"))
        .slice(0, 4) || [];

    let filesToSet = [];
    if (uploadedMedia.length + files.length > 4) {
      filesToSet = [...uploadedMedia, ...files].slice(-4);
    } else {
      filesToSet = [...uploadedMedia, ...files];
    }

    setIsCurrentlyUploading(true);
    const twitterAssets = (await updloadTwitterImages(filesToSet, threadIndex)) || [];
    const assets = await getAllAssets(twitterAssets);
    setIsCurrentlyUploading(false);
    // updateMarkAsDraft(false);
    setUploadedMedia(assets);
    e.target.value = "";
  };

  const removeUploadedFile = async (index) => {
    const status = await deleteAssetFromTwitter(postId, uploadedMedia[index].id, threadIndex);

    if (!status) return;

    const newImages = uploadedMedia.filter((_, i) => i !== index).map((asset) => asset.id);

    setUploadedMedia(newImages);
    changeHandler(textAreaRef.current.value, newImages);
  };

  const twoImagesStyles = "flex gap-[1rem] h-[200px]";
  const threeImagesStyles = "grid grid-cols-2 gap-[1rem] h-[calc(400px_+_1rem)] grid-rows-2";

  return (
    <div onFocus={addFocus}>
      <textarea
        ref={textAreaRef}
        value={value.text}
        onChange={onChange}
        disabled={false}
        className="w-full text-sm border-0 outline-none empty:before:content-[attr(placeholder)] before:text-[gray] resize-none"
      />

      {uploadedMedia.length > 0 && (
        <div
          className={`mt-3
            ${uploadedMedia.length === 2 ? twoImagesStyles : ""}
            ${uploadedMedia.length > 2 ? threeImagesStyles : ""}
          `}
        >
          {uploadedMedia.map((media, i) => {
            const mediaUrl = media instanceof File ? window.URL.createObjectURL(media) : media.url;

            return (
              <div className="relative rounded-[12px] overflow-hidden w-full h-full " key={i}>
                <img src={mediaUrl} alt="" className="w-full h-full object-cover" />
                <div
                  className="absolute top-[10px] left-[10px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full"
                  onClick={() => removeUploadedFile(i)}
                >
                  <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px]">
                    &times;
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {isCurrentlyUploading && (
        <Loader height="20px" width="20px" loaderFill="#333" color="#000" className="mt-[1rem]" />
      )}

      {isFocused && (
        <div className="flex items-center gap-[1rem] mr-auto mt-[1.2rem] min-h-[1.6rem]">
          <label
            htmlFor={`twitter-file-upload-${threadIndex}`}
            style={{
              opacity: uploadedMedia.length >= 4 ? 0.5 : 1,
              cursor: "pointer",
              pointerEvents: uploadedMedia.length >= 4 ? "none" : "all",
            }}
          >
            <svg className="h-[18px] w-[18px]" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 13L5.586 8.414C5.96106 8.03906 6.46967 7.82843 7 7.82843C7.53033 7.82843 8.03894 8.03906 8.414 8.414L13 13M11 11L12.586 9.414C12.9611 9.03906 13.4697 8.82843 14 8.82843C14.5303 8.82843 15.0389 9.03906 15.414 9.414L17 11M11 5H11.01M3 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z"
                stroke="#666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </label>
          {value.text.length > 0 && (
            <div
              onClick={() => addNewThread(threadIndex)}
              className="flex items-center justify-center w-6 h-6 border rounded-full cursor-pointer"
            >
              <div className="pb-0.5">+</div>
            </div>
          )}

          <div className="">
            <span
              className={`font-semibold 
              ${
                value.text.length < postWordLimit - 20
                  ? "text-gray-500"
                  : value.text.length >= postWordLimit - 20 && value.text.length < postWordLimit
                  ? "text-[#ffd400]"
                  : "text-[#f4212e]"
              }`}
            >
              {postWordLimit - value.text.length}
            </span>
          </div>
          {deleteActive && (
            <Tooltip title={"Delete this tweet."}>
              <div className="cursor-pointer" onClick={() => deleteNode(threadIndex)}>
                <IoTrashOutline />
              </div>
            </Tooltip>
          )}
          <input
            type="file"
            multiple={true}
            accept="image/png, image/gif, image/jpeg"
            id={`twitter-file-upload-${threadIndex}`}
            className="hidden"
            onChange={uploadFiles}
          />
          {improveHookActive && (
            <Tooltip title={"Improve hook with AI"}>
              <button
                className="ml-auto mx-2 flex items-center text-sm font-medium"
                onClick={async () => {
                  const res = await dispatch(
                    await ImproveHookWithAI({
                      postId: postId,
                      platform: "twitter",
                      trackId: trackId,
                    })
                  );
                  if (res) {
                    setFocusedEditor(focuesdEditor + 1);
                  }
                }}
              >
                <GiFairyWand className="mr-1" />
                <span className="">Improve hook</span>
              </button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default TwitterTextarea;
