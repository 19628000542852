import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTrack } from "../redux/actions/trackActions";
import { toggleAddCompanyModal } from "../redux/actions/modalActions";

export function Sidebar() {
  const { user } = useSelector((state) => state.auth);
  const itemsRef = React.useRef([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (user) {
      itemsRef.current = itemsRef.current.slice(0, user.profiles.length);
    }
  }, [user]);

  const onClickHandler = async (id) => {
    dispatch(createTrack(id));
  };

  const location = useLocation();
  const [active, setActive] = React.useState(false);
  const currentRoute = location.pathname;
  const [profilesNav, setProfilesNav] = React.useState([]);

  const closeSidebar = () => setActive(false);

  const openTrack = (track) => {
    if (track.status === "published") {
      window.location.href = `/${track.profileId}/tracks`;
      return;
    }

    if (track.status === "composing") {
      window.location.href = `/composer/${track.id}`;
      return;
    }

    window.location.href = `/staging/${track.id}`;
  };

  React.useEffect(() => {
    if (user) {
      var tempprofiles = [];
      for (let i = 0; i < user.profiles.length; i++) {
        tempprofiles.push({
          ...user.profiles[i],
          draftDropdown: false,
          dropdown: false,
        });
      }
      setProfilesNav(tempprofiles);
    }
  }, [user, active]);

  const getDrafts = async (id) => {
    var tmpprofilesNav = profilesNav;
    tmpprofilesNav.forEach((element) => {
      if (element.id === id) {
        element.draftDropdown = !element.draftDropdown;
        element.dropdown = !element.dropdown;
      } else {
        element.draftDropdown = false;
        element.dropdown = false;
      }
    });
    setProfilesNav(tmpprofilesNav);
  };

  const openDropDown = async (id) => {
    var tmpprofilesNav = profilesNav;
    tmpprofilesNav.forEach((element) => {
      if (element.id === id) {
        element.dropdown = !element.dropdown;
      } else {
        element.draftDropdown = false;
        element.dropdown = false;
      }
    });
    setProfilesNav(tmpprofilesNav);
  };

  const openAddCompanyModal = () => {
    dispatch(toggleAddCompanyModal(true));
  };

  return (
    <div>
      {active && <div className="h-screen w-screen fixed top-0 left-0 z-[199]" onClick={closeSidebar}></div>}
      <aside
        className="w-52 fixed left-0 top-[3.15rem] bottom-50  border-r-[0.5px] border-[#4B5563] border-opacity-20 z-50 bg-white z-[199] transition duration-200"
        aria-label="Sidebar"
        style={{
          transform: `translateX(${active ? "0" : "-100%"})`,
          boxShadow: active ? "rgb(0 0 0 / 24%) 0 3px 5px" : "none",
        }}
      >
        {active ? (
          <div
            onClick={() => setActive(!active)}
            className={`flex absolute hover:cursor-pointer left-[100%] top-[40%] w-12 h-12 text-center justify-center`}
          >
            <i className="fa fa-chevron-left my-auto mx-auto"></i>
          </div>
        ) : (
          <div
            id="open-sidebar-btn"
            onClick={() => setActive(!active)}
            className="flex absolute hover:cursor-pointer left-[100%] top-[40%] w-12 h-12 text-center justify-center z-[99]"
          >
            <i className="fa fa-chevron-right my-auto mx-auto"></i>
          </div>
        )}

        <div className="h-screen pt-4 px-2 flex flex-col justify-between pb-16 rounded">
          <ul className="space-y-2">
            <li>
              {profilesNav.map(
                (profile, i) =>
                  active && (
                    <NavLink
                      onClick={() => openDropDown(profile.id)}
                      ref={(el) => (itemsRef.current[i] = el)}
                      key={profile.id}
                      className={`text-sm  ${
                        currentRoute.includes("dashboard") && `bg-[#F3F4F6] rounded-lg hover:bg-[#F3F4F6]`
                      } `}
                    >
                      <div className="py-2 px-2 flex items-center font-Inter font-[500] rounded-lg hover:bg-[#F3F4F6]">
                        {currentRoute.includes("dashboard") ? (
                          <i className="fa fa-home text-[#3B82F6]"></i>
                        ) : (
                          <i className="fa fa-folder text-lg text-[#000]"></i>
                        )}
                        <div className="w-full flex justify-between">
                          {active && (
                            <>
                              <div
                                className={`ml-3 font-Inter font-[500] ${
                                  profile.dropdown ? `text-[#1c64f2]` : `text-[#031927]`
                                } text-md`}
                              >
                                {profile?.profileName}
                              </div>
                              {profile?.dropdown ? (
                                <i className="fa fa-chevron-up text-sm text-[#000]"></i>
                              ) : (
                                <i className="fa fa-chevron-down text-sm text-[#000]"></i>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {profile?.dropdown && (
                        <ul className=" px-2">
                          <li>
                            <NavLink
                              onClick={() => getDrafts(profile.id)}
                              className={`flex items-center py-1.5 px-4 text-sm font-Inter font-[500] rounded-lg hover:bg-[#F3F4F6] ${
                                currentRoute.includes("help") && `bg-[#F3F4F6] rounded-lg hover:bg-[#F3F4F6]`
                              }`}
                            >
                              {active && (
                                <span
                                  className={`flex-1 ml-3 font-Inter font-[500] ${
                                    profile.draftDropdown ? `text-[#1c64f2]` : `text-[#031927]`
                                  } text-md`}
                                >
                                  Organic
                                </span>
                              )}
                            </NavLink>
                            <div
                              className={`origin-top-left -mt-8 absolute ${
                                active ? `left-[12.03rem]` : `left-[3.5rem]`
                              }  flex justify-center items-center `}
                            >
                              <ul
                                id="navbarDropdown"
                                className={`w-[250px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none animate-fade-in-fast ${
                                  profile.draftDropdown ? "block" : "hidden"
                                } h-fit max-h-[75vh] overflow-y-scroll`}
                              >
                                <li className="border border-b-gray-300">
                                  <NavLink
                                    to={`/${profile.id}/tracks`}
                                    onClick={() => {
                                      setActive(!active);
                                      // window.location.href = `/${profile.id}/tracks`;
                                    }}
                                    className="font-semibold block py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-center"
                                  >
                                    <div>View all tracks</div>
                                  </NavLink>
                                </li>
                                <li className="border border-b-gray-300">
                                  <NavLink
                                    to={`/library/${profile.id}`}
                                    onClick={() => {
                                      setActive(!active);
                                      // window.location.href = `/library/${profile.id}`;
                                    }}
                                    className={`font-semibold block py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-center`}
                                  >
                                    {active && <span className={`flex-1 ml-3`}>Library</span>}
                                  </NavLink>
                                </li>
                                <li onClick={() => onClickHandler(profile.id)}>
                                  <Link
                                    className="w-full text-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    to="#"
                                  >
                                    <i className="fa fa-plus text-sm mr-3"></i>
                                    New Track
                                  </Link>
                                </li>

                                {profile?.tracks?.map((track, i) =>
                                  track.status === "published" || track.status === "publishing" ? null : (
                                    <Link key={track.id} onClick={() => openTrack(track)}>
                                      <span
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full overflow-hidden text-ellipsis whitespace-nowrap"
                                        href="#"
                                      >
                                        {track?.draft.body_json.title}
                                      </span>
                                    </Link>
                                  )
                                )}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      )}
                    </NavLink>
                  )
              )}
            </li>
          </ul>
          <div className="mt-[2.2rem] mb-auto">
            <div
              className="flex items-center gap-[10px] cursor-pointer text-[#111928] font-medium"
              onClick={openAddCompanyModal}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.3869 1 10 1C7.61305 1 5.32387 1.94821 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442Z"
                  fill="#111928"
                />
                <path
                  d="M10 7V10M10 10V13M10 10H13M10 10H7M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add New Company
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}
