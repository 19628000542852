import React, { useEffect, useState } from "react";
import PostSigninNavbar from "../Navbar/PostSigninNavbar";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, Select, TimePicker, Anchor } from "antd";
// import { DatePicker, TimePicker } from "antd";
import TimezoneSelect from "react-timezone-select";
import Modal from "../utils/Modal";
import { useDispatch } from "react-redux";
import { items, platformItems } from "./constants";
import { updateSchedule } from "../../redux/actions/scheduleActions";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
const SchedulesModal = ({ setisModalHidden, triggerPlatform }) => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { schedules } = useSelector((state) => state.schedules);
  const [Profile, setProfile] = useState({
    profileName: "",
  });
  const [currentSchedule, setcurrentSchedule] = useState(schedules.length > 0 ? schedules[0] : {});
  const [platform, setplatform] = useState(triggerPlatform);
  const [day, setday] = useState(items[0].value);
  const [selectedTimezone, setSelectedTimezone] = useState({
    abbrev: "GMT",
    altName: "British Standard Time",
    label: "(GMT+0:00) UTC",
    offset: 0,
    value: "Etc/GMT",
  });
  useEffect(() => {
    for (const pro of user.profiles) {
      if (pro.id == profileId) {
        // console.log(pro);
        setProfile(pro);
      }
    }
  }, [user]);
  useEffect(() => {
    console.log(schedules);
    for (const schedule of schedules) {
      if (schedule.profileId == profileId && schedule.platform == platform) {
        setcurrentSchedule(schedule);
      }
    }
  }, [schedules]);
  const changeCurrentSchedule = (id, plat) => {
    for (const schedule of schedules) {
      if (schedule.profileId == id && schedule.platform == plat) {
        setcurrentSchedule(schedule);
      }
    }
  };
  const [selectedTime, setSelectedTime] = useState(null);
  const convertTimeFromUtc = (hours, minutes) => {
    const currentTime = new Date();
    currentTime.setUTCHours(hours, minutes, 0, 0);
    const zonedTime = utcToZonedTime(currentTime, selectedTimezone.value);
    // console.log(zonedTime);
    return format(zonedTime, "HH:mm:ss z", { timeZone: selectedTimezone.value });
  };

  const convertTimeToUTC = (hours, minutes) => {
    if (!selectedTimezone) {
      alert("Please select a timezone.");
      return;
    }
    console.log(selectedTimezone);
    const now = new Date();
    const localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, 0, 0);
    // Convert the input date (already in the selected timezone) to UTC
    const utcTime = zonedTimeToUtc(localDate, selectedTimezone.value);
    return format(utcTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    // console.log(selectedTimezone);
    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  const AddNewPostingTime = async () => {
    // console.log(selectedTime);
    if (selectedTime == null) return;
    var time = convertTimeToUTC(selectedTime.hour, selectedTime.minute);
    console.log(time);
    var date = new Date(time);
    console.log(date);

    var hourString = date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
    var minuteString = date.getUTCMinutes() < 10 ? "0" + date.getUTCMinutes() : date.getUTCMinutes();
    console.log(date.getUTCHours());
    var timeString = hourString + ":" + minuteString;
    console.log(timeString);
    var json = currentSchedule.json;
    console.log(currentSchedule);
    // console.log(json);
    // return;
    const addToDayScheduleAndSort = (day) => {
      // console.log(day);
      if (!json[day].includes(timeString)) {
        json[day].push(timeString);
      }
      // Sort the schedule for the day in ascending order based on hours and minutes
      json[day].sort((a, b) => {
        const [ah, am] = a.split(":").map(Number);
        const [bh, bm] = b.split(":").map(Number);
        return ah * 60 + am - (bh * 60 + bm);
      });
    };

    if (day === "Everyday") {
      for (const dayitem of items) {
        if (dayitem.value === "Everyday") continue;
        // console.log(dayitem.value);
        addToDayScheduleAndSort(dayitem.value);
      }
    } else {
      addToDayScheduleAndSort(day);
    }
    console.log(json);
    // return;
    dispatch(updateSchedule(profileId, platform, json));
    changeCurrentSchedule(profileId, platform);
  };
  const handleClearAllPosting = async () => {
    var json = currentSchedule.json;
    for (const dayitem of items) {
      if (dayitem.value === "Everyday") continue;
      json[dayitem.value] = [];
    }
    dispatch(updateSchedule(profileId, platform, json));
    changeCurrentSchedule(profileId, platform);
  };
  const handleRemoveSingleDate = async (day, time) => {
    var json = currentSchedule.json;
    json[day] = json[day].filter((item) => item !== time);
    dispatch(updateSchedule(profileId, platform, json));
    changeCurrentSchedule(profileId, platform);
  };
  const updateProfileFilter = (item) => () => {
    setProfile(item);
    changeCurrentSchedule(item.id, platform);
  };
  return (
    <>
      <Modal setisModalHidden={setisModalHidden} className="px-12 my-6 mt-16 flex-col z-90">
        <div className="max-h-[80vh] overflow-y-scroll">
          <div className="flex items-center space-x-4 w-fit  border-b-2 mb-2">
            {user &&
              user.profiles.map((item) => (
                <button
                  onClick={updateProfileFilter(item)}
                  className={`text-black px-3 py-1 text-sm ${item.id == Profile.id && "border-b-2 border-[#1D4ED8]"}`}
                >
                  {item.profileName}
                </button>
              ))}
          </div>
          <h2 className=" text-lg">
            Your posting schedule for <span className="font-semibold">{Profile.profileName}</span>
          </h2>
          <div className="px-5 w-full flex-col my-2 items-center">
            <div className="flex items-center   justify-between">
              <h3 className="mt-6 mb-1 text-md">TimeZone</h3>
            </div>
            <div className="flex items-center   justify-between">
              <TimezoneSelect
                className="w-2/4 text-sm font-medium"
                value={selectedTimezone}
                onChange={setSelectedTimezone}
                defaultValue={selectedTimezone}
              />
            </div>
            <div className="mt-10">
              <h3 className="mt-6 mb-1 text-md">Add a new posting time.</h3>
              <div className="flex items-center py-1 my-auto">
                <Select
                  // defaultValue="lucy"
                  defaultValue={day}
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => {
                    setday(e);
                  }}
                  options={items}
                />
                <p className="px-2 text-sm my-auto">Choose Time:</p>
                <TimePicker
                  className="w-[170px]  h-8 rounded-none border border-[#D1D5DB]"
                  onChange={timePickerHandler}
                  // getPopupContainer={() => datepickerParent.current}
                  style={{ borderRadius: "6px", fontSize: "12px" }}
                />
                <button onClick={AddNewPostingTime} className="bg-[#1D4ED8] rounded-lg text-white px-3 ml-2 py-2">
                  Add Posting Time
                </button>
              </div>
            </div>
            <div className="mt-8">
              <Select
                defaultValue={platform}
                style={{
                  width: 160,
                }}
                onChange={(e) => {
                  setplatform(e);
                  changeCurrentSchedule(profileId, e);
                }}
                options={platformItems}
              ></Select>
            </div>
            <div className="mt-8 w-full">
              <div className="w-full flex  justify-between items-center">
                <h2 className="font-semibold">Posting Times:</h2>
                <button
                  onClick={handleClearAllPosting}
                  className="bg-white text-slate-700 font-medium text-sm border-2 rounded-lg  px-3 py-[7px] hover:bg-slate-100"
                >
                  Clear all Posting Times
                </button>
              </div>
              <div className="mt-4 flex justify-between">
                {items.slice(1, 8).map((item) => (
                  <div className="space-x-2 ">
                    <div className="flex-col items-center justify-between">
                      <p className="text-sm">{item.label}</p>
                      {selectedTimezone &&
                        currentSchedule &&
                        currentSchedule.json &&
                        currentSchedule.json[item.label].map((time) => (
                          <div className="flex space-x-1 items-center group my-auto">
                            <p className="text-sm  group-hover:text-sky-600">
                              {convertTimeFromUtc(time.split(":")[0], time.split(":")[1]).split(":00 ")[0]}
                            </p>
                            <div className="px-[6px] group-hover:hidden"></div>
                            <CloseCircleOutlined
                              className="text-red-500 cursor-pointer text-[12px] hidden group-hover:block -mt-8"
                              onClick={() => handleRemoveSingleDate(item.label, time)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SchedulesModal;
