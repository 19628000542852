import React from "react";

const NewLibrary = () => {
  return (
    <div class="content">
      <div class="content-inside">
        <div class="assets-wrap">
          <div class="assets-inside">
            <div class="date-divider">
              <div class="date-divider-text">April 22, 2023</div>
              <div class="date-divider-border"></div>
            </div>
            <div class="asset image active">
              <div class="asset-fake-border"></div>
              <div class="asset-image">
                <img src="images/photo-happy-mid-adult-woman-desk.png" alt="" />
              </div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Image">
                    <i class="ri-image-2-line"></i>
                  </div>
                  <div class="asset-title-text" data-asset-title="Woman at a desk with a tablet">
                    Photo of a woman at a desk with a tablet
                  </div>
                </div>
                <div class="asset-excerpt"></div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">modern</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">attractive</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">female</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">office</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">orange</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">desk</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">tablet</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">modern</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">attractive</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">female</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">office</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">orange</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">desk</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">tablet</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">127kb</div>
                  <div class="asset-imported-date meta-item">Apr 10, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure mid1" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">45</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <div class="page-image-wrap">
                    <img src="images/photo-happy-mid-adult-woman-desk.png" alt="" />
                    <div class="image-dimensions">1920x1080px</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="asset text">
              <div class="asset-fake-border"></div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Text">
                    <i class="ri-text"></i>
                  </div>
                  <div class="asset-title-text">The Best Way to Get the Word Out</div>
                </div>
                <div class="asset-excerpt">
                  Spend 80% less time promoting yourself online while reaching more customers with the ultimate
                  omni-channel growth app.
                </div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">hook</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">customers</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">promotion</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">growth</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">app</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">33 words</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure high" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">86</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <h1>The Best Way to Get the Word Out</h1>
                  <p>
                    Spend 80% less time promoting yourself online while reaching more customers with the ultimate
                    omni-channel growth app.
                  </p>
                </div>
              </div>
            </div>
            <div class="asset document">
              <div class="asset-fake-border"></div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Document">
                    <i class="ri-article-line"></i>
                  </div>
                  <div class="asset-title-text">Form.io Case Study: ImageAPI</div>
                </div>
                <div class="asset-excerpt">
                  How ImageAPI Builds Custom Forms With Workflows And Delivers In Less Than A Month, Instead of 6-8,
                  Without Developers Or Getting Stuck With Limitations
                </div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">forms</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">form</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">api</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">data management</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">development</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">workflows</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">business process applications</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">app</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">application</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">1033 words</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure mid1" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">45</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <h1>Building Flexible Forms With Workflows 6X Faster With In-House Deployment Like They're Native</h1>
                  <h2 class="lightweight">
                    How ImageAPI Builds Custom Forms With Workflows And Delivers In Less Than A Month, Instead of 6-8,
                    Without Developers Or Getting Stuck With Limitations
                  </h2>
                  <h3>Third Time's The Charm</h3>
                  <p>“Too scary,” said Shrinit Chavan, Sr. Product Manager at ImageAPI. “There's too much going on.”</p>
                  <p>
                    After reviewing Appian, a Platform as a Service (PaaS) for building enterprise applications, they
                    didn't even try to implement or test it.
                  </p>
                  <p>So that one doesn't count.</p>
                  <p>On to the next.</p>
                  <p>
                    ImageAPI's Caledo platform is built with a microservices architecture. Nintech simply didn't blend
                    well with ImageAPI's architectural approach. They gave it a brief go, but quickly decided to move
                    on.
                  </p>
                  <p>
                    That's something about ImageAPI: they move with speed. They get the necessary people involved, they
                    review products and services from all the angles, and then they test and adjust.
                  </p>
                  <p>
                    And they needed to get something in front of users quickly to go to market fast. ImageAPI works
                    primarily with state and local governments to automate their workflows and their customers have
                    significant custom feature and compliance requirements.
                  </p>
                  <p>
                    That's why their product selection process is involved—it has to work for a significant number of
                    use-cases.
                  </p>
                  <p>
                    ProcessMaker looked promising—a low-code platform to help automate business processes. They actually
                    began the integration work with ProcessMaker and were able to connect forms with workflows to come
                    up with a semi-functional POC, but there was a snag: technology lock-in.
                  </p>
                  <p>
                    Instead of having the flexibility to do things they wanted to do, they felt that ProcessMaker was
                    locking them into specific ways their platform wanted its users to do them.
                  </p>
                  <p>
                    That's because it's a <span class="strong italic">standalone</span> product. ImageAPI didn't need a
                    standalone product, they needed something that would <span class="strong italic">integrate</span>{" "}
                    into their platform, like it was native, *invisible*, without getting in the way.
                  </p>
                  <p>Strike two.</p>
                  <p>
                    One person on their team had previously worked with an open source automation process platform and
                    forms were the missing piece. They used Form.io to integrate forms with workflows and it was this
                    person that made the recommendation.
                  </p>
                  <p>They took a closer look.</p>
                  <p>
                    “One of the first things we did was review Form.io from a security perspective. One of the big
                    selling points was that we could self-host and control all the ins and outs of how it integrates
                    with our application,” said Chris Ryan, Director of Technology Services, ImageAPI.
                  </p>
                  <p>So they dug deeper.</p>
                  <h3>The Obstacle Dominoes Fall</h3>
                  <p>
                    There's ONE common thread about Form.io that stood out to the ImageAPI team, but before that, see
                    what each said with respect to their role and expertise.
                  </p>
                  <p>
                    Shrinit immediately jumped in to explain one of the key factors in choosing Form.io was the speed
                    that they could deliver solutions. Traditionally, it takes 6-8 months to build and implement one.
                    With Form.io, it takes less than a month to have it in front of users.
                  </p>
                  <p>
                    On top of the speed, it gave them the flexibility to connect it to their process engine and data
                    sources and operate invisibly, even down to the look and feel, as if it were custom built for them.
                  </p>
                  <p>
                    From a customer support perspective, Suzanne Korta, Director of Client Success, could build
                    moderately complex business process forms with workflows in 2 hours instead of a week.
                  </p>
                  <p>
                    A lot of government forms need to look like their paper counterparts. With Form.io, a non-developer
                    can make a PDF form that's identical to the paper version, deploy it, and capture the data in a
                    seamless process.
                  </p>
                  <p>
                    In addition, they're starting to leverage Form.io's <span class="strong">resources</span> that let
                    them build a single master form that gets used within numerous larger forms, streamlining their
                    processes even further.
                  </p>
                  <p>
                    For Shrinit, one of the best features is being able to stand up a public form portal all by itself,
                    published and usable, and you don't have to rely on a developer to achieve it. This has allowed them
                    to test a lot and move forward quickly.
                  </p>
                  <p>
                    He mentioned more than once the best part of Form.io was not having to rely on developers to build
                    and implement forms with workflows.
                  </p>
                  <p>
                    “Not only is it really easy to use, it's extremely extensive,” said Chris. “The APIs you guys have
                    and the ability to connect to external sources and pull the data into the tool really makes it a 360
                    degree solution—and you don't even need developers to get it going. Someone without any development
                    or IT experience can get up to speed and start deploying solutions within a week.”
                  </p>
                  <p>
                    For Rajesh Bhat, Director of Software Development, being able to implement custom components that
                    give them the ability to make form building even more intuitive was a significant factor. In
                    addition, being able to customize the look and feel with CSS and make it look like their own
                    application wasn't just a nice touch, but crucial to their strategy.
                  </p>
                  <p>
                    ImageAPI's experience paints a picture of how Form.io helped them to achieve 1) speed to go to
                    market fast, 2) flexibility without having to sacrifice their requirements or change strategies, and
                    3) control that gives them peace of mind about security and compatibility with their existing
                    software and systems.
                  </p>
                  <h3>What Was The Common Thread?</h3>
                  <p>Support.</p>
                  <p>
                    “Its been really easy to get help,” Said Susanne. “When I submit a question, I get a response right
                    away. I get the information I need to solve the problem. The information is fast and accessible.”
                  </p>
                  <p>
                    “I've been extremely impressed with support. I can just send an email, which is rare these days.
                    within 30 minutes I get the answer, clear and concise, then I'm offered more help, or even a phone
                    call,” said Chris. “It's rare to see this kind of white-glove service these days and it helped us
                    move quickly to adopting Form.io.”
                  </p>
                  <p>“I have to echo what everyone said about support. I was impressed,” said Rajesh.</p>
                </div>
              </div>
            </div>
            <div class="asset video">
              <div class="asset-fake-border"></div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Video">
                    <i class="ri-movie-line"></i>
                  </div>
                  <div class="asset-title-text">Site Audit: Gladinet</div>
                </div>
                <div class="asset-excerpt">
                  Hey Franklin, thanks for the reply, so I have 3 topics I want to cover in this video. Number 1, the
                  target audience, number 2, the messaging, and number 3, the brand and visual design.
                </div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">website</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">brand</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">target audience</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">design</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">audit</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">00:16:32</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure high" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">86</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <p>
                    Hey Franklin, thanks for the reply, so I have 3 topics I want to cover in this video. Number 1, the
                    target audience, number 2, the messaging, and number 3, the brand and visual design.
                  </p>
                  <p>...</p>
                </div>
              </div>
            </div>
            <div class="asset text">
              <div class="asset-fake-border"></div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Text">
                    <i class="ri-text"></i>
                  </div>
                  <div class="asset-title-text">This is a bunch of filler text, and that is sad.</div>
                </div>
                <div class="asset-excerpt">
                  Aenean ut eros et nisl sagittis vestibulum. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
                  justo. Nulla facilisi. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                </div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">filler</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">text</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">lorem</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">ipsum</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">almost</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">useless</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">but</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">here</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">we</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">are</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">127kb</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure mid1" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">45</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <h1>Pellentesque egestas neque sit amet</h1>
                  <p>
                    Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Sed hendrerit. Vivamus laoreet. Fusce
                    vel dui. Curabitur nisi.
                  </p>
                  <p>
                    Vivamus laoreet. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Proin viverra,
                    ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Nam
                    ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Praesent nec nisl a purus blandit
                    viverra.
                  </p>
                  <h2>Pellentesque egestas neque sit amet</h2>
                  <p>
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Praesent congue erat at massa.
                    Quisque ut nisi. Etiam sit amet orci eget eros faucibus tincidunt. Vivamus consectetuer hendrerit
                    lacus.
                  </p>
                  <p>
                    Etiam sit amet orci eget eros faucibus tincidunt. Pellentesque auctor neque nec urna. Fusce ac felis
                    sit amet ligula pharetra condimentum. Integer ante arcu, accumsan a, consectetuer eget, posuere ut,
                    mauris. Praesent vestibulum dapibus nibh.
                  </p>
                  <h3>Pellentesque egestas neque sit amet</h3>
                  <p>
                    Nullam nulla eros, ultricies sit amet, nonummy id, imperdiet feugiat, pede. Donec venenatis
                    vulputate lorem. Integer tincidunt. Aenean posuere, tortor sed cursus feugiat, nunc augue blandit
                    nunc, eu sollicitudin urna dolor sagittis lacus. Aenean tellus metus, bibendum sed, posuere ac,
                    mattis non, nunc.
                  </p>
                  <p>
                    Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Sed hendrerit. Vivamus laoreet. Fusce
                    vel dui. Curabitur nisi.
                  </p>
                  <p>
                    Vivamus laoreet. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Proin viverra,
                    ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Nam
                    ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Praesent nec nisl a purus blandit
                    viverra.
                  </p>
                  <p>
                    In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Praesent congue erat at massa.
                    Quisque ut nisi. Etiam sit amet orci eget eros faucibus tincidunt. Vivamus consectetuer hendrerit
                    lacus.
                  </p>
                  <p>
                    Etiam sit amet orci eget eros faucibus tincidunt. Pellentesque auctor neque nec urna. Fusce ac felis
                    sit amet ligula pharetra condimentum. Integer ante arcu, accumsan a, consectetuer eget, posuere ut,
                    mauris. Praesent vestibulum dapibus nibh.
                  </p>
                  <p>
                    Nullam nulla eros, ultricies sit amet, nonummy id, imperdiet feugiat, pede. Donec venenatis
                    vulputate lorem. Integer tincidunt. Aenean posuere, tortor sed cursus feugiat, nunc augue blandit
                    nunc, eu sollicitudin urna dolor sagittis lacus. Aenean tellus metus, bibendum sed, posuere ac,
                    mattis non, nunc.
                  </p>
                </div>
              </div>
            </div>
            <div class="date-divider">
              <div class="date-divider-text">April 20, 2023</div>
              <div class="date-divider-border"></div>
            </div>
            <div class="asset text">
              <div class="asset-fake-border"></div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Text">
                    <i class="ri-text"></i>
                  </div>
                  <div class="asset-title-text">The Best Way to Get the Word Out</div>
                </div>
                <div class="asset-excerpt">
                  Spend 80% less time promoting yourself online while reaching more customers with the ultimate
                  omni-channel growth app.
                </div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">hook</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">customers</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">promotion</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">growth</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">app</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">25 words</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure high" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">86</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <h1>The Best Way to Get the Word Out</h1>
                  <p>
                    Spend 80% less time promoting yourself online while reaching more customers with the ultimate
                    omni-channel growth app.
                  </p>
                </div>
              </div>
            </div>
            <div class="asset image">
              <div class="asset-fake-border"></div>
              <div class="asset-image">
                <img src="images/illustration-artifact-japanese-maple-2022.png" alt="" />
              </div>
              <div class="asset-content">
                <div class="asset-title">
                  <div class="asset-type" title="Image">
                    <i class="ri-image-2-line"></i>
                  </div>
                  <div class="asset-title-text">Artifact Japanese Maple</div>
                </div>
                <div class="asset-excerpt"></div>
                <div class="asset-tags">
                  <div class="tag">
                    <span class="tag-text">japan</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">maple</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">tree</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">logo</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                  <div class="tag">
                    <span class="tag-text">artifact</span>
                    <button class="remove-tag">
                      <i class="ri-close-line"></i>
                    </button>
                  </div>
                </div>
                <div class="asset-meta">
                  <div class="asset-size meta-item">50.4kb</div>
                  <div class="asset-imported-date meta-item">Apr 22, 2023</div>
                  <div class="asset-published meta-item">
                    <div class="pub-platform twitter" title="Twitter">
                      <i class="ri-twitter-fill"></i>
                      <span class="platform-count">2</span>
                    </div>
                    <div class="pub-platform instagram" title="Instagram">
                      <i class="ri-instagram-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform linkedin" title="LinkedIn">
                      <i class="ri-linkedin-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform facebook" title="Facebook">
                      <i class="ri-facebook-box-fill"></i>
                      <span class="platform-count">1</span>
                    </div>
                    <div class="pub-platform email" title="Email">
                      <i class="ri-mail-line"></i>
                      <span class="platform-count">1</span>
                    </div>
                  </div>
                  <div class="asset-heat-index meta-item">
                    <span class="heat-measure mid1" title="Heat Index">
                      <i class="ri-fire-fill"></i>
                      <span class="heat-index-value">92</span>
                    </span>
                  </div>
                </div>
                <div class="asset-page-content">
                  <div class="page-image-wrap">
                    <img src="images/illustration-artifact-japanese-maple-2022.png" alt="" />
                    <div class="image-dimensions">1740x1076px</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-one">
          <div class="page">
            <div class="page-header">
              <button class="page-close">
                <i class="ri-close-line"></i>
              </button>
              <div class="page-header-menu">
                <div class="page-header-menu-fake-border"></div>
                <a class="page-header-tab show-content active" data-page-menu-item="show-content">
                  Content
                </a>
                <a class="page-header-tab show-genealogy" data-page-menu-item="show-genealogy">
                  Genealogy
                </a>
                <a class="page-header-tab show-published" data-page-menu-item="show-published">
                  Published
                </a>
                <a class="page-header-tab show-campaign" data-page-menu-item="show-campaign">
                  Campaign
                </a>
                <div class="page-header-buttons">
                  <button class="button small primary withicon transformer">
                    <i class="ri-sparkling-line"></i>Transform
                  </button>
                  <button class="button small primary withicon composer">
                    <i class="ri-edit-2-line"></i>Composer
                  </button>
                </div>
              </div>
              <div class="page-header-sub-menu show-content active">
                <div class="asset-id">ID000001</div>
                <button class="wizzy-item" title="Plain Text (Remove Formatting)">
                  <i class="ri-text"></i>
                </button>
                <button class="wizzy-item" title="Bold">
                  <i class="ri-bold"></i>
                </button>
                <button class="wizzy-item" title="Underline">
                  <i class="ri-underline"></i>
                </button>
                <button class="wizzy-item" title="Italic">
                  <i class="ri-italic"></i>
                </button>
                <div class="wizzy-sub-wrap">
                  <button class="wizzy-item wizzy-show-sub" title="Heaeding">
                    <i class="ri-heading"></i>
                    <i class="ri-arrow-down-s-line right"></i>
                  </button>
                  <div class="wizzy-sub-menu">
                    <button class="wizzy-item">
                      <i class="ri-h-1"></i>
                    </button>
                    <button class="wizzy-item">
                      <i class="ri-h-2"></i>
                    </button>
                    <button class="wizzy-item">
                      <i class="ri-h-3"></i>
                    </button>
                    <button class="wizzy-item">
                      <i class="ri-h-4"></i>
                    </button>
                    <button class="wizzy-item">
                      <i class="ri-h-5"></i>
                    </button>
                    <button class="wizzy-item">
                      <i class="ri-h-6"></i>
                    </button>
                  </div>
                </div>
                <button class="wizzy-item" title="Bullet List">
                  <i class="ri-list-unordered"></i>
                </button>
                <button class="wizzy-item" title="Numbered List">
                  <i class="ri-list-ordered-2"></i>
                </button>
                <button class="wizzy-item" title="Strike Through">
                  <i class="ri-strikethrough"></i>
                </button>
                <button class="wizzy-item color" title="Text Color">
                  <input id="colorPicker" type="text" />
                </button>
                <button class="wizzy-item" title="View Source Code">
                  <i class="ri-code-s-slash-line"></i>
                </button>
                <button class="toolbar-button show-panel-two show-content" data-panel-two="show-content">
                  Notes <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
              <div class="page-header-sub-menu show-genealogy">
                <div class="gen-meta-item gen-parents">2 Parent(s)</div>
                <div class="gen-meta-item gen-children">4 Child(ren)</div>
                <button class="show-panel-two show-genealogy toolbar-button" data-panel-two="show-genealogy">
                  Preview <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
              <div class="page-header-sub-menu show-published">
                <div class="page-published-meta"></div>
                <div class="page-published-heat"></div>
              </div>
              <div class="page-header-sub-menu show-campaign"></div>
            </div>
            <div class="page-content-wrap show-content active">
              <div class="page-content-inside">
                <div class="page-title-wrap">
                  <div class="page-title-inside">
                    <div class="page-asset-type"></div>
                    <div class="page-title" contenteditable="true"></div>
                  </div>
                  <div class="page-asset-meta">
                    <button class="button delete-asset xsmall lightbox-show" data-lightbox="lightbox-delete-asset">
                      Delete Asset
                    </button>
                    <div class="page-asset-created"></div>
                    <div class="page-asset-size"></div>
                    <input class="page-tags" type="text" placeholder="Add tag" />
                  </div>
                  <div class="asset-tags clearfix"></div>
                </div>
                <div class="page-content" contenteditable="true"></div>
              </div>
            </div>
            <div class="page-content-wrap show-genealogy">
              <div class="page-content-inside">
                <div class="page-content tree">
                  <div class="gen-item-wrap gen0">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">127kb</div>
                        <div class="gen-item-import-date">Mar 14, 2023</div>
                        <div class="gen-item-title">
                          This is a title of a parent parent assets fsf sdfs dfdsf sadfsadf sadf safsad fsadf sadf
                          sdffdsfds
                        </div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen1">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">4312 words</div>
                        <div class="gen-item-import-date">Mar 27, 2023</div>
                        <div class="gen-item-title">This is a title of a parent asset</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen2 gen-target active">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">2126 words</div>
                        <div class="gen-item-import-date"></div>
                        <div class="gen-item-title"></div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen3">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">52kb</div>
                        <div class="gen-item-import-date">Apr 11, 2023</div>
                        <div class="gen-item-title">This is a title of a child asset</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen4">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">412 words</div>
                        <div class="gen-item-import-date">Apr 13, 2023</div>
                        <div class="gen-item-title">This is a title of a child child asset</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen3">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">748 words</div>
                        <div class="gen-item-import-date">Apr 12, 2023</div>
                        <div class="gen-item-title">This is a title of a child asset</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                  <div class="gen-item-wrap gen4">
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        <div class="gen-item-size">36kb</div>
                        <div class="gen-item-import-date">Apr 20, 2023</div>
                        <div class="gen-item-title">This is a title of a child child asset</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-content-wrap show-published">
              <div class="page-content-inside">
                <div class="published-platform twitter">
                  <div class="platform-title">
                    <i class="ri-twitter-fill"></i> Twitter
                  </div>
                  <div class="published-items-wrap">
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Tweet</div>
                        <div class="platform-item date">Apr 12, 2023 02:03PM</div>
                      </div>
                      <div class="platform-item withicon replies" title="Replies">
                        <i class="ri-chat-3-line"></i> 7
                      </div>
                      <div class="platform-item withicon retweets" title="Retweets">
                        <i class="ri-loop-right-fill"></i> 1
                      </div>
                      <div class="platform-item withicon likes" title="Likes">
                        <i class="ri-heart-line"></i> 26
                      </div>
                      <div class="platform-item withicon views" title="Views">
                        <i class="ri-bar-chart-2-fill"></i> 1041
                      </div>
                    </div>
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Tweet</div>
                        <div class="platform-item date">Jan 03, 2023 11:03AM</div>
                      </div>
                      <div class="platform-item withicon replies" title="Replies">
                        <i class="ri-chat-3-line"></i> 17
                      </div>
                      <div class="platform-item withicon retweets" title="Retweets">
                        <i class="ri-loop-right-fill"></i> 6
                      </div>
                      <div class="platform-item withicon likes" title="Likes">
                        <i class="ri-heart-line"></i> 87
                      </div>
                      <div class="platform-item withicon views" title="Views">
                        <i class="ri-bar-chart-2-fill"></i> 8121
                      </div>
                    </div>
                  </div>
                </div>
                <div class="published-platform instagram">
                  <div class="platform-title">
                    <i class="ri-instagram-fill"></i> Instagram
                  </div>
                  <div class="published-items-wrap">
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Post</div>
                        <div class="platform-item date">Apr 12, 2023 02:03PM</div>
                      </div>
                      <div class="platform-item withicon likes" title="Likes">
                        <i class="ri-heart-line"></i> 26
                      </div>
                      <div class="platform-item withicon comments" title="Comments">
                        <i class="ri-chat-3-line"></i> 7
                      </div>
                      <div class="platform-item withicon impressions" title="Impressions">
                        <i class="ri-bar-chart-2-fill"></i> 8121
                      </div>
                      <div class="platform-item withicon reach" title="Reach">
                        <i class="ri-user-3-line"></i> 1872
                      </div>
                    </div>
                  </div>
                </div>
                <div class="published-platform linkedin">
                  <div class="platform-title">
                    <i class="ri-linkedin-fill"></i> LinkedIn
                  </div>
                  <div class="published-items-wrap">
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Post</div>
                        <div class="platform-item date">Apr 12, 2023 02:03PM</div>
                      </div>
                      <div class="platform-item withicon likes" title="Likes">
                        <i class="ri-heart-line"></i> 26
                      </div>
                      <div class="platform-item withicon comments" title="Comments">
                        <i class="ri-chat-3-line"></i> 7
                      </div>
                      <div class="platform-item withicon reposts" title="Reposts">
                        <i class="ri-loop-right-fill"></i> 6
                      </div>
                    </div>
                  </div>
                </div>
                <div class="published-platform facebook">
                  <div class="platform-title">
                    <i class="ri-facebook-fill"></i> Facebook
                  </div>
                  <div class="published-items-wrap">
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Post</div>
                        <div class="platform-item date">Apr 12, 2023 02:03PM</div>
                      </div>
                      <div class="platform-item withicon likes" title="Likes">
                        <i class="ri-heart-line"></i> 26
                      </div>
                      <div class="platform-item withicon comments" title="Comments">
                        <i class="ri-chat-3-line"></i> 7
                      </div>
                      <div class="platform-item withicon shares" title="Shares">
                        <i class="ri-share-forward-line"></i> 6
                      </div>
                    </div>
                  </div>
                </div>
                <div class="published-platform facebook">
                  <div class="platform-title">
                    <i class="ri-mail-line"></i> Email
                  </div>
                  <div class="published-items-wrap">
                    <div class="published-item show-panel-two">
                      <div class="platform-items-wrap">
                        <div class="platform-item type">Email</div>
                        <div class="platform-item date">Apr 12, 2023 02:03PM</div>
                      </div>
                      <div class="platform-item withicon recipients" title="Recipients">
                        <i class="ri-user-3-line"></i> 22786
                      </div>
                      <div class="platform-item withicon open" title="Open">
                        <i class="ri-mail-open-line"></i> 8975
                      </div>
                      <div class="platform-item withicon read" title="Read">
                        <i class="ri-eye-line"></i> 6158
                      </div>
                      <div class="platform-item withicon clicks" title="Clicks">
                        <i class="ri-cursor-line"></i> 1578
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-content-wrap show-campaign"></div>
          </div>
        </div>
        <div class="panel panel-import">
          <div class="page">
            <div class="page-header">
              <button class="page-close">
                <i class="ri-close-line"></i>
              </button>
              <div class="page-header-menu">
                <div class="page-header-menu-fake-border"></div>
                <div class="page-header-title">
                  <span class="page-header-title-text">Import</span>{" "}
                  <button class="help">
                    <i class="ri-question-fill"></i>
                  </button>
                </div>
                <div class="help-bubble">
                  <button id="" class="close-help">
                    <i class="ri-close-line"></i>
                  </button>
                  <p>Please select any one of the following types of content.</p>
                </div>
                <a class="page-header-tab import-text active" data-page-menu-item="import-text">
                  Text
                </a>
                <a class="page-header-tab import-document" data-page-menu-item="import-document">
                  Document
                </a>
                <a class="page-header-tab import-audio-video" data-page-menu-item="import-audio-video">
                  Audio/Video
                </a>
                <a class="page-header-tab import-image" data-page-menu-item="import-image">
                  Images
                </a>
                <a class="page-header-tab import-url" data-page-menu-item="import-url">
                  URL
                </a>
              </div>
              <div class="page-header-sub-menu import import-text active">
                <div class="import-help-text">
                  <span class="strong">Text:</span> Blogs, articles, newsletters up to 20000 characters.
                </div>
                <div class="import-text-char-count">4312 chars</div>
              </div>
              <div class="page-header-sub-menu import import-document">
                <div class="import-help-text">
                  <span class="strong">Document:</span> .docx, .txt
                </div>
                <div class="import-text-file-status">1 file(s)</div>
              </div>
              <div class="page-header-sub-menu import import-audio-video">
                <div class="import-help-text">Videos &amp; audio files will be transcribed into text.</div>
                <div class="import-text-file-status">1 file(s)</div>
              </div>
              <div class="page-header-sub-menu import import-image">
                <div class="import-help-text">
                  <span class="strong">Image:</span> Upload one or more.
                </div>
                <div class="import-text-file-status">3 file(s)</div>
              </div>
              <div class="page-header-sub-menu import import-url">
                <div class="import-help-text">
                  <span class="strong">URL:</span> Paste a link and click scrape.
                </div>
                <div class="import-text-file-status">Processing...</div>
              </div>
            </div>
            <div class="page-content-wrap import import-text active">
              <div class="page-content-inside">
                <textarea class="import-text-area" placeholder="Copy and paste your text here."></textarea>
              </div>
              <button class="button primary finish-import">Finish Import</button>
            </div>
            <div class="page-content-wrap import import-document">
              <div class="page-content-inside">
                <button class="file-upload">
                  <div class="file-upload-title">Upload Document</div>
                </button>
              </div>
              <button class="button primary finish-import">Finish Import</button>
            </div>
            <div class="page-content-wrap import import-audio-video">
              <div class="page-content-inside">
                <button class="file-upload">
                  <div class="file-upload-title">Upload Audio Or Video</div>
                </button>
              </div>
              <button class="button primary finish-import">Finish Import</button>
            </div>
            <div class="page-content-wrap import import-image">
              <div class="page-content-inside">
                <button class="file-upload">
                  <div class="file-upload-title">Upload Image(s)</div>
                </button>
              </div>
              <button class="button primary finish-import">Finish Import</button>
            </div>
            <div class="page-content-wrap import import-url">
              <div class="page-content-inside">
                <div class="import-type url">
                  <input class="withbutton" type="text" placeholder="https://..." />
                  <button class="button withfield primary">Scrape URL</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-two">
          <div class="page">
            <div class="panel-two-page-wrap show-content active">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close">
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">Notes</div>
              </div>
              <div class="panel-two-content">
                <div class="notes-wrap">
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="add-note-wrap">
                  <div class="note-avatar">
                    <img src="images/photo-chevas.png" alt="" />
                  </div>
                  <textarea class="add-note"></textarea>
                </div>
              </div>
            </div>
            <div class="panel-two-page-wrap show-genealogy">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close">
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">
                  <div class="panel-two-title-text">Preview</div>
                  <button class="button small primary open-this">Open This</button>
                </div>
              </div>
              <div class="panel-two-content">
                <div class="panel-two-content-inside"></div>
              </div>
            </div>
            <div class="panel-two-page-wrap show-published">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close">
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">Details</div>
              </div>
              <div class="panel-two-content">
                <blockquote class="twitter-tweet">
                  <p lang="en" dir="ltr">
                    “If you can't explain something simply, you don't understand it well enough.”
                    <br /> — Albert Einstein
                  </p>
                  &mdash; Joshua Lisec | The Ghostwriter (@JoshuaLisec){" "}
                  <a href="https://twitter.com/JoshuaLisec/status/1649601977548939264?ref_src=twsrc%5Etfw">
                    April 22, 2023
                  </a>
                </blockquote>{" "}
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-composer">
          <div class="build-message">
            <div class="build-text">Composer View TBD</div>
            <button class="button xsmall primary close-build">Close</button>
          </div>
        </div>
        <div class="panel panel-transformer">
          <div class="build-message">
            <div class="build-text">Transformer View TBD</div>
            <button class="button xsmall primary close-build">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLibrary;
