import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import { ReactComponent as GenerateIcon } from "../../assets/icons/generateIcon.svg";
import Button from "../../components/utils/Button";
import GeneratedTweet from "../../components/Library/GeneratedTweet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleGenerateTweets, addApprovedTweets } from "../../redux/actions/libraryAction";
import { useNavigate } from "react-router-dom";
import LibraryQuestionsBottomBar from "../../components/Library/BottomBar/LibraryQuestionsBottomBar";
import axios from "axios";
import { successAlert } from "../../utils/sweetAlert";
const AccpetRejectTweets = () => {
  const navigate = useNavigate();
  const [tweetsData, setTweetsData] = useState([]);
  const { generatedTweets, baseText, profileId, assetId } = useSelector((state) => state.library);
  const [loading, setLoading] = useState(false);
  const [approvedTweets, setApprovedTweets] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const formattedArray = generatedTweets.map((tweet, index) => ({
      status: "undecided",
      text: `${tweet}`,
    }));
    setTweetsData(formattedArray.filter((el) => el.text.trim()));
  }, [generatedTweets]);

  function getStringsAtIndex(stringsArray, indexArray) {
    return indexArray.map((index) => stringsArray[index]);
  }
  const handleAutoSave = (updatedArray) => {
    var unapproved = updatedArray.filter((tweet) => tweet.status === "undecided");
    var temp = [];
    for (const tw of unapproved) {
      temp.push(tw.text);
    }
    const { data } = axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/update-lib-asset`,
      {
        item: {
          id: assetId,
          tweet: temp,
          text: null,
          type: "unapproved_tweet",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
  };
  const acceptTweet = (tweetIndex) => {
    const updatedArray = [...tweetsData];
    updatedArray[tweetIndex] = {
      ...updatedArray[tweetIndex],
      status: "accepted",
    };
    setTweetsData(updatedArray.filter((el) => el.text.trim()));
    setApprovedTweets([...approvedTweets, tweetIndex]);
  };

  const rejectTweet = (tweetIndex) => {
    const updatedArray = [...tweetsData];
    updatedArray[tweetIndex] = {
      ...updatedArray[tweetIndex],
      status: "rejected",
    };
    setTweetsData(updatedArray.filter((el) => el.text.trim()));

    handleAutoSave(updatedArray);
  };

  const undoTweet = (tweetIndex) => {
    const updatedArray = [...tweetsData];

    if (updatedArray[tweetIndex].status === "accepted") {
      const temp = [...approvedTweets];
      // temp.filter((tweet) => tweet !== updatedArray[tweetIndex].text);

      // FIXME: remove the set and implement other logic
      // if all the tweets are going to be unique, then no need to change the below line
      const newApprovedData = new Set(temp);
      newApprovedData.delete(updatedArray[tweetIndex].text);

      setApprovedTweets([...newApprovedData]);
    }

    updatedArray[tweetIndex] = {
      ...updatedArray[tweetIndex],
      status: "undecided",
    };
    setTweetsData(updatedArray.filter((el) => el.text.trim()));
    handleAutoSave(updatedArray);
  };

  const formatPrompt = () => {
    var promptString = `Use this text & the other example tweets to generate atleast 9 tweets based. Don't use hashtags. Use short sentences & punchy language.\n`;

    tweetsData.forEach((tweet, index) => {
      promptString += `example tweet ${index + 1}: "${tweet.text}"\n`;
    });

    promptString += `text: ${baseText}\n`;

    promptString += `Tweet 1: \n `;

    return promptString;
  };

  const handleSubmit = async () => {
    var unapproved = tweetsData.filter((tweet) => tweet.status === "undecided");
    console.log(unapproved);
    const result = getStringsAtIndex(generatedTweets, approvedTweets);
    const res = await dispatch(addApprovedTweets(profileId, result, assetId));
    var temp = [];
    for (const tw of unapproved) {
      temp.push(tw.text);
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/update-lib-asset`,
      {
        item: {
          id: assetId,
          tweet: temp,
          text: null,
          type: "unapproved_tweet",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res) {
      navigate(`/library/${profileId}`);
    }
  };

  const handleOnClick = async () => {
    setLoading(true);
    const formatString = await formatPrompt();
    var GPTResponce = await dispatch(
      handleGenerateTweets({ baseText: formatString, example: [""] }, profileId, assetId, true)
    );
    if (GPTResponce) {
      setLoading(false);
      successAlert({
        title: "Task Created Successfully!",
      });
      navigate(`/library/${profileId}`);
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "REMOVE_GENERATED_TWEETS" });
    };
  }, []);

  return (
    <>
      <PostSigninNavbar title="Transform your text to tweets" />

      <div className="w-[85%] m-auto mt-11 pb-5">
        <div className="pt-[20px] flex justify-between items-center">
          <div>
            <h3 className="text-[#111928] text-[30px] font-semibold">Do you like these tweets?</h3>
            <p className="text-[#6B7280] text-[18px]">
              Select the tweets you like. Click Accept to accept, Reject to reject.
            </p>
          </div>

          <Button onClick={handleOnClick} loading={loading}>
            <GenerateIcon height="17" width="15" className="mr-[5px]" />
            Generate More
          </Button>
        </div>

        <div className="grid grid-cols-3 gap-[20px] pb-14 items-start">
          {tweetsData.map(
            (tweet, index) =>
              tweet &&
              tweet.text.length > 1 && (
                <GeneratedTweet
                  key={index}
                  index={index}
                  data={tweet}
                  acceptTweet={() => acceptTweet(index)}
                  rejectTweet={() => rejectTweet(index)}
                  undoTweet={() => undoTweet(index)}
                />
              )
          )}
        </div>
      </div>

      <LibraryQuestionsBottomBar
        loading={loading}
        onClick={handleSubmit}
        buttonText="Import To Library"
        acceptedTweesCount={
          <div className="text-sm font-semibold text-[#374151] mr-[10px]">
            {approvedTweets.length}/{tweetsData.length}
          </div>
        }
      />
    </>
  );
};

export default AccpetRejectTweets;
