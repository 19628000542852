const Modal = (props) => {
  return (
    <div
      className="fixed inset-0  z-[1000] min-h-screen min-w-screen flex justify-center items-center w-full "
      aria-label="modal"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[90]"
        onClick={() => props.setisModalHidden(true)}
      ></div>
      <div
        className={`relative z-[99] bg-white rounded-[10px] p-10 px-12 w-1/2  max-h-[98vh] ${
          props.width == "large" && "w-2/3"
        } show-modal`}
      >
        <div
          className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
          onClick={() => props.setisModalHidden(true)}
        >
          &times;
        </div>
        {props.children}
        {/* <PublishingModalContent {...props} /> */}
      </div>
    </div>
  );
};

export default Modal;
