import { ReactComponent as CheckIcon } from "../../assets/icons/checkIcon.svg";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
const GeneratedTweet = (props) => {
  const dispatch = useDispatch();
  // status should be accepted | rejected | undecided
  const { index, acceptTweet, rejectTweet, undoTweet } = props;
  const { text, status } = props.data;
  const textAreaRef = useRef(null);

  useEffect(() => {
    //to resize the textarea on load
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [text]);

  const handleOnChange = async (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    dispatch({type: "UPDATE_GENERATED_TWEETS", index: index , newTweet : e.target.value})
  }


  return (
    <div className="bg-white border border-[#E4E4E7] mt-[20px] p-[15px] flex flex-col justify-between overflow-hidden rounded-[12px]">
      {/* <div className="text-[#6B7280]">{text}</div> */}
      <textarea
        className="text-[#6B7280] resize-none !min-h-max"
        placeholder="Genereted Tweet"
        ref={textAreaRef}
        onBlur={(e) => handleOnChange(e)}
      >{text}</textarea>

      <div className="font-semibold flex justify-between mt-2">
        {status === "accepted" ? (
          <div className="flex gap-[4px] items-center cursor-pointer text-[#31C48D]">
            <CheckIcon fill="#31C48D" />
            Accepted
          </div>
        ) : status === "rejected" ? (
          <div className="flex gap-[4px] items-center cursor-pointer text-[#E02424]">
            <CheckIcon fill="#E02424" />
            Rejected
          </div>
        ) : (
          <div className="flex gap-[10px] items-center">
            <div
              onClick={() => acceptTweet(index)}
              className="flex gap-[4px] items-center cursor-pointer text-[#1C64F2]"
            >
              <CheckIcon fill="#1C64F2" />
              Accept
            </div>

            <div
              onClick={() => rejectTweet(index)}
              className="flex gap-[4px] items-center cursor-pointer text-[#E02424]"
            >
              <CheckIcon fill="#E02424" />
              Reject
            </div>
          </div>
        )}
        {status !== "undecided" && (
          <div onClick={() => undoTweet(index)} className="text-[#71717A] cursor-pointer">
            Undo
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneratedTweet;
