import axios from "axios";

const Members = ({ members, user }) => {
  const handleRoleChange = async (e, memberId) => {
    const role = e.target.value;
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/team/update-member-role`,
      {
        memberId,
        role,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
  };

  return (
    <>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 p-4">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 notdark:divide-neutral-700">
              <thead className="bg-gray-50 notdark:bg-neutral-700">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 notdark:text-white sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 notdark:text-white"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 notdark:text-white"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 notdark:text-white"
                  >
                    Company
                  </th>
                  {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white notdark:bg-neutral-800">
                {members.map((member) => (
                  <tr key={member.id} className="border-2">
                    <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 notdark:text-white sm:pl-6">
                      {member.user.fullName}
                    </td>
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400">
                      {member.user.email}
                    </td>
                    {user.teamMembers[0].role === "owner" ? (
                      member.role === "owner" ? (
                        <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400">
                          Owner
                        </td>
                      ) : (
                        <select
                          name="role"
                          disabled={member.role === "owner"}
                          className="w-full text-center mx-auto"
                          onChange={(event) => handleRoleChange(event, member.id)}
                          id=""
                        >
                          <option selected={member.role == "admin"} value="admin">
                            Admin
                          </option>
                          <option selected={member.role == "member"} value="member">
                            Member
                          </option>
                        </select>
                      )
                    ) : (
                      <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400">
                        {member.role}
                      </td>
                    )}
                    <td className="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500 notdark:text-neutral-400">
                      {member.profile.profileName}
                    </td>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a href="#" className="text-primary-500 hover:text-indigo-900">
                        Edit
                        <span className="sr-only">, {member.user.name}</span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
