const initState = {
  loading: false,
  isAuthenticated: false,
  user: null,
  referredUsers: [],
  superAdmin: {
    usageByOrganization: [],
    usageByUser: [],
    ReferredUsers: [],
  },
};

const setUserAuth = (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "CHANGE_REFERRED_USER":
      return {
        ...state,
        referredUsers: action.payload,
      };
    case "CHANGE_SUPER_ADMIN_DATA":
      return {
        ...state,
        superAdmin: action.payload,
      };
    case "CHANGE_AUTH_TRUE":
      return {
        ...state,
        isAuthenticated: true,
        user: handleTeamProfileRelationship(action.payload),
      };
    case "CHANGE_AUTH_FALSE":
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default setUserAuth;

const handleTeamProfileRelationship = (user) => {
  // var plan = user.stripeCustomer.plan;
  var tmpuser = user;
  tmpuser.profiles = [];
  // console.log(user, "user")
  // console.log(tmpuser, "tmpuser")
  // if (plan === "INDIVIDUAL_PLAN") {
  if (user.teamMembers) {
    for (const tm of user.teamMembers) {
      tmpuser = {
        ...user,
        profiles: [tm.profile, ...tmpuser.profiles],
      };
    }
  }
  return tmpuser;
};
