import { useState, useEffect } from "react";
import { handleGenerateTweetsThreads } from "../../redux/actions/libraryAction";
import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import LibraryQuestionsBottomBar from "../../components/Library/BottomBar/LibraryQuestionsBottomBar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { failAlert, successAlert } from "../../utils/sweetAlert";
import { Select, Space } from "antd";
import { selectOptions } from "../../utils/multiSelect";

const BASE_TEXT_CHARACTER_LIMIT = 20000;
const HOOK_AND_CTA_CHARACTER_LIMIT = 255;
const GenerateThreads = () => {
  const [data, setData] = useState({
    text: "",
    hook: "",
    cta: "",
  });
  const { baseText } = useSelector((state) => state.library);
  const { profileId, assetId, selectedAsset } = useSelector((state) => state.library);
  const [topicOptions, setTopicOptions] = useState([]);
  useEffect(() => {
    if (selectedAsset) {
      var temp = [];
      for (var i = 0; i < selectedAsset.topics.length; i++) {
        temp.push({
          label: selectedAsset.topics[i],
          value: selectedAsset.topics[i],
        });
      }
      setTopicOptions(temp);
    }
  }, [selectedAsset]);
  const [loading, setLoading] = useState(false);
  const [selectVal, setSelectVal] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    let limit = BASE_TEXT_CHARACTER_LIMIT;
    if (e.target.name === "hook" || e.target.name === "cta") {
      limit = HOOK_AND_CTA_CHARACTER_LIMIT;
    }

    // if (e.target.value.length <= limit) {
    setData({ ...data, [e.target.name]: e.target.value });
    // }
  };

  const handleOnClick = async () => {
    setLoading(true);
    var GPTResponce = await dispatch(handleGenerateTweetsThreads(data, profileId, assetId));
    if (GPTResponce) {
      navigate(`/library/${profileId}`);
      setLoading(false);
      successAlert({
        title: "Transformation Started!",
      });
    } else {
      failAlert({
        title: "Some error occurred!",
      });
    }
  };

  useEffect(() => {
    setData({ ...data, text: baseText });
  }, [baseText]);

  return (
    <>
      <PostSigninNavbar title="Transform your text to tweets" />

      <div className="w-[85%] m-auto mt-11">
        <div className="pt-[20px]">
          <h3 className="text-[#111928] text-[30px] font-semibold">Generate a Twitter Thread</h3>
          <p className="text-[#6B7280] text-[18px]">Pick the type of content you want to transform into a thread.</p>
        </div>
        <div className="flex">
          <div className="w-2/3 bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] mt-[30px] h-fit mr-3">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">1. Enter the base text</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">
              Blogs, articles, newsletters up to 20,000 characters.
            </p>

            <div className="relative h-auto">
              <textarea
                name="text"
                className="bg-[#F9FAFB] w-full h-full min-h-[15rem] text-[#3F3F46] p-[18px] resize-y rounded-[12px] text-[14px]"
                placeholder="Enter your text here"
                value={data.text}
                onChange={(e) => handleOnChange(e)}
              ></textarea>
              <div
                className={`absolute ${
                  data.text.length > 20000 ? "text-red-500" : "text-[#6B7280]"
                } text-[14px] right-[10px] bottom-[10px]`}
              >
                {/* {data.text.length} */}
                {data.text.length <= 20000 ? data.text.length : 20000 - data.text.length} chars
              </div>
            </div>
          </div>
          <div className="w-1/3 bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] mt-[30px] h-fit">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">Enter the topics (Optional)</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">What would you like the text generated to be about?</p>
            <div className="relative ">
              <Select
                mode="tags"
                allowClear
                style={{
                  width: "100%",
                }}
                // disabled={true}
                placeholder="Please select"
                // defaultValue={["a10", "c12"]}
                onChange={(e) => {
                  // console.log(e);
                  setData({ ...data, topics: e.toString() });
                  // console.log(e.toString());
                  setSelectVal(e);
                }}
                value={selectVal}
                options={topicOptions}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-[25px] mt-[20px] mb-[110px]">
          <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] mt-[30px]">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">2. Enter the hook</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">
              Write a catchy, impressive hook. (max. 255 characters)
            </p>

            <div className="relative h-[150px]">
              <textarea
                name="hook"
                className="bg-[#F9FAFB] w-full h-full text-[#3F3F46] p-[18px] resize-none rounded-[12px] text-[14px]"
                placeholder="Enter your text here"
                value={data.hook}
                maxLength={255}
                onChange={(e) => handleOnChange(e)}
              ></textarea>
              <div
                className={`absolute  text-[14px] right-[10px] bottom-[10px] ${
                  data.hook.length > 255 ? "text-red-500" : "text-[#6B7280]"
                }`}
              >
                {data.hook.length} chars
              </div>
            </div>
          </div>
          <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] mt-[30px]">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">3. Enter the call-to-action</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">
              What do you want your readers to do? (max. 255 characters)
            </p>

            <div className="relative h-[150px]">
              <textarea
                name="cta"
                className="bg-[#F9FAFB] w-full h-full text-[#3F3F46] p-[18px] resize-none rounded-[12px] text-[14px]"
                placeholder="Enter your text here"
                value={data.cta}
                maxLength={255}
                onChange={(e) => handleOnChange(e)}
              ></textarea>
              <div
                className={`absolute text-[14px] right-[10px] bottom-[10px] ${
                  data.cta.length > 255 ? "text-red-500" : "text-[#6B7280]"
                }`}
              >
                {data.cta.length} chars
              </div>
            </div>
          </div>
        </div>
      </div>

      <LibraryQuestionsBottomBar
        loading={loading}
        onClick={handleOnClick}
        buttonText="Next"
        variant={data.text.length <= 20000 ? "blue" : "disabled"}
      />
    </>
  );
};

export default GenerateThreads;
