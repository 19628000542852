const CHARACTER_LIMIT = 20000;

const TextImport = ({ baseText, setBaseText, setdisableButton, isCurrentlyUploading, setIsCurrentlyUploading }) => {
  const handleChange = (e) => {
    if (e.target.value.length > CHARACTER_LIMIT) {
      setdisableButton(true);
    } else {
      setdisableButton(false);
    }

    setBaseText(e.target.value);
  };

  return (
    <div class="page-content-inside">
      <textarea
        class="import-text-area"
        placeholder="Copy and paste your text here."
        value={baseText}
        onChange={handleChange}
      ></textarea>
    </div>
  );
};

export default TextImport;
{
  /* <div className="flex flex-col relative h-full w-full">
  <div
    className={`absolute text-[#6B7280] text-[14px] right-[32px] bottom-[12px] ${
      baseText.length > 20000 ? "text-red-500" : "text-[#6B7280]"
    } `}
  >
    <p className="-mt-6 mr-2">{baseText.length <= 20000 ? baseText.length : 20000 - baseText.length} chars</p>
  </div>
</div>; */
}
