import React, { useState, useEffect } from "react";
import { handleSubscription } from "../utils/stripeClient";
import Loader from "./utils/Loader";
import { failAlert } from "../utils/sweetAlert";
import Button from "./utils/Button";
import { useSelector } from "react-redux";
export default function PaymentModal(props) {
  const { planDetails, currentPlan, setIsModalOpen } = props;
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const buyPlan = async (plan) => {
    try {
      setSelectedPlan(plan);
      var selectedplan = "";
      switch (plan) {
        case process.env.REACT_APP_STRIPE_WORDOUT_SUBSCRIPTION_PLAN_ID:
          selectedplan = "WORDOUT_SUBSCRIPTION";
          break;
        case process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_ID:
          selectedplan = "INDIVIDUAL_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_INDIVIDUAL_PLUS_PLAN_ID:
          selectedplan = "INDIVIDUAL_AGENCY_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_STARTUP_PLAN_ID:
          selectedplan = "TEAM_PLAN";
          break;
        case process.env.REACT_APP_STRIPE_STARTUP_PLUS_PLAN_ID:
          selectedplan = "TEAM_AGENCY_PLAN";
          break;
        default:
          selectedplan = plan;
      }

      // if (
      //   (currentPlan.plan === "TEAM_AGENCY_PLAN" || currentPlan.plan === "TEAM_PLAN") &&
      //   user.teamMembers.length > 1 &&
      //   selectedplan !== "TEAM_PLAN"
      // ) {
      //   setSelectedPlan(null);
      //   failAlert({
      //     title:
      //       "Sorry, You cannot downgrade your plan from your existing plan at this time. Please contact our support team for further assistance.",
      //   });
      //   return;
      // }

      // if (
      //   (currentPlan.plan === "INDIVIDUAL_AGENCY_PLAN" || currentPlan.plan === "TEAM_AGENCY_PLAN") &&
      //   user.profiles.length > 1 &&
      //   (selectedplan === "INDIVIDUAL_PLAN" || selectedplan === "TEAM_PLAN")
      // ) {
      //   setSelectedPlan(null);
      //   failAlert({
      //     title:
      //       "Sorry, You cannot downgrade your plan from your existing plan at this time. Please delete a organization before downgrading.",
      //   });
      //   return;
      // }

      if (plan) {
        const data = await handleSubscription(plan);
        if (data === false) throw new Error("Sorry, something went wrong");
      }
    } catch (error) {
      failAlert({ title: "Sorry, something went wrong" });
      setSelectedPlan(null);
    }
  };

  return (
    <div
      className="fixed inset-0 z-[199] min-h-screen min-w-screen flex justify-center items-center"
      aria-label="modal"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[190]"
        onClick={() => setIsModalOpen(false)}
      ></div>
      <div className="relative z-[199] bg-white rounded-[10px] p-[40px] pb-[15px] pr-[15px] w-1/2 show-modal">
        <h3 className="text-[30px] font-semibold">Available Plan</h3>
        <p className="text-[#6B7280] text-[16px] mb-[10px]">Please upgrade your plan to create multiple companies</p>

        <div className="flex justify-around items-center gap-x-6">
          {planDetails.map((item, index) => (
            <div
              className={`flex flex-col w-full max-w-sm p-5 space-y-8 text-center shadow-md bg-white border-2 rounded-[12px] ${
                currentPlan.plan === "INDIVIDUAL_PLAN" && currentPlan.planStatus === "active" && "border-[#1c64f2]"
              }`}
            >
              <div className="flex-shrink-0">
                <h2 className="text-[#1c64f2] font-semibold">{item.name}</h2>
              </div>
              <div className="flex-shrink-0">
                <span className="pt-2 text-4xl font-bold uppercase">{item.price}</span>
                <span>{item.support}</span>
              </div>
              <ul className="flex-1 space-y-4">
                <li className="text-gray-700 dark:text-gray-600">{item.description}</li>
              </ul>

              {currentPlan.plan === item.plan && currentPlan.planStatus === "active" ? (
                <>
                  <Button className="border-8">Current Plan</Button>
                  {/* <div className="text-[#000] font-semibold">Current Plan</div>
                                        <Button className="!bg-red-700 !hover:bg-red-400 font-semibold" >
                                            Current Plan
                                        </Button> */}
                </>
              ) : (
                <Button id={item.plan} className="uppercase font-semibold" onClick={(e) => buyPlan(e.target.id)}>
                  {selectedPlan === item.plan ? (
                    <div className="h-[24px]">
                      <Loader height="16px" width="16px" />
                    </div>
                  ) : (
                    <>Buy</>
                  )}
                </Button>
              )}
            </div>
          ))}
        </div>

        <form className="flex justify-end mt-[30px]">
          <button
            className="py-[10px] px-[15px] w-[120px] rounded-[12px] border-2 border-gray-600 flex items-center justify-center"
            onClick={() => setIsModalOpen(false)}
          >
            <span className="text-sm font-[500] text-semibold text-[#111928]">Cancel</span>
          </button>
          {/* <Button variant="blue" className="ml-3 gap-[5px]">
          <span className="text-sm font-[500] mr-2">Create</span>
        </Button> */}
        </form>
      </div>
    </div>
  );
}
