import { useState } from "react";
import Button from "../utils/Button";
import AudioVideoImport from "./LibraryItems/AudioVideoImport";
import ImageImport from "./LibraryItems/ImageImport";
import FileImport from "./LibraryItems/FileImport";
import TextImport from "./LibraryItems/TextImport";
import { useDispatch } from "react-redux";
import { getLibraryData } from "../../redux/actions/libraryAction";
import { uploadMedia } from "../../utils/uploadTos3";
import { useParams } from "react-router-dom";
import { addLibraryAsset } from "../../redux/actions/libraryAction";

const ImportToLibraryModal = (props) => {
  const { toggleModal } = props;
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [baseText, setBaseText] = useState("");
  const [fileText, setfileText] = useState("");
  const [disableButton, setdisableButton] = useState(false);

  const handleOnClick = async () => {
    if (baseText.length > 0) {
      await dispatch(addLibraryAsset(profileId, baseText.length > 0 ? baseText : null));
    }
    dispatch(getLibraryData(profileId));
    toggleModal();
  };

  const updloadTwitterImages = async (uploadedFiles, text = null) => {
    const data = await uploadMedia(uploadedFiles, "library", profileId, "", text);
    return data;
  };

  return (
    <div>
      <div
        className="fixed inset-0 z-[99] min-h-screen min-w-screen flex justify-center items-center overflow-y-scroll"
        aria-label="modal"
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100  z-[90]"
          onClick={toggleModal}
        ></div>
        <div className="relative z-[99] bg-white rounded-[10px] p-[30px] w-[80vw] show-modal">
          <div
            className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
            onClick={toggleModal}
          >
            &times;
          </div>

          <div>
            <p className="text-[#6B7280] text-[18px] mb-[1rem]">Step 1 of 2</p>
            <h3 className="text-[30px] font-semibold mb-[6px]">Import Your Content</h3>
            <p className="text-[#6B7280] text-[18px] mb-[1.2rem]">Please select any one of the following</p>

            <div className="flex space-x-3 h-full">
              <div className="w-1/2 [&>*]:border [&>*]:border-gray [&>*]:rounded-[12px] [&>*]:py-[14px] [&>*]:px-[20px] h-90 flex-col justify-between overflow-y-scroll">
                <div className="min-h-[14.7rem] mb-3">
                  <TextImport baseText={baseText} setBaseText={setBaseText} setdisableButton={setdisableButton} />
                </div>
                <FileImport fileText={fileText} setfileText={setfileText} updloadTwitterImages={updloadTwitterImages} />
              </div>
              <div className="w-1/2 [&>*]:border [&>*]:border-gray [&>*]:rounded-[12px] [&>*]:py-[14px] [&>*]:px-[20px]">
                {" "}
                <div className="h-3/7 mb-3">
                  <AudioVideoImport updloadTwitterImages={updloadTwitterImages} />
                </div>
                <div className="h-3/7">
                  {/* <></> */}
                  <ImageImport updloadTwitterImages={updloadTwitterImages} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center mt-[15px] gap-[15px]">
            <button
              className={`py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#D1D5DB]`}
              onClick={toggleModal}
            >
              <span className={`text-sm font-[500] text-semibold text-[#111928] flex items-center justify-center`}>
                <svg
                  className="mt-[1px] mr-1"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 15.8333L6.66667 9.99992L12.5 4.16659"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Back</span>
              </span>
            </button>
            <Button onClick={handleOnClick} variant={disableButton ? "disabled" : "blue"}>
              + Import
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportToLibraryModal;
