import React from "react";
import { GiWireCoil } from "react-icons/gi";
import TwitterThreadsContent from "../../../components/Library/LibraryItems/TwitterThreadsContent";
import mp3img from "../../../assets/mp3.png";
import { useParams } from "react-router-dom";
const PanelTwo = ({
  isPanelTwoActive,
  setIsPanelTwoActive,
  selectedAssets,
  panelTwoData,
  setPanelTwoData,
  panelTwoMode,
  setPanelTwoMode,
}) => {
  const textAreaRef = React.useRef(null);

  const { profileId } = useParams();
  React.useEffect(() => {
    //to resize the textarea on load
    // setIsEdited(false);
    if (!textAreaRef) return;
    const textarea = textAreaRef.current;
    if (!textarea) return;
    var h = textarea.scrollHeight;
    h < 100 ? (h = 100) : (h = h);
    textarea.style.height = `${h}px`;
    textarea.style.minHeight = "100px";
  }, [panelTwoData]);
  return (
    <>
      {panelTwoMode === "notes" && (
        <div class="panel panel-two">
          <div class="page">
            <div class="panel-two-page-wrap show-content active">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button
                  class="panel-two-close"
                  onClick={() => {
                    setIsPanelTwoActive(false);
                  }}
                >
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">Notes</div>
              </div>
              <div class="panel-two-content">
                <div class="notes-wrap">
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">This is a note.</div>
                    </div>
                  </div>
                  <div class="note-item">
                    <div class="note-avatar">
                      <img src="images/photo-chevas.png" alt="" />
                    </div>
                    <div class="note-content-wrap">
                      <div class="note-author-wrap">
                        <div class="note-author-name">Chevas</div>
                        <div class="note-timestamp">Apr 22, 2023 2:01PM</div>
                        <div class="note-buttons">
                          <button class="edit-note">
                            <i class="ri-edit-2-fill"></i> Edit
                          </button>
                          <button class="delete-note">
                            <i class="ri-close-line"></i> Delete
                          </button>
                        </div>
                      </div>
                      <div class="note-content">
                        Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Vestibulum suscipit nulla
                        quis orci. Pellentesque auctor neque nec urna. Nunc sed turpis. Fusce risus nisl, viverra et,
                        tempor et, pretium in, sapien.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="add-note-wrap">
                  <div class="note-avatar">
                    <img src="images/photo-chevas.png" alt="" />
                  </div>
                  <textarea ref={textAreaRef} class="add-note"></textarea>
                </div>
              </div>
            </div>
            <div class="panel-two-page-wrap show-genealogy">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close">
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">
                  <div class="panel-two-title-text">Preview</div>
                  <button class="button small primary open-this">Open This</button>
                </div>
              </div>
              <div class="panel-two-content">
                <div class="panel-two-content-inside"></div>
              </div>
            </div>
            <div class="panel-two-page-wrap show-published">
              <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close">
                  <i class="ri-close-line"></i>
                </button>
                <div class="panel-two-title">Details</div>
              </div>
              <div class="panel-two-content">
                <blockquote class="twitter-tweet">
                  <p lang="en" dir="ltr">
                    “If you can't explain something simply, you don't understand it well enough.”
                    <br /> — Albert Einstein
                  </p>
                  &mdash; Joshua Lisec | The Ghostwriter (@JoshuaLisec){" "}
                  <a href="https://twitter.com/JoshuaLisec/status/1649601977548939264?ref_src=twsrc%5Etfw">
                    April 22, 2023
                  </a>
                </blockquote>{" "}
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              </div>
            </div>
          </div>
        </div>
      )}
      {panelTwoMode == "genealogy" && (
        <div class="panel panel-two">
          <div class="page">
            <div class="panel-two-header">
              <div class="page-header-menu-fake-border"></div>
              <button
                class="panel-two-close"
                onClick={() => {
                  setIsPanelTwoActive(false);
                }}
              >
                <i class="ri-close-line"></i>
              </button>
              <div class="panel-two-title">Preview</div>
            </div>
            <div class=" overflow-y-scroll h-[100vh] p-6 mt-12" contenteditable="false">
              <div class="page-title-wrap">
                <div class="panel-two-content-inside">
                  {(panelTwoData.type === "text" ||
                    panelTwoData.type === "tweet" ||
                    panelTwoData.type == "linkedinpost" ||
                    panelTwoData.type == "linkedinslider" ||
                    panelTwoData.type == "newsletter" ||
                    panelTwoData.type == "instagramslider" ||
                    panelTwoData.type == "instagramcaption") && (
                    <>
                      <h2>{panelTwoData.text.substring(0, 60)}</h2>
                    </>
                  )}
                  {panelTwoData.type === "document" && (
                    <>
                      <h2 class="">{panelTwoData.documentTitle.substring(0, 60)}</h2>
                    </>
                  )}
                  {panelTwoData.type === "image" && (
                    <>
                      <h2 class="">{panelTwoData.imageTitle.substring(0, 60)}</h2>
                    </>
                  )}
                  {panelTwoData.type === "video" && (
                    <>
                      <h2 class="">{panelTwoData.videoTitle.substring(0, 60)}</h2>
                    </>
                  )}
                  {panelTwoData.type === "audio" && (
                    <>
                      <h2 class="">{panelTwoData.audioTitle.substring(0, 60)}</h2>
                    </>
                  )}
                  {panelTwoData.type === "thread" && (
                    <>
                      <h2 class="">
                        {panelTwoData && panelTwoData.tweet && panelTwoData.tweet[0].text.substring(0, 60)}
                      </h2>
                    </>
                  )}

                  {/* <div class="page-title" contenteditable="true"></div> */}
                </div>
                <div class="page-asset-meta p-0"></div>
                <div class="asset-tags clearfix">
                  {panelTwoData.topics.map((topic) => (
                    <div class="tag">
                      <span class="tag-text">{topic}</span>
                      <button class="remove-tag">
                        <i class="ri-close-line"></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              {(panelTwoData.type == "text" ||
                panelTwoData.type == "tweet" ||
                panelTwoData.type == "linkedinpost" ||
                panelTwoData.type == "linkedinslider" ||
                panelTwoData.type == "newsletter" ||
                panelTwoData.type == "instagramslider" ||
                panelTwoData.type == "instagramcaption" ||
                panelTwoData.type == "thread" ||
                panelTwoData.type == "document") && (
                <div>
                  {panelTwoData.type == "thread" ? (
                    <div className="w-full px-10 -mt-8 -mb-4 -pb-4">
                      <TwitterThreadsContent
                        generatedTweetsThreads={panelTwoData.tweet}
                        hideTitle={true}
                        profileId={profileId}
                      />
                    </div>
                  ) : (
                    <p>{panelTwoData.text ? panelTwoData.text : ""}</p>
                  )}
                </div>
              )}
              {panelTwoData && (panelTwoData.type == "audio" || panelTwoData.type == "video") && (
                <div>
                  <div className="w-full">
                    {panelTwoData.type == "audio" && (
                      <div className="-mt-4 panel-two-content-inside">
                        <h1 className="text-lg font-medium py-2"> {panelTwoData.audioTitle} </h1>
                        <div className="">
                          <img src={mp3img} alt="" className="mx-auto w-1/3 h-auto" />
                          <audio controls className="w-full">
                            <source
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelTwoData.audio}`}
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                        {panelTwoData.text && <p>{panelTwoData.text ? panelTwoData.text : ""}</p>}
                      </div>
                    )}
                    {panelTwoData.type == "video" && (
                      <div className="-mt-4">
                        <h1 className="text-lg font-medium py-2"> {panelTwoData.videoTitle} </h1>
                        <div className="">
                          <video controls className="w-full">
                            <source
                              src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelTwoData.video}`}
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio element.
                          </video>
                        </div>
                        {panelTwoData.text && <p>{panelTwoData.text ? panelTwoData.text : ""}</p>}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PanelTwo;
{
  /* <div class="panel-two-page-wrap show-genealogy active">
            <div class="panel-two-header">
                <div class="page-header-menu-fake-border"></div>
                <button class="panel-two-close"><i class="ri-close-line"></i></button>
                <div class="panel-two-title">
                    <div class="panel-two-title-text">Preview</div>
                    <button class="button small primary open-this">Open This</button>
                </div>
            </div>
            <div class="panel-two-content">
                <div class="panel-two-content-inside">
                    <h1>The Best Way to Get the Word Out</h1>
                    <p>Spend 80% less time promoting yourself online while reaching more customers with the ultimate omni-channel growth app.</p>
                </div>
            </div>
        </div> */
}
