const INITAIAL_STATE = {
  twitter: true,
  instagram: false,
  convertkit: true,
};

const postValidationReducer = (state = INITAIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "CHANGE_TWITTER_STATE":
      return {
        ...state,
        twitter: payload,
      };
    case "CHANGE_INSTAGRAM_STATE":
      return {
        ...state,
        instagram: payload,
      };
    case "CHANGE_LINKEDIN_STATE":
      return {
        ...state,
        linkedin: payload,
      };
    case "CHANGE_CONVERTKIT_STATE":
      return {
        ...state,
        convertkit: payload,
      };
    default:
      return state;
  }
};

export default postValidationReducer;
