import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getLoggedUserInfo } from "../redux/actions/authActions";

const Home = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getLoggedUserInfo())
  // }, []);

  useEffect(() => {
    if (!user) return;

    const track = user?.profiles[0]?.tracks[0];
    if (track) {
      if (track.status === "composing") {
        window.location.href = `/composer/${track.id}`;
        return;
      }

      if (track.status === "staging") {
        navigate(`/staging/${track.id}`);
        return;
      }
    }
    navigate(`/${user.profiles[0].id}/tracks`);
  }, [user, navigate]);

  return <></>;
};

export default Home;
