import { useNavigate } from "react-router-dom";
import Button from "../../utils/Button";

const LibraryBottomBar = (props) => {
  const { totalSelectedAssets, importToComposer } = props;
  const navigate = useNavigate();

  return (
    <div className="bg-[#f9fafb] w-full py-2 flex fixed bottom-0 z-50" style={{ borderTop: "0.5px solid #D4D4D8" }}>
      <div className="flex justify-between w-full">
        {totalSelectedAssets > 0 && (
          <button className={`flex justify-center items-center gap-2 mx-3 ml-10`} onClick={props.handleClear}>
            {/* prettier-ignore */}

            <span className="text-[#6B7280] text-base">Clear</span>
          </button>
        )}
        <div className="ml-auto mr-5 w-fit flex items-center justify-center">
          {totalSelectedAssets > 0 && (
            <button
              className={`flex justify-center items-center gap-2 ${totalSelectedAssets === 1 ? "" : "opacity-50"}`}
              onClick={totalSelectedAssets === 1 ? () => navigate(`/library/questions`) : null}
            >
              {/* prettier-ignore */}
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6666 4.83333L7.99992 1.5L1.33325 4.83333M14.6666 4.83333L7.99992 8.16667M14.6666 4.83333V13.1667L7.99992 16.5M1.33325 4.83333L7.99992 8.16667M1.33325 4.83333V13.1667L7.99992 16.5M7.99992 8.16667V16.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                />
              </svg>
              <span className="text-[#1C64F2] font-semibold">Transform</span>
            </button>
          )}
          <Button
            className="ml-3" // variant={disabled ? "disabled" : "blue"}
            // onClick={disabled ? null : props.onClickHandler}
          >
            <div className="flex justify-center items-center" onClick={importToComposer}>
              {/* prettier-ignore */}
              <svg className="mt-[2px]" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.66675L13.3333 10.5001L7.5 16.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
              <div className="text-sm font-[500] ml-1">Import To Composer</div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LibraryBottomBar;
