import { useState, useEffect, useRef } from "react";
import LibraryTextItem from "./LibraryItems/LibraryTextItem";
import LibraryDocument from "./LibraryItems/LibraryDocument";
import LibraryVideo from "./LibraryItems/LibraryVideo";
import LibraryUnapprovedTweets from "./LibraryItems/LibraryUnapprovedTweets";
import LibraryImage from "./LibraryItems/LibraryImage";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryData, getInfiniteLibraryData } from "../../redux/actions/libraryAction";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./LibraryMasonry.css";
import LibraryTwitterThread from "./LibraryItems/LibraryTwitterThread";
import InfiniteScroll from "react-infinite-scroll-component";
const LibraryMasonry = (props) => {
  const {
    setSelectedAssets,
    selectedAssets,
    clearFlag,
    setClearFlag,
    isPanelOneActive,
    setIsPanelOneActive,
    isPanelTwoActive,
    setIsPanelTwoActive,
    setImportPanelActive,
    panelOneItem,
    setPanelOneItem,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileId } = useParams();
  const { libraryData, libraryLoading, hasMore } = useSelector((state) => state.library);
  const [isModalHidden, setisModalHidden] = useState(true);
  // const [selectedAssets, setSelectedAssets] = useState([]);
  const [modalItem, setModalItem] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const checkIsAssetSelected = (asset) => {
    const data = selectedAssets.find((data) => data.id === asset.id);
    return data ? true : false;
  };

  const itemSelectionHandler = (index) => {
    if (libraryData[index].status === "PROCESSING") {
      return;
    }
    if (libraryData[index].type === "unapproved_tweet") {
      dispatch({ type: "ADD_GENERATED_TWEETS", payload: libraryData[index].tweet });
      navigate(`/accept-reject-tweets`);
      return;
    }
    // console.log(libraryData[index]);
    if (checkIsAssetSelected(libraryData[index])) {
      const data = [...selectedAssets];
      setSelectedAssets(data.filter((asset) => asset.id !== libraryData[index].id));
      return;
    }
    setModalItem(libraryData[index]);
    setPanelOneItem(libraryData[index]);
    // setSelectedAssets([...selectedAssets, libraryData[index]]);
    setSelectedAssets([libraryData[index]]);
    setImportPanelActive(false);
    setIsPanelOneActive(true);
    selectClickHandler(libraryData[index]);
  };
  const selectClickHandler = (item) => {
    var isSelected = checkIsAssetSelected(item);
    if (item.type === "thread") {
      let data = "";
      var thread = item;
      thread.tweet.forEach((item) => {
        data += `${item.text}\n`;
      });
      if (isSelected) {
        dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
        dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
        dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
        dispatch({ type: "CHANGE_SELECTED_ASSET", payload: null });
      } else {
        dispatch({ type: "CHANGE_BASE_TEXT", payload: data });
        dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
        dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: thread.id });
        dispatch({ type: "CHANGE_SELECTED_ASSET", payload: thread });
      }
    } else {
      if (isSelected) {
        dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
        dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
        dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
        dispatch({ type: "CHANGE_SELECTED_ASSET", payload: null });
      } else {
        dispatch({ type: "CHANGE_BASE_TEXT", payload: item.text });
        dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
        dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: item.id });
        dispatch({ type: "CHANGE_SELECTED_ASSET", payload: item });
      }
    }
  };
  useEffect(() => {
    if (selectedAssets.length === 0) {
      setIsPanelOneActive(false);
      setIsPanelTwoActive(false);
      return;
    }
  }, [selectedAssets]);
  useEffect(() => {
    if (clearFlag) {
      setSelectedAssets([]);
      setClearFlag(false);
    }
  }, [clearFlag]);

  const intervalId = useRef(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [firstReq, setFirstReq] = useState(true);
  useEffect(() => {
    async function fetchData() {
      console.log("fetching data");
      if (firstReq) {
        console.log("fetching data getLibraryData");
        const result = await dispatch(getLibraryData(profileId, firstReq, 1, firstReq));
        if (!result) {
          navigate("/login");
        } else {
          // Check if any item is 'PROCESSING' and update 'isProcessing' accordingly
          setFirstReq(false);
          setIsProcessing(checkForProcessingStatus(result));
        }
        setFirstReq(false);
      }
    }

    const fetchData2 = async () => {
      console.log("fetching data getInfiniteLibraryData");
      const result = await dispatch(getInfiniteLibraryData(profileId, firstReq));
      if (!result) {
        navigate("/login");
      } else {
        // Check if any item is 'PROCESSING' and update 'isProcessing' accordingly
        setIsProcessing(checkForProcessingStatus(result));
      }
      setFirstReq(false);
    };

    function checkForProcessingStatus(data) {
      // console.log(data);
      return data.some((item) => item.status === "PROCESSING");
    }

    // Call fetchData only if there's some data with status 'PROCESSING'
    if (isProcessing) {
      console.log(firstReq);
      fetchData();
      setFirstReq(false);
      // Start interval only if there isn't one already
      if (!intervalId.current) {
        intervalId.current = setInterval(() => fetchData2(), 5000);
      }
    } else if (intervalId.current) {
      // If there's an interval but no data is 'PROCESSING', clear the interval
      clearInterval(intervalId.current);
      setIsProcessing(false);
      intervalId.current = null;
    }

    // Clean up on component unmount or when dependencies change
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        setIsProcessing(false);
      }
    };
  }, [profileId, isProcessing]); // Note 'isProcessing' instead of 'libraryData' here

  // useEffect(() => {
  //   async function fetchData() {
  //     const result = await dispatch(getLibraryData(profileId));
  //     if (!result) {
  //       navigate("/login");
  //     }
  //   }

  //   fetchData();
  // }, [profileId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    console.log("fetching data");
    try {
      const result = await dispatch(getInfiniteLibraryData(profileId, page));
      if (result) {
        setPage(page + 1);
      }
    } catch (error) {
      console.error("error:", error);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  if (libraryLoading) {
    return (
      <div>
        <h4>Loading...</h4>
      </div>
    );
  }
  if (libraryData.length === 0) {
    return (
      <div>
        <h4>Nothing in the library right now. Try importing.</h4>
      </div>
    );
  }

  return (
    <div className="assets-wrap h-full">
      <div className="assets-inside">
        {libraryData.length > 0 &&
          libraryData.map((item, index) => {
            if (
              [
                "text",
                "tweet",
                "linkedinpost",
                "linkedinslider",
                "newsletter",
                "instagramslider",
                "instagramcaption",
              ].includes(item.type)
            ) {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset text ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryTextItem isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }
            if (["document"].includes(item.type)) {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset document ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryDocument isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }

            if (item.type === "video") {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset video rounded-[3px] ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryVideo isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }

            if (item.type === "audio") {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset audio rounded-[3px] ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryVideo isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }

            if (item.type === "image") {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset image rounded-[3px] ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryImage isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }

            if (item.isTwitterThread || item.type === "thread") {
              return (
                <div
                  onClick={() => itemSelectionHandler(index)}
                  className={`asset text rounded-[3px] ${checkIsAssetSelected(item) && "active"}`}
                >
                  <LibraryTwitterThread isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }

            if (item.type === "unapproved_tweet") {
              return (
                <div onClick={() => itemSelectionHandler(index)} className="asset text rounded-[3px]">
                  <LibraryUnapprovedTweets isSelected={checkIsAssetSelected(item)} item={item} profileId={profileId} />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default LibraryMasonry;
