import React from "react";

function WebflowModal({ setWebflowSecret, setIsWebflowModal, checkWebflow }) {
  return (
    <div
      tabIndex={-1}
      className="flex justify-center items-center fixed top-0 left-0 z-50 bg-[#00000080] w-full h-full overflow-auto"
    >
      <div
        className="px-8 py-6 w-[50%] bg-[#FFFFFF] rounded-[10px] z-[1200]"
        style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="mb-5">
          <div className="font-semibold text-3xl text-[#111928] mb-1">Webflow Connection</div>
          <div className="font-normal text-[#6B7280]">
            Please provide your webflow secret key to connect your account.
          </div>
        </div>

        <div className="mt-3 flex items-center gap-x-4">
          <label htmlFor="email" className="text-[#4E5674] text-lg whitespace-nowrap font-[500] font-Inter">
            Secret Key :
          </label>
          <div className="mb-4 mt-1 flex w-full items-center rounded-lg border-2">
            <input
              id="renameContractName"
              onChange={(e) => {
                setWebflowSecret(e.target.value);
              }}
              className="block w-[90%] text-base font-normal text-[#6B7280] py-[13px] px-[18px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[16px]"
              placeholder="<your_secret_api_key>"
            />
          </div>
        </div>
        <div className="flex justify-end mt-12 gap-3">
          <button
            onClick={() => {
              setIsWebflowModal(false);
            }}
            style={{
              borderRadius: "12px",
              padding: "9px 17px 9px 17px",
              border: "1px solid #D1D5DB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
            }}
          >
            <div className="text-sm font-semibold text-[#111928]">Cancel</div>
          </button>
          <button
            onClick={checkWebflow}
            style={{
              borderRadius: "12px",
              padding: "9px 17px 9px 17px",
              border: "1px solid #1C64F2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#1C64F2",
              width: "100px",
            }}
          >
            <svg
              className="mr-3"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7931 7.50003L12.9396 8.35358L8.65013 12.643C8.56064 12.737 8.51118 12.8622 8.51231 12.9921C8.51345 13.1232 8.56603 13.2486 8.65874 13.3413C8.75144 13.434 8.87685 13.4866 9.00794 13.4877C9.13783 13.4889 9.26301 13.4394 9.35703 13.3499L15.3534 7.35353C15.3534 7.35351 15.3534 7.35349 15.3534 7.35348C15.4471 7.25972 15.4998 7.13259 15.4998 7.00003C15.4998 6.86747 15.4471 6.74034 15.3534 6.64658L13.7931 7.50003ZM13.7931 7.50003H12.586H1C0.867392 7.50003 0.740215 7.44735 0.646446 7.35358C0.552678 7.25982 0.5 7.13264 0.5 7.00003C0.5 6.86742 0.552678 6.74025 0.646446 6.64648C0.740215 6.55271 0.867392 6.50003 1 6.50003H12.586H13.7931L12.9396 5.64648L8.64661 1.35353C8.64659 1.35351 8.64657 1.35349 8.64655 1.35348C8.55285 1.25972 8.50021 1.13259 8.50021 1.00003C8.50021 0.867498 8.55283 0.740391 8.6465 0.646637C8.74026 0.552902 8.86742 0.500244 9 0.500244C9.13256 0.500244 9.25969 0.552882 9.35345 0.646584C9.35346 0.646602 9.35348 0.646619 9.3535 0.646637L15.3534 6.64653L13.7931 7.50003Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <div className="text-sm font-semibold text-[white]">Next</div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WebflowModal;
