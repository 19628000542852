import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBillingInfo } from "../../redux/actions/authActions";
import PaymentModal from "../PaymentModal";
import Button from "../utils/Button";
import { failAlert } from "../../utils/sweetAlert";
import { handleSubscription } from "../../utils/stripeClient";
import { useNavigate } from "react-router-dom";
const AddCompany = (props) => {
  const [currentPlan, setCurrentPlan] = useState({});
  const { isModalOpen, setIsModalOpen } = props;
  const { user } = useSelector((state) => state.auth);
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const addNewCompany = async (e) => {
    e.preventDefault();
    if (!companyName) {
      failAlert({
        title: "Please provide a company name!",
      });
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/add-new-company`,
        { companyName, teamId: user.teamMembers[0].teamId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!data.stripeExists) {
        // failAlert({
        //   title: "Please add a payment method!",
        // });
        // window.location.href = `/billing`;
        setIsModalOpen(false);

        navigate("/billing/true");
        return;
      }
      if (data.status === "success") {
        dispatch({ type: "ADD_TRACK", payload: data.track });
        dispatch({ type: "SET_CURRENT_TRACK", payload: data.track });
        setIsModalOpen(false);
        window.location.href = `/composer/${data.track.id}`;
      }
    } catch (error) {
      console.log(error);
      return failAlert({
        title: error?.response?.data.message ?? "Sorry, something went wrong",
      });
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { stripeCustomer } = await getUserBillingInfo();

      if (!stripeCustomer) return;

      setCurrentPlan(stripeCustomer);
      setIsLoading(false);
    })();
  }, []);

  if (!isModalOpen) {
    return <></>;
  }

  const planDetails = [
    {
      name: "Individual Agency",
      price: "$300.00",
      support: "/month/organization",
      description: "Unlimited Organization and no Team Member",
      plan: process.env.REACT_APP_STRIPE_INDIVIDUAL_PLUS_PLAN_ID,
    },
    {
      name: "Team Agency",
      price: "$600.00",
      support: "/month/organization",
      description: "Unlimited Organization and upto 8 Team Member per organization",
      plan: process.env.REACT_APP_STRIPE_STARTUP_PLUS_PLAN_ID,
    },
  ];

  return (
    <>
      {!isLoading && currentPlan ? (
        <div
          className="fixed inset-0 z-[199] min-h-screen min-w-screen flex justify-center items-center"
          aria-label="modal"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[190]"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="relative z-[199] bg-white rounded-[10px] p-[40px] pb-[15px] pr-[15px] w-[650px] show-modal">
            <h3 className="text-[30px] font-semibold">Enter the name</h3>
            <p className="text-[#6B7280] text-[16px] mb-[10px]">Give your company a name.</p>

            <div>
              <input
                type="text"
                value={companyName}
                className="relative block w-full sm:text-sm px-[20px] py-[13px] h-[40px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[12px]"
                placeholder="Acme, Inc. Mutual India"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <form className="flex justify-end mt-[30px]" onSubmit={addNewCompany}>
              <button
                className="py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#D1D5DB] flex items-center justify-center"
                onClick={() => setIsModalOpen(false)}
              >
                <span className="text-sm font-[500] text-semibold text-[#111928]">Cancel</span>
              </button>
              <Button variant="blue" className="ml-3 gap-[5px]">
                <span className="text-sm font-[500] mr-2">Create</span>
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <PaymentModal planDetails={planDetails} currentPlan={currentPlan} setIsModalOpen={setIsModalOpen} />
      )}
    </>
  );
};

export default AddCompany;
