import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationCenter from "../NotificationCenter/NotificationCenter";
import { ReactComponent as BookIcon } from "../../assets/icons/bookIcon.svg";
import { getNotifications } from "../../redux/actions/notificationActions";
import { updateDraftTitle } from "../../redux/actions/draftActions";
import { getLoggedUserInfo } from "../../redux/actions/authActions";

const PostSigninNavbar = (props) => {
  const { title, composerPage, editableTitle } = props;
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [currentURL, setCurrentURL] = React.useState("");
  useEffect(() => {
    setCurrentURL(window.location.href);
  }, [currentURL]);
  let btnRef = React.useRef(null);
  const [dropdown, setDropdown] = React.useState(false);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [inputTitle, setInputTitle] = React.useState(title);
  const [profilesNav, setProfilesNav] = React.useState([]);
  const { profileId } = useParams();
  useEffect(() => {
    if (user) {
      var tempprofiles = [];
      for (let i = 0; i < user.profiles.length; i++) {
        tempprofiles.push({
          ...user.profiles[i],
          draftDropdown: false,
          dropdown: false,
        });
      }
      setProfilesNav(tempprofiles);
    }
  }, [user]);

  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    const closeDropdown = (event) => {
      if (!btnRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  // React.useEffect(() => {
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  //     dispatch(getNotifications());
  //   } else {
  //     getNotifications();

  //     const interval = setInterval(() => {
  //       dispatch(getNotifications());
  //     }, 3000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, []);

  React.useEffect(() => {
    setInputTitle(title);
  }, [title]);

  const { currentTrack } = useSelector((state) => state.tracks);
  const { newNotifications } = useSelector((state) => state.notifications);

  const [currentProfile, setCurrentProfile] = React.useState(null);

  const initialCurrentProfile = useMemo(() => {
    if (currentTrack?.profileId) return currentTrack.profileId;

    return user?.profiles[0]?.id;
  }, [currentTrack, user?.profiles]);
  useEffect(() => {
    if (profileId) {
      setCurrentProfile(profileId);
      dispatch({
        type: "SET_CURRENT_PROFILE",
        payload: user.profiles.find((t) => t.id == profileId),
      });
      return;
    }
    setCurrentProfile(initialCurrentProfile);
    if (initialCurrentProfile) {
      dispatch({
        type: "SET_CURRENT_PROFILE",
        payload: user.profiles.find((t) => t.id == initialCurrentProfile),
      });
    }
  }, [initialCurrentProfile, profileId]);
  useEffect(() => {
    if (currentProfile) {
      dispatch({
        type: "SET_CURRENT_PROFILE",
        payload: user.profiles.find((t) => t.id == currentProfile),
      });
    }
  }, [currentProfile]);
  const updateTitle = async (e) => {
    setInputTitle(e.target.value);
    await dispatch(updateDraftTitle(Number(id), e.target.value, true));
    dispatch(getLoggedUserInfo());
  };
  const updateSelectedProfile = (id) => {
    setCurrentProfile(id);
    dispatch({
      type: "SET_CURRENT_TRACK",
      payload: user.profiles.find((t) => t.id == id).tracks[0],
    });
    dispatch({
      type: "SET_CURRENT_PROFILE",
      payload: user.profiles.find((t) => t.id == id),
    });
    if (currentURL.includes("library")) {
      navigate(`/library/${id}`);
      // window.location.href = `/${id}/tracks`;
    } else if (currentURL.includes("tracks")) {
      navigate(`/${id}/tracks`);
    }
    setDropdown(false);
  };
  return (
    <div className="header">
      <div className="header-fake-border"></div>
      <div className="header-inside">
        <div className="header-left">
          <Link to={`/${currentProfile}/tracks`} className="logo">
            <img src="/Images/logo-wordout.svg" alt="WordOut" />
          </Link>
          <div className="menu">
            <Link
              to={`/${currentProfile}/tracks`}
              title="View All Tracks"
              className={currentURL.includes("tracks") ? "active" : ""}
            >
              <i className="ri-calendar-2-line"></i> Tracks
            </Link>
            <Link
              title="Library"
              to={`/library/${currentProfile}`}
              className={currentURL.includes("library") ? "active" : ""}
            >
              <i className="ri-book-3-line"></i> Library
            </Link>
          </div>
        </div>
        <div className="header-right" ref={btnRef}>
          <button className="user show-sub" onClick={() => setDropdown(!dropdown)}>
            <div className="user-avatar">
              <img src="/Images/Navbar/twitter_default.png" alt={user && user.fullName} />
            </div>
            <div className="user-name-wrap">
              <span className="user-name">{user && user.fullName} </span>{" "}
              {currentProfile && (
                <span className="account-name"> ({user.profiles.find((t) => t.id == currentProfile).profileName})</span>
              )}
            </div>
            <i className="ri-arrow-down-s-line"></i>
          </button>
          <div className={`sub-menu account ${dropdown && "active"}`}>
            <div className="sub-menu-section accounts">
              <div className="sub-menu-title">Select Account</div>
              {profilesNav &&
                profilesNav.map((profile) => (
                  <a
                    className={`account-item ${currentProfile == profile.id && "active"}`}
                    data-account-item={`${profile.profileName}`}
                    onClick={() => updateSelectedProfile(profile.id)}
                  >
                    <i className="ri-folder-line"></i> {profile.profileName}
                  </a>
                ))}
            </div>
            <div className="sub-menu-section ">
              <Link to="/billing">
                {" "}
                <i className="ri-bank-card-2-line"></i> Billing
              </Link>
              <Link to="/settings">
                {" "}
                <i class="ri-settings-2-line"></i> Settings
              </Link>
              {user && user.isSuperAdmin && (
                <Link to="/super-admin">
                  {" "}
                  <i class="ri-lock-2-line"></i> Admin
                </Link>
              )}
              <a
                href="/signin"
                onClick={() => {
                  localStorage.clear();
                }}
              >
                <i className="ri-logout-box-line"></i> Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <nav
      className="bg-white py-1 fixed top-0 z-10 w-full"
      style={{
        borderBottom: composerPage ? "none" : "1px solid #E5E7EB",
        background: composerPage ? "#fff" : "#f9fafb",
      }}
    >
      <div className="mx-auto px-2 sm:px-4">
        <div className="relative flex items-center justify-between">
          <div className="m-1 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link to={`/${currentProfile}/tracks`}>
                <img src="/Images/Navbar/WordOut-Logo.png" className="h-[15px] w-auto" alt="WordOut" />
              </Link>
            </div>

            {title && (
              <>
                <div className="h-[22px] border-r-[1.5px] border-black mx-[15px] self-center"></div>
                <div className="text-[#000000] mr-4 font-medium self-center text-[14px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {!editableTitle ? (
                    <div>{title}</div>
                  ) : (
                    <input
                      id="composer-title"
                      className="border-none outline-none w-full font-Inter text-inherit"
                      type="text"
                      value={inputTitle}
                      onChange={updateTitle}
                      placeholder="Please provide a title"
                      style={{
                        background: composerPage ? "#fff" : "#f9fafb",
                      }}
                    />
                  )}
                </div>
              </>
            )}

            <div className="ml-auto flex justify-center items-center">
              <Link title="Library" to={`/library/${currentProfile}`} className="mt-0.5 mr-[4px]">
                <BookIcon height="19" width="19" fill="#000" />
              </Link>
              <Link className="cursor-pointer" to={`/${currentProfile}/tracks`} title="View All Tracks">
                <svg
                  className="mt-0.5"
                  width="26"
                  height="26"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="32" height="32" rx="16" fill="transparent" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1999 6.90002C10.8816 6.90002 10.5764 7.02645 10.3514 7.2515C10.1263 7.47654 9.9999 7.78176 9.9999 8.10002V9.30002H8.7999C8.16338 9.30002 7.55293 9.55288 7.10285 10.003C6.65276 10.4531 6.3999 11.0635 6.3999 11.7V23.7C6.3999 24.3365 6.65276 24.947 7.10285 25.3971C7.55293 25.8472 8.16338 26.1 8.7999 26.1H23.1999C23.8364 26.1 24.4469 25.8472 24.897 25.3971C25.347 24.947 25.5999 24.3365 25.5999 23.7V11.7C25.5999 11.0635 25.347 10.4531 24.897 10.003C24.4469 9.55288 23.8364 9.30002 23.1999 9.30002H21.9999V8.10002C21.9999 7.78176 21.8735 7.47654 21.6484 7.2515C21.4234 7.02645 21.1182 6.90002 20.7999 6.90002C20.4816 6.90002 20.1764 7.02645 19.9514 7.2515C19.7263 7.47654 19.5999 7.78176 19.5999 8.10002V9.30002H12.3999V8.10002C12.3999 7.78176 12.2735 7.47654 12.0484 7.2515C11.8234 7.02645 11.5182 6.90002 11.1999 6.90002ZM11.1999 12.9C10.8816 12.9 10.5764 13.0265 10.3514 13.2515C10.1263 13.4765 9.9999 13.7818 9.9999 14.1C9.9999 14.4183 10.1263 14.7235 10.3514 14.9486C10.5764 15.1736 10.8816 15.3 11.1999 15.3H20.7999C21.1182 15.3 21.4234 15.1736 21.6484 14.9486C21.8735 14.7235 21.9999 14.4183 21.9999 14.1C21.9999 13.7818 21.8735 13.4765 21.6484 13.2515C21.4234 13.0265 21.1182 12.9 20.7999 12.9H11.1999Z"
                    fill="#111928"
                  />
                </svg>
              </Link>
              {/* <div onClick={() => setShowNotifications(true)} className="relative" title="Notifications">
                {newNotifications && (
                  <div className="h-[8px] w-[8px] absolute right-[8px] top-[4px] bg-[#1c64f2] rounded rounded-full"></div>
                )}
                <svg
                  className="mr-1 mt-0.5 cursor-pointer"
                  width="26"
                  height="26"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="0.5" width="32" height="32" rx="16" fill="transparent" />
                  <path
                    d="M16.0001 6.90002C14.0905 6.90002 12.2592 7.65859 10.9089 9.00886C9.55866 10.3591 8.80009 12.1905 8.80009 14.1V18.4032L7.95169 19.2516C7.78391 19.4194 7.66967 19.6332 7.62339 19.866C7.5771 20.0987 7.60087 20.34 7.69167 20.5592C7.78248 20.7785 7.93624 20.9658 8.13354 21.0977C8.33083 21.2296 8.56279 21.3 8.80009 21.3H23.2001C23.4374 21.3 23.6693 21.2296 23.8666 21.0977C24.0639 20.9658 24.2177 20.7785 24.3085 20.5592C24.3993 20.34 24.4231 20.0987 24.3768 19.866C24.3305 19.6332 24.2163 19.4194 24.0485 19.2516L23.2001 18.4032V14.1C23.2001 12.1905 22.4415 10.3591 21.0913 9.00886C19.741 7.65859 17.9096 6.90002 16.0001 6.90002ZM16.0001 26.1C15.0453 26.1 14.1296 25.7207 13.4545 25.0456C12.7794 24.3705 12.4001 23.4548 12.4001 22.5H19.6001C19.6001 23.4548 19.2208 24.3705 18.5457 25.0456C17.8705 25.7207 16.9549 26.1 16.0001 26.1Z"
                    fill="#111928"
                  />
                </svg>

                {showNotifications === true && <NotificationCenter />}
              </div> */}
              {showNotifications && (
                <div
                  className="fixed top-0 left-0 h-screen w-screen id z-[99]"
                  onClick={() => setShowNotifications(false)}
                ></div>
              )}
              <div
                className="flex items-center ml-3 cursor-pointer gap-[6px]"
                ref={btnRef}
                onClick={() => setDropdown(!dropdown)}
              >
                <img
                  className="w-[25px] h-[25px] rounded-full"
                  src="/Images/Navbar/twitter_default.png"
                  alt={user && user.fullName}
                />
                <div className="text-base font-medium text-[#111928]">{user && user.fullName}</div>
                <svg width="10" height="10" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.410145 0.710241C0.672684 0.447781 1.02872 0.300339 1.39995 0.300339C1.77117 0.300339 2.12721 0.447781 2.38974 0.710241L6.99995 5.32044L11.6101 0.710241C11.7393 0.576526 11.8938 0.469871 12.0646 0.396499C12.2354 0.323126 12.4191 0.284505 12.605 0.28289C12.7909 0.281274 12.9752 0.316697 13.1473 0.38709C13.3193 0.457483 13.4756 0.561437 13.6071 0.692887C13.7385 0.824337 13.8425 0.98065 13.9129 1.1527C13.9833 1.32476 14.0187 1.50911 14.0171 1.695C14.0155 1.88089 13.9769 2.0646 13.9035 2.23541C13.8301 2.40621 13.7235 2.56069 13.5897 2.68984L7.98974 8.28984C7.72721 8.5523 7.37117 8.69974 6.99995 8.69974C6.62872 8.69974 6.27268 8.5523 6.01015 8.28984L0.410145 2.68984C0.147686 2.4273 0.000244141 2.07127 0.000244141 1.70004C0.000244141 1.32881 0.147686 0.972779 0.410145 0.710241Z"
                    fill="#111928"
                  />
                </svg>
              </div>
              <ul
                id="navbarDropdown"
                className={`origin-top-right absolute right-1 top-10 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none animate-fade-in-down ${
                  dropdown ? "block" : "hidden"
                }`}
              >
                <li>
                  <Link
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    to="/billing"
                  >
                    Billing
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      localStorage.clear();
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    href="/login"
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PostSigninNavbar;
