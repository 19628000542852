import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getLibraryData, addLibraryAsset, addLibraryMediaAsset } from "../../../redux/actions/libraryAction";
import { uploadMedia } from "../../../utils/uploadTos3";
import AudioVideoImport from "../../../components/Library/LibraryItems/AudioVideoImport";
import ImageImport from "../../../components/Library/LibraryItems/ImageImport";
import FileImport from "../../../components/Library/LibraryItems/FileImport";
import TextImport from "../../../components/Library/LibraryItems/TextImport";
import { useParams } from "react-router-dom";
const ImportPanel = ({ setSelectedAssets, setImportPanelActive, setIsPanelOneActive, setIsPanelTwoActive }) => {
  const tabs = ["text", "document", "audio-video", "image", "url"];
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [baseText, setBaseText] = useState("");
  const [fileText, setfileText] = useState("");
  const [disableButton, setdisableButton] = useState(false);
  const [activeTab, setActiveTab] = useState("text");
  const [uploadedAssets, setUploadedAssets] = useState([]);
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const handleOnClick = async () => {
    if (baseText.length > 0) {
      await dispatch(addLibraryAsset(profileId, baseText.length > 0 ? baseText : null));
      setBaseText("");
    }
    dispatch(getLibraryData(profileId));
    setImportPanelActive(false);
  };

  const updloadTwitterImages = async (uploadedFiles, text = null) => {
    const data = await uploadMedia(uploadedFiles, "library", profileId, "", text, setUploadPercentage);
    console.log(data);
    setUploadedAssets([...uploadedAssets, ...data]);
    return data;
  };

  const handleOnFileClick = async () => {
    if (uploadedAssets.length > 0) {
      await dispatch(addLibraryMediaAsset(profileId, uploadedAssets.length > 0 ? uploadedAssets : null));
      setfileText("");
    }
    dispatch(getLibraryData(profileId));
    setImportPanelActive(false);
  };

  return (
    <div class="panel panel-import">
      <div class="page">
        <div class="page-header">
          <button class="page-close" onClick={() => setImportPanelActive(false)}>
            <i class="ri-close-line"></i>
          </button>
          <div class="page-header-menu">
            <div class="page-header-menu-fake-border"></div>
            <div class="page-header-title">
              <span class="page-header-title-text">Import</span>{" "}
              <button class="help">
                <i class="ri-question-fill"></i>
              </button>
            </div>
            <div class="help-bubble">
              <button id="" class="close-help">
                <i class="ri-close-line"></i>
              </button>
              <p>Please select any one of the following types of content.</p>
            </div>
            <a
              class={`page-header-tab import-text ${activeTab === "text" ? "active" : ""}`}
              data-page-menu-item="import-text"
              onClick={() => setActiveTab("text")}
            >
              <i class="ri-text"></i> Text
            </a>
            <a
              class={`page-header-tab import-document ${activeTab === "document" ? "active" : ""}`}
              data-page-menu-item="import-document"
              onClick={() => setActiveTab("document")}
            >
              <i class="ri-article-line"></i> Document
            </a>
            <a
              class={`page-header-tab import-audio-video ${activeTab === "audio-video" ? "active" : ""}`}
              data-page-menu-item="import-audio-video"
              onClick={() => setActiveTab("audio-video")}
            >
              <i class="ri-movie-line"></i> Audio/Video
            </a>
            <a
              class={`page-header-tab import-image ${activeTab === "image" ? "active" : ""}`}
              data-page-menu-item="import-image"
              onClick={() => setActiveTab("image")}
            >
              <i class="ri-image-2-line"></i> Images
            </a>
            {/* <a class="page-header-tab import-url" data-page-menu-item="import-url">
              <i class="ri-link"></i> URL
            </a> */}
          </div>
          <div class={`page-header-sub-menu import import-text ${activeTab === "text" ? "active" : ""}`}>
            <div class="import-help-text">
              <span class="strong">Text:</span> Blogs, articles, newsletters up to 20000 characters.
            </div>
            <div class="import-text-char-count">4312 chars</div>
          </div>
          <div class={`page-header-sub-menu import import-document ${activeTab === "document" ? "active" : ""}`}>
            <div class="import-help-text">
              <span class="strong">Document:</span> .docx, .txt
            </div>
            {/* <div class="import-text-file-status">1 file(s)</div> */}
          </div>
          <div class={`page-header-sub-menu import import-audio-video ${activeTab === "audio-video" ? "active" : ""}`}>
            <div class="import-help-text">
              Videos &amp; audio files will be transcribed into text. Max file size limit is 500MB.
            </div>
            {/* <div class="import-text-file-status">1 file(s)</div> */}
          </div>
          <div class={`page-header-sub-menu import import-image ${activeTab === "image" ? "active" : ""}`}>
            <div class="import-help-text">
              <span class="strong">Image:</span> Upload one or more.
            </div>
            {/* <div class="import-text-file-status">3 file(s)</div> */}
          </div>
          {/* <div class="page-header-sub-menu import import-url">
            <div class="import-help-text">
              <span class="strong">URL:</span> Paste a link and click scrape.
            </div>
            <div class="import-text-file-status">Processing...</div>
          </div> */}
        </div>
        {activeTab === "text" && (
          <div class="page-content-wrap import import-text active">
            <TextImport
              baseText={baseText}
              setBaseText={setBaseText}
              setdisableButton={setdisableButton}
              isCurrentlyUploading={isCurrentlyUploading}
              setIsCurrentlyUploading={setIsCurrentlyUploading}
            />
            <button
              className={`button primary finish-import ${isCurrentlyUploading && "hover:cursor-not-allowed"}`}
              type="button"
              onClick={handleOnClick}
              disabled={isCurrentlyUploading}
            >
              {isCurrentlyUploading ? uploadPercentage + "%" : "Finish Import"}
            </button>
          </div>
        )}
        {activeTab === "document" && (
          <div class="page-content-wrap import import-document active">
            <FileImport
              fileText={fileText}
              setfileText={setfileText}
              updloadTwitterImages={updloadTwitterImages}
              isCurrentlyUploading={isCurrentlyUploading}
              setIsCurrentlyUploading={setIsCurrentlyUploading}
            />
            <button
              className={`button primary finish-import ${isCurrentlyUploading && "hover:cursor-not-allowed"}`}
              type="button"
              onClick={handleOnFileClick}
              disabled={isCurrentlyUploading}
            >
              {isCurrentlyUploading ? uploadPercentage + "%" : "Finish Import"}
            </button>
          </div>
        )}
        {activeTab === "audio-video" && (
          <div class="page-content-wrap import import-audio-video active">
            <AudioVideoImport
              updloadTwitterImages={updloadTwitterImages}
              isCurrentlyUploading={isCurrentlyUploading}
              setIsCurrentlyUploading={setIsCurrentlyUploading}
            />
            <button
              className={`button primary finish-import ${isCurrentlyUploading && "hover:cursor-not-allowed"}`}
              type="button"
              onClick={handleOnFileClick}
              disabled={isCurrentlyUploading}
            >
              {isCurrentlyUploading ? uploadPercentage + "%" : "Finish Import"}
            </button>
          </div>
        )}
        {activeTab === "image" && (
          <div class="page-content-wrap import import-image active">
            <ImageImport
              updloadTwitterImages={updloadTwitterImages}
              isCurrentlyUploading={isCurrentlyUploading}
              setIsCurrentlyUploading={setIsCurrentlyUploading}
            />
            <button
              className={`button primary finish-import ${isCurrentlyUploading && "hover:cursor-not-allowed"}`}
              onClick={handleOnFileClick}
              disabled={isCurrentlyUploading}
            >
              {isCurrentlyUploading ? uploadPercentage + "%" : "Finish Import"}
            </button>
          </div>
        )}

        {/* <div class="page-content-wrap import import-url">
          <div class="page-content-inside">
            <div class="import-type url">
              <input class="withbutton" type="text" placeholder="https://..." />
              <button class="button withfield primary">Scrape URL</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ImportPanel;
