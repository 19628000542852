import Loader from "../../utils/Loader";
import { useState } from "react";
const AudioVideoImport = ({ updloadTwitterImages, isCurrentlyUploading, setIsCurrentlyUploading }) => {
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const onFileDrop = async (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    uploadFiles({
      currentTarget: {
        files: files,
      },
    });
    setHighlight(false);
  };

  const uploadFiles = async (e) => {
    console.log(e, "files");
    const files =
      Array.from(e.currentTarget.files)
        .filter((file) => file.type.startsWith("video/") || file.type.startsWith("audio/"))
        .slice(0, 10) || [];

    console.log(files, "asd");
    setIsCurrentlyUploading(true);
    const twitterAssets = (await updloadTwitterImages(files)) || [];
    setIsCurrentlyUploading(false);
    setUploadedMedia(twitterAssets);
  };
  return (
    <div class="page-content-inside m-2">
      {!isCurrentlyUploading && !uploadedMedia.length > 0 && (
        <>
          <button
            class="file-upload"
            onClick={() => document.getElementById("twitter-video-upload").click()}
            onDrop={onFileDrop}
            onDragOver={(e) => {
              e.preventDefault();
              setHighlight(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setHighlight(false);
            }}
            style={{
              backgroundColor: highlight ? "lightgray" : "",
            }}
          >
            <div class="file-upload-title">Upload Audio Or Video</div>
          </button>
          <input
            type="file"
            multiple={true}
            accept="audio/*,video/*,.m4a"
            id={`twitter-video-upload`}
            className="hidden"
            onChange={uploadFiles}
          />
        </>
      )}
      {uploadedMedia.length > 0 && (
        <div
          className={`mt-3 h-full flex  items-center gap-[1rem] 
          `}
        >
          {uploadedMedia.map((media, i) => {
            return (
              <div
                className="relative flex items-center  rounded-[12px] overflow-hidden w-full h-full object-cover"
                key={i}
              >
                <div className="text-center mx-auto w-full my-auto">
                  {media.type === "audio" && (
                    <audio controls className="w-[90%] h-auto mx-auto">
                      <source src={media.url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                  {media.type === "video" && (
                    <video controls className="w-[90%] h-auto mx-auto">
                      <source src={media.url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </video>
                  )}
                  {/* <img src={videopng} alt="" className="w-[30%] h-auto object-cover mx-auto" />
                  <p className="text-sm import-help-text">Video Uploaded. Click "Finish Import" to continue.</p> */}
                </div>

                <div
                  className="absolute top-[10px] left-[10px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full"
                  onClick={() => {
                    setUploadedMedia([]);
                  }}
                >
                  <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px]">
                    &times;
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isCurrentlyUploading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loader height="20px" width="20px" loaderFill="#333" color="#000" className="mt-[1rem]" />
        </div>
      )}
    </div>
  );
};

export default AudioVideoImport;
