import Joyride from "react-joyride";
import { Routes, Route, useNavigate } from "react-router-dom";
import Staging from "./Staging";
import Settings from "./Settings";
import SuperAdmin from "./SuperAdmin";
import Composer from "./Composer";
import { useEffect, useState } from "react";
import Tracks from "./Tracks";
import Queues from "./Queues/Queues";
import { useDispatch, useSelector } from "react-redux";
import Billing from "./Billing";
import AddCompany from "../components/AddCompany/AddCompany";
import { toggleAddCompanyModal } from "../redux/actions/modalActions";
import Home from "./Home";
import { Sidebar } from "../components/Sidebar";
import Library from "./Library";
import UpgradeModal from "../components/UpgradeModal";
import LibraryQuestions from "./LibraryQuestions";
import GenerateTweets from "./Library/GenerateTweets";
import GenerateThreads from "./Library/GenerateThreads";
import TextToBullets from "./Library/TextToBullets";
import TextToLinkedInPost from "./Library/TextToLinkedInPost";
import TextToLinkedInSlider from "./Library/TextToLinkedInSlider";
import TextToNewsletter from "./Library/TextToNewsletter";
import TextToInstagramSlider from "./Library/TextToInstagramSlider";
import TextToInstagramCaption from "./Library/TextToInstagramCaption";
import AccpetRejectTweets from "./Library/AcceptRejectTweets";
import TwitterThreads from "./Library/TwitterThreads";
import NewLibrary from "./NewLibrary";
import { getUserBillingInfo } from "../redux/actions/authActions";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import { set } from "lodash";
export const Index = () => {
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const { steps, run, stepIndex } = useSelector((state) => state.appTour);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const handleCallback = (data) => {
    const { action, index, lifecycle, type } = data;

    if (type === "step:after" && index === 0 /* or step.target === '#home' */) {
      dispatch({ type: "STOP_TOUR" });
      navigate("/staging/7");
    } else if (type === "step:after" && index === 1) {
      if (action === "next") {
        dispatch({ type: "STOP_TOUR" });
      } else {
        dispatch({ type: "START_TOUR" });
      }
    } else if (type === "step:after" && action === "prev" && index === 2) {
      dispatch({ type: "STOP_TOUR" });

      navigate("/multi-route/a");
    } else if (action === "reset" || lifecycle === "complete") {
      dispatch({ type: "STOP_TOUR" });
    }
  };

  const { visible } = useSelector((state) => state.modals);

  const closeAddCompanyModal = () => {
    dispatch(toggleAddCompanyModal(false));
  };

  useEffect(() => {
    (async () => {
      const { stripeCustomer } = await getUserBillingInfo();
      setLoading(false);
      if (!stripeCustomer) return;

      setCurrentPlan(stripeCustomer);
    })();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center w-full min-h-screen">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-8 h-8 mr-2 text-transparent animate-spin  fill-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : currentPlan && currentPlan.planStatus === "active" ? (
        <Routes>
          <Route path="/staging/:id/:publishModal?/:trackId?" element={<Staging />} />
          <Route exact path="/composer/:id" element={<Composer />} />
          <Route exact path="/library/:profileId" element={<Library />} />
          <Route exact path="/library/questions" element={<LibraryQuestions />} />
          <Route exact path="/generate-tweets" element={<GenerateTweets />} />
          <Route exact path="/generate-threads" element={<GenerateThreads />} />
          <Route exact path="/text-to-bullets" element={<TextToBullets />} />
          <Route exact path="/text-to-linkedin-posts" element={<TextToLinkedInPost />} />
          <Route exact path="/text-to-linkedin-sliders" element={<TextToLinkedInSlider />} />
          <Route exact path="/text-to-newsletter" element={<TextToNewsletter />} />
          <Route exact path="/text-to-instagram-sliders" element={<TextToInstagramSlider />} />
          <Route exact path="/text-to-instagram-captions" element={<TextToInstagramCaption />} />
          <Route exact path="/queues/:profileId" element={<Queues />} />
          <Route exact path="/accept-reject-tweets" element={<AccpetRejectTweets />} />
          <Route exact path="/twitter-threads" element={<TwitterThreads />} />
          <Route path="/:profileId/tracks/:publishModal?/:trackId?" element={<Tracks />} />
          <Route path="/billing/:buy?" element={<Billing />} />
          <Route path="/new-lib" element={<NewLibrary />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/*" element={<Home />} />
          {user && user.isSuperAdmin && <Route path="/super-admin" element={<SuperAdmin />} />}
        </Routes>
      ) : (
        <Routes>
          <Route path="/*" element={<UpgradeModal />} />
          <Route path="/billing" element={<Billing />} />
        </Routes>
      )}

      <Sidebar />
      <AddCompany isModalOpen={visible} setIsModalOpen={closeAddCompanyModal} />
    </>
  );
};

export default Index;
