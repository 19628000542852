import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./animations.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import {
  initFacebookSdk,
  // jwtInterceptor, errorInterceptor
} from "./utils/facebook/_helpers";
initFacebookSdk().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  );
});
