import { useState } from "react";
import LibraryQuestionsBottomBar from "../components/Library/BottomBar/LibraryQuestionsBottomBar";
import LibraryQuestionItem from "../components/Library/LibraryQuestionItem";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import { useNavigate } from "react-router-dom";

const questions = [
  {
    key: 0,
    questionTitle: "Text to bullets",
    questionDescription: "Summarize your text into bullets.",
    urlPath: "/text-to-bullets",
    isSelected: false,
    comingSoon: false,
    disabled: false,
  },
  {
    key: 1,
    questionTitle: "Text to Tweets",
    questionDescription: "Convert your blog posts, newsletters or transcripts into multiple tweets.",
    urlPath: "/generate-tweets",
    isSelected: false,
    disabled: false,
  },
  {
    key: 2,
    questionTitle: "Text to Threads",
    questionDescription: "Convert your blog posts, newsletters or transcripts into threads.",
    urlPath: "/generate-threads",
    isSelected: false,
    disabled: false,
  },
  {
    key: 3,
    questionTitle: "Instagram sliders",
    questionDescription: "Convert your blog posts, newsletters or transcripts into Instagram Sliders.",
    urlPath: "/text-to-instagram-sliders",
    isSelected: false,
    disabled: false,
  },
  {
    key: 4,
    questionTitle: "Instagram Captions",
    questionDescription: "Convert your blog posts, newsletters or transcripts into Instagram Captions.",
    urlPath: "/text-to-instagram-captions",
    isSelected: false,
    disabled: false,
  },
  {
    key: 5,
    questionTitle: "Text to LinkedIn Posts",
    questionDescription: "Convert your blog posts, newsletters or transcripts into LinkedIn Posts.",
    urlPath: "/text-to-linkedin-posts",
    isSelected: false,
    disabled: false,
  },
  {
    key: 6,
    questionTitle: "Text to LinkedIn sliders",
    questionDescription: "Convert your blog posts, newsletters or transcripts into LinkedIn Sliders.",
    urlPath: "/text-to-linkedin-sliders",
    isSelected: false,
    disabled: false,
  },
  {
    key: 7,
    questionTitle: "Text to Newsletter",
    questionDescription: "Convert your blog posts, newsletters or transcripts into Newsletters.",
    urlPath: "/text-to-newsletter",
    isSelected: false,
    disabled: false,
  },
];

const LibraryQuestions = () => {
  const [questionsData, setQuestionData] = useState(questions);
  const navigate = useNavigate();

  const selectAnswer = (index) => {
    setQuestionData((prev) => {
      return prev.map((option, i) => ({
        ...option,
        isSelected: index === i ? true : false,
      }));
    });
  };

  const handleOnClick = () => {
    const urlPath = questionsData.find((question) => question.isSelected === true)?.urlPath;
    if (urlPath) {
      navigate(urlPath);
    }
  };
  return (
    <div className="min-h-[100vh]">
      <PostSigninNavbar title="Transform your text" />
      <div className="mt-11 mb-14">
        <div className="w-[70%] m-auto py-[25px]">
          <p className="text-[#6B7280] text-[18px] mb-[20px]">Text Transform</p>
          <h3 className="text-[30px] font-semibold mb-[5ffpx] text-[#111928]">What do you want to generate?</h3>
          <p className="text-[#6B7280] text-[18px] mb-[1.5rem]">Pick the type of content you want to transform into.</p>

          <div className="flex flex-col gap-[1rem]">
            {questionsData.map((question) => {
              return (
                <LibraryQuestionItem
                  key={question.key}
                  index={question.key}
                  questionTitle={question.questionTitle}
                  questionDescription={question.questionDescription}
                  selectAnswer={selectAnswer}
                  isSelected={question.isSelected}
                  comingSoon={question.comingSoon}
                  disabled={question.disabled}
                />
              );
            })}
          </div>
        </div>
      </div>
      <LibraryQuestionsBottomBar onClick={handleOnClick} buttonText="Next" />
    </div>
  );
};

export default LibraryQuestions;
