import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "./utils/Button";
import { confirmationModal } from "../utils/sweetAlert";

const BottomBar = (props) => {
  const { stage, disabled } = props;
  const navigate = useNavigate();
  const [lastSaved, setlastSaved] = React.useState(null);

  React.useEffect(() => {
    if (props.savedTime) {
      const intervalId = setInterval(() => {
        setlastSaved(moment(props.savedTime).fromNow());
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [props.savedTime]);

  const buttonText = ["composer", "Send to Staging", "Next"];
  const navStage = [`/composer/${props.trackId}`, `/staging/${props.trackId}`, "/tracks"];

  const goBackToComposer = () => {
    confirmationModal(
      {
        text: "You'll lose all changes made in the staging area.",
        confirmButtonText: "Yes, go back",
      },
      async () => {
        navigate(navStage[stage - 1]);
      }
    );
  };

  return (
    <div className="bg-[#FAFAFA] w-full py-2 flex fixed bottom-0 z-50" style={{ borderTop: "0.5px solid #D4D4D8" }}>
      <div
        className={`ml-5 w-fit flex items-center justify-center hover:cursor-pointer ${
          stage === 0 ? `hidden` : `block`
        }`}
        onClick={stage !== 0 ? goBackToComposer : () => navigate("/library")}
      >
        <svg
          className="mt-[1px] mr-1"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 15.8333L6.66667 9.99992L12.5 4.16659"
            stroke="#374151"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="text-sm font-semibold text-[#374151]">{stage === 0 ? "Import from Library" : "Back"}</div>
      </div>
      <div
        className={`ml-5 w-fit flex items-center justify-center hover:cursor-pointer ${
          stage === 0 ? `block` : `hidden`
        }`}
      >
        {stage === 0 && (
          <>
            <div
              onClick={() => props.saveDraft()}
              className=" hover:cursor-pointer mt-0.5 text-[#1C64F2] text-sm font-semibold"
            >
              Save
            </div>
            <div className="hidden md:block text-[#6B7280] text-sm ml-3">
              {lastSaved && (
                <>
                  Last saved, <span className="underline">{lastSaved}</span>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className="ml-auto mr-5 w-fit flex items-center justify-center">
        {stage === 1 && (
          <>
            <div className="hidden md:block text-[#6B7280] text-sm]">
              {lastSaved && (
                <>
                  Last saved, <span className="underline">{lastSaved}</span>
                </>
              )}
            </div>
            <div
              onClick={() => props.saveDraft()}
              className="ml-3 hover:cursor-pointer mt-0.5 text-[#1C64F2] text-sm font-semibold"
            >
              Save
            </div>
          </>
        )}
        {stage === 0 && (
          <>
            <div
              onClick={() => props.showTransformModal()}
              className="ml-3 flex space-x-1 hover:cursor-pointer mt-0.5 text-[#1C64F2] text-sm font-semibold items-center"
            >
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.6666 4.83333L7.99992 1.5L1.33325 4.83333M14.6666 4.83333L7.99992 8.16667M14.6666 4.83333V13.1667L7.99992 16.5M1.33325 4.83333L7.99992 8.16667M1.33325 4.83333V13.1667L7.99992 16.5M7.99992 8.16667V16.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="ml-1">Transform</p>
            </div>
          </>
        )}

        <Button
          className="ml-3"
          variant={disabled ? "disabled" : "blue"}
          onClick={disabled ? null : props.onClickHandler}
        >
          <div className="flex justify-center items-center">
            <svg
              className="mt-[2px]"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 4.66675L13.3333 10.5001L7.5 16.3334"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="text-sm font-[500] ml-1">{buttonText[stage + 1]}</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default BottomBar;
