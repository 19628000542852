const INITAIAL_STATE = {
  run: true,
  stepIndex: 0,
  steps: [
    {
      target: "#composer-title",
      content: "my first step",
      disableBeacon: true,
    },
    {
      target: "#staging-area",
      content: "this is the staging area",
      disableBeacon: true,
    },
  ],
  tourActive: false,
};

const tourReducer = (state = INITAIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case "STOP_TOUR":
      return {
        ...state,
        run: false,
      };
    case "START_TOUR":
      return {
        ...state,
        run: true,
      };
    case "SET_STEP_INDEX":
      return {
        ...state,
        stepIndex: payload,
      }
    default:
      return state;
  }
};

export default tourReducer;
