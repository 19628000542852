const initState = {
  generatingTweetsLoading: false,
  generatedTweets: [],
  baseText: "",
  assetId: null,
  selectedAsset: null,
  generatedTweetsThreads: [],
  libraryData: [],
  allLibraryData: [],
  profileId: null,
  libraryLoading: false,
  hasMore: true,
  filterArray: [],
  sort: "Newest",
  search: "",
};

// eslint-disable-next-line import/no-anonymous-default-export

function applyFilter(data, filterArray) {
  var temp = [];
  if (filterArray.length == 0) {
    return data;
  }
  for (const d of data) {
    var push = false;
    if (filterArray.includes(d.type)) {
      temp.push(d);
      push = true;
    }
    if (filterArray.includes("has-parents") && !push && d.prev) {
      temp.push(d);
      push = true;
    }
    if (filterArray.includes("original") && !push && !d.prev) {
      temp.push(d);
      push = true;
    }
    if (filterArray.includes("published") && !push) {
      if (d.tags && d.tags.includes("published")) {
        temp.push(d);
        push = true;
      }
    }
    if (filterArray.includes("paraphrased&published") && !push) {
      if (d.tags && d.tags.includes("published") && d.tags.includes("paraphrased")) {
        temp.push(d);
        push = true;
      }
    }
  }
  return temp;
}
function applySorting(data, sort) {
  if (sort == "Newest") {
    return data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  } else if (sort == "Oldest") {
    console.log(data.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)));
    return data.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
  } else if (sort == "A-Z") {
    return data.sort((a, b) => {
      var am = "";
      var bm = "";
      am = a.documentTitle || a.videoTitle || a.imageTitle || a.audioTitle || a.text || JSON.stringify(a.tweet);
      a.am = am;
      console.log(a.am);
      bm = b.documentTitle || b.videoTitle || b.imageTitle || b.audioTitle || b.text || JSON.stringify(b.tweet);
      b.bm = bm;
      if (am && bm) {
        return a.am.localeCompare(b.bm);
      }
    });
  } else if (sort == "Z-A") {
    return data.sort((a, b) => {
      var am = "";
      var bm = "";
      am = a.documentTitle || a.videoTitle || a.imageTitle || a.audioTitle || a.text;
      a.am = am;
      console.log(a.am);
      bm = b.documentTitle || b.videoTitle || b.imageTitle || b.audioTitle || b.text;
      b.bm = bm;
      if (am && bm) {
        return b.bm.localeCompare(a.am);
      }
    });
  }
  return data;
}
function applySearch(data, search) {
  if (search == "" || search == null) {
    return data;
  }
  var temp = [];
  for (const d of data) {
    var push = false;
    var am = "";
    am = d.documentTitle || d.videoTitle || d.imageTitle || d.audioTitle || d.text;
    var txt = "";
    if (
      (am && am.toLowerCase().includes(search.toLowerCase())) ||
      d.topics.includes(search) ||
      (d.text && d.text.includes(search))
    ) {
      temp.push(d);
      push = true;
    }
    if (!push) {
      for (const t of d.topics) {
        if (t.toLowerCase().includes(search.toLowerCase())) {
          temp.push(d);
          push = true;
          break;
        }
      }
    }
  }
  return temp;
}

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        generatingTweetsLoading: action.payload,
      };
    case "UPDATE_GENERATED_TWEETS":
      const { index, newTweet } = action;
      return {
        ...state,
        generatedTweets: [
          ...state.generatedTweets.slice(0, index),
          newTweet,
          ...state.generatedTweets.slice(index + 1),
        ],
      };
    case "CHANGE_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.payload,
      };
    case "ADD_GENERATED_TWEETS":
      return {
        ...state,
        generatedTweets: [...state.generatedTweets, ...action.payload.filter((el) => el.trim())],
      };
    case "ADD_BULLET_TWEETS":
      return {
        ...state,
        generatedTweets: action.payload,
      };
    case "REMOVE_GENERATED_TWEETS":
      return {
        ...state,
        generatedTweets: [],
      };
    case "CHANGE_BASE_TEXT":
      return {
        ...state,
        baseText: action.payload,
      };
    case "ADD_GENERATED_TWEETS_THREADS":
      return {
        ...state,
        generatedTweetsThreads: action.payload,
      };
    case "ADD_LIBRARY_DATA":
      return {
        ...state,
        libraryData: applyFilter(
          applySearch(applySorting(action.payload, state.sort), state.search),
          state.filterArray
        ),
        allLibraryData: action.payload,
      };
    case "ADD_LIBRARY_DATA_NEW":
      return {
        ...state,
        libraryData: applyFilter(
          applySearch(applySorting(action.payload, state.sort), state.search),
          state.filterArray
        ),
        allLibraryData: action.payload,
      };
    case "ADD_LIBRARY_DATA_INFINITE":
      return {
        ...state,
        libraryData: [...state.libraryData, ...action.payload],
        allLibraryData: [...state.allLibraryData, ...action.payload],
        hasMore: action.payload.length > 0,
      };
    case "CHANGE_PROFILE_ID":
      return {
        ...state,
        profileId: action.payload,
      };
    case "CHANGE_SELECTED_ASSET_ID":
      return {
        ...state,
        assetId: action.payload,
      };
    case "CHANGE_SELECTED_ASSET":
      return {
        ...state,
        selectedAsset: action.payload,
      };
    case "LIBRARY_LOADING_TRUE":
      return {
        ...state,
        libraryLoading: true,
      };
    case "LIBRARY_LOADING_FALSE":
      return {
        ...state,
        libraryLoading: false,
      };
    case "FILTER_LIBRARY_DATA":
      return {
        ...state,
        libraryData: applyFilter(state.allLibraryData, action.payload),
        filterArray: action.payload,
      };
    case "SORT_LIBRARY_DATA":
      return {
        ...state,
        libraryData: applySorting(state.allLibraryData, action.payload),
        sort: action.payload,
      };
    case "SEARCH_LIBRARY_DATA":
      return {
        ...state,
        libraryData: applySearch(state.allLibraryData, action.payload),
        search: action.payload,
      };
    case "UPDATE_LIBRARY_ASSET":
      return {
        ...state,
        libraryData: state.libraryData.map((el) => {
          if (el.id == action.payload.id) {
            return action.payload;
          }
          return el;
        }),
        allLibraryData: state.allLibraryData.map((el) => {
          if (el.id == action.payload.id) {
            return action.payload;
          }
          return el;
        }),
      };
    case "DELETE_LIBRARY_ASSET":
      return {
        ...state,
        libraryData: state.libraryData.filter((el) => el.id != action.payload),
        allLibraryData: state.allLibraryData.filter((el) => el.id != action.payload),
      };
    default:
      return state;
  }
};
