import PublishingModalContent from "./PublishingModalContent";

const PublishingModal = (props) => {
  return (
    <div className="fixed inset-0 z-[99] min-h-screen min-w-screen flex justify-center items-center" aria-label="modal">
      <div
        className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[90]"
        onClick={() => props.setisModalHidden(true)}
      ></div>
      <div className="max-w-screen relative z-[99] bg-white rounded-[10px] p-[40px] pb-[15px] pr-[15px] w-fit md:w-[65rem] px-10 show-modal">
        <div
          className="text-3xl absolute top-[5px] right-[25px] z-[99] text-[#52525b] cursor-pointer"
          onClick={() => props.setisModalHidden(true)}
        >
          &times;
        </div>

        <PublishingModalContent {...props} />
      </div>
    </div>
  );
};

export default PublishingModal;
