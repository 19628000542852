import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import LibraryQuestionsBottomBar from "../../components/Library/BottomBar/LibraryQuestionsBottomBar";
import { handleGenerateNewsletter, addLibraryAsset, getLibraryData } from "../../redux/actions/libraryAction";
import { useSelector } from "react-redux";
import { failAlert, successAlert } from "../../utils/sweetAlert";

import Button from "../../components/utils/Button";
import { Select, Space } from "antd";
import { selectOptions } from "../../utils/multiSelect";

const CHARACTER_LIMIT = 20000;
const TextToLinkedInPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { baseText, profileId, assetId, selectedAsset } = useSelector((state) => state.library);
  const [topics, setTopics] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  useEffect(() => {
    if (selectedAsset) {
      var temp = [];
      for (var i = 0; i < selectedAsset.topics.length; i++) {
        temp.push({
          label: selectedAsset.topics[i],
          value: selectedAsset.topics[i],
        });
      }
      setTopicOptions(temp);
    }
  }, [selectedAsset]);
  const [text, setText] = useState("");
  const [prompt, setPrompt] = useState({
    text: text,
    topics: topics.toString(),
  });
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);
  const [response, setResponse] = useState("");
  useEffect(() => {
    setText(baseText);
    setPrompt({
      ...prompt,
      text: baseText,
    });
  }, [baseText]);

  useEffect(() => {
    //to resize the textarea on load
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [text]);
  const handleOnChange = async (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + 10}px`;
    setText(e.target.value);
    setPrompt({
      ...prompt,
      text: e.target.value,
    });

    e.target.scroll = e.target.scrollHeight + 50;
    window.scrollTo(0, e.target.scrollHeight);
  };
  // const handleChange = (e, index) => {
  //   if (e.target.value.length <= 1800) {
  //     const updatedPrompt = {
  //       ...prompt,
  //       example: [...prompt.example],
  //     };
  //     updatedPrompt.example[index] = e.target.value;
  //     setPrompt(updatedPrompt);
  //   }
  // };
  const handleOnClick = async () => {
    setLoading(true);
    var GPTResponce = await dispatch(handleGenerateNewsletter(prompt, assetId, profileId));
    if (GPTResponce) {
      // navigate(`/accept-reject-tweets`);
      setResponse(GPTResponce);
      successAlert({ title: "Transformation Started!" });
      navigate(`/library/${profileId}`);
      setLoading(false);
    } else {
      setLoading(false);
      failAlert({
        title: "Some error occurred!",
      });
    }
  };
  const handleAddToLibrary = () => {
    var res = dispatch(addLibraryAsset(profileId, response, assetId));
    if (res) {
      dispatch(getLibraryData(profileId));
      successAlert({ title: "Added to Library" });
      navigate(`/library/${profileId}`);
    } else {
      failAlert({
        title: "An error occurred!",
      });
    }
  };
  return (
    <>
      <PostSigninNavbar title="Transform your text to tweets" />
      <div className="w-[85%] m-auto mt-11 mb-20">
        <div className="pt-[20px]">
          <h3 className="text-[#111928] text-[30px] font-semibold">Convert text to Newsletter</h3>
          <p className="text-[#6B7280] text-[18px]">
            Pick the type of content you want to transform into a Newsletter.
          </p>
        </div>

        <div className="  gap-[25px] mt-[20px] mb-[20px]">
          <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-10 min-h-[20rem] flex flex-col relative h-fit">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">Enter the base text</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">
              Blogs, articles, newsletters up to 20,000 characters.
            </p>
            <div className="relative h-full">
              <textarea
                className="bg-[#F9FAFB] w-full text-[#3F3F46] p-[18px]  rounded-[12px] flex-1 resize-none !min-h-[290px] text-[14px] pb-4 position-relative display-inline-block"
                placeholder="Enter your text here"
                ref={textAreaRef}
                value={text}
                onInput={(e) => handleOnChange(e)}
              ></textarea>
              <div style={{ flexGrow: 1 }}></div>
              <div
                className={`w-full ml-auto text-end   text-[14px] right-[10px] ${
                  text.length > 20000 ? "text-red-500" : "text-[#6B7280]"
                } `}
              >
                <p className="-mt-6 mr-2">{text.length <= 20000 ? text.length : 20000 - text.length} chars</p>
              </div>
              <div className="w-1/2 bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] my-[25px] h-fit">
                <h4 className="text-[#4B5563] text-[16px] font-[500]">Enter the topics (Optional)</h4>
                <p className="text-[#6B7280] text-[14px] my-[10px]">
                  What would you like the text generated to be about?
                </p>
                <div className="relative ">
                  <Select
                    mode="tags"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select"
                    // defaultValue={["a10", "c12"]}

                    onChange={(e) => {
                      setPrompt({ ...prompt, topics: e.toString() });
                      setTopics(e);
                    }}
                    options={topicOptions}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full  mt-3 pt-1">
                <button className=" text-sm font-semibold text-[#374151]" onClick={() => navigate(-1)}>
                  Go Back
                </button>
                <Button
                  loading={loading}
                  className="ml-3"
                  onClick={handleOnClick}
                  variant={text.length <= 20000 ? "blue" : "disabled"}
                >
                  <div className="flex justify-center items-center">
                    {/* prettier-ignore */}
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 4.66675L13.3333 10.5001L7.5 16.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <div className="text-sm font-[500] ml-1">Transform</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <LibraryQuestionsBottomBar loading={loading} onClick={handleOnClick} buttonText="next" /> */}
    </>
  );
};

export default TextToLinkedInPost;
