import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PreSigninNavbar from "../components/Navbar/PreSigninNavbar";
import { useDispatch } from "react-redux";
import { handleSignup } from "../redux/actions/authActions";
import Button from "../components/utils/Button";
import Loader from "../components/utils/Loader";
import { failAlert } from "../utils/sweetAlert";
import { useParams } from "react-router-dom";
const SignUp = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [fullName, setfullName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { code } = useParams();
  useEffect(() => {
    if (!code) return;
    setReferralCode(code);
  }, [code]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fullName.length !== 0 && email.length !== 0 && document.getElementById("t&c").checked) {
      setIsLoading(true);

      const res = await dispatch(handleSignup({ email, fullName, referralCode }));
      if (res[0] === false) {
        setIsLoading(false);

        failAlert({
          title: res[1] ?? "Something went wrong",
          confirmButtonText: "OK",
        });
      }
    } else if (email.length === 0 || fullName.length === 0) {
      failAlert({
        title: "Please enter a valid email and name",
      });
    } else if (!document.getElementById("t&c").checked) {
      failAlert({
        title: "Please accept the terms and conditions",
      });
    }
  };

  return (
    <div className="h-[100vh] flex flex-col bg-[#f9fafb]">
      <PreSigninNavbar />
      <div className="m-auto flex justify-center items-center">
        <div className=" md:flex items-center p-8 sm:mx-[17vw] sm:my-[9vh] bg-[#FFFFFF] rounded-[16px] border border-[#E4E4E7]">
          <div className="flex items-center justify-center pr-12">
            <img width={"400px"} height={"400px"} src="Images/SignUp/SignUp.png" alt="" />
          </div>
          <div>
            <div className="text-3xl font-bold text-[#111928] font-bold mb-5">Create your Account</div>
            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Your Name</h4>
              <input
                id="fullName"
                name="fullName"
                type="text"
                required
                value={fullName}
                onChange={(e) => setfullName(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="Full Name"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>

            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Your email</h4>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="name@example.com"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>
            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Referal Code</h4>
              <input
                id="referralCode"
                name="referralCode"
                type="text"
                autoComplete="referralCode"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="Optional"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>
            <div className="flex items-center" style={{ marginTop: "1rem" }}>
              <div className="flex items-center">
                <input
                  id="t&c"
                  name="t&c"
                  type="checkbox"
                  required
                  className="border-[#D1D5DB] text-[#D1D5DB] focus:ring-[white]"
                />
                <label htmlFor="t&c" className="ml-2 text-xs font-normal text-[#6B7280]">
                  I accept the{" "}
                  <a
                    href="https://wordoutapp.com/terms/"
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-[#1C64F2] hover:text-blue-700"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                className="border border-[#1C64F2] w-full !py-2 px-4 text-[#FFFFFF] text-[16px]"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="flex gap-[5px]">
                    <div>
                      <Loader height="20px" width="20px" />
                    </div>
                    Loading...
                  </div>
                ) : (
                  <>Create account</>
                )}
              </Button>
              {/* <button
              type="submit"
              style={{
                border: "1px solid #1C64F2",
                borderRadius: "12px",
              }}
              onClick={handleSubmit}
              className="flex w-full justify-center bg-[#1C64F2] py-2 px-4 text-base font-semibold text-[#FFFFFF] hover:bg-blue-700">
              Create account
            </button> */}
            </div>
            <hr
              style={{
                width: "50%",
                marginLeft: "25%",
                borderColor: "#3F83F8",
                marginTop: "1rem",
              }}
            />
            <div className="flex items-center justify-center mr-6" style={{ marginTop: "0.7rem" }}>
              <Link to="/login" className="text-base font-semibold text-[#1C64F2] ml-7 hover:text-blue-700">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
