import React, { useState, useEffect } from 'react'
import Button from './utils/Button'
import { useNavigate } from 'react-router-dom'
export default function UpgradeModal(props) {
    const navigate = useNavigate()
    return (
        <div className="fixed inset-0 z-[199] min-h-screen min-w-screen flex justify-center items-center" aria-label="modal" >
            <div
                className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[190]"

            ></div>
            <div className="relative z-[199] bg-white rounded-[10px] p-[32px] pb-[15px] pr-[15px] w-1/2 show-modal">
                <h4 className="text-[26px] font-semibold">Upgrade Plan</h4>
                <p className="text-[#6B7280] text-[16px] mb-[10px] leading-5 mt-1">Unlock exclusive features and get the most out of WordOut with our premium plans. Choose a plan today and start enjoying the benefits!</p>

                <div className='float-right py-4 px-4'>
                    <Button onClick={
                        () => {
                            navigate('/billing')
                        }
                    }>
                        Upgrade
                    </Button>
                </div>


            </div>
        </div>
    )
}
