import React from "react";
import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { failAlert } from "../../utils/sweetAlert";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../components/utils/ErrorBoundary";
import LibraryQuestionsBottomBar from "../../components/Library/BottomBar/LibraryQuestionsBottomBar";
import TwitterThreadsContent from "../../components/Library/LibraryItems/TwitterThreadsContent";

function TwitterThreads() {
  const [isLoading, setIsLoading] = useState(false);
  const { generatedTweetsThreads, profileId, assetId } = useSelector((state) => state.library);
  const navigate = useNavigate();

  const importThreadsToLibrary = async () => {
    setIsLoading(true);
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/add-generated-threads`,
      { profileId, assetId, threads: generatedTweetsThreads },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status !== true) {
      failAlert({
        text: "Failed to import to library, please try again!",
      });
      return;
    }

    navigate(`/library/${profileId}`);
  };

  return (
    <>
      <PostSigninNavbar title="Transform your text to tweets" />
      <ErrorBoundary className="!mt-12">
        <TwitterThreadsContent generatedTweetsThreads={generatedTweetsThreads} profileId={profileId} />
      </ErrorBoundary>

      <LibraryQuestionsBottomBar loading={isLoading} onClick={importThreadsToLibrary} buttonText="Import To Library" />
    </>
  );
}

export default TwitterThreads;
