import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import { handleSuperAdmin } from "../redux/actions/authActions";
import { Space, Table, Tag, Divider } from "antd";
const SuperAdmin = () => {
  const dispatch = useDispatch();
  const { members } = useSelector((state) => state.team);
  const { user, superAdmin } = useSelector((state) => state.auth);
  const { usageByOrganization, usageByUser, ReferredUsers } = superAdmin;
  const orgColumns = [
    {
      title: "Company ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Company Name",
      dataIndex: "profileName",
      key: "profileName",
    },

    {
      title: "Owner Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Owner Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Payment Status",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "OpenAI Usage (in $)",
      dataIndex: "wordoutCreditsUsed",
      key: "wordoutCreditsUsed",
      sorter: (a, b) => a.wordoutCreditsUsed - b.wordoutCreditsUsed,
    },
    {
      title: "Number of Users",
      dataIndex: "numberOfTeamMembers",
      key: "numberOfTeamMembers",
      sorter: (a, b) => a.numberOfTeamMembers - b.numberOfTeamMembers,
    },
    {
      title: "Published Posts",
      dataIndex: "publishedAssets",
      key: "publishedAssets",
      sorter: (a, b) => a.publishedAssets - b.publishedAssets,
    },
    {
      title: "Generated Assets",
      dataIndex: "generatedAssets",
      key: "generatedAssets",
      sorter: (a, b) => a.generatedAssets - b.generatedAssets,
    },
    {
      title: "Total Views",
      dataIndex: "views",
      key: "views",
      sorter: (a, b) => a.views - b.views,
    },
  ];
  const userColumns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Usage (in $)",
      dataIndex: "wordoutCreditsUsed",
      key: "wordoutCreditsUsed",
      sorter: (a, b) => a.wordoutCreditsUsed - b.wordoutCreditsUsed,
    },
    {
      title: "Number Of Organizations",
      dataIndex: "numberOfOrganizations",
      key: "numberOfOrganizations",
      sorter: (a, b) => a.numberOfOrganizations - b.numberOfOrganizations,
    },
  ];

  const referedUserColumns = [
    {
      title: "User Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Referred By",
      dataIndex: "referredBy",
      key: "referredBy",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    if (!user) return;
    dispatch(handleSuperAdmin());
  }, []);
  return (
    <>
      <PostSigninNavbar />
      <div className="container px-1 md:px-20 py-8 mx-auto mt-[45px] animate-fade-in">
        <div className="w-full flex-col justify-between">
          <h2 className="my-4">Usage By Organization</h2>
          {/* Teams */}
          <Table columns={orgColumns} dataSource={usageByOrganization} size="small" />
        </div>
        <Divider />
        <div className="w-full flex-col justify-between mt-2">
          <h2 className="my-4">Reffered Users</h2>
          <Table columns={referedUserColumns} dataSource={ReferredUsers} size="small" />
        </div>
        {/* <Divider />
        <div className="w-full flex-col justify-between mt-2">
          <h2 className="my-4">Usage By Users</h2>
          <Table columns={userColumns} dataSource={usageByUser} size="small" />
        </div> */}
      </div>
    </>
  );
};

export default SuperAdmin;
