import axios from "axios";
import { useSearchParams } from "react-router-dom";
import PreSigninNavbar from "../components/Navbar/PreSigninNavbar";
import { failAlert, successAlert } from "../utils/sweetAlert";

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();

  const resendMail = async () => {
    try {
      const email = searchParams.get("email");
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login/`, { email });
      if (res.status === 200) {
        successAlert({
          title: "Mail sent successfully",
          confirmButtonText: "Close",
        });
      }
    } catch (error) {
      failAlert({
        title: error.response.data?.message ?? "Something weng wrong",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="h-screen bg-[#F9FAFB]">
      <PreSigninNavbar />
      <div className="max-w-screen flex justify-center items-center">
        <div className="max-w-screen  flex p-8 sm:mx-[17vw] sm:my-[9vh] bg-[#FFFFFF] rounded-[16px] border border-[#E4E4E7]">
          <div className="max-w-screen w-[400px] md:w-[800px] bg-[#fff] text-center">
            <h1 className="text-[30px] text-center font-bold text-[#111928] mb-4">Check your inbox</h1>
            <p className="text-[#6B7280]">We've sent you a verification email.</p>

            <img className="w-[200px] md:w-[330px] m-auto" src="Images/SignUp/SignUp.png" alt="Confirm Email" />

            <p className="text-[#6B7280] text-[16px]">
              Didn't receive an email?
              <br /> Make sure to check spam.
            </p>
            <button className="text-[16px] text-[#1C64F2] font-semibold" onClick={resendMail}>
              Resend
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
