import { loadStripe } from "@stripe/stripe-js";

import { createCheckOutSession, stripeCustomerPortal } from "./stripe";

// Get the stripe instance
export const getStripe = () => {
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
};

// Creates a stripe subscription
// 1. create a stripe checkout session
// 2. redirect to the created session using the stripe-js library
export const handleSubscription = async (planId) => {
  try {
    const sessionId = await createCheckOutSession(planId);
  
    const stripe = await getStripe();
    stripe.redirectToCheckout({ sessionId: sessionId });
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const handleStripe = async () => {
  const data = await stripeCustomerPortal();
  if (data.status) {
    return window.open(data.return_url, "_blank", "noopener,noreferrer");
  }
};
