import { useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "antd";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";

import Loader from "../utils/Loader";
import Button from "../utils/Button";
import axios from "axios";
import { failAlert, successAlert } from "../../utils/sweetAlert";
import { set } from "lodash";
const PublishingModalFooter = (props) => {
  const {
    isAnyPlatformSelected,
    selectedPlatform,
    isLoading,
    postNowHandler,
    schedulePost,
    scheduledTime,
    setisModalHidden,
    getAllTracks,
    activeTrack,
  } = props;
  const datepickerParent = useRef(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [scheduleMode, setscheduleMode] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [isReadyToSchedule, setIsReadyToSchedule] = useState(false);

  useEffect(() => {
    setIsReadyToSchedule(selectedDate && selectedTime && isAnyPlatformSelected);
  }, [selectedDate, selectedTime, isAnyPlatformSelected]);
  useEffect(() => {
    if (activeTrack) {
      setHideButtons(false);
      if (activeTrack.twitterPost.length > 1 && selectedPlatform.twitter) {
        setHideButtons(true);
      }
      if (activeTrack.linkedInPost.length > 1 && selectedPlatform.linkedin) {
        setHideButtons(true);
      }
      if (activeTrack.instaPost.length > 1 && selectedPlatform.instagram) {
        setHideButtons(true);
      }

      // if (
      //   activeTrack.twitterPost.length > 0 ||
      //   activeTrack.linkedInPost.length > 0 ||
      //   activeTrack.instaPost.length > 0
      // ) {
      //   setHideButtons(true);
      // }
    }
  }, [activeTrack, selectedPlatform]);
  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };

  const datePickerHandler = (e) => {
    if (e === null) {
      setSelectedDate(null);
      return;
    }

    setSelectedDate({
      day: e.$D,
      month: e.$M,
      year: e.$y,
    });
  };

  const scheduleHandler = () => {
    if (!selectedDate || !selectedTime) {
      return;
    }

    const date = new Date(
      selectedDate.year,
      selectedDate.month,
      selectedDate.day,
      selectedTime.hour,
      selectedTime.minute,
      0
    );

    if (date) {
      schedulePost(date);
    }
  };

  const AddtoQueuehandler = async () => {
    console.log(selectedPlatform, props.trackId);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/schedule/add-to-queue`,
        {
          trackId: props.trackId,
          platform: selectedPlatform,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (data.status) {
        successAlert({ title: "Added to Queue" });
        setisModalHidden(true);
        getAllTracks();
      } else {
        failAlert({ title: data.message });
      }
    } catch (error) {}
  };

  return (
    <div className="flex justify-center md:block mt-[50px]">
      {isAnyPlatformSelected ? (
        <div className="hidden md:block text-[#6B7280] text-[14px] mr-auto mb-[5px]">
          Selected Platforms:
          <b className="capitalize">
            {Object.entries(selectedPlatform)
              .filter(([_, val]) => val === true)
              .map((el) => {
                if (!activeTrack) return el[0];
                if (el[0] === "twitter") {
                  var count = 0;
                  count = activeTrack.twitterPost.length;
                  // activeTrack.twitterPost.forEach((el) => {
                  //   if (el.status === "draft" || el.status === "staging") count++;
                  // });
                  return `Twitter ${activeTrack && `(${count})`}`;
                }
                if (el[0] === "instagram") {
                  var count = 0;
                  count = activeTrack.instaPost.length;
                  // activeTrack.instaPost.forEach((el) => {
                  //   if (el.status === "draft" || el.status === "staging") count++;
                  // });
                  return `Instagram ${activeTrack && `(${count})`}`;
                }
                if (el[0] === "linkedin") {
                  var count = 0;
                  count = activeTrack.linkedInPost.length;
                  // activeTrack.linkedInPost.forEach((el) => {
                  //   if (el.status === "draft" || el.status === "staging") count++;
                  // });
                  return `LinkedIn ${activeTrack && `(${count})`}`;
                }

                // if (el[0] === "linkedin") return `Linkedin ${activeTrack && `(${activeTrack.linkedInPost.length})`}`;
                // if (el[0] === "instagram") return `Instagram ${activeTrack && `(${activeTrack.instaPost.length})`}`;
              })
              .join(", ")}
          </b>
        </div>
      ) : (
        <></>
      )}
      <div
        className="flex-col w-full -mt-8 md:mt-0 text-center justify-center md:flex md:justify-end relative items-center"
        ref={datepickerParent}
      >
        {scheduleMode && (
          <div className="w-full md:w-fit flex md:block">
            <DatePicker
              className="mb-1 md:mb-0  md:w-[200px] mr-[2px] md:mr-[10px] border border-[#D1D5DB]"
              onChange={datePickerHandler}
              getPopupContainer={() => datepickerParent.current}
              format={"DD/MM/YYYY"}
            />
            <TimePicker
              className="mb-1 md:mb-0 md:w-[200px] border border-[#D1D5DB]"
              onChange={timePickerHandler}
              getPopupContainer={() => datepickerParent.current}
            />
          </div>
        )}
        {/* This renders all the selected platforms for schedule */}

        {scheduleMode ? (
          <div className="flex justify-center text-center md:justify-end items-center w-full">
            <button
              className={`py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#9A9D9F]
               flex items-center justify-center transition duration-300 `}
              onClick={() => setscheduleMode(false)}
              // disabled={isAnyPlatformSelected && !isLoading ? false : true}
            >
              <span className={`text-sm font-[500] text-semibold mr-2 text-[#111928]`}>Cancel</span>
            </button>
            <Button
              variant={isReadyToSchedule ? "blue" : "disabled"}
              onClick={isReadyToSchedule ? scheduleHandler : null}
              className="md:ml-3"
            >
              {isLoading ? (
                <span className="text-sm flex items-center gap-2 font-[500] mr-2">
                  <Loader height="15px" width="15px" />
                  Loading...
                </span>
              ) : (
                <span
                  className={`text-sm font-[500] text-semibold flex justify-center items-center  ${
                    isAnyPlatformSelected && !isLoading ? "text-[#fff]" : "text-[#f7f7f7]"
                  }`}
                >
                  Save
                </span>
              )}
              <CheckOutlined className="ml-1" />
            </Button>
          </div>
        ) : (
          <div className="flex justify-end items-center w-full">
            {!hideButtons && (
              <button
                className={`py-[10px] px-[15px] w-fit md:w-[120px] rounded-[12px]  
             flex items-center justify-center transition duration-300`}
                // onClick={isReadyToSchedule ? scheduleHandler : null}
                onClick={() => setscheduleMode(true)}
              >
                <span
                  // className={`text-sm font-[500] text-semibold ${isReadyToSchedule ? "text-[#111928]" : "text-[#9A9D9F]"}`}
                  className={`text-sm font-[500] text-semibold ${
                    isAnyPlatformSelected && !isLoading ? "text-[#111928]" : "text-[#9A9D9F]"
                  }`}
                >
                  Schedule
                </span>
              </button>
            )}
            {!hideButtons && (
              <button
                className={`py-[3px] md:py-[8px] px-[15px] w-fit md:w-[120px] rounded-[12px] border ${
                  isAnyPlatformSelected && !isLoading ? "border-[#9A9D9F]" : "border-[#D1D5DB]"
                } flex items-center justify-center transition duration-300 `}
                onClick={isAnyPlatformSelected && !isLoading ? postNowHandler : null}
                disabled={isAnyPlatformSelected && !isLoading ? false : true}
                style={{
                  border: isAnyPlatformSelected && !isLoading ? "1px solid #9A9D9F" : "1px solid #D1D5DB",
                }}
              >
                {isLoading ? (
                  <span className="text-sm flex items-center gap-2 font-[500] mr-2">
                    <Loader height="15px" width="15px" />
                    Loading...
                  </span>
                ) : (
                  <span
                    className={`text-sm font-[500] text-semibold mr-2 ${
                      isAnyPlatformSelected && !isLoading ? "text-[#111928]" : "text-[#9A9D9F]"
                    }`}
                  >
                    Post Now
                  </span>
                )}
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7049 0.296812C14.5963 0.188199 14.4585 0.113302 14.3083 0.0811387C14.1581 0.048975 14.0018 0.0609158 13.8582 0.115523L0.57597 5.14281H0.573314C0.420168 5.20171 0.288938 5.30645 0.197548 5.44273C0.106158 5.579 0.0590629 5.74017 0.0626954 5.90421C0.0663279 6.06825 0.120511 6.22717 0.217844 6.35927C0.315176 6.49137 0.450914 6.5902 0.606517 6.64226L0.62013 6.64658L5.17892 8.59328C5.26784 8.62027 5.36228 8.62347 5.45282 8.60255C5.54336 8.58164 5.62683 8.53734 5.6949 8.47408L13.0115 1.65648C13.0333 1.63468 13.0592 1.61739 13.0877 1.60559C13.1162 1.59379 13.1467 1.58771 13.1776 1.58771C13.2084 1.58771 13.2389 1.59379 13.2674 1.60559C13.2959 1.61739 13.3218 1.63468 13.3436 1.65648C13.3654 1.67828 13.3827 1.70416 13.3945 1.73265C13.4063 1.76113 13.4123 1.79166 13.4123 1.8225C13.4123 1.85333 13.4063 1.88386 13.3945 1.91234C13.3827 1.94083 13.3654 1.96671 13.3436 1.98851L6.52564 9.30183C6.46238 9.3699 6.41808 9.45337 6.39716 9.54391C6.37625 9.63445 6.37945 9.72889 6.40644 9.81781L8.3538 14.3793C8.35579 14.3859 8.35779 14.3919 8.36011 14.3982C8.46636 14.706 8.73531 14.9231 9.06036 14.9377H9.09357C9.25767 14.9387 9.41825 14.8902 9.55445 14.7987C9.69064 14.7071 9.79615 14.5767 9.85724 14.4244L14.8839 1.14582C14.9392 1.00214 14.9518 0.845493 14.9201 0.694822C14.8883 0.54415 14.8136 0.405907 14.7049 0.296812Z"
                    fill={isAnyPlatformSelected && !isLoading ? "#111928" : "#9A9D9F"}
                  />
                </svg>
              </button>
            )}

            <Button
              variant={isAnyPlatformSelected && !isLoading ? "blue" : "disabled"}
              onClick={isAnyPlatformSelected && !isLoading ? AddtoQueuehandler : null}
              className="ml-3"
            >
              {isLoading ? (
                <span className="text-sm flex items-center gap-2 font-[500] mr-2">
                  <Loader height="15px" width="15px" />
                  Loading...
                </span>
              ) : (
                <span
                  className={`text-sm font-[500] text-semibold flex justify-center items-center  ${
                    isAnyPlatformSelected && !isLoading ? "text-[#fff]" : "text-[#f7f7f7]"
                  }`}
                >
                  Add to Queue
                </span>
              )}
              <PlusOutlined className="ml-1" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublishingModalFooter;
