import axios from "axios";

/**
 * Uploads the file to the s3 server and adds it to assets table
 * @param file - The file to be uploaded
 * @param assetPlatform - "composer" | "instagram" | "twitter" | "linkedin" | "library"
 * @param assetPlatformId - The id of the platform's track you're uploading to.
 * @returns The data.data object or null if there was an error.
 */
const uploadFile = async (file, assetPlatform, assetPlatformId, type, text, setUploadPercentage) => {
  try {
    // Fetch presigned URL from your server
    const presignedUrlResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/asset/upload`,
      {
        filename: file.name,
        offset: file.size,
        platform: assetPlatform,
        platformId: assetPlatformId,
        lastChunk: false,
        type: type,
        text: text,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (presignedUrlResponse.data.status !== true) {
      console.error("Failed to get presigned URL");
      return null;
    }
    console.log(file);
    // Upload the file directly to S3
    try {
      await axios.put(presignedUrlResponse.data.presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadPercentage(percentCompleted);
        },
      });
    } catch (error) {
      console.log(error);
    }

    // Notify your server that the upload is complete
    const uploadCompleteResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/asset/upload`,
      {
        filename: file.name,
        offset: file.size,
        platform: assetPlatform,
        platformId: assetPlatformId,
        lastChunk: true,
        type: type,
        text: text,
        uploadedFile: presignedUrlResponse.data.uploadFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (uploadCompleteResponse.data.status === true) {
      return uploadCompleteResponse.data.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// const uploadFile = async (file, assetPlatform, assetPlatformId, type, text) => {
//   try {
//     const formData = new FormData();
//     formData.append("media", file);
//     formData.append("platform", assetPlatform);
//     formData.append("platformId", assetPlatformId);
//     if (text) {
//       // update type to text
//       formData.append("type", "document");
//       formData.append("text", text);
//     } else {
//       formData.append("type", type);
//     }
//     // console.log("formData", formData);
//     // return;
//     const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/asset/upload`, formData, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//       },
//     });
//     if (data.status === true) {
//       return data.data;
//     }
//     return null;
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// };

/**
 * Updates instaPost with the assets
 * @param assets - AssetId[]
 * @param id - the id of the track
 */
const updateInstaAssets = async (assets, id) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/insta/update-assets`,
      { data: assets, id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return data.data;
    }

    throw new Error("Something went wrong");
  } catch (error) {
    throw new Error(error);
  }
};

const updateLinkedInAssets = async (assets, id) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/linkedin/update-assets`,
      { data: assets, id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return data.data;
    }

    throw new Error("Something went wrong");
  } catch (error) {
    throw new Error(error);
  }
};

const updateDraftAssets = async (assets, id) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/draft/update-assets`,
      { data: assets, id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return data.assets;
    }

    throw new Error("Something went wrong");
  } catch (error) {
    throw new Error(error);
  }
};

const updateTwitterAssets = async (assets, postId, threadIndex) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/twitter/update-assets`,
      { assets, id: postId, threadIndex, action: "upload" },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return data.data;
    }

    throw new Error("Something went wrong");
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadMedia = async (
  files,
  assetPlatform,
  assetPlatformId,
  threadIndex,
  text = null,
  setUploadPercentage
) => {
  try {
    // console.log(files, "tepm", type)
    var newFiles = await Promise.all(
      files.map(async (file) => {
        if (!(file instanceof File)) {
          return file.id;
        }

        const newFile = await uploadFile(file, assetPlatform, assetPlatformId, file.type, text, setUploadPercentage);
        return newFile;
      })
    );
    console.log(newFiles);
    const newFileIds = newFiles.map((file) => file.id);
    if (assetPlatform === "library") {
      return newFiles;
    }

    if (assetPlatform === "twitter") {
      const twitterAssets = await updateTwitterAssets(newFileIds, assetPlatformId, threadIndex);
      const thread = twitterAssets.find((_, i) => i === threadIndex);

      return thread.assets ?? [];
    }

    if (assetPlatform === "instagram") {
      const data = await updateInstaAssets(newFileIds, assetPlatformId);
      return data;
    }

    if (assetPlatform === "linkedin") {
      const data = await updateLinkedInAssets(newFileIds, assetPlatformId);
      return data;
    }

    if (assetPlatform === "composer") {
      const draftAssets = await updateDraftAssets(newFileIds, assetPlatformId);
      return draftAssets ?? [];
    }
  } catch (error) {
    console.log(error);
  }
};
