import { useDispatch } from "react-redux";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitterIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedinIcon.svg";
import TimesWatched from "./TimesWatched";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FcDocument } from "react-icons/fc";
import { FaRobot } from "react-icons/fa";
import { formatTags, formatedDate } from "../../../utils/date";

import { Tooltip } from "antd";
const LibraryDocument = (props) => {
  const { item, profileId, isSelected } = props;
  const dispatch = useDispatch();
  const selectClickHandler = () => {
    console.log(isSelected);
    if (isSelected) {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
    } else {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: item.text });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: props.item.id });
    }
  };

  // console.log(item.type);
  if (item.status === "PROCESSING") {
    return (
      <div className="">
        <div className={`min-h-[8rem] opacity-70 p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}>
          <Skeleton count={3} />
          <div>
            <div className="text-[#1F2A37] flex text-[12px] mt-[20px]">
              processing | {formatedDate(item.updatedAt)}
              {item.type == "tweet" && (
                <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded-[12px] ml-auto mr-[10px]">
                  <TwitterIcon className="w-[14px] h-[14px]" fill="#1C64F2" />
                </div>
              )}
              {item.type == "document" && (
                <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded-[12px] ml-auto mr-[10px]">
                  <FcDocument size={14} color="#0c59f2" />
                </div>
              )}
              {/* <TimesWatched timesWatched={3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div class="asset-fake-border "></div>
      <div class="asset-content">
        <div class="asset-title">
          <div class="asset-type" title="Document">
            <i class="ri-article-line"></i>
          </div>
          <div class="asset-title-text">
            {item && item.text && item.text.substring(0, 40)} {item && item.text && item.text.length > 40 && "..."}
          </div>
        </div>
        <div class="asset-excerpt">
          {item && item.text && item.text.substring(0, 120)} {item && item.text && item.text.length > 120 && "..."}
        </div>
        <div class="asset-tags">
          {item.topics.map((topic) => (
            <div class="tag">
              <span class="tag-text">{topic}</span>
              <button class="remove-tag">
                <i class="ri-close-line"></i>
              </button>
            </div>
          ))}
        </div>
        <div class="asset-meta">
          <div class="asset-size meta-item">
            {item && item.text
              ? item.text.split(/\s+/).length + " words "
              : item.status == "PROCESSING" && "processing "}{" "}
          </div>
          <div class="asset-imported-date meta-item"> {formatedDate(item.updatedAt)}</div>
          <div class="asset-imported-date meta-item">{formatTags(item.tags)}</div>
          {item.prev && (
            <div className="flex gap-2 ml-auto items-center my-auto bg-[#fff] py-[5px] px-[6px]  rounded-[12px] mr-[10px]">
              <Tooltip title={"AI Generated"}>
                <FaRobot size={14} color="#373737" />
              </Tooltip>
            </div>
          )}
        </div>
        <div class="asset-page-content">
          <h1>The Best Way to Get the Word Out</h1>
          <p>
            Spend 80% less time promoting yourself online while reaching more customers with the ultimate omni-channel
            growth app.
          </p>
        </div>
      </div>
    </>
  );
};

export default LibraryDocument;
