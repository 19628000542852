import axios from "axios";
export const createDraft =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "CHANGE_LOADING_STATE" });
      //   const res = await axios.post(
      //     `${process.env.REACT_APP_BASE_URL}/user/signup/`,
      //     { email, password }
      //   );
    } catch (error) {
      dispatch({ type: "CHANGE_LOADING_STATE" });
      console.log(error);
    }
  };

export const updateDraft = (data) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/draft/update/`,
      { data },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
  } catch (error) {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    console.log(error);
  }
};

export const updateDraftTitle = (trackId, title, updatedManually, draftStatus) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });

    if (updatedManually === true) {
      sessionStorage.setItem("draftUpdatedManually", trackId);
    }

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/draft/update-title`,
      {
        trackId,
        title,
        updatedManually: updatedManually === true ? true : false,
        draftStatus: draftStatus || "",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    return true;
  } catch (error) {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    console.log(error);

    return false;
  }
};
export const transformDraft = (data) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/draft/transform`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res.data.status === true) {
      dispatch({ type: "ADD_TRACK", payload: res.data.track });
      dispatch({ type: "SET_CURRENT_TRACK", payload: res.data.track });
      return true;
    } else {
      return false;
    }
    dispatch({ type: "CHANGE_LOADING_STATE" });
  } catch (error) {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    console.log(error);

    return false;
  }
};
