import Loader from "./Loader";

const VARIANTS = {
  blue: "bg-[#1C64F2] hover:bg-blue-700",
  disabled: "bg-[#A4CAFE]",
};

const Button = (props) => {
  const { children, className, variant = "blue", loading = false, ...otherButtonAttributes } = props;

  return (
    <>
      {loading ? (
        <button
          className={`${VARIANTS[variant]} rounded-[8px] md:rounded-[12px] flex items-center justify-center w-24 h-10`}
        >
          <div role="status">
            <Loader height="20px" width="20px" />
            <span className="sr-only">Loading...</span>
          </div>
        </button>
      ) : (
        <button
          className={`${
            VARIANTS[variant]
          } transition duration-300 py-[7px] px-[15px] md:py-[8px] md:px-[15px] text-white rounded-[8px] md:rounded-[12px] flex items-center justify-center  ${
            className ?? ""
          }`}
          {...otherButtonAttributes}
          disabled={variant === "disabled"}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
