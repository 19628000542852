import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateTwitterPostValidState } from "../../redux/actions/stageActions";
import { RePurposeWithAI } from "../../redux/actions/trackActions";
import { updateTwitterPost } from "../../utils/saveAllPosts";
import { uploadMedia } from "../../utils/uploadTos3";
import Button from "../utils/Button";
import ScheduleButtonsFotter from "./ScheduleButtonsFotter";
import TwitterTextarea from "./TwitterTextarea";
import instaDefault from "../../assets/Images/Navbar/instagram_default.jpg";
import axios from "axios";
import { GiFairyWand } from "react-icons/gi";
import { Tooltip } from "antd";
import Skeleton from "react-loading-skeleton";

function stripHtml(html) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const TwitterPreview = (props) => {
  const { id: trackId } = useParams();
  const { data, setSavedTime, isInsideRescheduleModal, isdeleteActive, setData } = props;
  const twitterPost = data.twitterPost;
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeZomeSelected, setTimeZomeSelected] = useState("GMT");
  let date = new Date();
  let fullDate = date.toLocaleString(undefined, { month: "short", day: "numeric" });

  const { user } = useSelector((state) => state.auth);
  const [threads, setThreads] = useState([{ text: "", assets: [] }]);
  const [isSetAsDraft, setIsSetAsDraft] = useState(null);

  const { twitter: isPostValid } = useSelector((state) => state.stagePostValidation);
  const { currentTrack } = useSelector((state) => state.tracks);

  const savePost = (postId, threads) => {
    updateTwitterPost(postId, threads);
    if (setSavedTime) {
      setSavedTime(new Date());
    }
  };

  const [focuesdEditor, setFocusedEditor] = useState(0);

  const updateMarkAsDraft = async (markAsDraft) => {
    if (isInsideRescheduleModal) return;

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/draft/update-set-as-draft`,
        { postId: currentTrack.twitterPostId, platform: "twitter", markAsDraft },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status !== true) return;

      setIsSetAsDraft(data.markAsDraft);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const textareaChangeHandler = (updatedValue, index, assets) => {
    const newThreads = [...threads.slice(0, index), { text: updatedValue, assets }, ...threads.slice(index + 1)];
    savePost(data.twitterPost.id, newThreads);
    setThreads(newThreads);
  };

  const removeThread = (index) => {
    const newThreads = [...threads.slice(0, index), ...threads.slice(index + 1)];
    savePost(data.twitterPost.id, newThreads);
    const threadIndex = index === 0 ? 0 : index - 1;

    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }
    setThreads(newThreads);
    setFocusedEditor(threadIndex);
  };

  useEffect(() => {
    dispatch(updateTwitterPostValidState(checkAllThreadsValid() ? true : false));
  }, [threads]);

  const checkAllThreadsValid = () => {
    for (const thread of threads) {
      if (stripHtml(thread.text).length > 280) {
        return false;
      }
    }

    return true;
  };

  const addNewThread = (index) => {
    const newThreads = [...threads.slice(0, index + 1), { text: "", assets: [] }, ...threads.slice(index + 1)];
    setThreads(newThreads);
    savePost(data.twitterPost.id, newThreads);
    // Have focus on the newly created thread
    setFocusedEditor(newThreads.length - 1);

    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }
  };
  const deleteNode = (index) => {
    // remove the node from the array
    const newThreads = [...threads.slice(0, index), ...threads.slice(index + 1)];
    setThreads(newThreads);
    savePost(data.twitterPost.id, newThreads);
    // Have focus on the newly created thread
    setFocusedEditor(newThreads.length - 1);

    if (isSetAsDraft === true) {
      updateMarkAsDraft(false);
    }
  };

  const updloadTwitterImages = async (uploadedFiles, threadId) => {
    const data = await uploadMedia(uploadedFiles, "twitter", twitterPost.id, threadId);

    return data;
  };

  useEffect(() => {
    if (!data.twitterPost.body_json) return;
    setThreads(data.twitterPost.body_json);
    if (data.twitterPost.scheduledTime) {
      setSelectedDate({
        day: new Date(data.twitterPost.scheduledTime).getDate(),
        month: new Date(data.twitterPost.scheduledTime).getMonth(),
        year: new Date(data.twitterPost.scheduledTime).getFullYear(),
      });
      setSelectedTime({
        hour: new Date(data.twitterPost.scheduledTime).getHours(),
        minute: new Date(data.twitterPost.scheduledTime).getMinutes(),
      });
    }
  }, [data.twitterPost.body_json]);

  useEffect(() => {
    if (!currentTrack || !currentTrack.twitterPost) return;
    setIsSetAsDraft(currentTrack.twitterPost.markedAsDraft);
  }, []);
  // console.log(threads);
  const datePickerHandler = (e) => {
    if (e === null) {
      setSelectedDate(null);
      return;
    }

    setSelectedDate({
      day: e.$D,
      month: e.$M,
      year: e.$y,
    });
  };
  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  const checkBoxChangeHandler = async ({ checkbox, date, time }) => {
    var selectedDateVar = date ? date : selectedDate;
    var selectedTimeVar = time ? time : selectedTime;
    if (checkbox) {
      if (!selectedDateVar || !selectedTimeVar) {
        console.log("Please select date and time");
        return;
      }
      const date = new Date(
        selectedDateVar.year,
        selectedDateVar.month,
        selectedDateVar.day,
        selectedTimeVar.hour,
        selectedTimeVar.minute,
        0
      );
      if (date) {
        // make api to save this info
        const resdata = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
          {
            postId: data?.twitterPost.id,
            trackId: currentTrack.id,
            platform: "twitter",
            schedule: date,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (resdata.data.status === true) {
          setData(resdata.data.track);
        }
      }
    }
  };
  const cancelSchedule = async () => {
    const resdata = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
      {
        postId: data?.twitterPost.id,
        trackId: currentTrack.id,
        platform: "twitter",
        schedule: null,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (resdata.data.status === true) {
      setData(resdata.data.track);
    }
  };

  return (
    <>
      <div className="pt-2 animate-fade-in p-2">
        {threads &&
          threads.length > 0 &&
          threads.map((item, index) => {
            return (
              <div
                key={index}
                className="flex w-full "
                style={{ opacity: focuesdEditor === index ? 1 : 0.5 }}
                onClick={() => setFocusedEditor(index)}
              >
                <div className="flex flex-col items-center mr-[12px]">
                  <div>
                    <div className="flex items-center justify-center w-7 h-7 md:w-10 md:h-10">
                      {props.profile.twitter ? (
                        <img
                          className="w-7 h-7 md:w-10 md:h-10 rounded-full"
                          src={props.profile.twitter.profile_image_url}
                          alt="Avatar"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = instaDefault;
                          }}
                        />
                      ) : (
                        <img className="w-7 h-7 md:w-10 md:h-10 rounded-full" src={instaDefault} alt="Avatar" />
                      )}
                    </div>
                  </div>
                  {index !== threads.length - 1 && <div className="w-px h-full bg-gray-300" />}
                </div>
                <div className="mb-[1.5rem] w-full">
                  <div className="flex justify-between items-center mb-3">
                    <div className="flex max-w-full items-center">
                      <span className="text-[#191c1f] font-[600] whitespace-nowrap overflow-hidden text-ellipsis">
                        {props.profile.twitter ? props.profile.twitter.name : user && user?.fullName}
                      </span>
                      <span className="text-[#536471] text-sm ml-[3px] whitespace-nowrap overflow-hidden text-ellipsis">
                        @
                        {props.profile.twitter
                          ? props.profile.twitter.username
                          : user && user?.fullName.replaceAll(" ", ".").toLowerCase()}
                      </span>
                      <span className="text-[#536471] text-sm mx-1">·</span>
                      <span className="text-[#536471] text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                        {fullDate}
                      </span>
                    </div>
                    {focuesdEditor === index &&
                      threads.length > 1 &&
                      item.assets.length === 0 &&
                      item.text &&
                      item.text.length === 0 && (
                        <div className="cursor-pointer" onClick={() => removeThread(index)}>
                          <svg className=" w-[14px]" viewBox="0 0 24 24" aria-hidden="true" fill="#536471">
                            <g>
                              <path d="M10.59 12L4.54 5.96l1.42-1.42L12 10.59l6.04-6.05 1.42 1.42L13.41 12l6.05 6.04-1.42 1.42L12 13.41l-6.04 6.05-1.42-1.42L10.59 12z"></path>
                            </g>
                          </svg>
                        </div>
                      )}
                  </div>
                  {data.twitterPost.status == "processing" || item.text == "...loading..." ? (
                    // <div className="flex items-center justify-center w-full h-[200px]">
                    <Skeleton count={3} />
                  ) : (
                    // </div>
                    <TwitterTextarea
                      value={item}
                      index={index}
                      checkAllThreadsValid={checkAllThreadsValid}
                      changeHandler={(updatedValue, assets) => {
                        if (assets) {
                          textareaChangeHandler(updatedValue, index, assets);
                        } else {
                          textareaChangeHandler(updatedValue, index, item.assets);
                        }
                        if (isSetAsDraft === true) updateMarkAsDraft(false);
                      }}
                      updloadTwitterImages={updloadTwitterImages}
                      postId={data.twitterPost.id}
                      addFocus={() => setFocusedEditor(index)}
                      isFocused={focuesdEditor === index}
                      addNewThread={addNewThread}
                      updateMarkAsDraft={updateMarkAsDraft}
                      improveHookActive={threads.length > 1 && index == 0}
                      deleteActive={threads.length > 1}
                      trackId={data?.twitterPost?.trackId}
                      focuesdEditor={focuesdEditor}
                      setFocusedEditor={setFocusedEditor}
                      deleteNode={deleteNode}
                    />
                  )}
                </div>
              </div>
            );
          })}
        {data.twitterPost.status != "processing" && (
          <div className="mx-3">
            <div className="bg-[#E4E4E7] w-full h-[1px] m-auto rounded-[10px] mt-[15px]"></div>

            <ScheduleButtonsFotter
              isChecked={data.twitterPost.scheduledTime ? true : false}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              checkBoxChangeHandler={checkBoxChangeHandler}
              datePickerHandler={datePickerHandler}
              timePickerHandler={timePickerHandler}
              data={data.twitterPost}
              cancelSchedule={cancelSchedule}
            />
            <div className="set-as-draft-btn-container flex justify-between">
              {isdeleteActive && (
                <button className="text-red-600 text-sm font-medium ml-0 mt-1" onClick={props.deleteItem}>
                  Delete
                </button>
              )}
              <Tooltip title={"Repurpose with AI"}>
                <button
                  className="ml-auto mx-2 flex items-center text-sm font-medium"
                  onClick={() => {
                    dispatch(
                      RePurposeWithAI({
                        postId: data?.twitterPost.id,
                        platform: "twitter",
                        trackId: data?.twitterPost?.trackId,
                      })
                    );
                  }}
                >
                  <GiFairyWand className="mr-1" />
                  <span className="">AI Repurpose</span>
                </button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TwitterPreview;
