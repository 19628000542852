import Loader from "../../utils/Loader";
import { useState } from "react";
import { Document, Packer, Paragraph } from "docx";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";

const FileImport = ({ setfileText, updloadTwitterImages, isCurrentlyUploading, setIsCurrentlyUploading }) => {
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const onFileDrop = async (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    uploadFiles({
      currentTarget: {
        files: files,
      },
    });
    setHighlight(false);
  };
  const extractTextFromDocx = async (arrayBuffer) => {
    const zip = new PizZip(arrayBuffer);
    const doc = new Docxtemplater().loadZip(zip);
    const text = doc
      .getFullText()
      .split("\n")
      .map((line) => line.trim())
      .join("\n");
    return text;
  };

  const extractText = async (file) => {
    return new Promise(async (resolve) => {
      if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        const buffer = await file.arrayBuffer();
        const text = await extractTextFromDocx(buffer);
        resolve(text);
      } else if (file.type === "text/plain") {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsText(file);
      } else {
        resolve("");
      }
    });
  };
  const uploadFiles = async (e) => {
    var temptext = "";
    console.log(e.currentTarget.files, "files");
    const files =
      Array.from(e.currentTarget.files)
        .filter(
          (file) =>
            file.type.startsWith("text/") ||
            file.type.startsWith("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
        )
        .slice(0, 4) || [];
    setIsCurrentlyUploading(true);
    setIsCurrentlyUploading(false);
    setIsCurrentlyUploading(true);
    for (const file of files) {
      if (
        file.type.startsWith("application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
        file.type.startsWith("text/plain")
      ) {
        const text = await extractText(file);
        console.log(text, "Extracted text");
        file.generatedTextContent = text;
        setfileText(text);
        temptext = text;
        // Process the extracted text
      } else {
        // Handle other file types
      }
    }
    const twitterAssets = (await updloadTwitterImages(files, temptext)) || [];
    setUploadedMedia(files);
    console.log(files);
    setIsCurrentlyUploading(false);
  };

  return (
    <div class="page-content-inside">
      {!isCurrentlyUploading && !uploadedMedia.length > 0 && (
        <>
          <button
            class="file-upload"
            onClick={() => document.getElementById("twitter-doc-upload").click()}
            onDrop={onFileDrop}
            onDragOver={(e) => {
              e.preventDefault();
              setHighlight(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setHighlight(false);
            }}
            style={{
              backgroundColor: highlight ? "lightgray" : "",
            }}
          >
            <div class="file-upload-title">Upload Document</div>
          </button>
          <input
            type="file"
            multiple={false}
            accept=".docx,.txt"
            id={`twitter-doc-upload`}
            className="hidden"
            onChange={uploadFiles}
          />
        </>
      )}
      {uploadedMedia.length > 0 && (
        <div
          className={`mt-3 h-full flex items-center gap-[1rem] 
          `}
        >
          {uploadedMedia.map((media, i) => {
            return (
              <div
                className="relative flex rounded-[12px] overflow-hidden w-full h-full items-center  object-cover bg-[#F9FAFB] p-2"
                key={i}
              >
                <img
                  src="https://freevector.co/wp-content/uploads/2012/10/25307-text-file1.png"
                  alt=""
                  className="w-auto h-[4rem] mx-auto object-cover my-auto items-center"
                />
                <div
                  className="absolute top-[10px] left-[10px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full"
                  onClick={() => {
                    setUploadedMedia([]);
                  }}
                >
                  <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px]">
                    &times;
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isCurrentlyUploading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loader height="20px" width="20px" loaderFill="#333" color="#000" className="mt-[1rem]" />
        </div>
      )}
    </div>
  );
};

export default FileImport;
