import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import uploadImage from "../../assets/instagram-upload.png";
import Button from "../utils/Button";

const InstagramMediaUpload = (props) => {
  const {
    setInstagramStage,
    uploadedFiles,
    setUploadedFiles,
    uploadFiles,
    updateMarkAsDraft,
    setIsCurrentlyUploading,
  } = props;

  const addFile = useCallback(async (files) => {
    updateMarkAsDraft(false);
    setIsCurrentlyUploading(true);
    setInstagramStage("preview");
    await uploadFiles([...files]);
    setIsCurrentlyUploading(false);
    setUploadedFiles([...files]);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const filteredFiles = Array.from(acceptedFiles).filter((file) =>
        file.type.startsWith("image")
      );
      addFile(filteredFiles);
    },
    [addFile, setInstagramStage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".jpg", ".jpeg", ".png"],
    },
  });

  return (
    <div className="relative">
      {uploadedFiles.length > 0 && (
        <span
          className="absolute top-[10px] right-[25px] text-[12px] text-[#3f3f46] font-normal cursor-pointer underline"
          onClick={(e) => setInstagramStage("preview")}
        >
          Cancel
        </span>
      )}
      <div
        className="w-full h-[400px] border border-[#E4E4E7] rounded-[10px] flex flex-col justify-center items-center gap-[40px] p-5 m-auto"
        {...getRootProps()}
      >
        <img src={uploadImage} alt="Upload" />
        <p>Drag photos and videos here</p>
        <Button>
          <span>Upload from Computer</span>
        </Button>
        <input
          className="hidden"
          accept="image/png, image/gif, image/jpeg"
          multiple={true}
          {...getInputProps()}
        />
      </div>
    </div>
  );
};

export default InstagramMediaUpload;
