import axios from "axios";
import { failAlert, successAlert } from "../../utils/sweetAlert";

export const handleSignup =
  ({ email, fullName, referralCode }) =>
    async (dispatch) => {
      try {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        console.log(process.env)
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/signup/`, { email, fullName, referralCode });

        if (res.status === 200) {
          window.location.href = `/confirm-email?email=${email}`;
        }
        return [true, null];
      } catch (error) {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        return [false, error.response?.data?.message];
      }
    };

export const getLoggedUserInfo = () => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/get-user/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (res.status === 200) {
      // localStorage.setItem("accessToken", res.data.accessToken);
      dispatch({ type: "CHANGE_LOADING_STATE" });
      dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
    }
  } catch (error) {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    console.log(error);
  }
};

export const getUserBillingInfo = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/billing-info`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    return data ?? {};
  } catch (error) {
    console.log(error);
  }
};

export const handleLogin =
  ({ email }) =>
    async (dispatch) => {
      try {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login/`, { email });
        if (res.status === 200) {
          window.location.href = `/confirm-email?email=${email}`;
        }
        return [true, null];
      } catch (error) {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        dispatch({ type: "CHANGE_AUTH_FALSE" });
        return [false, error.response?.data?.message];
      }
    };

export const verifyUser = (token) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/verify/`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      localStorage.setItem("accessToken", res.data.accessToken);
      dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
      dispatch({
        type: "SET_CURRENT_TRACK",
        payload: res.data.user.profiles[0].tracks[0],
      });

      window.location.href = `/composer/${res.data.user.profiles[0].tracks[0].id}`;
      return true;
    }
  } catch (error) {
    // failAlert({ title: error?.response?.data?.message ?? "Sorry something went wrong" });
    dispatch({ type: "CHANGE_AUTH_FALSE" });
    return false;
  }
};
export const verifyInvite = (token) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/verify-invite/`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      localStorage.setItem("accessToken", res.data.accessToken);
      dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
      dispatch({
        type: "SET_CURRENT_TRACK",
        payload: res.data.user.profiles[0].tracks[0],
      });

      window.location.href = `/composer/${res.data.user.profiles[0].tracks[0].id}`;
      return true;
    }
  } catch (error) {
    // failAlert({ title: error?.response?.data?.message ?? "Sorry something went wrong" });
    dispatch({ type: "CHANGE_AUTH_FALSE" });
    return false;
  }
};
export const referredUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/referred-user/`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res.status === 200) {
      dispatch({ type: "CHANGE_REFERRED_USER", payload: res.data.referredUsers });
      return true;
    }
  } catch (error) {
    // failAlert({ title: error?.response?.data?.message ?? "Sorry something went wrong" });
    dispatch({ type: "CHANGE_AUTH_FALSE" });
    return false;
  }
};
export const handleSuperAdmin = () => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/user/super-admin/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.status === 200) {
      dispatch({ type: "CHANGE_SUPER_ADMIN_DATA", payload: res.data.superAdmin });
      return true;
    }
  } catch (error) {
    // failAlert({ title: error?.response?.data?.message ?? "Sorry something went wrong" });
    dispatch({ type: "CHANGE_AUTH_FALSE" });
    return false;
  }
};
export const saveFinalAuthLinkedin = (data) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_LOADING_STATE" });
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/linkedin/save-auth/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.status === 200) {
      successAlert({ title: "Linkedin Successfully saved" });

      return true;
    }
  } catch (error) {
    failAlert({ title: error?.response?.data?.message ?? "Sorry something went wrong" });
    // dispatch({ type: "CHANGE_AUTH_FALSE" });
    return false;
  }
};
// ----------------------------------------------------------------

export const handleRegister =
  ({ email, fullName, password }) =>
    async (dispatch) => {
      try {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/register/`, { email, fullName, password });

        if (res.status === 200) {
          localStorage.setItem("accessToken", res.data.accessToken);
          dispatch({ type: "CHANGE_LOADING_STATE" });
          dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
          dispatch({
            type: "SET_CURRENT_TRACK",
            payload: res.data.user.profiles[0].tracks[0],
          });

          window.location.href = `/composer/${res.data.user.profiles[0].tracks[0].id}`;
        }
        return [true, null];
      } catch (error) {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        return [false, error.response?.data?.message];
      }
    };

export const handleSignin =
  ({ email, password }) =>
    async (dispatch) => {
      try {
        dispatch({ type: "CHANGE_LOADING_STATE" });
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login/`, { email, password });
        console.log(res, "************")
        if (res.status === 200) {
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          // console.log(res.data.user, "res.data.user")
          dispatch({ type: "CHANGE_LOADING_STATE" });
          dispatch({ type: "CHANGE_AUTH_TRUE", payload: res.data.user });
          dispatch({
            type: "SET_CURRENT_TRACK",
            payload: res.data.user.profiles[0]?.tracks[0],
          });

          // console.log(res.data, "res.data.user?.profiles[0]?.tracks")


          if (res.data.user?.profiles[0]?.tracks && res.data.user?.profiles[0].tracks.length > 0) {
            // console.log('testing')
            window.location.href = `/composer/${res.data.user.profiles[0].tracks[0].id}`;
            return [true, null];
          }

          window.location.href = `/${res.data.user.id}/tracks`;
        }
        return [true, null];
      } catch (error) {
        console.log(error);
        dispatch({ type: "CHANGE_LOADING_STATE" });
        dispatch({ type: "CHANGE_AUTH_FALSE" });
        return [false, error.response?.data?.message];
      }
    };

const handleTeamProfileRelationship = (user) => {
  // var plan = user.stripeCustomer.plan;
  var tmpuser = user;
  // if (plan === "INDIVIDUAL_PLAN") {
  for (const tm of user.teamMembers) {
    tmpuser = {
      ...user,
      profiles: tm.team.profiles,
    };
    break;
  }
  // } else {
  for (const tm of user.teamMembers) {
    tmpuser = {
      ...user,
      profiles: tm.team.profiles,
    };
    break;
    // }
  }
  // console.log(tmpuser);
  return tmpuser;
};
