import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import _, { set } from "lodash";
import edjsHTML from "editorjs-html";
import { updateWebflowPost } from "../../utils/saveAllPosts";
const edjsParser = edjsHTML();
function WebflowPreview(props) {
  const { data, profile, setSavedTime } = props;
  const [columnMeta, setColumnMeta] = React.useState({
    title: "",
    description: "",
  });
  const [block, setBlock] = React.useState([]);
  const [json, setJson] = React.useState([]);
  const ejInstance = useRef();

  function convertToHtml(dataArray) {
    let htmlString = "";

    for (let i = 0; i < dataArray.length; i++) {
      const data = dataArray[i].data;

      switch (dataArray[i].type) {
        case "header":
          htmlString += `<h${data.level}>${data.text}</h${data.level}><br />`;
          break;
        case "paragraph":
          htmlString += `<p>${data.text}</p> <br />`;
          break;
        case "list":
          const listType = data.style === "ordered" ? "ol" : "ul";
          htmlString += `<${listType}>`;
          for (let j = 0; j < data.items.length; j++) {
            htmlString += `<li>${data.items[j]}</li>`;
          }
          htmlString += `</${listType}><br />`;
          break;
        default:
          break;
      }
    }

    return htmlString;
  }

  const initEditor = (draft) => {
    const editor = new EditorJS({
      holder: "webflowEditor",
      tools: {
        header: Header,
        list: List,
      },
      data: {
        time: new Date().getTime(),
        blocks: draft.body_json,
      },
      onReady: () => {
        ejInstance.current = editor;
      },
      placeholder: "Let`s write an awesome story",
      onChange: _.debounce(() => saveDraft(), 200),
    });
  };

  const saveDraft = async () => {
    var data = [];

    ejInstance.current?.save().then(async (outputData) => {
      setJson(outputData.blocks);
      let html = edjsParser.parse(outputData);
      setBlock(html.join("<br />"));
    });
  };

  const savePost = async () => {
    if (!data || data.WebflowPost.length === 0) {
      return;
    }
    const postId = data.WebflowPost[0].id;
    var htmlData = [];
    await ejInstance.current?.save().then(async (outputData) => {
      console.log(outputData);
      htmlData = outputData.blocks;
    });

    updateWebflowPost(postId, htmlData, block, columnMeta);
    if (setSavedTime) {
      setSavedTime(new Date());
    }
  };

  useEffect(() => {
    if (data && data.WebflowPost.length > 0) {
      initEditor(data.WebflowPost[0]);

      if (data.WebflowPost[0].columnMeta) {
        setColumnMeta(data.WebflowPost[0].columnMeta);
      }

      //   setRenderedData(html);
    }
  }, []);

  useEffect(() => {
    if (!columnMeta.title) {
      return;
    }

    savePost();
  }, [columnMeta]);

  useEffect(() => {
    if (block.length > 0) {
      savePost();
    }
  }, [block]);

  // handle Input

  const handleInputs = (e) => {
    setColumnMeta({ ...columnMeta, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] p-5 h-fit mx-2 md:mx-0 w-fit md:w-2/4">
      <div className="mt-3">
        <label htmlFor="email" className="text-[#4E5674] font-[500] font-Inter">
          Title
        </label>
        <div className="mb-4 mt-1 flex items-center rounded-lg border-2 py-2 px-3">
          <input
            onChange={(e) => handleInputs(e)}
            value={columnMeta.title}
            name="title"
            className="border-none outline-none w-full font-Inter"
            type="text"
            id="title"
            placeholder="Title"
          />
        </div>
      </div>

      <div className="mt-3 w-full">
        <label htmlFor="email" className="text-[#4E5674] font-[500] font-Inter">
          Content
        </label>
        <div className="mb-4 mt-1 bg-gray-100 w-full flex items-center rounded-lg border-2 py-2 px-3">
          <div id="webflowEditor" className="w-full"></div>
        </div>
      </div>
    </div>
  );
}

export default WebflowPreview;
