import axios from "axios";
import { useEffect, useState, useRef } from "react";
import Skeleton from "react-loading-skeleton";

const contentWordLimit = 2200;

function stripHtml(html) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const InstagramEdit = (props) => {
  const { user, data, setsavedTime, updateMarkAsDraft } = props;
  const [value, setValue] = useState("");
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (!data?.instaPost.body_json.texts) return;
    var text = data?.instaPost.body_json.texts;

    text = text.replace(/<br ?\/?>/g, "\n");
    text = stripHtml(text);

    setValue(stripHtml(text));
  }, [data.instaPost]);

  useEffect(() => {
    //to resize the textarea on load
    if (!textAreaRef.current) return;
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  const handleOnChange = async (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    if (e.target.value.length >= contentWordLimit) {
      setValue(e.target.value.slice(0, contentWordLimit));
    } else {
      setValue(e.target.value);
    }

    updateMarkAsDraft(false);

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/track/insta/update`,
        { data: e.target.value, id: data.instaPost.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setsavedTime(new Date());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-[12px]">
      <span className="text-[#27272A] font-semibold text-[16px]">
        {props.profile?.instagram
          ? props.profile.instagram.instaDetails.username
          : user && user?.fullName.replaceAll(" ", ".").toLowerCase()}
      </span>
      {data.instaPost.status == "processing" ? (
        <Skeleton count={3} />
      ) : (
        <textarea
          className="w-full text-sm border-0 outline-none resize-none !min-h-max"
          ref={textAreaRef}
          value={value}
          onChange={handleOnChange}
          placeholder="What's happening?"
        />
      )}

      <div className="text-right mr-[20px]">
        <span className="text-[12px] text-[#c7c7c7] hover:text-[#262626] cursor-pointer transition duration-500">
          <span>{value.length}</span>&nbsp;/ 2,200
        </span>
      </div>
    </div>
  );
};

export default InstagramEdit;
