import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaRobot } from "react-icons/fa";
import { Tooltip } from "antd";
import { GiWireCoil } from "react-icons/gi";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitterIcon.svg";
import { formatedDate } from "../../../utils/date";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function formatTags(tags) {
  if (!tags || tags.length === 0) return "";
  if (tags.length === 1) return tags[0];

  let lastTag = tags[tags.length - 1];
  let otherTags = tags.slice(0, tags.length - 1);
  return `${otherTags.join(", ")} & ${lastTag}`;
}
const LibraryTwitterThread = (props) => {
  const { isSelected, item: thread } = props;

  var textlength = 0;
  const dispatch = useDispatch();
  const { profileId } = useParams();

  const selectClickHandler = () => {
    let data = "";

    thread.tweet.forEach((item) => {
      data += `${item.text}\n`;
    });

    if (isSelected) {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
    } else {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: data });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: thread.id });
    }
  };
  if (thread.status === "PROCESSING") {
    return (
      <div className="">
        <div
          className={`min-h-[8rem] opacity-70 w-full p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}
        >
          {/* {thread.tweet.map((item, index) => (
          <div key={item.id}>
            <p className="font-[500]">{textlength < 160 ? item.text : item.text.substring(0, 160)}</p>
            {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />}
          </div>
        ))} */}
          <Skeleton count={3} />

          <div>
            <div className="text-[#1F2A37] flex text-[12px] mt-[20px]">
              processing | {formatedDate(thread.updatedAt)}
              {/* {item.type == "tweet" && (
                <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded-[12px] ml-auto mr-[10px]">
                  <TwitterIcon className="w-[14px] h-[14px]" fill="#1C64F2" />
                </div>
              )}
              {item.type == "document" && (
                <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded-[12px] ml-auto mr-[10px]">
                  <FcDocument size={14} color="#0c59f2" />
                </div>
              )} */}
              {/* <TimesWatched timesWatched={3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div class="asset-fake-border "></div>
      <div class="asset-content">
        <div class="asset-title">
          <div class="asset-type" title="thread">
            <GiWireCoil color="#000" fontWeight={900} size={14} />
          </div>
          <div class="asset-title-text">
            {thread && thread.tweet && thread.tweet[0].text.substring(0, 40)}{" "}
            {thread && thread.tweet && thread.tweet[0].text.length > 40 && "..."}
          </div>
        </div>
        <div class="asset-excerpt">
          {thread && thread.tweet[0].text && thread.tweet[0].text.substring(0, 120)}{" "}
          {thread && thread.tweet[0].text && thread.tweet[0].text.length > 120 && "..."}
        </div>
        <div class="asset-tags">
          {thread.topics.map((topic) => (
            <div class="tag">
              <span class="tag-text">{topic}</span>
              <button class="remove-tag">
                <i class="ri-close-line"></i>
              </button>
            </div>
          ))}
        </div>
        <div class="asset-meta">
          <div class="asset-size meta-item">{thread.tweet.length > 0 && thread.tweet.length + " tweets "}</div>
          <div class="asset-imported-date meta-item">{formatedDate(thread.updatedAt)}</div>
          <div class="asset-imported-date meta-item">{formatTags(thread.tags)}</div>
          {thread.prev && (
            <div className="flex gap-2 ml-auto items-center my-auto bg-[#fff] py-[5px] px-[6px]  rounded-[12px] mr-[10px]">
              <Tooltip title={"AI Generated"}>
                <FaRobot size={14} color="#373737" />
              </Tooltip>
            </div>
          )}
        </div>
        <div class="asset-page-content">
          <h1>The Best Way to Get the Word Out</h1>
          <p>
            Spend 80% less time promoting yourself online while reaching more customers with the ultimate omni-channel
            growth app.
          </p>
        </div>
      </div>
    </>
  );
  return (
    <div>
      <div
        className={`bg-white border-[2px] 
        ${isSelected ? "border-[#1C64F2]" : "border-[#e4e4e7]"} 
        rounded-[3px] shadow-[0_1px_2px_rgba(0,0,0,0.06)] cursor-pointer w-[400px] p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}
        onClick={selectClickHandler}
      >
        {/* {thread.tweet.map((item, index) => (
          <div key={item.id}>
            <p className="font-[500]">{textlength < 160 ? item.text : item.text.substring(0, 160)}</p>
            {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />}
          </div>
        ))} */}
        {thread && thread.tweet.length > 0 && (
          <div>
            <p className="font-[500]">
              {/* {console.log(thread.tweet[0].text)} */}
              {thread.tweet[0].text && thread.tweet[0].text.length > 220
                ? thread.tweet[0].text.substring(0, 220)
                : thread.tweet[0].text}{" "}
              ...
            </p>
            {/* {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />} */}
          </div>
        )}

        <div>
          <div className="text-[#1F2A37] flex text-[12px] mt-[20px]">
            {thread.tweet.length > 0 && thread.tweet.length + " tweets |"} {formatedDate(thread.updatedAt)}
            <div className="flex gap-[4px] bg-[#EBF5FF] py-[4px] px-[6px] rounded-[12px] ml-auto mr-[10px]">
              <TwitterIcon className="w-[14px] h-[14px]" fill="#1C64F2" />

              <GiWireCoil color="#0c59f2" fontWeight={900} size={13} />
              {/* <LinkedInIcon className="w-[14px] h-[14px]" fill="#1C64F2" /> */}
            </div>
            {/* <TimesWatched timesWatched={3} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryTwitterThread;
