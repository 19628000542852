/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteSingleTrack, duplicateSingleTrack } from "../../redux/actions/trackActions";
import { confirmationModal, failAlert, successAlert } from "../../utils/sweetAlert";
import { getLoggedUserInfo } from "../../redux/actions/authActions";
import Button from "../utils/Button";
import { updateDraftTitle } from "../../redux/actions/draftActions";
import axios from "axios";

const Dots = (props) => {
  const { trackId, profileId, title, getAllTracks, draft, schedule, ...buttonAttributes } = props;

  const dispatch = useDispatch();
  const [isdropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newName, setNewName] = useState(title ?? "");

  const duplicateTrack = async () => {
    await dispatch(duplicateSingleTrack({ trackId, profileId }));
    await Promise.all([getAllTracks(), dispatch(getLoggedUserInfo())]);
  };

  const deleteTrack = () => {
    confirmationModal({}, async () => {
      await dispatch(deleteSingleTrack(trackId));
      await Promise.all([getAllTracks(), dispatch(getLoggedUserInfo())]);
    });
  };

  const cancelSchedule = async () => {
    confirmationModal({ confirmButtonText: "Yes, cancel schedule" }, async () => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/cancel-schedule`,
          { trackId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (data.status === true) {
          successAlert({ title: "Successfully canceled schedule" });
          getAllTracks();
        } else {
          throw Error("Sorry, something went wrong");
        }
      } catch (error) {
        console.log(error);
        failAlert({ title: "Sorry, something went wrong" });
      }
    });
  };

  const renameDraft = async (e) => {
    e.preventDefault();

    const updateStatus = await dispatch(updateDraftTitle(trackId, newName, true, draft.status));

    setIsModalOpen(false);

    if (updateStatus !== true) {
      failAlert({ title: "Sorry, something went wrong" });
      return;
    }

    await Promise.all([getAllTracks(), dispatch(getLoggedUserInfo())]);
  };

  return (
    <>
      {isModalOpen === true && (
        <div
          className="fixed inset-0 z-[199] min-h-screen min-w-screen flex justify-center items-center"
          aria-label="modal"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-60 transition-opacity h-100 z-[190]"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="relative z-[199] bg-white rounded-[10px] p-[40px] pb-[15px] pr-[15px] w-[650px] show-modal">
            <h3 className="text-[30px] font-semibold">Rename the track</h3>
            <p className="text-[#6B7280] text-[16px] mb-[10px]">Provide a new name for the draft.</p>

            <div>
              <input
                type="text"
                value={newName}
                className="relative block w-full sm:text-sm px-[20px] py-[13px] h-[40px] bg-[#F9FAFB] border border-[#E5E7EB] rounded-[12px]"
                placeholder="Draft title"
                onChange={(e) => setNewName(e.target.value)}
              />
            </div>

            <form className="flex justify-end mt-[30px]" onSubmit={renameDraft}>
              <button
                className="py-[10px] px-[15px] w-[120px] rounded-[12px] border border-[#D1D5DB] flex items-center justify-center"
                onClick={() => setIsModalOpen(false)}
              >
                <span className="text-sm font-[500] text-semibold text-[#111928]">Cancel</span>
              </button>
              <Button variant="blue" className="ml-3 gap-[5px]">
                <span className="text-sm font-[500] mr-2">Rename</span>
              </Button>
            </form>
          </div>
        </div>
      )}
      <button
        className="w-[25px] h-[25px] flex justify-center items-center relative"
        {...buttonAttributes}
        onClick={() => setIsDropdownOpen((prev) => (prev === false ? true : false))}
        onBlur={() => setIsDropdownOpen(false)}
      >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.0001 7.6999C11.3636 7.6999 10.7531 7.44705 10.303 6.99696C9.85295 6.54687 9.6001 5.93642 9.6001 5.2999C9.6001 4.66338 9.85295 4.05293 10.303 3.60285C10.7531 3.15276 11.3636 2.8999 12.0001 2.8999C12.6366 2.8999 13.2471 3.15276 13.6972 3.60285C14.1472 4.05293 14.4001 4.66338 14.4001 5.2999C14.4001 5.93642 14.1472 6.54687 13.6972 6.99696C13.2471 7.44705 12.6366 7.6999 12.0001 7.6999ZM12.0001 14.8999C11.3636 14.8999 10.7531 14.647 10.303 14.197C9.85295 13.7469 9.6001 13.1364 9.6001 12.4999C9.6001 11.8634 9.85295 11.2529 10.303 10.8028C10.7531 10.3528 11.3636 10.0999 12.0001 10.0999C12.6366 10.0999 13.2471 10.3528 13.6972 10.8028C14.1472 11.2529 14.4001 11.8634 14.4001 12.4999C14.4001 13.1364 14.1472 13.7469 13.6972 14.197C13.2471 14.647 12.6366 14.8999 12.0001 14.8999ZM12.0001 22.0999C11.3636 22.0999 10.7531 21.847 10.303 21.397C9.85295 20.9469 9.6001 20.3364 9.6001 19.6999C9.6001 19.0634 9.85295 18.4529 10.303 18.0028C10.7531 17.5528 11.3636 17.2999 12.0001 17.2999C12.6366 17.2999 13.2471 17.5528 13.6972 18.0028C14.1472 18.4529 14.4001 19.0634 14.4001 19.6999C14.4001 20.3364 14.1472 20.9469 13.6972 21.397C13.2471 21.847 12.6366 22.0999 12.0001 22.0999Z"
            fill="#4B5563"
          />
        </svg>

        {isdropdownOpen && (
          <ul
            id="navbarDropdown"
            className="origin-top-right absolute z-[99] right-1 bottom-8 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none block animate-fade-in-up w-[max-content]"
          >
            {!!draft && (
              <li>
                <a
                  role="button"
                  className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100 hover:text-gray-900"
                  onClick={() => setIsModalOpen(true)}
                >
                  Rename
                </a>
              </li>
            )}

            {schedule === true && (
              <li>
                <a
                  role="button"
                  className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100 hover:text-gray-900"
                  onClick={cancelSchedule}
                >
                  Cancel Schedule
                </a>
              </li>
            )}
            <li>
              <a
                role="button"
                className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100 hover:text-gray-900"
                onClick={duplicateTrack}
              >
                Duplicate
              </a>
            </li>

            <li>
              <a
                role="button"
                className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100 hover:text-gray-900"
                onClick={deleteTrack}
              >
                Delete
              </a>
            </li>
          </ul>
        )}
      </button>
    </>
  );
};

export default Dots;
