import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

const TwitterTextarea = (props) => {
  const { index: threadIndex, value, changeHandler, addFocus } = props;

  const dispatch = useDispatch();

  const textAreaRef = useRef(null);
  const onChange = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    const text = e.target.value;
    changeHandler(text);
  };

  useEffect(() => {
    //to resize the textarea on load
    const textarea = textAreaRef.current;
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  return (
    <div onFocus={addFocus}>
      <textarea
        ref={textAreaRef}
        value={value}
        onChange={onChange}
        disabled={false}
        className="w-full text-sm border-0 outline-none empty:before:content-[attr(placeholder)] before:text-[gray] resize-none"
      />
    </div>
  );
};

export default TwitterTextarea;
