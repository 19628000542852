export const formatedDate = (given) => {
  var newData = new Date();
  var givenDate = new Date(given);
  // Calculate the time difference in milliseconds
  var diffMs = newData - givenDate;
  // Calculate the time difference in seconds, minutes, hours, and days
  var diffSeconds = Math.floor(diffMs / 1000);
  var diffMinutes = Math.floor(diffMs / (1000 * 60));
  var diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  var diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

  // Format the output based on the duration of the time difference
  if (diffSeconds < 60) {
    return diffSeconds + " seconds ago";
  } else if (diffMinutes < 60) {
    return diffMinutes + " minutes ago";
  } else if (diffHours < 24) {
    return diffHours + " hours ago";
  } else if (diffDays == 1) {
    return "Yesterday";
  } else if (diffDays < 7) {
    return diffDays + " days ago";
  } else if (diffDays < 30) {
    return Math.floor(diffDays / 7) + " weeks ago";
  } else if (diffDays < 365) {
    return Math.floor(diffDays / 30) + " months ago";
  }
};
export const formatTags = (tags) => {
  if (!tags || tags.length === 0) return "";
  if (tags.length === 1) return tags[0];

  let lastTag = tags[tags.length - 1];
  let otherTags = tags.slice(0, tags.length - 1);
  return `${otherTags.join(", ")} & ${lastTag}`;
};
