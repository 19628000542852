import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PrivateRoutes from "./components/PrivateRoute";
import Index from "./pages/Index";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import UnprotectedRoute from "./components/UnprotectedRoute";
import { getLoggedUserInfo } from "./redux/actions/authActions";
import { getSchedule } from "./redux/actions/scheduleActions";
import { isExpired } from "react-jwt";
import Loader from "./components/utils/Loader";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(getLoggedUserInfo());
      dispatch(getSchedule());
    }
  }, [isAuthenticated, user, dispatch, token]);

  if (!token || isExpired(token)) {
    return (
      <ErrorBoundary>
        <UnprotectedRoute />
      </ErrorBoundary>
    );
  }

  if (isAuthenticated) {
    return (
      <ErrorBoundary>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/*" element={<Index />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    );
  }

  return (
    <div className="h-[100vh] w-full flex justify-center items-center">
      <Loader height="40px" width="40px" loaderFill="#333" color="#000" />
    </div>
  );
}

export default App;
