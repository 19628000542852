import React from "react";
import { GiWireCoil } from "react-icons/gi";
import TwitterThreadsContent from "../../../../components/Library/LibraryItems/TwitterThreadsContent";
import mp3img from "../../../../assets/mp3.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { successAlert, failAlert, confirmationModal } from "../../../../utils/sweetAlert";
const Content = ({ panelOneItem, profileId, setPanelOneItem, setIsPanelOneActive, setIsPanelTwoActive }) => {
  console.log("panelOneItem", panelOneItem);
  const textAreaRef = React.useRef(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    //to resize the textarea on load
    // setIsEdited(false);
    if (!textAreaRef) return;
    const textarea = textAreaRef.current;
    if (!textarea) return;
    var h = textarea.scrollHeight;
    h < 100 ? (h = 100) : (h = h);
    textarea.style.height = `${h}px`;
    textarea.style.minHeight = "100px";
  }, [panelOneItem]);
  const handleDelete = async () => {
    confirmationModal({ text: "" }, async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/library/delete-lib-asset`,
        {
          assetId: panelOneItem.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      dispatch({ type: "DELETE_LIBRARY_ASSET", payload: panelOneItem.id });
      successAlert("Asset deleted successfully");
      setIsPanelOneActive(false);
      setIsPanelTwoActive(false);
    });
  };

  const onSaveClick = async (item) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/update-lib-asset`,
      {
        item,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    dispatch({ type: "UPDATE_LIBRARY_ASSET", payload: data.LibraryAsset });
  };

  const textareaChangeHandler = async (updatedValue, index) => {
    const threads = panelOneItem.tweet;
    const newThreads = [...threads.slice(0, index), { text: updatedValue }, ...threads.slice(index + 1)];
    setPanelOneItem({ ...panelOneItem, tweet: newThreads });
    onSaveClick({ ...panelOneItem, tweet: newThreads });
  };
  const handleRemoveTags = (index) => {
    var topics = panelOneItem.topics;
    topics = topics.filter((item, i) => i !== index);
    setPanelOneItem({ ...panelOneItem, topics });
    onSaveClick({ ...panelOneItem, topics });
  };
  const handleAddTags = (e) => {
    if (e.key === "Enter") {
      var topics = panelOneItem.topics;
      topics.push(e.target.value);
      setPanelOneItem({ ...panelOneItem, topics });
      onSaveClick({ ...panelOneItem, topics });
      e.target.value = "";
    }
  };
  return (
    <div class="page-content-wrap show-content active">
      <div class="page-content-inside">
        <div class="page-title-wrap">
          <div class="page-title-inside">
            {(panelOneItem.type === "text" ||
              panelOneItem.type === "tweet" ||
              panelOneItem.type == "linkedinpost" ||
              panelOneItem.type == "linkedinslider" ||
              panelOneItem.type === "newsletter" ||
              panelOneItem.type === "instagramcaption" ||
              panelOneItem.type === "instagramslider" ||
              panelOneItem.type === "linkedinpost") && (
              <>
                <div class="page-asset-type">
                  <i class="ri-text"></i>
                </div>
                <div class="page-title">{panelOneItem.text && panelOneItem.text.substring(0, 60)}</div>
              </>
            )}
            {panelOneItem.type === "document" && (
              <>
                <div class="page-asset-type">
                  <i class="ri-image-2-line"></i>
                </div>
                <div class="page-title">{panelOneItem.documentTitle.substring(0, 60)}</div>
              </>
            )}
            {panelOneItem.type === "image" && (
              <>
                <div class="page-asset-type">
                  <i class="ri-image-2-line"></i>
                </div>
                <div class="page-title">{panelOneItem.imageTitle.substring(0, 60)}</div>
              </>
            )}
            {panelOneItem.type === "video" && (
              <>
                <div class="page-asset-type">
                  <i class="ri-image-2-line"></i>
                </div>
                <div class="page-title">{panelOneItem.videoTitle.substring(0, 60)}</div>
              </>
            )}
            {panelOneItem.type === "audio" && (
              <>
                <div class="page-asset-type">
                  <i class="ri-image-2-line"></i>
                </div>
                <div class="page-title">{panelOneItem.audioTitle.substring(0, 60)}</div>
              </>
            )}
            {panelOneItem.type === "thread" && (
              <>
                <div class="page-asset-type flex items-center justify-center">
                  <GiWireCoil color="#000" fontWeight={900} size={18} />
                </div>
                <div class="page-title">
                  {panelOneItem && panelOneItem.tweet && panelOneItem.tweet[0].text.substring(0, 60)}
                </div>
              </>
            )}

            {/* <div class="page-title" contenteditable="true"></div> */}
          </div>
          <div class="page-asset-meta">
            <button
              class="button delete-asset xsmall lightbox-show"
              data-lightbox="lightbox-delete-asset"
              onClick={handleDelete}
            >
              Delete Asset
            </button>
            <div class="page-asset-created">{new Date(panelOneItem.createdAt).toLocaleString()}</div>
            <div class="page-asset-size"></div>
            <input class="page-tags" type="text" placeholder="Add tag" onKeyDown={handleAddTags} />
          </div>
          <div class="asset-tags clearfix">
            {panelOneItem.topics.map((topic, index) => (
              <div class="tag" key={index}>
                <span class="tag-text">{topic}</span>
                <button class="remove-tag" onClick={() => handleRemoveTags(index)}>
                  <i class="ri-close-line"></i>
                </button>
              </div>
            ))}
          </div>
        </div>
        <div class="page-content" contenteditable="false">
          {(panelOneItem.type == "text" ||
            panelOneItem.type == "tweet" ||
            panelOneItem.type == "linkedinpost" ||
            panelOneItem.type == "linkedinslider" ||
            panelOneItem.type == "newsletter" ||
            panelOneItem.type == "instagramslider" ||
            panelOneItem.type == "instagramcaption" ||
            panelOneItem.type == "thread" ||
            panelOneItem.type == "document") && (
            <div>
              {panelOneItem.type == "thread" ? (
                <div className="w-full px-10 -mt-8 -mb-4 -pb-4">
                  <TwitterThreadsContent
                    generatedTweetsThreads={panelOneItem.tweet}
                    hideTitle={true}
                    profileId={profileId}
                    textareaChangeHandler={textareaChangeHandler}
                  />
                </div>
              ) : (
                <textarea
                  ref={textAreaRef}
                  className="w-full px-10  text-sm border-0 outline-none empty:before:content-[attr(placeholder)] bg-transparent resize-none min-h-12"
                  value={panelOneItem.text ? panelOneItem.text : ""}
                  onChange={(e) => {
                    setPanelOneItem({ ...panelOneItem, text: e.target.value });
                    onSaveClick({ ...panelOneItem, text: e.target.value });
                  }}
                />
              )}
            </div>
          )}
          {panelOneItem && (panelOneItem.type == "audio" || panelOneItem.type == "video") && (
            <div>
              <div className="w-full">
                {panelOneItem.type == "audio" && (
                  <div className="-mt-4">
                    <h1 className="text-lg font-medium py-2"> {panelOneItem.audioTitle} </h1>
                    <div className="bg-gray-100">
                      <img src={mp3img} alt="" className="mx-auto w-1/3 h-auto" />
                      <audio controls className="w-full">
                        <source
                          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelOneItem.audio}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    {panelOneItem.text && (
                      <textarea
                        ref={textAreaRef}
                        className="w-full px-10 my-5 text-sm border-0 outline-none bg-transparent empty:before:content-[attr(placeholder)]  resize-none"
                        value={panelOneItem.text}
                        onChange={(e) => {
                          setPanelOneItem({ ...panelOneItem, text: e.target.value });
                          onSaveClick({ ...panelOneItem, text: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
                {panelOneItem.type == "video" && (
                  <div className="-mt-4">
                    <h1 className="text-lg font-medium py-2"> {panelOneItem.videoTitle} </h1>
                    <div className="bg-gray-100 ">
                      <video controls className="w-full">
                        <source
                          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelOneItem.video}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </video>
                    </div>
                    {panelOneItem.text && (
                      <textarea
                        ref={textAreaRef}
                        className="w-full px-10 my-5 text-sm border-0 outline-none empty:before:content-[attr(placeholder)] bg-transparent resize-none"
                        value={panelOneItem.text}
                        onChange={(e) => {
                          setPanelOneItem({ ...panelOneItem, text: e.target.value });
                          onSaveClick({ ...panelOneItem, text: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {panelOneItem && panelOneItem.type == "image" && (
            <div>
              <div className="w-full">
                {panelOneItem.type == "image" && (
                  <div className="mx-auto my-auto">
                    <img
                      src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelOneItem.image}`}
                      alt=""
                    />
                  </div>
                )}
                {panelOneItem.type == "video" && (
                  <div className="-mt-4">
                    <h1 className="text-lg font-medium py-2"> {panelOneItem.videoTitle} </h1>
                    <div className="bg-gray-100 ">
                      <video controls className="w-full">
                        <source
                          src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${panelOneItem.video}`}
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </video>
                    </div>
                    {panelOneItem.text && (
                      <textarea
                        ref={textAreaRef}
                        className="w-full px-10 my-5 text-sm border-0 outline-none empty:before:content-[attr(placeholder)] bg-transparent resize-none"
                        value={panelOneItem.text}
                        onChange={(e) => {
                          setPanelOneItem({ ...panelOneItem, text: e.target.value });
                          onSaveClick({ ...panelOneItem, text: e.target.value });
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Content;
