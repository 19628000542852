/**
 * It takes in a height and width and returns a green tick SVG
 */
const GreenTick = ({ height, width }) => {
  return (
    <div className="bg-[#DEF7EC] rounded-full flex justify-center items-center" style={{ height, width }}>
      <svg
        height={16}
        width={12}
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4998 1.83345L5.49984 11.8334L0.916504 7.25011L2.0915 6.07511L5.49984 9.47511L14.3248 0.658447L15.4998 1.83345Z"
          fill="#31C48D"
        />
      </svg>
    </div>
  );
};

export default GreenTick;
