import axios from "axios";

const getOneAsset = async (assetId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/asset/${assetId}`
    );

    if (data.status === true) {
      return data.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getLibraryAsset = async (assetId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/asset/library/${assetId}`
    );

    if (data.status === true) {
      return data.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getAllAssets = async (assetIds = []) => {
  try {
    const assets = await Promise.all(assetIds.map(async (id) => await getOneAsset(id)));

    if (!assets) return [];

    return assets;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteAssetFromDraft = async (draftId, assetId) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/draft/delete-asset`,
      { draftId, assetId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === true) {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteAssetFromTwitter = async (id, assetId, threadIndex) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/twitter/update-assets`,
      { id, assetId, threadIndex },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status === false) return false;

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};


