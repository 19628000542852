import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitterIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../../assets/icons/linkedinIcon.svg";
import TimesWatched from "./TimesWatched";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { formatTags, formatedDate } from "../../../utils/date";

import mp3img from "../../../assets/mp3.png";
const LibraryVideo = (props) => {
  const { item, isSelected, profileId } = props;
  const dispatch = useDispatch();

  const selectClickHandler = () => {
    if (isSelected) {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: "" });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: null });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: null });
    } else {
      dispatch({ type: "CHANGE_BASE_TEXT", payload: item.text });
      dispatch({ type: "CHANGE_PROFILE_ID", payload: profileId });
      dispatch({ type: "CHANGE_SELECTED_ASSET_ID", payload: props.item.id });
    }
  };
  // if (item.type === "audio") {
  if (item.status === "PROCESSING") {
    return (
      <div className="">
        <div
          className={`min-h-[8rem] opacity-70 w-full p-[10px] text-[#3F3F46] text-[14px] flex flex-col justify-between`}
        >
          {/* {thread.tweet.map((item, index) => (
          <div key={item.id}>
            <p className="font-[500]">{textlength < 160 ? item.text : item.text.substring(0, 160)}</p>
            {index !== thread.tweet.length - 1 && <hr className="my-[10px]" />}
          </div>
        ))} */}
          <Skeleton count={3} />

          <div>
            <div className="text-[#1F2A37] flex text-[12px] mt-[20px]">
              processing | {formatedDate(item.updatedAt)}
              {/* <TimesWatched timesWatched={3} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div class="asset-fake-border "></div>
      <div class="asset-content">
        <div class="asset-title">
          {item && item.type === "audio" ? (
            <>
              <div class="asset-type" title="Audio">
                <i class="ri-music-2-line"></i>
              </div>
              <div class="asset-title-text">
                {item && item.audioTitle && item.audioTitle.substring(0, 40)}{" "}
                {item && item.audioTitle && item.audioTitle.length > 40 && "..."}
              </div>
            </>
          ) : (
            <>
              <div class="asset-type" title="Video">
                <i class="ri-movie-line"></i>
              </div>
              <div class="asset-title-text">
                {item && item.videoTitle && item.videoTitle.substring(0, 40)}{" "}
                {item && item.videoTitle && item.videoTitle.length > 40 && "..."}
              </div>
            </>
          )}
        </div>
        <div class="asset-excerpt">
          {item && item.text && item.text.substring(0, 120)} {item && item.text && item.text.length > 120 && "..."}
        </div>
        <div class="asset-tags">
          {item.topics.map((topic) => (
            <div class="tag">
              <span class="tag-text">{topic}</span>
              <button class="remove-tag">
                <i class="ri-close-line"></i>
              </button>
            </div>
          ))}
        </div>
        <div class="asset-meta">
          <div class="asset-size meta-item">
            {item && item.text ? item.text.split(/\s+/).length + " words" : item.status == "PROCESSING" && "processing"}
          </div>
          <div class="asset-imported-date meta-item">{formatedDate(item.updatedAt)}</div>
          <div class="asset-imported-date meta-item">{formatTags(item.tags)}</div>
        </div>
        <div class="asset-page-content">
          <h1>The Best Way to Get the Word Out</h1>
          <p>
            Spend 80% less time promoting yourself online while reaching more customers with the ultimate omni-channel
            growth app.
          </p>
        </div>
      </div>
    </>
  );
  // }
  // if (item.type === "video") {
  // }
  return (
    <div
      className={`${item.status == "PROCESSING" && "opacity-70 "} ${
        item.status != "PROCESSING" && "bg-white hover:bg-[#F5F5F5] "
      } border-[2px] h-[14rem]
      ${isSelected ? "border-[#1C64F2] active" : "border-[#e4e4e7]"} 
      rounded-[3px] shadow-[0_1px_2px_rgba(0,0,0,0.06)] cursor-pointer w-[300px] overflow-hidden relative`}
      onClick={item.status == "PROCESSING" ? () => {} : selectClickHandler}
    >
      {/* <TimesWatched timesWatched={6} className="absolute top-[5px] left-[5px]" /> */}
      {item.type == "audio" ? (
        <img
          src={mp3img}
          alt=""
          className={`object-cover py-5 mx-auto h-[8rem] w-[6rem] ${
            item.status == "PROCESSING" && "opacity-50 bg-transparent bg-black"
          }`}
        />
      ) : (
        <img
          src="http://via.placeholder.com/640x360"
          alt=""
          className={`object-cover h-[8rem]  w-full ${
            item.status == "PROCESSING" && "opacity-50 bg-transparent bg-black"
          }`}
        />
      )}
      <div className="flex gap-[12px] p-[10px] ">
        <div>
          <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.89581 7.45817L6.90623 9.59359L4.77081 10.5832L6.90623 11.5728L7.89581 13.7082L8.8854 11.5728L11.0208 10.5832L8.8854 9.59359L7.89581 7.45817ZM14.1458 7.45817L13.4687 8.86442L12.0625 9.5415L13.4687 10.2186L14.1458 11.6248L14.8229 10.2186L16.2291 9.5415L14.8229 8.86442L14.1458 7.45817ZM0.083313 16.8332V0.166504H2.16665L4.24998 4.33317H7.37498L5.29165 0.166504H7.37498L9.45831 4.33317H12.5833L10.5 0.166504H12.5833L14.6666 4.33317H17.7916L15.7083 0.166504H20.9166V16.8332H0.083313Z"
              fill="#A1A1AA"
            />
          </svg>
        </div>

        <div className="flex-1 ">
          <p className="text-[#3F3F46] text-[14px] mb-[5px] font-semibold">
            {(item.type == "audio" && item.audioTitle) || (item.type == "video" && item.videoTitle)}
          </p>
          <div className="text-[#1F2A37] flex justify-between text-[12px]">
            {item.status === "PROCESSING" ? <div>Transcribing...</div> : <div>{formatedDate(item.updatedAt)}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryVideo;
