import axios from "axios";

export const handleGenerateTweets =
  (prompt, profileId, assetId, generateMore = false) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/openai/generate-tweets`,
        { prompt, profileId, assetId, generateMore },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log("GPTResponce", data.status);
      if (data.status) {
        dispatch({ type: "ADD_GENERATED_TWEETS", payload: [data.GPTResponce] });

        // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

export const handleGenerateTweetsThreads = (prompt, profileId, assetId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-twitter-threads`,
      { prompt, profileId, assetId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      console.log("GPTResponce", data.GPTResponce);
      dispatch({ type: "ADD_GENERATED_TWEETS_THREADS", payload: data.GPTResponce });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const handleGenerateBullets = (prompt, assetId, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-bullets`,
      { prompt, assetId, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      dispatch({ type: "ADD_BULLET_TWEETS", payload: data.GPTResponce });

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return data.GPTResponce;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const handleGenerateTextToLinkedInPosts = (prompt, assetId, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-text-to-linkedin-posts`,
      { prompt, assetId, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      dispatch({ type: "ADD_BULLET_TWEETS", payload: data.GPTResponce });

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return data.GPTResponce;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const handleGenerateNewsletter = (prompt, assetId, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-text-to-newsletter`,
      { prompt, assetId, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      dispatch({ type: "ADD_BULLET_TWEETS", payload: data.GPTResponce });

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return data.GPTResponce;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const handleGenerateInstagramSlider = (prompt, assetId, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-text-to-instagram-slider`,
      { prompt, assetId, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      dispatch({ type: "ADD_BULLET_TWEETS", payload: data.GPTResponce });

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return data.GPTResponce;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const handleGenerateInstagramCaption = (prompt, assetId, profileId) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/openai/generate-text-to-instagram-caption`,
      { prompt, assetId, profileId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (data.status) {
      dispatch({ type: "ADD_BULLET_TWEETS", payload: data.GPTResponce });

      // dispatch({ type: 'ADD_GENERATED_TWEETS', payload: data.GPTResponce?.filter(thread => thread.length !== 0) })
      return data.GPTResponce;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getLibraryData =
  (profileId, changeLoading = true, pageNumber = 1, firstReq) =>
  async (dispatch) => {
    try {
      if (changeLoading) {
        dispatch({ type: "LIBRARY_LOADING_TRUE", payload: true });
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/library`,
        {
          profileId,
          pageNumber,
          firstReq: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (data.status) {
        dispatch({ type: "ADD_LIBRARY_DATA", payload: data.LibraryAssets });
        dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });
        libraryData = data.LibraryAssets;
      }
      if (firstReq) {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/library`,
          {
            profileId,
            pageNumber,
            firstReq: false,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        var libraryData = [];
        if (data.status) {
          dispatch({ type: "ADD_LIBRARY_DATA", payload: data.LibraryAssets });
          dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });
          libraryData = data.LibraryAssets;
          return data.LibraryAssets;
        }
      }
      return data.LibraryAssets;
    } catch (error) {
      console.log(error);
      dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });

      return false;
    }
  };
export const getInfiniteLibraryData =
  (profileId, pageNumber = 1) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/library`,
        {
          profileId,
          pageNumber,
          firstReq: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status) {
        dispatch({ type: "ADD_LIBRARY_DATA", payload: data.LibraryAssets });
        dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });
        return data.LibraryAssets;
        return true;
      }
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "LIBRARY_LOADING_FALSE", payload: false });

      return false;
    }
  };

export const addLibraryAsset =
  (profileId, text, assetId = null) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/library/add`,
        {
          profileId,
          text,
          assetId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const addLibraryMediaAsset = (profileId, uploadedAssets) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/add/media`,
      {
        profileId,
        uploadedAssets,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addApprovedTweets = (profileId, textArray, assetId) => async (dispatch) => {
  try {
    const regex = /^tweet (\d+): /;

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/add-approved-tweets`,
      {
        profileId,
        textArray: textArray.map((el) => el.replace(regex, "")),
        assetId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
