import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PreSigninNavbar from "../components/Navbar/PreSigninNavbar";
import { useDispatch, useSelector } from "react-redux";
import { handleRegister } from "../redux/actions/authActions";
import Button from "../components/utils/Button";
import Loader from "../components/utils/Loader";
import { failAlert } from "../utils/sweetAlert";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [fullName, setfullName] = useState("");
  const [password, setPassword] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !fullName || !password) {
      failAlert({
        title: "Please fill in all the fields",
        confirmButtonText: "OK",
      });
      return;
    }

    if (!document.getElementById("t&c").checked) {
      failAlert({
        title: "Please accept the terms and conditions",
        confirmButtonText: "OK",
      });
      return;
    }

    setIsLoading(true);

    const res = await dispatch(handleRegister({ email, fullName, password }));
    if (res[0] === false) {
      setIsLoading(false);

      failAlert({
        title: res[1] ?? "Something went wrong",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user) {
        window.location.href = `/composer/${user.profiles[0].tracks[0].id}`;
      }
    }
  }, [isAuthenticated, navigate, user]);

  return (
    <div className="h-[100vh] flex flex-col bg-[#f9fafb]">
      <PreSigninNavbar />
      <div className="m-auto flex justify-center items-center">
        <div className="flex items-center p-8 sm:mx-[17vw] sm:my-[9vh] bg-[#FFFFFF] rounded-[16px] border border-[#E4E4E7]">
          <div className="flex items-center justify-center pr-12">
            <img width={"400px"} height={"400px"} src="Images/SignUp/SignUp.png" alt="" />
          </div>
          <div>
            <div className="text-3xl font-bold text-[#111928] font-bold mb-5">Create your Account</div>
            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Your Name</h4>
              <input
                id="fullName"
                name="fullName"
                type="text"
                required
                value={fullName}
                onChange={(e) => setfullName(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="Full Name"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>
            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Your email</h4>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="name@example.com"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>

            <div className="mb-4">
              <h4 className="text-base font-medium text-[#111928] font-medium">Password</h4>
              <input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="relative block w-full rounded-t-md sm:text-sm sm:w-[29vw]"
                placeholder="**********"
                style={{
                  padding: "13px 20px",
                  gap: "10px",
                  height: "40px",
                  background: "#F9FAFB",
                  border: "1px solid #E5E7EB",
                  borderRadius: "16px",
                }}
              />
            </div>

            <div className="flex items-center" style={{ marginTop: "1rem" }}>
              <div className="flex items-center">
                <input
                  id="t&c"
                  name="t&c"
                  type="checkbox"
                  required
                  className="border-[#D1D5DB] text-[#D1D5DB] focus:ring-[white]"
                />
                <label htmlFor="t&c" className="ml-2 text-xs font-normal text-[#6B7280]">
                  I accept the{" "}
                  <a href="#!" className="font-semibold text-[#1C64F2] hover:text-blue-700">
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                className="border border-[#1C64F2] w-full !py-2 px-4 text-[#FFFFFF] text-[16px]"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="flex gap-[5px]">
                    <div>
                      <Loader height="20px" width="20px" />
                    </div>
                    Loading...
                  </div>
                ) : (
                  <>Create account</>
                )}
              </Button>
            </div>
            <hr
              style={{
                width: "50%",
                marginLeft: "25%",
                borderColor: "#3F83F8",
                marginTop: "1rem",
              }}
            />
            <div className="flex items-center justify-center mr-6" style={{ marginTop: "0.7rem" }}>
              <Link to="/signin" className="text-base font-semibold text-[#1C64F2] ml-7 hover:text-blue-700">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
