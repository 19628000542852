import { useEffect, useState, useRef } from "react";
import instaDefault from "../../../assets/Images/Navbar/instagram_default.jpg";
import { useDispatch, useSelector } from "react-redux";
import TwitterTextarea from "../../../pages/Library/TwitterTextareaLib";
const TwitterThreadsContent = (props) => {
  const { generatedTweetsThreads, profileId, hideTitle = false, textareaChangeHandler } = props;

  const textAreaRef = useRef(null);
  const uploadedMedia = [];
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  // const { profileId } = useSelector((state) => state.library);

  const [platform, setplatform] = useState(null);
  useEffect(() => {
    // console.log("useeffect");
    // console.log("user: ");
    // console.log(user);
    // console.log("isAuthenticated: ");
    // console.log(isAuthenticated);
    // console.log("profileId: ");
    // console.log(profileId);
    if (isAuthenticated === true) {
      var profiles = user.profiles;
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i].id === parseInt(profileId)) {
          var socialAccounts = profiles[i].socialAccounts;
          var tempselectedplatform = {
            twitter: false,
            linkedin: false,
            instagram: false,
          };
          for (let j = 0; j < socialAccounts.length; j++) {
            if (socialAccounts[j].accountType === "twitter") {
              tempselectedplatform.twitter = socialAccounts[j].authJSON;
              // console.log("found twitter");
              // console.log("twitter account json : ");
              // console.log(socialAccounts[j].authJSON);
            }
            if (socialAccounts[j].accountType === "linkedin") {
              tempselectedplatform.linkedin = socialAccounts[j].authJSON;
            }
            if (socialAccounts[j].accountType === "instagram") {
              tempselectedplatform.instagram = socialAccounts[j].authJSON;
            }
          }
          setplatform(tempselectedplatform);
        } else {
          console.log("not found");
        }
      }
    }
  }, [isAuthenticated, user, profileId]);
  useEffect(() => {
    //to resize the textarea on load
    if (!textAreaRef.current) return;
    textAreaRef.current.style.minHeight = "100px";
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  });

  return (
    <div className={`w-[85%] m-auto mt-11 ${!hideTitle && "pb - 5"}`}>
      {!hideTitle && (
        <div className="pt-[20px] flex justify-between items-center">
          <div>
            <h3 className="text-[#111928] text-[30px] font-semibold">Do you like this thread?</h3>
            <p className="text-[#6B7280] text-[18px]">
              Select the tweets you like. Click Accept to accept, Reject to reject.
            </p>
          </div>
        </div>
      )}
      <div className="gap-[20px]">
        <div className="flex justify-center  flex-1 my-7 mb-[100px]">
          <div className="bg-[#FFFFFF] border border-[#E4E4E7]  rounded-[10px] p-5 h-fit w-[min(600px,90vw)]">
            {generatedTweetsThreads.map((item, index) => {
              return (
                <div key={index} className="flex w-full">
                  <div className="flex flex-col items-center  mr-[12px]">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10">
                        {platform && platform.twitter ? (
                          <img
                            className="w-10 h-10 rounded-full"
                            src={platform.twitter.profile_image_url}
                            alt="Avatar"
                          />
                        ) : (
                          <img className="w-10 h-10 rounded-full" src={instaDefault} alt="Avatar" />
                        )}
                      </div>
                    </div>
                    {index !== generatedTweetsThreads.length - 1 && <div className="w-px h-full bg-gray-300" />}
                  </div>
                  <div className="mb-[1.5rem] w-full">
                    <div className=" mb-3">
                      <div className="flex max-w-full">
                        <span className="text-[#191c1f] font-[600] whitespace-nowrap overflow-hidden text-ellipsis">
                          {/* {props.profile.twitter ? props.profile.twitter.name : user && user?.fullName} */}
                          {platform && platform.twitter ? platform.twitter.name : "User"}
                        </span>
                        <span className="text-[#536471] text-sm ml-[3px] whitespace-nowrap overflow-hidden text-ellipsis">
                          {platform && platform.twitter ? "@" + platform.twitter.username : "@username"}
                        </span>
                        <span className="text-[#536471] text-sm mx-1">·</span>
                        <span className="text-[#536471] text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                          {/* {fullDate} */}
                          {new Date().toDateString()}
                        </span>
                      </div>

                      <div className="mt-1">
                        <TwitterTextarea
                          value={item.text}
                          disabled={false}
                          // changeHandler={changeThreadHandler}
                          changeHandler={(updatedValue) => {
                            textareaChangeHandler(updatedValue, index);
                          }}
                          className="w-full text-sm border-0 outline-none empty:before:content-[attr(placeholder)] before:text-[gray] resize-none"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterThreadsContent;
