import React from "react";
import LinkedinPreview from "./LinkedinPreview";
import { PlusOutlined } from "@ant-design/icons";
import { AddNewPost, DeleteAPost } from "../../redux/actions/trackActions";
import { useDispatch } from "react-redux";
import { confirmationModal } from "../../utils/sweetAlert";
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { Tooltip } from "antd";
const LinkedIn = (props) => {
  const [linkedinItems, setlinkedinItems] = React.useState([]);

  const deleteHandler = async (item, index) => {
    confirmationModal(
      {
        text: "Are you sure you want to delete this post?",
        confirmButtonText: "Yes, Delete it!",
      },
      async () => {
        console.log(item);
        var temp = [...linkedinItems];
        temp.splice(index, 1);
        await dispatch(DeleteAPost("linkedin", item));

        setlinkedinItems(temp);
      }
    );
  };
  React.useEffect(() => {
    var temp = props.data.linkedInPost.sort((a, b) => a.id - b.id);
    setlinkedinItems(temp);
  }, [props.data.linkedInPost]);
  const dispatch = useDispatch();
  return (
    <div
      className={` space-y-2 flex-col ${
        linkedinItems.length > 2 && "md:grid md:grid-cols-2"
      } md:space-y-0 md:gap-6 md:flex md:justify-center `}
    >
      {linkedinItems.map((item, index) => (
        <>
          <div className="mx-4 md:mx-0 bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] p-4 md:p-5 h-fit">
            <div className="hidden md:flex justify-between">
              <div></div>
              <div className="flex items-center space-x-2">
                {item.status === "published" && (
                  <div className="flex space-x-1 text-xs justify-end bg-green-300 items-center p-1 rounded-full px-2  -mb-10">
                    <AiOutlineCheckCircle />
                    <span className="ml-auto">{item.status.toUpperCase()}</span>
                  </div>
                )}
                {item.status === "scheduled" && (
                  <div className="flex space-x-1 text-xs justify-end bg-orange-300 items-center p-1 rounded-full px-2  -mb-10">
                    <AiOutlineClockCircle />
                    <span className="ml-auto">{item.status.toUpperCase()}</span>
                  </div>
                )}
                {item.status === "failed" && (
                  <div className="flex space-x-1 text-xs justify-end bg-red-500 items-center p-1 rounded-full px-2 -mb-8">
                    <AiOutlineExclamationCircle className="text-white" />
                    <span className="ml-auto text-white">{item.status.toUpperCase()}</span>
                  </div>
                )}
                {item.aiGenerated && (
                  <Tooltip title="AI Generated" className="-mb-10">
                    <FaRobot className="text-[#1C64F2] text-xl" />
                  </Tooltip>
                )}
              </div>
            </div>
            <LinkedinPreview
              {...props}
              data={{ linkedInPost: item }}
              isdeleteActive={linkedinItems.length > 1 ? true : false}
              deleteItem={() => deleteHandler(item, index)}
            />
          </div>
        </>
      ))}
      <div className="flex justify-center  w-auto min-h-[17rem] max-h-96">
        <div
          className={`flex justify-center h-60 items-center ${
            linkedinItems.length < 3 && "-mr-16 ml-6"
          } text-sm text-[#1C64F2] font-medium cursor-pointer`}
          onClick={() => {
            dispatch(AddNewPost("linkedin", linkedinItems[0]));
            // setlinkedinItems([...linkedinItems, { ...linkedinItems[0] }]);
          }}
        >
          <PlusOutlined className=" text-[#1C64F2] mr-1 text-[12px] font-medium" /> Add
        </div>
      </div>
    </div>
  );
};

export default LinkedIn;
