const INITAIAL_STATE = {
  members: [],
  loading: false,
};

const teamReducer = (state = INITAIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_TEAM_DATA":
      return {
        ...state,
        members: payload,
        loading: true,
      };
    case "ADD_TEAM_DATA":
      return {
        ...state,
        members: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default teamReducer;
