import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInstagramPostValidState } from "../../redux/actions/stageActions";
import { uploadMedia } from "../../utils/uploadTos3";
import Button from "../utils/Button";
import InstagramEdit from "./InstagramEdit";
import InstagramMediaPreview from "./InstagramMediaPreview";
import InstagramMediaUpload from "./InstagramMediaUpload";
import { getAllAssets } from "../../utils/asset";
import { RePurposeWithAI } from "../../redux/actions/trackActions";
import axios from "axios";
import ScheduleButtonsFotter from "./ScheduleButtonsFotter";
import { GiFairyWand } from "react-icons/gi";
import { Tooltip } from "antd";
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
const InstagramPreview = (props) => {
  const { data, setsavedTime, isInsideRescheduleModal, setData } = props;
  const instaa = data.instaPost;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isCurrentlyUploading, setIsCurrentlyUploading] = useState(false);
  const [instagramStage, setInstagramStage] = useState("upload");
  const [isSetAsDraft, setIsSetAsDraft] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const { user } = useSelector((state) => state.auth);

  const { instagram: isPostValid } = useSelector((state) => state.stagePostValidation);
  const { currentTrack } = useSelector((state) => state.tracks);

  // const { instagram: savedAsDraft } = useSelector((state) => state.saveAsDraft);
  const isCurrentPostValid = isPostValid && uploadedFiles.length > 0;

  const dispatch = useDispatch();

  const updateMarkAsDraft = async (markAsDraft) => {
    if (isInsideRescheduleModal) return;
    if (markAsDraft === false && isSetAsDraft === false) return;
    if (!currentTrack) return;

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/draft/update-set-as-draft`,
        { postId: currentTrack.linkedInPostId, platform: "instagram", markAsDraft },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (data.status !== true) return;

      setIsSetAsDraft(data.markAsDraft);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      const assets = data.instaPost?.body_json.assets;
      setIsCurrentlyUploading(true);
      const allAssets = await getAllAssets(assets);
      setIsCurrentlyUploading(false);
      setUploadedFiles(allAssets);
    })();
  }, []);

  useEffect(() => {
    setInstagramStage(uploadedFiles.length > 0 ? "preview" : "upload");
  }, [uploadedFiles]);

  const uploadFiles = async (files) => {
    // if (!currentTrack) return;
    console.log("uploading files", instaa);
    const img = await uploadMedia(files, "instagram", instaa.id);
    // updateMarkAsDraft(false);
    return img;
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      dispatch(updateInstagramPostValidState(true));
    } else {
      dispatch(updateInstagramPostValidState(false));
    }
  }, []);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      dispatch(updateInstagramPostValidState(true));
      return;
    }

    dispatch(updateInstagramPostValidState(false));
  }, [uploadedFiles.length]);

  useEffect(() => {
    if (!currentTrack) return;

    setIsSetAsDraft(currentTrack.instaPost.markedAsDraft);
  }, []);

  const datePickerHandler = (e) => {
    if (e === null) {
      setSelectedDate(null);
      return;
    }

    setSelectedDate({
      day: e.$D,
      month: e.$M,
      year: e.$y,
    });
  };
  const timePickerHandler = (e) => {
    if (e === null) {
      setSelectedTime(null);
      return;
    }

    setSelectedTime({
      hour: e.$H,
      minute: e.$m,
    });
  };
  const checkBoxChangeHandler = async ({ checkbox, date, time }) => {
    var selectedDateVar = date ? date : selectedDate;
    var selectedTimeVar = time ? time : selectedTime;
    if (checkbox) {
      if (!selectedDateVar || !selectedTimeVar) {
        console.log("Please select date and time");
        return;
      }
      const date = new Date(
        selectedDateVar.year,
        selectedDateVar.month,
        selectedDateVar.day,
        selectedTimeVar.hour,
        selectedTimeVar.minute,
        0
      );
      if (date) {
        // make api to save this info
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
          {
            postId: data?.instaPost.id,
            trackId: currentTrack.id,
            platform: "instagram",
            schedule: date,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (res.data.status === true) {
          setsavedTime(date);
          setData(res.data.track);
        }
      }
    }
  };
  const cancelSchedule = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/track/update-schedule-by-id`,
      {
        postId: data?.instaPost.id,
        trackId: currentTrack.id,
        platform: "instagram",
        schedule: null,
      },

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res.data.status === true) {
      setsavedTime(null);
      setData(res.data.track);
    }
  };
  return (
    <div className="w-fit mx-1 md:mx-0 bg-[#FFFFFF] border border-[#E4E4E7] rounded-[10px] h-fit md:w-[550px] py-3 px-3 editor-container">
      <div className="hidden md:flex justify-between">
        <div></div>
        <div className="flex items-center space-x-2">
          {data.instaPost.status === "published" && (
            <div className="flex space-x-1 text-xs justify-end bg-green-300 items-center p-1 rounded-full px-2">
              <AiOutlineCheckCircle />
              <span className="ml-auto">{data.instaPost.status.toUpperCase()}</span>
            </div>
          )}
          {data.instaPost.status === "scheduled" && (
            <div className="flex space-x-1 text-xs justify-end bg-orange-300 items-center p-1 rounded-full px-2">
              <AiOutlineClockCircle />
              <span className="ml-auto">{data.instaPost.status.toUpperCase()}</span>
            </div>
          )}
          {data.instaPost.status === "failed" && (
            <div className="flex space-x-1 text-xs justify-end bg-red-500 items-center p-1 rounded-full px-2 -mb-8">
              <AiOutlineExclamationCircle className="text-white" />
              <span className="ml-auto text-white">{data.instaPost.status.toUpperCase()}</span>
            </div>
          )}
          {data.instaPost.aiGenerated && (
            <Tooltip title="AI Generated">
              <FaRobot className="text-[#1C64F2] text-xl" />
            </Tooltip>
          )}
        </div>
      </div>
      {instagramStage === "upload" ? (
        <InstagramMediaUpload
          data={data}
          setIsCurrentlyUploading={setIsCurrentlyUploading}
          setInstagramStage={setInstagramStage}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          uploadFiles={uploadFiles}
          updateMarkAsDraft={updateMarkAsDraft}
        />
      ) : (
        <InstagramMediaPreview
          isCurrentlyUploading={isCurrentlyUploading}
          profile={props.profile}
          user={user}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          setInstagramStage={setInstagramStage}
          uploadFiles={uploadFiles}
          updateMarkAsDraft={updateMarkAsDraft}
        />
      )}

      <InstagramEdit
        user={user}
        data={data}
        setsavedTime={setsavedTime}
        profile={props.profile}
        updateMarkAsDraft={updateMarkAsDraft}
      />
      {data.instaPost.status != "processing" && (
        <div className="mx-2 py-1">
          <div className="bg-[#E4E4E7] w-full h-[1px] m-auto rounded-[10px] mt-[15px]"></div>
          <ScheduleButtonsFotter
            isChecked={data.instaPost.scheduledTime ? true : false}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            checkBoxChangeHandler={checkBoxChangeHandler}
            datePickerHandler={datePickerHandler}
            timePickerHandler={timePickerHandler}
            data={data.instaPost}
            cancelSchedule={cancelSchedule}
          />

          <div className="set-as-draft-btn-container flex justify-between">
            {props.isdeleteActive && (
              <button className="text-red-600 text-sm font-medium ml-4 my-auto" onClick={props.deleteItem}>
                Delete
              </button>
            )}
            <Tooltip title={"Repurpose with AI"}>
              <button
                className="ml-auto mx-2 my-auto flex items-center text-sm font-medium"
                onClick={() => {
                  dispatch(
                    RePurposeWithAI({
                      postId: data?.instaPost.id,
                      platform: "instagram",
                      trackId: data?.instaPost?.trackId,
                    })
                  );
                }}
              >
                <GiFairyWand className="mr-1" />
                <span className="">AI Repurpose</span>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstagramPreview;
