import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PostSigninNavbar from "../../components/Navbar/PostSigninNavbar";
import LibraryQuestionsBottomBar from "../../components/Library/BottomBar/LibraryQuestionsBottomBar";
import { handleGenerateTweets } from "../../redux/actions/libraryAction";
import { useSelector } from "react-redux";
import { failAlert, successAlert } from "../../utils/sweetAlert";
import { Select, Space } from "antd";
import { selectOptions } from "../../utils/multiSelect";

const CHARACTER_LIMIT = 20000;

const GenerateTweets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { baseText, profileId, assetId, selectedAsset } = useSelector((state) => state.library);
  const textAreaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [topicOptions, setTopicOptions] = useState([]);
  useEffect(() => {
    if (selectedAsset) {
      var temp = [];
      for (var i = 0; i < selectedAsset.topics.length; i++) {
        temp.push({
          label: selectedAsset.topics[i],
          value: selectedAsset.topics[i],
        });
      }
      setTopicOptions(temp);
    }
  }, [selectedAsset]);
  const [prompt, setPrompt] = useState({
    baseText: "",
    example: [""],
  });

  useEffect(() => {
    setPrompt({ ...prompt, baseText: baseText });
  }, [baseText]);

  // useEffect(() => {
  //   //to resize the textarea on load
  //   const textarea = textAreaRef.current;
  //   textarea.style.height = `${textarea.scrollHeight}px`;
  // }, [prompt]);

  const handleChange = (e, index) => {
    const updatedPrompt = {
      ...prompt,
      example: [...prompt.example],
    };
    updatedPrompt.example[index] = e.target.value;
    setPrompt(updatedPrompt);
  };

  const handleAddExampleTweets = () => {
    if (prompt.example.length <= 5) {
      setPrompt({
        ...prompt,
        example: [...prompt.example, ""],
      });
    }
  };

  const handleOnClick = async () => {
    setLoading(true);
    var GPTResponce = await dispatch(handleGenerateTweets(prompt, profileId, assetId));
    if (GPTResponce) {
      // navigate(`/accept-reject-tweets`);

      successAlert({ title: "Transformation Started!" });
      navigate(`/library/${profileId}`);

      setLoading(false);
    } else {
      failAlert({
        title: "Some error occurred!",
      });
    }
  };
  const handleInputChange = (e) => {
    setPrompt({ ...prompt, baseText: e.target.value });
    window.scrollTo(0, e.target.scrollHeight);
  };

  return (
    <div>
      <PostSigninNavbar title="Transform your text to tweets" />
      <div className="w-[85%] m-auto mt-11">
        <div className="pt-[20px]">
          <h3 className="text-[#111928] text-[30px] font-semibold">Generate Tweets</h3>
          <p className="text-[#6B7280] text-[18px]">Pick the type of content you want to transform into a tweets</p>
        </div>

        <div className="grid grid-cols-2 gap-[25px] mt-[20px] mb-[110px]">
          <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] min-h-[35rem] flex flex-col h-fit">
            <h4 className="text-[#4B5563] text-[16px] font-[500]">Enter the base text</h4>
            <p className="text-[#6B7280] text-[14px] my-[10px]">
              Blogs, articles, newsletters up to 20,000 characters.
            </p>

            <div className="relative h-full">
              <textarea
                className="bg-[#F9FAFB] w-full text-[#3F3F46] p-[18px] resize-none rounded-[12px] flex-1 text-[14px] min-h-full h-[30rem]"
                placeholder="Enter your text here"
                value={prompt.baseText}
                ref={textAreaRef}
                onChange={handleInputChange}
              ></textarea>
              <div
                className={`absolute text-[14px] right-[10px] bottom-[10px] ${
                  prompt.baseText.length > 20000 ? "text-red-500" : "text-[#6B7280]"
                }`}
              >
                {prompt.baseText.length <= 20000 ? prompt.baseText.length : 20000 - prompt.baseText.length}
              </div>
            </div>
          </div>
          <div>
            <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] mb-[25px] h-fit">
              <h4 className="text-[#4B5563] text-[16px] font-[500]">Enter the topics (Optional)</h4>
              <p className="text-[#6B7280] text-[14px] my-[10px]">
                What would you like the text generated to be about?
              </p>
              <div className="relative ">
                <Select
                  mode="tags"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  // defaultValue={["a10", "c12"]}
                  // onChange={handleChange}
                  onChange={(e) => {
                    // console.log(e);
                    setPrompt({ ...prompt, topics: e.toString() });

                    // console.log(e.toString());
                  }}
                  options={topicOptions}
                />
              </div>
            </div>
            <div className="w-full bg-white border border-[#E4E4E7] rounded-[12px] p-[18px] flex flex-col h-max">
              <h4 className="text-[#4B5563] text-[16px] font-[500]">Sample Tweets</h4>
              <p className="text-[#6B7280] text-[14px] my-[10px]">What the new tweets should look like?</p>

              <div className="grid gap-[10px] text-[14px]">
                {prompt.example.map((item, index) => (
                  <textarea
                    className="bg-[#F9FAFB] w-full shadow-md text-[#3F3F46] px-[18px] py-2 resize-none rounded-[12px] h-32"
                    value={item}
                    maxLength={280}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="Add Your Example Tweets Here"
                    key={index}
                  />
                ))}
              </div>
              <div className={`${prompt.example.length === 5 ? `hidden` : `block`}`}>
                <div
                  className="mt-[10px] text-[14px] cursor-pointer text-[#1C64F2] font-[500]"
                  onClick={handleAddExampleTweets}
                >
                  + Add Another
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LibraryQuestionsBottomBar
        loading={loading}
        onClick={handleOnClick}
        buttonText="Next"
        variant={prompt.baseText.length <= 20000 ? "blue" : "disabled"}
      />
    </div>
  );
};

export default GenerateTweets;
