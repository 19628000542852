import Loader from "../../utils/Loader";
import { useState } from "react";

const ImageImport = ({ updloadTwitterImages, isCurrentlyUploading, setIsCurrentlyUploading }) => {
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const onFileDrop = async (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    uploadFiles({
      currentTarget: {
        files: files,
      },
    });
    setHighlight(false);
  };
  const uploadFiles = async (e) => {
    const files =
      Array.from(e.currentTarget.files)
        .filter((file) => file.type.startsWith("image/"))
        .slice(0, 10) || [];
    setIsCurrentlyUploading(true);
    const twitterAssets = (await updloadTwitterImages(files)) || [];
    setIsCurrentlyUploading(false);
    setUploadedMedia(twitterAssets);
  };
  return (
    <div class="page-content-inside">
      {!isCurrentlyUploading && !uploadedMedia.length > 0 && (
        <>
          <button
            class="file-upload"
            onClick={() => document.getElementById("twitter-file-upload").click()}
            onDrop={onFileDrop}
            onDragOver={(e) => {
              e.preventDefault();
              setHighlight(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setHighlight(false);
            }}
            style={{
              backgroundColor: highlight ? "lightgray" : "",
            }}
          >
            <div class="file-upload-title">Upload Image(s)</div>
          </button>
          <input
            type="file"
            multiple={true}
            accept="image/png, image/gif, image/jpeg"
            id={`twitter-file-upload`}
            className="hidden"
            onChange={uploadFiles}
          />
        </>
      )}

      {uploadedMedia.length > 0 && (
        <div className={`mt-3 flex gap-[1rem]  max-h-[120px]`}>
          {uploadedMedia.map((media, i) => {
            return (
              <div
                className={`mt-3 h-full flex  items-center gap-[1rem] 
          `}
              >
                {uploadedMedia.map((media, i) => {
                  return (
                    <div
                      className="relative flex items-center  rounded-[12px] overflow-hidden w-full h-full object-cover"
                      key={i}
                    >
                      <img src={media.url} alt="" className="w-auto h-full " />

                      <div
                        className="absolute top-[10px] left-[10px] bg-[#434749] cursor-pointer h-[20px] w-[20px] text-[#fff] rounded rounded-full"
                        onClick={() => {
                          setUploadedMedia([]);
                        }}
                      >
                        <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px]">
                          &times;
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      {isCurrentlyUploading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loader height="20px" width="20px" loaderFill="#333" color="#000" className="mt-[1rem]" />
        </div>
      )}
    </div>
  );
};

export default ImageImport;
