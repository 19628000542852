import { useState } from "react";
const LinkedinMediaPreview = (props) => {
  const { uploadedFiles, removeUploadedFile } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const removeImage = (i) => {
    removeUploadedFile(i);
    setCurrentSlide(currentSlide === 0 ? 0 : currentSlide - 1);
  };

  return (
    <div className="w-full h-[400px] relative overflow-hidden">
      {/* Slider control button */}
      {currentSlide > 0 && (
        <div
          className="h-[25px] w-[25px] rounded-full bg-[rgba(26,26,26,.8)] absolute top-[50%] left-[10px] z-10 flex justify-center items-center cursor-pointer"
          onClick={() => setCurrentSlide(currentSlide - 1)}
        >
          <i className="fa fa-chevron-left ml-[-2px] text-[12px] text-[#fff]"></i>
        </div>
      )}
      {currentSlide < uploadedFiles.length - 1 && (
        <div
          className="h-[25px] w-[25px] rounded-full bg-[rgba(26,26,26,.8)] absolute top-[50%] right-[10px] z-10 flex justify-center items-center cursor-pointer"
          onClick={() => setCurrentSlide(currentSlide + 1)}
        >
          <i className="fa fa-chevron-right mr-[-2px] text-[12px] text-[#fff]"></i>
        </div>
      )}

      {/* Slider media */}
      {uploadedFiles.map((media, i) => {
        const translateNumber = (i - currentSlide) * 100;
        const mediaUrl = media instanceof File ? window.URL.createObjectURL(media) : media.url;
        console.log(mediaUrl, "mediaUrl");
        return (
          <div
            className="absolute top-0 left-0 w-full h-full object-contain duration-500"
            style={{ transform: `translateX(${translateNumber}%)` }}
            key={i}
          >
            <div className="h-full relative rounded rounded-[12px] overflow-hidden">
              <div
                className="absolute top-[10px] right-[10px] bg-[#434749] cursor-pointer h-[30px] w-[30px] text-[#fff] rounded rounded-full"
                onClick={() => removeImage(i)}
              >
                <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mt-[-1.5px] text-[22px]">
                  &times;
                </span>
              </div>

              {mediaUrl.includes(".pdf") ? (
                // <Document
                //   file={mediaUrl}
                //   // options={{ workerSrc: "/pdf.worker.js" }}
                //   onLoadSuccess={onDocumentLoadSuccess}
                // >
                //   {Array.from(new Array(numPages), (el, index) => (
                //     <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                //   ))}
                // </Document>
                <iframe
                  src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + mediaUrl}
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
              ) : (
                <img className="w-full h-full object-cover" src={mediaUrl} alt="" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LinkedinMediaPreview;
