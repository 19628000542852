import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../utils/Button";
import Dots from "./Dots";
import ListItem from "./ListItem";

const checkInstagramCorrect = (post) => {
  if (!post) return 0;

  if (!post.body_json?.assets.length) {
    return 0;
  }

  if (post.body_json.texts > 2200) {
    return 0;
  }

  return 1;
};

const progressColors = {
  0: {
    background: "#fde8e8",
    text: "#E02424",
  },
  1: {
    background: "#FDE8E8",
    text: "#E02424",
  },
  2: {
    background: "#FEECDC",
    text: "#FF5A1F",
  },
  3: {
    background: "#DEF7EC",
    text: "#31C48D",
  },
};

const DraftsList = (props) => {
  const { draftTracks, openPublishingModal, getAllTracks, profileId, hasCompletedBilling } = props;

  const navigate = useNavigate();

  const openPublishingModalHandler = (draftId) => {
    if (!hasCompletedBilling) {
      navigate("/billing");
      return;
    }

    openPublishingModal(draftId);
  };

  return draftTracks.map((draft, i) => {
    // const instaPoint = checkInstagramCorrect(draft.instaPost) && Number(draft.instaPost.markedAsDraft) ? 1 : 0;
    // const totalScore =
    //   draft.status === "composing"
    //     ? 0
    //     : instaPoint + Number(draft.linkedInPost.markedAsDraft) + Number(draft.twitterPost.markedAsDraft);
    // const backgroundColor = progressColors[totalScore]["background"];
    // const textColor = progressColors[totalScore]["text"];

    return (
      <ListItem key={draft.id}>
        <div className="flex justify-between items-center h-full">
          <div
            className="text-[12px] md:text-[18px] text-[#374151] max-w-[50%] whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer"
            onClick={() => {
              draft.status === "composing"
                ? (window.location.href = `/composer/${draft.id}`)
                : navigate(`/staging/${draft.id}`);
            }}
          >
            {draft.draft.body_json.title}
          </div>
          <div className="hidden md:block mr-auto text-[8px] md:text-[14px] text-[#6B7280] md:ml-[10px] md:mt-[4px]">
            {moment(draft.updatedAt).fromNow()}
          </div>
          {/* <div className={`rounded-[10px] px-[10px] py-[2px]`} style={{ backgroundColor, color: textColor }}>
            {totalScore} / 3
          </div> */}
          {/* <div className="w-px h-[36px] bg-gray-300 ml-[70px]"></div> */}
          <div className="flex items-center gap-[5px] md:gap-[8px]">
            <Button className="ml-3 text-sm md:text-base" onClick={() => openPublishingModalHandler(draft.id)}>
              Publish
            </Button>
            <button
              className={`hidden md:flex py-[8px] px-[13px] w-[120px] rounded-[12px] border border-[#D1D5DB]" items-center justify-center`}
              onClick={() => openPublishingModalHandler(draft.id)}
              style={{
                border: "1px solid #D1D5DB",
              }}
            >
              <span className={`text-[16px] font-[500] text-semibold text-[#111928]`}>Schedule</span>
            </button>
            <Dots
              getAllTracks={getAllTracks}
              trackId={Number(draft.id)}
              profileId={Number(profileId)}
              title={draft.draft.body_json.title}
              draft={draft}
            />
          </div>
        </div>
      </ListItem>
    );
  });
};

export default DraftsList;
