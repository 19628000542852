import React, { useRef, useState } from "react";
import { DatePicker, TimePicker, Select, Space } from "antd";
import dayjs from "dayjs";
import { CloseCircleOutlined } from "@ant-design/icons";
import "dayjs/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
// The default locale is en-US, if you want to use other locale, just set locale in entry file globally.
import TimezoneSelect from "react-timezone-select";

function ScheduleButtonsFotter({
  datePickerHandler,
  timePickerHandler,
  checkBoxChangeHandler,
  isChecked,
  selectedDate,
  selectedTime,
  data,
  cancelSchedule,
}) {
  const datepickerParent = useRef(null);
  const [isScheduleVisible, setIsScheduleVisible] = useState(false);
  const [checkbox, setCheckbox] = useState(isChecked);
  // console.log(isChecked);
  const [selectedTimezone, setSelectedTimezone] = useState({
    abbrev: "GMT",
    altName: "British Standard Time",
    label: "(GMT+0:00) UTC",
    offset: 0,
    value: "Etc/GMT",
  });

  return (
    <>
      <div className="flex flex-col py-3" ref={datepickerParent}>
        <div className="flex">
          <input
            type="checkbox"
            checked={checkbox}
            onChange={(e) => {
              // console.log(e.target.checked);
              setCheckbox(e.target.checked);
              checkBoxChangeHandler({ checkbox: e.target.checked });
            }}
            name=""
            id=""
            className="mr-2"
          />
          <p className="flex items-center">
            <span className="font-medium">Post at :</span>
            <span className="ml-2">{data.status === "scheduled" && new Date(data.scheduledTime).toLocaleString()}</span>
            <span
              onClick={() => {
                setCheckbox(false);
                cancelSchedule();
              }}
            >
              {data.status === "scheduled" && (
                <span className="text-red-700 ml-2 cursor-pointer flex items-center">
                  <CloseCircleOutlined
                    style={{
                      fontSize: "12px",
                      color: "#F87171",
                    }}
                  />
                </span>
              )}
            </span>
          </p>
        </div>
        <div className="flex">
          {/* //changeing the height of the dropdown inindex.css */}
          {/* <TimezoneSelect
            className="w-2/5 text-sm font-medium z-99"
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            defaultValue={selectedTimezone}
          /> */}
          <DatePicker
            className="w-[150px] ml-5 h-8 mr-[10px] border border-[#D1D5DB]"
            //   value={
            //     isChecked
            //       ? dayjs(selectedDate.year + "-" + selectedDate.month + "-" + selectedDate.day, "YYYY-MM-DD")
            //       : null
            //   }
            onChange={(e) => {
              datePickerHandler(e);
              checkBoxChangeHandler({
                checkbox,
                date: {
                  day: e.$D,
                  month: e.$M,
                  year: e.$y,
                },
                time: null,
              });
            }}
            //   disabled={true}
            getPopupContainer={() => datepickerParent.current}
            format={"DD/MM/YYYY"}
          />
          <TimePicker
            className="w-[150px] h-8 border border-[#D1D5DB]"
            onChange={(e) => {
              timePickerHandler(e);
              checkBoxChangeHandler({
                checkbox,
                time: {
                  hour: e.$H,
                  minute: e.$m,
                },
                date: null,
              });
            }}
            //   disabled={true}
            getPopupContainer={() => datepickerParent.current}
          />
        </div>
      </div>
    </>
  );
}

export default ScheduleButtonsFotter;
