import { useState, useEffect } from "react";
import Content from "./PanelOneTabs/Content";
import axios from "axios";
import { GiWireCoil } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

const PanelOne = ({
  isPanelOneActive,
  setIsPanelOneActive,
  setSelectedAssets,
  profileId,
  setIsPanelTwoActive,
  panelOneItem,
  setPanelOneItem,
  panelTwoMode,
  setPanelTwoMode,
  setPanelTwoData,
  importToComposer,
}) => {
  function formatTags(tags) {
    if (!tags || tags.length === 0) return "";
    if (tags.length === 1) return tags[0];

    let lastTag = tags[tags.length - 1];
    let otherTags = tags.slice(0, tags.length - 1);
    return `${otherTags.join(", ")} & ${lastTag}`;
  }
  function contentMetagen(item) {
    // console.log(item);
    if (
      item.type === "text" ||
      item.type === "tweet" ||
      item.type === "document" ||
      item.type == "linkedinpost" ||
      item.type == "linkedinslider" ||
      item.type == "newsletter" ||
      item.type == "instagramslider" ||
      item.type == "instagramcaption"
    ) {
      return {
        title: item.text && item.text.substring(0, 60),
        icon: item.type === "document" ? <i class="ri-article-line"></i> : <i class="ri-text"></i>,
        words: item.text ? item.text.split(/\s+/).length + " words" : "",
      };
    }
    if (item.type === "audio") {
      return {
        title: item.audioTitle.substring(0, 60),
        icon: <i class="ri-music-2-line"></i>,
        words: item.text ? item.text.split(/\s+/).length + " words" : "",
      };
    }
    if (item.type === "image") {
      return {
        title: item.imageTitle.substring(0, 60),
        icon: <i class="ri-image-2-line"></i>,
        words: "",
      };
    }
    if (item.type === "video") {
      return {
        title: item.videoTitle.substring(0, 60),
        icon: <i class="ri-movie-line"></i>,
        words: item.text ? item.text.split(/\s+/).length + " words" : "",
      };
    }
    if (item.type === "thread") {
      return {
        title: item.tweet[0].text.substring(0, 60),
        icon: (
          <div className="flex h-full w-auto justify-center items-center">
            {" "}
            <GiWireCoil color="#000" fontWeight={900} size={14} />
          </div>
        ),
        words: item.tweet.length + " tweets",
      };
    }
  }
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("content");
  const [genActiveItem, setGenActiveItem] = useState(null);
  const [prevItems, setPrevItems] = useState([]);
  const [nextItems, setNextItems] = useState([]);
  const [publishedData, setPublishedData] = useState({
    twitter: [],
    linkedin: [],
    instagram: [],
  });
  const getDetails = async (ele) => {
    // setIsEdited(false);
    // setIsLoading(true);
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/get-lib-asset`,
      {
        assetId: ele.id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    setPrevItems(data.previous);
    for (const lp of data.LibraryAssets.LibraryPostRelation) {
      if (lp.platform === "twitter") {
        setPublishedData((prev) => ({
          ...prev,
          twitter: [...prev.twitter, lp],
        }));
      }
      if (lp.platform === "linkedin") {
        setPublishedData((prev) => ({
          ...prev,
          linkedin: [...prev.linkedin, lp],
        }));
      }
      if (lp.platform === "instagram") {
        setPublishedData((prev) => ({
          ...prev,
          instagram: [...prev.instagram, lp],
        }));
      }
    }
    var temp = [];
    for (const nxtitem of data.nextItems) {
      var key = nxtitem.id;
      var element = nxtitem;
      var label = <p> {nxtitem.generatedTitle} </p>;
      temp.push({ key, element, label });
    }
    setNextItems(temp);
    // console.log(data);
  };
  useEffect(() => {
    if (!panelOneItem) return;
    getDetails(panelOneItem);
  }, [panelOneItem]);
  return (
    <div class="panel panel-one">
      <div class="page">
        <div class="page-header">
          <button
            class="page-close"
            onClick={() => {
              setIsPanelOneActive(false);
              setIsPanelTwoActive(false);
              setSelectedAssets([]);
            }}
            type="button"
          >
            <i class="ri-close-line"></i>
          </button>
          <div class="page-header-menu">
            <div class="page-header-menu-fake-border"></div>
            <a
              class={`page-header-tab show-content ${activeTab == "content" && "active"}`}
              onClick={() => setActiveTab("content")}
              data-page-menu-item="show-content"
            >
              <i class="ri-article-line"></i> Content
            </a>
            <a
              class={`page-header-tab show-genealogy ${activeTab == "genealogy" && "active"}`}
              data-page-menu-item="show-genealogy"
              onClick={() => setActiveTab("genealogy")}
            >
              <i class="ri-git-merge-line"></i> Genealogy
            </a>
            <a
              class={`page-header-tab show-published ${activeTab == "published" && "active"}`}
              data-page-menu-item="show-published"
              onClick={() => setActiveTab("published")}
            >
              <i class="ri-book-open-line"></i> Published
            </a>
            <a
              class={`page-header-tab show-campaign  ${activeTab == "campaign" && "active"}`}
              data-page-menu-item="show-campaign"
              onClick={() => setActiveTab("campaign")}
            >
              <i class="ri-send-plane-fill"></i> Campaign
            </a>
            <div class="page-header-buttons">
              <button class="button small primary withicon transformer" onClick={() => navigate(`/library/questions`)}>
                <i class="ri-sparkling-line"></i>Transform
              </button>
              <button class="button small primary withicon composer" onClick={importToComposer}>
                <i class="ri-edit-2-line"></i>Composer
              </button>
            </div>
          </div>
          {activeTab == "content" && (
            <div class="page-header-sub-menu show-content active">
              <div class="asset-id">ID000001</div>
              <button class="wizzy-item" title="Plain Text (Remove Formatting)">
                <i class="ri-text"></i>
              </button>
              <button class="wizzy-item" title="Bold">
                <i class="ri-bold"></i>
              </button>
              <button class="wizzy-item" title="Underline">
                <i class="ri-underline"></i>
              </button>
              <button class="wizzy-item" title="Italic">
                <i class="ri-italic"></i>
              </button>
              <div class="wizzy-sub-wrap">
                <button class="wizzy-item wizzy-show-sub" title="Heaeding">
                  <i class="ri-heading"></i>
                  <i class="ri-arrow-down-s-line right"></i>
                </button>
                <div class="wizzy-sub-menu">
                  <button class="wizzy-item">
                    <i class="ri-h-1"></i>
                  </button>
                  <button class="wizzy-item">
                    <i class="ri-h-2"></i>
                  </button>
                  <button class="wizzy-item">
                    <i class="ri-h-3"></i>
                  </button>
                  <button class="wizzy-item">
                    <i class="ri-h-4"></i>
                  </button>
                  <button class="wizzy-item">
                    <i class="ri-h-5"></i>
                  </button>
                  <button class="wizzy-item">
                    <i class="ri-h-6"></i>
                  </button>
                </div>
              </div>
              <button class="wizzy-item" title="Bullet List">
                <i class="ri-list-unordered"></i>
              </button>
              <button class="wizzy-item" title="Numbered List">
                <i class="ri-list-ordered-2"></i>
              </button>
              <button class="wizzy-item" title="Strike Through">
                <i class="ri-strikethrough"></i>
              </button>
              {/* <button class="wizzy-item color" title="Text Color">
                <input id="colorPicker" type="text" />
              </button> */}
              <button class="wizzy-item" title="View Source Code">
                <i class="ri-code-s-slash-line"></i>
              </button>
              <button
                class="toolbar-button show-panel-two show-content"
                data-panel-two="show-content"
                onClick={() => {
                  setIsPanelTwoActive(true);
                  setPanelTwoMode("notes");
                }}
              >
                Notes <i class="ri-arrow-right-s-line"></i>
              </button>
            </div>
          )}
          {activeTab == "genealogy" && (
            <div class="page-header-sub-menu show-genealogy active">
              <div class="gen-meta-item gen-parents">{prevItems.length} Parent(s)</div>
              <div class="gen-meta-item gen-children">{nextItems.length} Child(ren)</div>
              <button class="show-panel-two show-genealogy toolbar-button" data-panel-two="show-genealogy">
                Preview <i class="ri-arrow-right-s-line"></i>
              </button>
            </div>
          )}
          {activeTab == "published" && (
            <div class="page-header-sub-menu show-published active">
              <div class="page-published-meta"></div>
              <div class="page-published-heat"></div>
            </div>
          )}
          {activeTab == "campaign" && <div class="page-header-sub-menu show-campaign active"></div>}
        </div>
        {activeTab == "content" && (
          <Content
            panelOneItem={panelOneItem}
            setPanelOneItem={setPanelOneItem}
            profileId={profileId}
            setIsPanelOneActive={setIsPanelOneActive}
            setIsPanelTwoActive={setIsPanelTwoActive}
          />
        )}
        {activeTab == "genealogy" && (
          <div class="page-content-wrap show-genealogy active ">
            <div class="page-content-inside">
              <div class="page-content tree">
                {/* <div class="gen-item-wrap gen0">
                  <div class="gen-item show-panel-two">
                    <div class="gen-item-meta">
                      <i class="ri-movie-line"></i>
                      <div class="gen-item-size">127kb</div>
                      <div class="gen-item-import-date">Mar 14, 2023</div>
                      <div class="gen-item-title">
                        This is a title of a parent parent assets fsf sdfs dfdsf sadfsadf sadf safsad fsadf sadf
                        sdffdsfds
                      </div>
                    </div>
                    <button class="button xsmall gray">Open</button>
                  </div>
                </div> */}
                {prevItems.map((item) => (
                  <div
                    class={`gen-item-wrap gen1 ${genActiveItem && item.id == genActiveItem.id && "active"}`}
                    key={item.id}
                    onClick={() => {
                      setGenActiveItem(item);
                      setPanelTwoData(item);
                      setIsPanelTwoActive(true);
                      setPanelTwoMode("genealogy");
                    }}
                  >
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        {contentMetagen(item).icon}
                        <div class="gen-item-size">{contentMetagen(item).words}</div>
                        <div class="gen-item-import-date">{new Date(item.createdAt).toLocaleDateString()}</div>
                        <div class="gen-item-title">{contentMetagen(item).title}</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                ))}
                <div class="gen-item-wrap gen2 gen-target">
                  <div class="gen-item show-panel-two">
                    <div class="gen-item-meta">
                      {contentMetagen(panelOneItem).icon}
                      <div class="gen-item-size"> {contentMetagen(panelOneItem).words}</div>
                      <div class="gen-item-import-date">{new Date(panelOneItem.createdAt).toLocaleDateString()}</div>
                      <div class="gen-item-title">{contentMetagen(panelOneItem).title}</div>
                    </div>
                    <button class="button xsmall gray">Open</button>
                  </div>
                </div>
                {nextItems.map((item) => (
                  <div
                    key={item.id}
                    class={`gen-item-wrap gen3 ${genActiveItem && item.element.id == genActiveItem.id && "active"}`}
                    onClick={() => {
                      setGenActiveItem(item.element);
                      setPanelTwoData(item.element);
                      setIsPanelTwoActive(true);
                      setPanelTwoMode("genealogy");
                    }}
                  >
                    <div class="gen-item show-panel-two">
                      <div class="gen-item-meta">
                        {contentMetagen(item.element).icon}
                        <div class="gen-item-size">{contentMetagen(item.element).words}</div>
                        <div class="gen-item-import-date">{new Date(item.element.createdAt).toLocaleDateString()}</div>
                        <div class="gen-item-title">{contentMetagen(item.element).title}</div>
                      </div>
                      <button class="button xsmall gray">Open</button>
                    </div>
                  </div>
                ))}

                {/* <div class="gen-item-wrap gen4">
                  <div class="gen-item show-panel-two">
                    <div class="gen-item-meta">
                      <i class="ri-link"></i>
                      <div class="gen-item-size">412 words</div>
                      <div class="gen-item-import-date">Apr 13, 2023</div>
                      <div class="gen-item-title">This is a title of a child child asset</div>
                    </div>
                    <button class="button xsmall gray">Open</button>
                  </div>
                </div>
                <div class="gen-item-wrap gen3">
                  <div class="gen-item show-panel-two">
                    <div class="gen-item-meta">
                      <i class="ri-text"></i>
                      <div class="gen-item-size">748 words</div>
                      <div class="gen-item-import-date">Apr 12, 2023</div>
                      <div class="gen-item-title">This is a title of a child asset</div>
                    </div>
                    <button class="button xsmall gray">Open</button>
                  </div>
                </div>
                <div class="gen-item-wrap gen4">
                  <div class="gen-item show-panel-two">
                    <div class="gen-item-meta">
                      <i class="ri-image-2-line"></i>
                      <div class="gen-item-size">36kb</div>
                      <div class="gen-item-import-date">Apr 20, 2023</div>
                      <div class="gen-item-title">This is a title of a child child asset</div>
                    </div>
                    <button class="button xsmall gray">Open</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {activeTab == "published" && (
          <div class="page-content-wrap show-published active">
            <div class="page-content-inside">
              {publishedData.twitter.length > 0 && (
                <div class="published-platform twitter">
                  <div class="platform-title">
                    <i class="ri-twitter-fill"></i> Twitter
                  </div>
                  <div class="published-items-wrap">
                    {publishedData.twitter.map((item) => (
                      <div class="published-item show-panel-two">
                        <div class="platform-items-wrap">
                          <div class="platform-item type">Tweet</div>
                          <div class="platform-item date">{new Date(item.createdAt).toLocaleString()}</div>
                          <div class="platform-item date">{formatTags(item.tag)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {publishedData.instagram.length > 0 && (
                <div class="published-platform instagram">
                  <div class="platform-title">
                    <i class="ri-instagram-fill"></i> Instagram
                  </div>
                  <div class="published-items-wrap">
                    {publishedData.instagram.map((item) => (
                      <div class="published-item show-panel-two">
                        <div class="platform-items-wrap">
                          <div class="platform-item type">Post</div>
                          <div class="platform-item date">{new Date(item.createdAt).toLocaleString()}</div>
                          <div class="platform-item date">{formatTags(item.tag)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {publishedData.linkedin.length > 0 && (
                <div class="published-platform linkedin">
                  <div class="platform-title">
                    <i class="ri-linkedin-fill"></i> LinkedIn
                  </div>
                  <div class="published-items-wrap">
                    {publishedData.linkedin.map((item) => (
                      <div class="published-item show-panel-two">
                        <div class="platform-items-wrap">
                          <div class="platform-item type">Post</div>
                          <div class="platform-item date">{new Date(item.createdAt).toLocaleString()}</div>
                          <div class="platform-item date">{formatTags(item.tag)}</div>
                          {item.post?.postAnalytics && (
                            <>
                              <div class="platform-item withicon likes" title="Likes">
                                <i class="ri-heart-line"></i> {item.post?.postAnalytics?.likeCount}
                              </div>
                              <div class="platform-item withicon comments" title="Comments">
                                <i class="ri-chat-3-line"></i> {item.post?.postAnalytics?.commentCount}
                              </div>
                              <div class="platform-item withicon impressions" title="Impressions">
                                <i class="ri-bar-chart-2-fill"></i> {item.post?.postAnalytics?.uniqueImpressionsCount}
                              </div>
                            </>
                          )}
                          {/* <div class="platform-item withicon reach" title="Reach">
                            <i class="ri-user-3-line"></i> 1872
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {activeTab === "campaign" && (
          <div class="page-content-wrap show-campaign active flex items-center justify-center">
            <div>Coming Soon...</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelOne;
