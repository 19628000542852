import { useEffect, useState } from "react";
import ImportToLibraryModal from "../components/Library/ImportToLibraryModal";
import LibraryBottomBar from "../components/Library/BottomBar/LibraryBottomBar";
import LibraryMasonry from "../components/Library/LibraryMasonry";
import PanelOne from "./Library/components/PanelOne";
import PanelTwo from "./Library/components/PanelTwo";
import ImportPanel from "./Library/components/ImportPanel";
import LibraryTopBar from "../components/Library/LibraryTopBar";
import PostSigninNavbar from "../components/Navbar/PostSigninNavbar";
import ErrorBoundary from "../components/utils/ErrorBoundary";
import { getLibraryData, getInfiniteLibraryData } from "../redux/actions/libraryAction";
import axios from "axios";
import { failAlert, successAlert } from "../utils/sweetAlert";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const Library = () => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [isPanelOneActive, setIsPanelOneActive] = useState(false);
  const [isPanelTwoActive, setIsPanelTwoActive] = useState(false);
  const [panelTwoMode, setPanelTwoMode] = useState("notes");
  const [panelTwoData, setPanelTwoData] = useState(null);
  const [importPanelActive, setImportPanelActive] = useState(false);
  const [panelOneItem, setPanelOneItem] = useState(null);
  // will be used to show/hide transform button
  const [selectedAssets, setSelectedAssets] = useState([]);
  const toggleImportModal = () => setIsImportModalOpen((prev) => !prev);
  const [clearFlag, setClearFlag] = useState(false);
  const { libraryData, libraryLoading } = useSelector((state) => state.library);

  const [libItems, setLibItems] = useState(libraryData);
  const [libLoading, setLibLoading] = useState(libraryLoading);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isImportModalOpen) {
      // reset scroll position
      window.scrollTo(0, 0);
    }
  }, [isImportModalOpen]);
  const importToComposer = async () => {
    if (selectedAssets.length === 0) return;

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/library/import-to-composer`,
      {
        assetsToImport: selectedAssets,
        profileId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (data.status !== true) {
      failAlert({
        title: "Failed to import. Please try again.",
      });
      return;
    }

    successAlert({
      title: data.importType === "twitterThread" ? "Successfully imported" : "Successfully imported to composer",
      timer: 1500,
      willClose: () => {
        if (data.importType === "twitterThread") {
          navigate(`/staging/${data.track.id}`);
        } else {
          window.location.href = `/composer/${data.track.id}`;
        }
      },
    });
  };
  const handleClear = () => {
    setSelectedAssets([]);
    setClearFlag(true);
  };
  useEffect(() => {
    const fetchData2 = async () => {
      console.log("fetching data getInfiniteLibraryData");
      const result = await dispatch(getInfiniteLibraryData(profileId, false));
      if (!result) {
        navigate("/login");
      }
    };
    fetchData2();
  }, [profileId]);

  return (
    <div className="container overflow-y-hidden">
      <PostSigninNavbar />
      {isImportModalOpen && <ImportToLibraryModal toggleModal={toggleImportModal} />}
      <LibraryTopBar
        openModal={toggleImportModal}
        totalSelectedAssets={selectedAssets.length}
        setSelectedAssets={setSelectedAssets}
        setImportPanelActive={setImportPanelActive}
        setIsPanelOneActive={setIsPanelOneActive}
        setIsPanelTwoActive={setIsPanelTwoActive}
      />
      {/* <div className="bodydiv panel-one-active panel-two-active"> */}
      <ErrorBoundary>
        <div
          className={`bodydiv ${isPanelOneActive && "panel-one-active"} ${isPanelTwoActive && "panel-two-active"} ${
            importPanelActive && "panel-import-active"
          }`}
        >
          <div className="container">
            <div className="content">
              <div className="content-inside">
                <LibraryMasonry
                  setSelectedAssets={setSelectedAssets}
                  clearFlag={clearFlag}
                  setClearFlag={setClearFlag}
                  selectedAssets={selectedAssets}
                  isPanelOneActive={isPanelOneActive}
                  setIsPanelOneActive={setIsPanelOneActive}
                  isPanelTwoActive={isPanelTwoActive}
                  setIsPanelTwoActive={setIsPanelTwoActive}
                  setImportPanelActive={setImportPanelActive}
                  panelOneItem={panelOneItem}
                  setPanelOneItem={setPanelOneItem}
                />
                {isPanelOneActive && (
                  <PanelOne
                    setSelectedAssets={setSelectedAssets}
                    setIsPanelOneActive={setIsPanelOneActive}
                    setIsPanelTwoActive={setIsPanelTwoActive}
                    setPanelTwoData={setPanelTwoData}
                    panelTwoMode={panelTwoMode}
                    setPanelTwoMode={setPanelTwoMode}
                    panelOneItem={panelOneItem}
                    setPanelOneItem={setPanelOneItem}
                    profileId={profileId}
                    importToComposer={importToComposer}
                  />
                )}
                {isPanelTwoActive && (
                  <PanelTwo
                    setSelectedAssets={setSelectedAssets}
                    setIsPanelTwoActive={setIsPanelTwoActive}
                    panelTwoData={panelTwoData}
                    setPanelTwoData={setPanelTwoData}
                    panelTwoMode={panelTwoMode}
                    setPanelTwoMode={setPanelTwoMode}
                  />
                )}
                {importPanelActive && (
                  <ImportPanel
                    setSelectedAssets={setSelectedAssets}
                    setImportPanelActive={setImportPanelActive}
                    setIsPanelOneActive={setIsPanelOneActive}
                    setIsPanelTwoActive={setIsPanelTwoActive}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
      {selectedAssets.length < 0 && (
        <LibraryBottomBar
          handleClear={handleClear}
          totalSelectedAssets={selectedAssets.length}
          importToComposer={importToComposer}
        />
      )}
    </div>
  );
};

export default Library;
